'use strict';

var _ = require('lodash');

function init() {

  function language() {
    return language;
  }

  language.speechCode = 'en-US';
  language.lang = 'en';

  language.languages = [
    { code: 'bg', language: 'Български', speechCode: 'bg-BG' }
    , { code: 'cs', language: 'Čeština', speechCode: 'cs-CZ' }
    , { code: 'de', language: 'Deutsch', speechCode: 'de-DE' }
    , { code: 'dk', language: 'Dansk', speechCode: 'dk-DK' }
    , { code: 'el', language: 'Ελληνικά', speechCode: 'el-GR'}
    , { code: 'en', language: 'English', speechCode: 'en-US' }
    , { code: 'es', language: 'Español', speechCode: 'es-ES' }
    , { code: 'fi', language: 'Suomi', speechCode: 'fi-FI' }
    , { code: 'fr', language: 'Français', speechCode: 'fr-FR' }
    , { code: 'he', language: 'עברית', speechCode: 'he-IL' }
    , { code: 'hr', language: 'Hrvatski', speechCode: 'hr-HR' }
    , { code: 'hu', language: 'Magyar', speechCode: 'hu-HU' }
    , { code: 'it', language: 'Italiano', speechCode: 'it-IT' }
    , { code: 'ja', language: '日本語', speechCode: 'ja-JP' }
    , { code: 'ko', language: '한국어', speechCode: 'ko-KR' }
    , { code: 'nb', language: 'Norsk (Bokmål)', speechCode: 'no-NO' }
    , { code: 'nl', language: 'Nederlands', speechCode: 'nl-NL' }
    , { code: 'pl', language: 'Polski', speechCode: 'pl-PL' }
    , { code: 'pt', language: 'Português (Brasil)', speechCode: 'pt-BR' }
    , { code: 'ro', language: 'Română', speechCode: 'ro-RO' }
    , { code: 'ru', language: 'Русский', speechCode: 'ru-RU' }
    , { code: 'sk', language: 'Slovenčina', speechCode: 'sk-SK' }
    , { code: 'sv', language: 'Svenska', speechCode: 'sv-SE' }
    , { code: 'tr', language: 'Türkçe', speechCode: 'tr-TR' }
    , { code: 'zh_cn', language: '中文（简体）', speechCode: 'cmn-Hans-CN' }
    , { code: 'zh_tw', language: '中文（繁體）', speechCode: 'cmn-Hant-TW' }
  ];

  var translations = {
    // Server
    'Listening on port' : {
      cs: 'Poslouchám na portu'
      ,es: 'Escuchando en el puerto'
      ,dk: 'Lytter på port'
      ,fr: 'Ecoute sur port'
      ,pt: 'Escutando porta'
      ,sv: 'Lyssnar på port'
      ,ro: 'Activ pe portul'
      ,el: 'Σύνδεση στην πόρτα'
      ,bg: 'Активиране на порта'
      ,hr: 'Slušanje na portu'
      ,it: 'Porta in ascolto'
      ,ja: '接続可能'
      ,fi: 'Kuuntelen porttia'
      ,nb: 'Lytter på port'
      ,he: 'מקשיב על פתחה'
      ,pl: 'Słucham na porcie'
      ,ru: 'Прослушивание порта'
      ,sk: 'Načúvam na porte'
      ,de: 'Lauscht auf Port'
      ,nl: 'Luistert op poort'
      ,ko: '포트에서 수신'
      ,tr: 'Port dinleniyor'
      ,zh_cn: '正在监听端口'
      ,hu: 'Port figyelése'
    }
    // Client
    ,'Mo' : {
      cs: 'Po'
      ,de: 'Mo'
      ,es: 'Lu'
      ,fr: 'Lu'
      ,el: 'Δε'
      ,pt: 'Seg'
      ,sv: 'Mån'
      ,ro: 'Lu'
      ,bg: 'Пон'
      ,hr: 'Pon'
      ,it: 'Lun'
      ,ja: '月'
      ,dk: 'Man'
      ,fi: 'Ma'
      ,nb: 'Man'
      ,he: 'ב'
      ,pl: 'Pn'
      ,ru: 'Пон'
      ,sk: 'Po'
      ,nl: 'Ma'
      ,ko: '월'
      ,tr: 'Pzt'
      ,zh_cn: '一'
      ,hu: 'Hé'
     }
    ,'Tu' : {
      cs: 'Út'
      ,de: 'Di'
      ,es: 'Mar'
      ,fr: 'Ma'
      ,el: 'Τρ'
      ,pt: 'Ter'
      ,sv: 'Tis'
      ,ro: 'Ma'
      ,bg: 'Вт'
      ,hr: 'Uto'
      ,it: 'Mar'
      ,ja: '火'
      ,dk: 'Tir'
      ,fi: 'Ti'
      ,nb: 'Tir'
      ,he: 'ג'
      ,pl: 'Wt'
      ,ru: 'Вт'
      ,sk: 'Ut'
      ,nl: 'Di'
      ,ko: '화'
      ,tr: 'Sal'
      ,zh_cn: '二'
      ,hu: 'Ke'
      }
    ,'We' : {
      cs: 'St'
      ,de: 'Mi'
      ,es: 'Mie'
      ,fr: 'Me'
      ,el: 'Τε'
      ,pt: 'Qua'
      ,sv: 'Ons'
      ,ro: 'Mie'
      ,bg: 'Ср'
      ,hr: 'Sri'
      ,it: 'Mer'
      ,ja: '水'
      ,dk: 'Ons'
      ,fi: 'Ke'
      ,nb: 'Ons'
      ,he: 'ד'
      ,pl: 'Śr'
      ,ru: 'Ср'
      ,sk: 'St'
      ,nl: 'Wo'
      ,ko: '수'
      ,tr: 'Çar'
      ,zh_cn: '三'
      ,hu: 'Sze'
      }
    ,'Th' : {
      cs: 'Čt'
      ,de: 'Do'
      ,es: 'Jue'
      ,fr: 'Je'
      ,el: 'Πε'
      ,pt: 'Qui'
      ,sv: 'Tor'
      ,ro: 'Jo'
      ,bg: 'Четв'
      ,hr: 'Čet'
      ,it: 'Gio'
      ,ja: '木'
      ,dk: 'Tor'
      ,fi: 'To'
      ,nb: 'Tor'
      ,he: 'ה'
      ,pl: 'Cz'
      ,ru: 'Чт'
      ,sk: 'Št'
      ,nl: 'Do'
      ,ko: '목'
      ,tr: 'Per'
      ,zh_cn: '四'
      ,hu: 'Csü'
      }
    ,'Fr' : {
      cs: 'Pá'
      ,de: 'Fr'
      ,es: 'Vie'
      ,fr: 'Ve'
      ,el: 'Πα'
      ,pt: 'Sex'
      ,sv: 'Fre'
      ,ro: 'Vi'
      ,bg: 'Пет'
      ,hr: 'Pet'
      ,it: 'Ven'
      ,ja: '金'
      ,dk: 'Fre'
      ,fi: 'Pe'
      ,nb: 'Fre'
      ,he: 'ו'
      ,pl: 'Pt'
      ,ru: 'Пт'
      ,sk: 'Pi'
      ,nl: 'Vr'
      ,ko: '금'
      ,tr: 'Cum'
      ,zh_cn: '五'
      ,hu: 'Pé'
      }
    ,'Sa' : {
      cs: 'So'
      ,de: 'Sa'
      ,es: 'Sab'
      ,fr: 'Sa'
      ,el: 'Σα'
      ,pt: 'Sab'
      ,sv: 'Lör'
      ,ro: 'Sa'
      ,bg: 'Съб'
      ,hr: 'Sub'
      ,it: 'Sab'
      ,ja: '土'
      ,dk: 'Lør'
      ,fi: 'La'
      ,nb: 'Lør'
      ,he: 'ש'
      ,pl: 'So'
      ,ru: 'Сб'
      ,sk: 'So'
      ,nl: 'Za'
      ,ko: '토'
      ,tr: 'Cmt'
      ,zh_cn: '六'
      ,hu: 'Szo'
      }
    ,'Su' : {
      cs: 'Ne'
      ,de: 'So'
      ,es: 'Dom'
      ,fr: 'Di'
      ,el: 'Κυ'
      ,pt: 'Dom'
      ,sv: 'Sön'
      ,ro: 'Du'
      ,bg: 'Нед'
      ,hr: 'Ned'
      ,it: 'Dom'
      ,ja: '日'
      ,dk: 'Søn'
      ,fi: 'Su'
      ,nb: 'Søn'
      ,he: 'א'
      ,pl: 'Nd'
      ,ru: 'Вс'
      ,sk: 'Ne'
      ,nl: 'Zo'
      ,ko: '일'
      ,tr: 'Paz'
      ,zh_cn: '日'
      ,hu: 'Vas'
      }
    ,'Monday' : {
      cs: 'Pondělí'
      ,de: 'Montag'
      ,es: 'Lunes'
      ,fr: 'Lundi'
      ,el: 'Δευτέρα'
      ,pt: 'Segunda'
      ,sv: 'Måndag'
      ,ro: 'Luni'
      ,bg: 'Понеделник'
      ,hr: 'Ponedjeljak'
      ,it: 'Lunedì'
      ,ja: '月曜日'
      ,dk: 'Mandag'
      ,fi: 'Maanantai'
      ,nb: 'Mandag'
      ,he: 'שני'
      ,pl: 'Poniedziałek'
      ,ru: 'Понедельник'
      ,sk: 'Pondelok'
      ,nl: 'Maandag'
      ,ko: '월요일'
      ,tr: 'Pazartesi'
      ,zh_cn: '星期一'
      ,hu: 'Hétfő'
      }
    ,'Tuesday' : {
      cs: 'Úterý'
      ,de: 'Dienstag'
      ,es: 'Martes'
      ,fr: 'Mardi'
      ,el: 'Τρίτη'
      ,pt: 'Terça'
      ,ro: 'Marți'
      ,bg: 'Вторник'
      ,hr: 'Utorak'
      ,sv: 'Tisdag'
      ,it: 'Martedì'
      ,ja: '火曜日'
      ,dk: 'Tirsdag'
      ,fi: 'Tiistai'
      ,nb: 'Tirsdag'
      ,he: 'שלישי'
      ,pl: 'Wtorek'
      ,ru: 'Вторник'
      ,sk: 'Utorok'
      ,nl: 'Dinsdag'
      ,ko: '화요일'
      ,tr: 'Salı'
      ,zh_cn: '星期二'
      ,hu: 'Kedd'
      }
    ,'Wednesday' : {
      cs: 'Středa'
      ,de: 'Mittwoch'
      ,es: 'Miércoles'
      ,fr: 'Mercredi'
      ,el: 'Τετάρτη'
      ,pt: 'Quarta'
      ,sv: 'Onsdag'
      ,ro: 'Miercuri'
      ,bg: 'Сряда'
      ,hr: 'Srijeda'
      ,it: 'Mercoledì'
      ,ja: '水曜日'
      ,dk: 'Onsdag'
      ,fi: 'Keskiviikko'
      ,nb: 'Onsdag'
      ,he: 'רביעי'
      ,pl: 'Środa'
      ,ru: 'Среда'
      ,sk: 'Streda'
      ,nl: 'Woensdag'
      ,ko: '수요일'
      ,tr: 'Çarşamba'
      ,zh_cn: '星期三'
      ,hu: 'Szerda'
      }
    ,'Thursday' : {
      cs: 'Čtvrtek'
      ,de: 'Donnerstag'
      ,es: 'Jueves'
      ,fr: 'Jeudi'
      ,el: 'Πέμπτη'
      ,pt: 'Quinta'
      ,sv: 'Torsdag'
      ,ro: 'Joi'
      ,bg: 'Четвъртък'
      ,hr: 'Četvrtak'
      ,it: 'Giovedì'
      ,ja: '木曜日'
      ,dk: 'Torsdag'
      ,fi: 'Torstai'
      ,nb: 'Torsdag'
      ,he: 'חמישי'
      ,pl: 'Czwartek'
      ,ru: 'Четверг'
      ,sk: 'Štvrtok'
      ,nl: 'Donderdag'
      ,ko: '목요일'
      ,tr: 'Perşembe'
      ,zh_cn: '星期四'
      ,hu: 'Csütörtök'
      }
    ,'Friday' : {
      cs: 'Pátek'
      ,de: 'Freitag'
      ,fr: 'Vendredi'
      ,el: 'Παρασκευή'
      ,pt: 'Sexta'
      ,sv: 'Fredag'
      ,ro: 'Vineri'
      ,es: 'Viernes'
      ,bg: 'Петък'
      ,hr: 'Petak'
      ,it: 'Venerdì'
      ,ja: '金曜日'
      ,dk: 'Fredag'
      ,fi: 'Perjantai'
      ,nb: 'Fredag'
      ,he: 'שישי'
      ,pl: 'Piątek'
      ,ru: 'Пятница'
      ,sk: 'Piatok'
      ,nl: 'Vrijdag'
      ,ko: '금요일'
      ,tr: 'Cuma'
      ,zh_cn: '星期五'
      ,hu: 'Péntek'
      }
    ,'Saturday' : {
      cs: 'Sobota'
      ,de: 'Samstag'
      ,es: 'Sábado'
      ,fr: 'Samedi'
      ,el: 'Σάββατο'
      ,pt: 'Sábado'
      ,ro: 'Sâmbătă'
      ,bg: 'Събота'
      ,hr: 'Subota'
      ,sv: 'Lördag'
      ,it: 'Sabato'
      ,ja: '土曜日'
      ,dk: 'Lørdag'
      ,fi: 'Lauantai'
      ,nb: 'Lørdag'
      ,he: 'שבת'
      ,pl: 'Sobota'
      ,ru: 'Суббота'
      ,sk: 'Sobota'
      ,nl: 'Zaterdag'
      ,ko: '토요일'
      ,tr: 'Cumartesi'
      ,zh_cn: '星期六'
      ,hu: 'Szombat'
      }
    ,'Sunday' : {
      cs: 'Neděle'
      ,de: 'Sonntag'
      ,es: 'Domingo'
      ,fr: 'Dimanche'
      ,el: 'Κυριακή'
      ,pt: 'Domingo'
      ,ro: 'Duminică'
      ,bg: 'Неделя'
      ,hr: 'Nedjelja'
      ,sv: 'Söndag'
      ,it: 'Domenica'
      ,ja: '日曜日'
      ,dk: 'Søndag'
      ,fi: 'Sunnuntai'
      ,nb: 'Søndag'
      ,he: 'ראשון'
      ,pl: 'Niedziela'
      ,ru: 'Воскресенье'
      ,sk: 'Nedeľa'
      ,nl: 'Zondag'
      ,ko: '일요일'
      ,tr: 'Pazar'
      ,zh_cn: '星期日'
      ,hu: 'Vasárnap'
      }
    ,'Category' : {
      cs: 'Kategorie'
      ,de: 'Kategorie'
      ,es: 'Categoría'
      ,fr: 'Catégorie'
      ,el: 'Κατηγορία'
      ,pt: 'Categoria'
      ,sv: 'Kategori'
      ,ro: 'Categorie'
      ,bg: 'Категория'
      ,hr: 'Kategorija'
      ,it:'Categoria'
      ,ja: 'カテゴリー'
      ,dk: 'Kategori'
      ,fi: 'Luokka'
      ,nb: 'Kategori'
      ,he: 'קטגוריה'
      ,pl: 'Kategoria'
      ,ru: 'Категория'
      ,sk: 'Kategória'
      ,nl: 'Categorie'
      ,ko: '분류'
      ,tr: 'Kategori'
      ,zh_cn: '类别'
      ,hu: 'Kategória'
      }
    ,'Subcategory' : {
      cs: 'Podkategorie'
      ,de: 'Unterkategorie'
      ,es: 'Subcategoría'
      ,fr: 'Sous-catégorie'
      ,el: 'Υποκατηγορία'
      ,pt: 'Subcategoria'
      ,sv: 'Underkategori'
      ,ro: 'Subcategorie'
      ,bg: 'Подкатегория'
      ,hr: 'Podkategorija'
      ,it: 'Sottocategoria'
      ,ja: 'サブカテゴリー'
      ,dk: 'Underkategori'
      ,fi: 'Alaluokka'
      ,nb: 'Underkategori'
      ,he: 'תת-קטגוריה'
      ,pl: 'Podkategoria'
      ,ru: 'Подкатегория'
      ,sk: 'Podkategória'
      ,nl: 'Subcategorie'
      ,ko: '세부 분류'
      ,tr: 'Altkategori'
      ,zh_cn: '子类别'
      ,hu: 'Alkategória'
      }
    ,'Name' : {
      cs: 'Jméno'
      ,de: 'Name'
      ,es: 'Nombre'
      ,fr: 'Nom'
      ,el: 'Όνομα'
      ,pt: 'Nome'
      ,sv: 'Namn'
      ,ro: 'Nume'
      ,bg: 'Име'
      ,hr: 'Ime'
      ,it: 'Nome'
      ,ja: '名前'
      ,dk: 'Navn'
      ,fi: 'Nimi'
      ,nb: 'Navn'
      ,he: 'שם'
      ,pl: 'Imie'
      ,ru: 'Имя'
      ,sk: 'Meno'
      ,nl: 'Naam'
      ,ko: '프로파일 명'
      ,tr: 'İsim'
      ,zh_cn: '名称'
      ,hu: 'Név'
      }
    ,'Today' : {
      cs: 'Dnes'
      ,de: 'Heute'
      ,es: 'Hoy'
      ,fr: 'Aujourd\'hui'
      ,el: 'Σήμερα'
      ,pt: 'Hoje'
      ,ro: 'Astăzi'
      ,bg: 'Днес'
      ,hr: 'Danas'
      ,sv: 'Idag'
      ,it: 'Oggi'
      ,ja: '今日'
      ,dk: 'I dag'
      ,fi: 'Tänään'
      ,nb: 'Idag'
      ,he: 'היום'
      ,pl: 'Dziś'
      ,ru: 'Сегодня'
      ,sk: 'Dnes'
      ,nl: 'Vandaag'
      ,ko: '오늘'
      ,tr: 'Bugün'
      ,zh_cn: '今天'
      ,hu: 'Ma'
      }
    ,'Last 2 days' : {
      cs: 'Poslední 2 dny'
      ,de: 'Letzten 2 Tage'
      ,es: 'Últimos 2 días'
      ,fr: '2 derniers jours'
      ,el: 'Τελευταίες 2 μέρες'
      ,pt: 'Últimos 2 dias'
      ,ro: 'Ultimele 2 zile'
      ,bg: 'Последните 2 дни'
      ,hr: 'Posljednja 2 dana'
      ,sv: 'Senaste 2 dagarna'
      ,it: 'Ultimi 2 giorni'
      ,ja: '直近の2日間'
      ,dk: 'Sidste 2 dage'
      ,fi: 'Edelliset 2 päivää'
      ,nb: 'Siste 2 dager'
      ,he: 'יומיים אחרונים'
      ,pl: 'Ostatnie 2 dni'
      ,ru: 'Прошедшие 2 дня'
      ,sk: 'Posledné 2 dni'
      ,nl: 'Afgelopen 2 dagen'
      ,ko: '지난 2일'
      ,tr: 'Son 2 gün'
      ,zh_cn: '过去2天'
      ,hu: 'Utolsó 2 nap'
      }
    ,'Last 3 days' : {
      cs: 'Poslední 3 dny'
      ,de: 'Letzten 3 Tage'
      ,es: 'Últimos 3 días'
      ,fr: '3 derniers jours'
      ,el: 'Τελευταίες 3 μέρες'
      ,pt: 'Últimos 3 dias'
      ,sv: 'Senaste 3 dagarna'
      ,ro: 'Ultimele 3 zile'
      ,bg: 'Последните 3 дни'
      ,hr: 'Posljednja 3 dana'
      ,it: 'Ultimi 3 giorni'
      ,ja: '直近の3日間'
      ,dk: 'Sidste 3 dage'
      ,fi: 'Edelliset 3 päivää'
      ,nb: 'Siste 3 dager'
      ,he: 'שלושה ימים אחרונים'
      ,pl: 'Ostatnie 3 dni'
      ,ru: 'Прошедшие 3 дня'
      ,sk: 'Posledné 3 dni'
      ,nl: 'Afgelopen 3 dagen'
      ,ko: '지난 3일'
      ,tr: 'Son 3 gün'
      ,zh_cn: '过去3天'
      ,hu: 'Utolsó 3 nap'
      }
    ,'Last week' : {
      cs: 'Poslední týden'
      ,de: 'Letzte Woche'
      ,es: 'Semana pasada'
      ,fr: 'Semaine Dernière'
      ,el: 'Τελευταία εβδομάδα'
      ,pt: 'Semana passada'
      ,ro: 'Săptămâna trecută'
      ,bg: 'Последната седмица'
      ,hr: 'Protekli tjedan'
      ,sv: 'Senaste veckan'
      ,it: 'Settimana scorsa'
      ,ja: '直近の1週間'
      ,dk: 'Sidste uge'
      ,fi: 'Viime viikko'
      ,nb: 'Siste uke'
      ,he: 'שבוע אחרון'
      ,pl: 'Ostatni tydzień'
      ,ru: 'Прошедшая неделя'
      ,sk: 'Posledný týždeň'
      ,nl: 'Afgelopen week'
      ,ko: '지난주'
      ,tr: 'Geçen Hafta'
      ,zh_cn: '上周'
      ,hu: 'Előző hét'
      }
    ,'Last 2 weeks' : {
      cs: 'Poslední 2 týdny'
      ,de: 'Letzten 2 Wochen'
      ,es: 'Últimas 2 semanas'
      ,fr: '2 dernières semaines'
      ,el: 'Τελευταίες 2 εβδομάδες'
      ,pt: 'Últimas 2 semanas'
      ,ro: 'Ultimele 2 săptămâni'
      ,bg: 'Последните 2 седмици'
      ,hr: 'Protekla 2 tjedna'
      ,sv: 'Senaste 2 veckorna'
      ,it: 'Ultime 2 settimane'
      ,ja: '直近の2週間'
      ,dk: 'Sidste 2 uger'
      ,fi: 'Viimeiset 2 viikkoa'
      ,nb: 'Siste 2 uker'
      ,he: 'שבועיים אחרונים'
      ,pl: 'Ostatnie 2 tygodnie'
      ,ru: 'Прошедшие 2 недели'
      ,sk: 'Posledné 2 týždne'
      ,nl: 'Afgelopen 2 weken'
      ,ko: '지난 2주'
      ,tr: 'Son 2 hafta'
      ,zh_cn: '过去2周'
      ,hu: 'Előző 2 hét'
      }
    ,'Last month' : {
      cs: 'Poslední měsíc'
      ,de: 'Letzter Monat'
      ,es: 'Mes pasado'
      ,fr: 'Mois dernier'
      ,el: 'Τελευταίος μήνας'
      ,pt: 'Mês passado'
      ,ro: 'Ultima lună'
      ,bg: 'Последният месец'
      ,hr: 'Protekli mjesec'
      ,sv: 'Senaste månaden'
      ,it: 'Mese scorso'
      ,ja: '直近の1ヶ月'
      ,dk: 'Sidste måned'
      ,fi: 'Viime kuu'
      ,nb: 'Siste måned'
      ,he: 'חודש אחרון'
      ,pl: 'Ostatni miesiąc'
      ,ru: 'Прошедший месяц'
      ,sk: 'Posledný mesiac'
      ,nl: 'Afgelopen maand'
      ,ko: '지난달'
      ,tr: 'Geçen Ay'
      ,zh_cn: '上个月'
      ,hu: 'Előző hónap'
      }
    ,'Last 3 months' : {
      cs: 'Poslední 3 měsíce'
      ,de: 'Letzten 3 Monate'
      ,es: 'Últimos 3 meses'
      ,fr: '3 derniers mois'
      ,el: 'Τελευταίοι 3 μήνες'
      ,pt: 'Últimos 3 meses'
      ,ro: 'Ultimele 3 luni'
      ,bg: 'Последните 3 месеца'
      ,hr: 'Protekla 3 mjeseca'
      ,sv: 'Senaste 3 månaderna'
      ,it: 'Ultimi 3 mesi'
      ,ja: '直近の3ヶ月'
      ,dk: 'Sidste 3 måneder'
      ,fi: 'Viimeiset 3 kuukautta'
      ,nb: 'Siste 3 måneder'
      ,he: 'שלושה חודשים אחרונים'
      ,pl: 'Ostatnie 3 miesiące'
      ,ru: 'Прошедшие 3 месяца'
      ,sk: 'Posledné 3 mesiace'
      ,nl: 'Afgelopen 3 maanden'
      ,ko: '지난 3달'
      ,tr: 'Son 3 ay'
      ,zh_cn: '过去3个月'
      ,hu: 'Előző 3 hónap'
      }
    , 'between': {
      cs: 'between'
      ,de: 'between'
      ,es: 'between'
      ,fr: 'between'
      ,el: 'between'
      ,pt: 'between'
      ,sv: 'between'
      ,ro: 'between'
      ,bg: 'between'
      ,hr: 'between'
      ,it: 'between'
      ,ja: 'between'
      ,dk: 'between'
      ,fi: 'between'
      ,nb: 'between'
      ,he: 'between'
      ,pl: 'between'
      ,ru: 'между'
      ,sk: 'between'
      ,nl: 'tussen'
      ,ko: 'between'
      ,tr: 'between'
      ,zh_cn: 'between'
      ,hu: 'között'
    }
    , 'around': {
      cs: 'around'
      ,de: 'around'
      ,es: 'around'
      ,fr: 'around'
      ,el: 'around'
      ,pt: 'around'
      ,sv: 'around'
      ,ro: 'around'
      ,bg: 'around'
      ,hr: 'around'
      ,it: 'around'
      ,ja: 'around'
      ,dk: 'around'
      ,fi: 'around'
      ,nb: 'around'
      ,he: 'around'
      ,pl: 'around'
      ,ru: 'около'
      ,sk: 'around'
      ,nl: 'rond'
      ,ko: 'around'
      ,tr: 'around'
      ,zh_cn: 'around'
      ,hu: 'körülbelül'
    }
    , 'and': {
      cs: 'and'
      ,de: 'and'
      ,es: 'and'
      ,fr: 'and'
      ,el: 'and'
      ,pt: 'and'
      ,sv: 'and'
      ,ro: 'and'
      ,bg: 'and'
      ,hr: 'and'
      ,it: 'and'
      ,ja: 'and'
      ,dk: 'and'
      ,fi: 'and'
      ,nb: 'and'
      ,he: 'and'
      ,pl: 'and'
      ,ru: 'и'
      ,sk: 'and'
      ,nl: 'en'
      ,ko: 'and'
      ,tr: 'and'
      ,zh_cn: 'and'
      ,hu: 'és'
    }
    ,'From' : {
      cs: 'Od'
      ,de: 'Von'
      ,es: 'Desde'
      ,fr: 'Du'
      ,el: 'Από'
      ,pt: 'De'
      ,sv: 'Från'
      ,ro: 'De la'
      ,bg: 'От'
      ,hr: 'Od'
      ,it: 'Da'
      ,ja: '開始日'
      ,dk: 'Fra'
      ,fi: 'Alkaen'
      ,nb: 'Fra'
      ,he: 'מ'
      ,pl: 'Od'
      ,ru: 'С'
      ,sk: 'Od'
      ,nl: 'Van'
      ,ko: '시작일'
      ,tr: 'Başlangıç'
      ,zh_cn: '从'
      ,hu: 'Tól'
      }
    ,'To' : {
      cs: 'Do'
      ,de: 'Bis'
      ,es: 'Hasta'
      ,fr: 'Au'
      ,el: 'Έως'
      ,pt: 'a'
      ,ro: 'La'
      ,bg: 'До'
      ,hr: 'Do'
      ,sv: 'Till'
      ,it: 'A'
      ,ja: '終了日'
      ,dk: 'Til'
      ,fi: 'Asti'
      ,nb: 'Til'
      ,he: 'עד'
      ,pl: 'Do'
      ,ru: 'По'
      ,sk: 'Do'
      ,nl: 'Tot'
      ,ko: '종료일'
      ,tr: 'Bitiş'
      ,zh_cn: '到'
      ,hu: 'Ig'
      }
    ,'Notes' : {
      cs: 'Poznámky'
      ,de: 'Notiz'
      ,es: 'Notas'
      ,fr: 'Notes'
      ,el: 'Σημειώσεις'
      ,pt: 'Notas'
      ,sv: 'Notering'
      ,ro: 'Note'
      ,bg: 'Бележки'
      ,hr: 'Bilješke'
      ,it: 'Note'
      ,ja: 'メモ'
      ,dk: 'Noter'
      ,fi: 'Merkinnät'
      ,nb: 'Notater'
      ,he: 'הערות'
      ,pl: 'Uwagi'
      ,ru: 'Примечания'
      ,sk: 'Poznámky'
      ,nl: 'Notities'
      ,ko: '메모'
      ,tr: 'Not'
      ,zh_cn: '记录'
      ,hu: 'Jegyzetek'
      }
    ,'Food' : {
      cs: 'Jídlo'
      ,de: 'Ernährung'
      ,es: 'Comida'
      ,fr: 'Nourriture'
      ,el: 'Φαγητό'
      ,pt: 'Comida'
      ,sv: 'Föda'
      ,ro: 'Mâncare'
      ,bg: 'Храна'
      ,hr: 'Hrana'
      ,it: 'Cibo'
      ,ja: '食事'
      ,dk: 'Mad'
      ,fi: 'Ruoka'
      ,nb: 'Mat'
      ,he: 'אוכל'
      ,pl: 'Jedzenie'
      ,ru: 'Еда'
      ,sk: 'Jedlo'
      ,nl: 'Voeding'
      ,ko: '음식'
      ,tr: 'Gıda'
      ,zh_cn: '食物'
      ,hu: 'Étel'
      }
    ,'Insulin' : {
      cs: 'Inzulín'
      ,de: 'Insulin'
      ,es: 'Insulina'
      ,fr: 'Insuline'
      ,el: 'Ινσουλίνη'
      ,pt: 'Insulina'
      ,ro: 'Insulină'
      ,bg: 'Инсулин'
      ,hr: 'Inzulin'
      ,sv: 'Insulin'
      ,it: 'Insulina'
      ,ja: 'インスリン'
      ,dk: 'Insulin'
      ,fi: 'Insuliini'
      ,nb: 'Insulin'
      ,he: 'אינסולין'
      ,pl: 'Insulina'
      ,ru: 'Инсулин'
      ,sk: 'Inzulín'
      ,nl: 'Insuline'
      ,ko: '인슐린'
      ,tr: 'İnsülin'
      ,zh_cn: '胰岛素'
      ,hu: 'Inzulin'
      }
    ,'Carbs' : {
      cs: 'Sacharidy'
      ,de: 'Kohlenhydrate'
      ,es: 'Hidratos de carbono'
      ,fr: 'Glucides'
      ,el: 'Υδατάνθρακες'
      ,pt: 'Carboidrato'
      ,ro: 'Carbohidrați'
      ,bg: 'Въглехидрати'
      ,hr: 'Ugljikohidrati'
      ,sv: 'Kolhydrater'
      ,it: 'Carboidrati'
      ,ja: '炭水化物'
      ,dk: 'Kulhydrater'
      ,fi: 'Hiilihydraatit'
      ,nb: 'Karbohydrater'
      ,he: 'פחמימות'
      ,pl: 'Węglowodany'
      ,ru: 'Углеводы'
      ,sk: 'Sacharidy'
      ,nl: 'Koolhydraten'
      ,ko: '탄수화물'
      ,tr: 'Karbonhidrat'
      ,zh_cn: '碳水化合物'
      ,hu: 'Szénhidrát'
      }
    ,'Notes contain' : {
      cs: 'Poznámky obsahují'
      ,de: 'Notizen enthalten'
      ,he: 'ההערות מכילות'
      ,es: 'Contenido de las notas'
      ,fr: 'Notes contiennent'
      ,el: 'Οι σημειώσεις περιέχουν'
      ,pt: 'Notas contém'
      ,ro: 'Conținut note'
      ,bg: 'бележките съдържат'
      ,hr: 'Sadržaj bilješki'
      ,sv: 'Notering innehåller'
      ,it: 'Contiene note'
      ,ja: 'メモ内容'
      ,dk: 'Noter indeholder'
      ,fi: 'Merkinnät sisältävät'
      ,nb: 'Notater inneholder'
      ,pl: 'Zawierają uwagi'
      ,ru: 'Примечания содержат'
      ,sk: 'Poznámky obsahujú'
      ,nl: 'Inhoud aantekening'
      ,ko: '메모 포함'
      ,tr: 'Notlar içerir'
      ,zh_cn: '记录包括'
      ,hu: 'Jegyzet tartalmazza'
      }
    ,'Target BG range bottom' : {
      cs: 'Cílová glykémie spodní'
      ,de: 'Vorgabe unteres BG-Ziel'
      ,es: 'Objetivo inferior de glucemia'
      ,fr: 'Limite inférieure glycémie'
      ,el: 'Στόχος γλυκόζης - Κάτω όριο'
      ,pt: 'Limite inferior de glicemia'
      ,ro: 'Limită de jos a glicemiei'
      ,bg: 'Долна граница на КЗ'
      ,hr: 'Ciljna donja granica GUK-a'
      ,sv: 'Gräns för nedre blodsockervärde'
      ,it: 'Limite inferiore della glicemia'
      ,ja: '目標血糖値　下限'
      ,dk: 'Nedre grænse for blodsukkerværdier'
      ,fi: 'Tavoitealueen alaraja'
      ,nb: 'Nedre grense for blodsukkerverdier'
      ,he: 'טווח מטרה סף תחתון'
      ,pl: 'Docelowy zakres glikemii, dolny'
      ,ru: 'Нижний порог целевых значений СК'
      ,sk: 'Cieľová glykémia spodná'
      ,nl: 'Ondergrens doelbereik glucose'
      ,ko: '최저 목표 혈당 범위'
      ,tr: 'Hedef KŞ aralığı düşük'
      ,zh_cn: '目标血糖范围 下限'
      ,hu: 'Alsó cukorszint határ'
      }
    ,'top' : {
      cs: 'horní'
      ,de: 'oben'
      ,es: 'Superior'
      ,fr: 'Supérieure'
      ,el: 'Πάνω όριο'
      ,pt: 'Superior'
      ,ro: 'Sus'
      ,bg: 'горна'
      ,hr: 'Gornja'
      ,sv: 'Toppen'
      ,it: 'Superiore'
      ,ja: '上限'
      ,dk: 'Top'
      ,fi: 'yläraja'
      ,nb: 'Topp'
      ,he: 'למעלה'
      ,pl: 'górny'
      ,ru: 'Верхний'
      ,sk: 'horná'
      ,nl: 'Top'
      ,ko: '최고치'
      ,tr: 'Üstü'
      ,zh_cn: '上限'
      ,hu: 'Felső'
      }
    ,'Show' : {
      cs: 'Zobraz'
      ,de: 'Zeigen'
      ,es: 'Mostrar'
      ,fr: 'Montrer'
      ,el: 'Εμφάνιση'
      ,pt: 'Mostrar'
      ,ro: 'Arată'
      ,sv: 'Visa'
      ,bg: 'Покажи'
      ,hr: 'Prikaži'
      ,it: 'Mostra'
      ,ja: '作成'
      ,dk: 'Vis'
      ,fi: 'Näytä'
      ,nb: 'Vis'
      ,he: 'הצג'
      ,pl: 'Pokaż'
      ,ru: 'Показать'
      ,sk: 'Ukáž'
      ,nl: 'Laat zien'
      ,ko: '확인'
      ,tr: 'Göster'
      ,zh_cn: '生成'
      ,hu: 'Mutasd'
      }
    ,'Display' : {
      cs: 'Zobraz'
      ,de: 'Anzeigen'
      ,es: 'Visualizar'
      ,fr: 'Afficher'
      ,el: 'Εμφάνιση'
      ,pt: 'Visualizar'
      ,ro: 'Afișează'
      ,bg: 'Покажи'
      ,hr: 'Prikaži'
      ,sv: 'Visa'
      ,it: 'Schermo'
      ,ja: '表示'
      ,dk: 'Vis'
      ,fi: 'Näyttö'
      ,nb: 'Vis'
      ,he: 'תצוגה'
      ,pl: 'Wyświetl'
      ,ru: 'Визуально'
      ,sk: 'Zobraz'
      ,nl: 'Weergeven'
      ,ko: '출력'
      ,tr: 'Görüntüle'
      ,zh_cn: '显示'
      ,hu: 'Ábrázol'
      }
    ,'Loading' : {
      cs: 'Nahrávám'
      ,de: 'Laden'
      ,es: 'Cargando'
      ,fr: 'Chargement'
      ,el: 'Ανάκτηση'
      ,pt: 'Carregando'
      ,ro: 'Se încarcă'
      ,bg: 'Зареждане'
      ,hr: 'Učitavanje'
      ,sv: 'Laddar'
      ,it: 'Carico'
      ,ja: 'ロード中'
      ,dk: 'Indlæser'
      ,fi: 'Lataan'
      ,nb: 'Laster'
      ,he: 'טוען'
      ,pl: 'Ładowanie'
      ,ru: 'Загрузка'
      ,sk: 'Nahrávam'
      ,nl: 'Laden'
      ,ko: '로딩'
      ,tr: 'Yükleniyor'
      ,zh_cn: '载入中'
      ,hu: 'Betöltés'
      }
    ,'Loading profile' : {
      cs: 'Nahrávám profil'
      ,de: 'Lade Profil'
      ,es: 'Cargando perfil'
      ,fr: 'Chargement du profil'
      ,el: 'Ανάκτηση Προφίλ'
      ,pt: 'Carregando perfil'
      ,sv: 'Laddar profil'
      ,ro: 'Încarc profilul'
      ,bg: 'Зареждане на профил'
      ,hr: 'Učitavanje profila'
      ,it: 'Carico il profilo'
      ,ja: 'プロフィールロード中'
      ,dk: 'Indlæser profil'
      ,fi: 'Lataan profiilia'
      ,nb: 'Leser profil'
      ,he: 'טוען פרופיל'
      ,pl: 'Ładowanie profilu'
      ,ru: 'Загрузка профиля'
      ,sk: 'Nahrávam profil'
      ,nl: 'Profiel laden'
      ,ko: '프로파일 로딩'
      ,tr: 'Profil yükleniyor'
      ,zh_cn: '载入配置文件'
      ,hu: 'Profil betöltése'
      }
    ,'Loading status' : {
      cs: 'Nahrávám status'
      ,de: 'Lade Status'
      ,es: 'Cargando estado'
      ,fr: 'Statut du chargement'
      ,el: 'Ανάκτηση Κατάστασης'
      ,pt: 'Carregando status'
      ,sv: 'Laddar status'
      ,ro: 'Încarc statusul'
      ,bg: 'Зареждане на статус'
      ,hr: 'Učitavanje statusa'
      ,it: 'Stato di caricamento'
      ,ja: 'ステータスロード中'
      ,dk: 'Indlæsnings status'
      ,fi: 'Lataan tilaa'
      ,nb: 'Leser status'
      ,he: 'טוען סטטוס'
      ,pl: 'Status załadowania'
      ,ru: 'Загрузка состояния'
      ,sk: 'Nahrávam status'
      ,nl: 'Laadstatus'
      ,ko: '상태 로딩'
      ,tr: 'Durum Yükleniyor'
      ,zh_cn: '载入状态'
      ,hu: 'Állapot betöltése'
      }
    ,'Loading food database' : {
      cs: 'Nahrávám databázi jídel'
      ,de: 'Lade Ernährungs-Datenbank'
      ,es: 'Cargando base de datos de alimentos'
      ,fr: 'Chargement de la base de données alimentaire'
      ,el: 'Ανάκτηση Βάσης Δεδομένων Φαγητών'
      ,pt: 'Carregando dados de alimentos'
      ,sv: 'Laddar födoämnesdatabas'
      ,ro: 'Încarc baza de date de alimente'
      ,bg: 'Зареждане на данни с храни'
      ,hr: 'Učitavanje baze podataka o hrani'
      ,it: 'Carico database alimenti'
      ,ja: '食事データベースロード中'
      ,dk: 'Indlæser mad database'
      ,fi: 'Lataan ruokatietokantaa'
      ,nb: 'Leser matdatabase'
      ,he: 'טוען נתוני אוכל'
      ,pl: 'Ładowanie bazy posiłków'
      ,ru: 'Загрузка БД продуктов'
      ,sk: 'Nahrávam databázu jedál'
      ,nl: 'Voeding database laden'
      ,ko: '음식 데이터 베이스 로딩'
      ,tr: 'Gıda veritabanı yükleniyor'
      ,zh_cn: '载入食物数据库'
      ,hu: 'Étel adatbázis betöltése'
      }
    ,'not displayed' : {
      cs: 'není zobrazeno'
      ,de: 'nicht angezeigt'
      ,es: 'No mostrado'
      ,fr: 'non affiché'
      ,el: 'Δεν προβάλλεται'
      ,pt: 'não mostrado'
      ,ro: 'neafișat'
      ,bg: 'Не се показва'
      ,hr: 'Ne prikazuje se'
      ,sv: 'Visas ej'
      ,it: 'Non visualizzato'
      ,ja: '表示できません'
      ,dk: 'Vises ikke'
      ,fi: 'ei näytetä'
      ,nb: 'Vises ikke'
      ,he: 'לא מוצג'
      ,pl: 'Nie jest wyświetlany'
      ,ru: 'Не показано'
      ,sk: 'Nie je zobrazené'
      ,nl: 'Niet weergegeven'
      ,ko: '출력되지 않음'
      ,tr: 'görüntülenmedi'
      ,zh_cn: '未显示'
      ,hu: 'nincs megjelenítve'
      }
    ,'Loading CGM data of' : {
      cs: 'Nahrávám CGM data'
      ,de: 'Lade CGM-Daten von'
      ,es: 'Cargando datos de CGM de'
      ,fr: 'Chargement données CGM de'
      ,el: 'Ανάκτηση δεδομένων CGM'
      ,pt: 'Carregando dados de CGM de'
      ,sv: 'Laddar CGM-data för'
      ,ro: 'Încarc datele CGM ale lui'
      ,bg: 'Зареждане на CGM данни от'
      ,hr: 'Učitavanja podataka CGM-a'
      ,it: 'Carico dati CGM'
      ,ja: 'CGMデータロード中'
      ,dk: 'Indlæser CGM-data for'
      ,fi: 'Lataan sensoritietoja: '
      ,nb: 'Leser CGM-data for'
      ,he: 'טוען נתוני חיישן סוכר של'
      ,pl: 'Ładowanie danych z CGM'
      ,ru: 'Загрузка данных мониторинга'
      ,sk: 'Nahrávam CGM dáta'
      ,nl: 'CGM data laden van'
      ,ko: 'CGM 데이터 로딩'
      ,tr: 'den CGM veriler yükleniyor'
      ,zh_cn: '载入CGM（连续血糖监测）数据从'
      ,hu: 'CGM adatok betöltése'
      }
    ,'Loading treatments data of' : {
      cs: 'Nahrávám data ošetření'
      ,de: 'Lade Behandlungsdaten von'
      ,es: 'Cargando datos de tratamientos de'
      ,fr: 'Chargement données traitement de'
      ,el: 'Ανάκτηση δεδομένων ενεργειών'
      ,pt: 'Carregando dados de tratamento de'
      ,sv: 'Laddar behandlingsdata för'
      ,ro: 'Încarc datele despre tratament pentru'
      ,bg: 'Зареждане на въведените лечения от'
      ,hr: 'Učitavanje podataka o tretmanu'
      ,it: 'Carico dati dei trattamenti'
      ,ja: '治療データロード中'
      ,dk: 'Indlæser behandlingsdata for'
      ,fi: 'Lataan toimenpidetietoja: '
      ,nb: 'Leser behandlingsdata for'
      ,he: 'טוען נתוני טיפולים של'
      ,pl: 'Ładowanie danych leczenia'
      ,ru: 'Загрузка данных лечения'
      ,sk: 'Nahrávam dáta ošetrenia'
      ,nl: 'Behandel gegevens laden van'
      ,ko: '처리 데이터 로딩'
      ,tr: 'dan Tedavi verilerini yükle'
      ,zh_cn: '载入操作数据从'
      ,hu: 'Kezelés adatainak betöltése'
      }
    ,'Processing data of' : {
      cs: 'Zpracovávám data'
      ,de: 'Verarbeite Daten von'
      ,es: 'Procesando datos de'
      ,fr: 'Traitement des données de'
      ,el: 'Επεξεργασία Δεδομένων'
      ,pt: 'Processando dados de'
      ,sv: 'Behandlar data för'
      ,ro: 'Procesez datele pentru'
      ,bg: 'Зареждане на данни от'
      ,hr: 'Obrada podataka'
      ,it: 'Elaborazione dei dati'
      ,ja: 'データ処理中の日付：'
      ,dk: 'Behandler data for'
      ,fi: 'Käsittelen tietoja: '
      ,nb: 'Behandler data for'
      ,he: 'מעבד נתונים של'
      ,pl: 'Przetwarzanie danych'
      ,ru: 'Обработка данных от'
      ,sk: 'Spracovávam dáta'
      ,nl: 'Gegevens verwerken van'
      ,ko: '데이터 처리 중'
      ,tr: 'dan Veri işleme'
      ,zh_cn: '处理数据从'
      ,hu: 'Adatok feldolgozása'
      }
    ,'Portion' : {
      cs: 'Porce'
      ,de: 'Abschnitt'
      ,es: 'Porción'
      ,fr: 'Portion'
      ,el: 'Μερίδα'
      ,pt: 'Porção'
      ,ro: 'Porție'
      ,bg: 'Порция'
      ,hr: 'Dio'
      ,sv: 'Portion'
      ,it: 'Porzione'
      ,ja: '一食分'
      ,dk: 'Portion'
      ,fi: 'Annos'
      ,nb: 'Porsjon'
      ,he: 'מנה'
      ,pl: 'Część'
      ,ru: 'Порция'
      ,sk: 'Porcia'
      ,nl: 'Portie'
      ,ko: '부분'
      ,tr: 'Porsiyon'
      ,zh_cn: '部分'
      ,hu: 'Porció'
      }
    ,'Size' : {
      cs: 'Rozměr'
      ,de: 'Größe'
      ,es: 'Tamaño'
      ,fr: 'Taille'
      ,el: 'Μέγεθος'
      ,pt: 'Tamanho'
      ,ro: 'Mărime'
      ,sv: 'Storlek'
      ,bg: 'Големина'
      ,hr: 'Veličina'
      ,it: 'Formato'
      ,ja: '量'
      ,dk: 'Størrelse'
      ,fi: 'Koko'
      ,nb: 'Størrelse'
      ,he: 'גודל'
      ,pl: 'Rozmiar'
      ,ru: 'Объем'
      ,sk: 'Veľkosť'
      ,nl: 'Grootte'
      ,ko: '크기'
      ,tr: 'Boyut'
      ,zh_cn: '大小'
      ,hu: 'Méret'
      }
    ,'(none)' : {
      cs: '(Žádný)'
      ,de: '(nichts)'
      ,es: '(ninguno)'
      ,fr: '(aucun)'
      ,el: '(κενό)'
      ,pt: '(nenhum)'
      ,sv: '(tom)'
      ,ro: '(fără)'
      ,bg: '(няма)'
      ,hr: '(Prazno)'
      ,it: '(Nessuno)'
      ,ja: '(データなし)'
      ,dk: '(ingen)'
      ,fi: '(tyhjä)'
      ,nb: '(ingen)'
      ,he: '(ללא)'
      ,pl: '(brak)'
      ,ru: '(нет)'
      ,sk: '(žiadny)'
      ,nl: '(geen)'
      ,ko: '(없음)'
      ,tr: '(hiç)'
      ,zh_cn: '(无)'
      ,zh_tw: '(無)'
      ,hu: '(semmilyen)'
      }
    ,'None' : {
      cs: 'Žádný'
      ,de: 'Nichts'
      ,es: 'Ninguno'
      ,fr: 'aucun'
      ,el: 'Κενό'
      ,pt: 'nenhum'
      ,sv: 'tom'
      ,ro: 'fără'
      ,bg: 'няма'
      ,hr: 'Prazno'
      ,it: 'Nessuno'
      ,ja: 'データなし'
      ,dk: 'Ingen'
      ,fi: 'tyhjä'
      ,nb: 'ingen'
      ,he: 'ללא'
      ,pl: 'brak'
      ,ru: 'Нет'
      ,sk: 'Žiadny'
      ,nl: 'geen'
      ,ko: '없음'
      ,tr: 'Hiç'
      ,zh_cn: '无'
      ,zh_tw: '無'
      ,hu: 'Semmilyen'
      }
    ,'<none>' : {
      cs: '<Žádný>'
      ,de: '<nichts>'
      ,es: '<ninguno>'
      ,fr: '<aucun>'
      ,el: '<κενό>'
      ,pt: '<nenhum>'
      ,sv: '<tom>'
      ,ro: '<fără>'
      ,bg: '<няма>'
      ,hr: '<Prazno>'
      ,it: '<Nessuno>'
      ,ja: '<データなし>'
      ,dk: '<ingen>'
      ,fi: '<tyhjä>'
      ,nb: '<ingen>'
      ,he: '<ללא>'
      ,pl: '<brak>'
      ,ru: '<нет>'
      ,sk: '<žiadny>'
      ,nl: '<Geen>'
      ,ko: '<없음>'
      ,tr: '<hiç>'
      ,zh_cn: '<无>'
      ,hu: '<semmilyen>'
      }
    ,'Result is empty' : {
      cs: 'Prázdný výsledek'
      ,de: 'Ergebnis ist leer'
      ,es: 'Resultado vacío'
      ,fr: 'Pas de résultat'
      ,el: 'Δεν υπάρχουν αποτελέσματα'
      ,pt: 'Resultado vazio'
      ,sv: 'Resultat saknas'
      ,ro: 'Fără rezultat'
      ,bg: 'Няма резултат'
      ,hr: 'Prazan rezultat'
      ,it: 'Risultato vuoto'
      ,ja: '結果がありません'
      ,dk: 'Tomt resultat'
      ,fi: 'Ei tuloksia'
      ,nb: 'Tomt resultat'
      ,he: 'אין תוצאה'
      ,pl: 'Brak wyniku'
      ,ru: 'Результата нет '
      ,sk: 'Prázdny výsledok'
      ,nl: 'Geen resultaat'
      ,ko: '결과 없음'
      ,tr: 'Sonuç boş'
      ,zh_cn: '结果为空'
      ,hu: 'Az eredmény üres'
      }
    ,'Day to day' : {
      cs: 'Den po dni'
      ,de: 'Von Tag zu Tag'
      ,es: 'Día a día'
      ,fr: 'jour par jour'
      ,el: 'Ανά ημέρα'
      ,pt: 'Dia a dia'
      ,sv: 'Dag för dag'
      ,ro: 'Zi cu zi'
      ,bg: 'Ден за ден'
      ,hr: 'Svakodnevno'
      ,it: 'Giorno per giorno'
      ,ja: '日差'
      ,dk: 'Dag til dag'
      ,fi: 'Päivittäinen'
      ,nb: 'Dag til dag'
      ,he: 'יום ביומו'
      ,pl: 'Dzień po dniu'
      ,ru: 'По дням'
      ,sk: 'Deň po dni'
      ,nl: 'Dag tot Dag'
      ,ko: '일별 그래프'
      ,tr: 'Günden Güne'
      ,zh_cn: '日到日'
      ,hu: 'Napi'
      }
    ,'Week to week' : {
      cs: 'Week to week'
      ,de: 'Woche zu Woche'
      ,es: 'Week to week'
      ,fr: 'Week to week'
      ,el: 'Week to week'
      ,pt: 'Week to week'
      ,sv: 'Week to week'
      ,ro: 'Week to week'
      ,bg: 'Week to week'
      ,hr: 'Tjedno'
      ,it: 'Week to week'
      ,dk: 'Week to week'
      ,fi: 'Week to week'
      ,nb: 'Week to week'
      ,he: 'Week to week'
      ,pl: 'Tydzień po tygodniu'
      ,ru: 'По неделям'
      ,sk: 'Week to week'
      ,nl: 'Week to week'
      ,ko: '주별 그래프'
      ,zh_cn: 'Week to week'
      ,hu: 'Heti'
      }
    ,'Daily Stats' : {
      cs: 'Denní statistiky'
      ,de: 'Tägliche Statistik'
      ,es: 'Estadísticas diarias'
      ,fr: 'Stats quotidiennes'
      ,el: 'Ημερήσια Στατιστικά'
      ,pt: 'Estatísticas diárias'
      ,sv: 'Dygnsstatistik'
      ,ro: 'Statistici zilnice'
      ,bg: 'Дневна статистика'
      ,hr: 'Dnevna statistika'
      ,it: 'Statistiche giornaliere'
      ,ja: '１日統計'
      ,dk: 'Daglig statistik'
      ,fi: 'Päivittäiset tilastot'
      ,nb: 'Daglig statistikk'
      ,he: 'סטטיסטיקה יומית'
      ,pl: 'Statystyki dzienne'
      ,ru: 'Ежедневная статистика'
      ,sk: 'Denné štatistiky'
      ,nl: 'Dagelijkse statistiek'
      ,ko: '일간 통계'
      ,tr: 'Günlük İstatistikler'
      ,zh_cn: '每日状态'
      ,hu: 'Napi statisztika'
      }
    ,'Percentile Chart' : {
      cs: 'Percentil'
      ,de: 'Perzentil-Diagramm'
      ,es: 'Percentiles'
      ,fr: 'Percentiles'
      ,el: 'Γράφημα Εκατοστημορίων'
      ,pt: 'Percentis'
      ,ro: 'Grafic percentile'
      ,bg: 'Процентна графика'
      ,hr: 'Percentili'
      ,sv: 'Procentgraf'
      ,it: 'Grafico percentile'
      ,ja: 'パーセント図'
      ,dk: 'Procentgraf'
      ,fi: 'Suhteellinen kuvaaja'
      ,nb: 'Prosentgraf'
      ,he: 'טבלת עשירונים'
      ,pl: 'Wykres percentyl'
      ,ru: 'Процентильная диаграмма'
      ,sk: 'Percentil'
      ,nl: 'Procentuele grafiek'
      ,ko: '백분위 그래프'
      ,tr: 'Yüzdelik Grafiği'
      ,zh_cn: '百分位图形'
      ,hu: 'Százalékos'
      }
    ,'Distribution' : {
      cs: 'Rozložení'
      ,de: 'Verteilung'
      ,es: 'Distribución'
      ,fr: 'Distribution'
      ,el: 'Κατανομή'
      ,pt: 'Distribuição'
      ,ro: 'Distribuție'
      ,bg: 'Разпределение'
      ,hr: 'Distribucija'
      ,sv: 'Distribution'
      ,it: 'Distribuzione'
      ,ja: '配分'
      ,dk: 'Distribution'
      ,fi: 'Jakauma'
      ,nb: 'Distribusjon'
      ,he: 'התפלגות'
      ,pl: 'Dystrybucja'
      ,ru: 'Распределение'
      ,sk: 'Distribúcia'
      ,nl: 'Verdeling'
      ,ko: '분포'
      ,tr: 'Dağılım'
      ,zh_cn: '分布'
      ,hu: 'Szétosztás'
      }
    ,'Hourly stats' : {
      cs: 'Statistika po hodinách'
      ,de: 'Stündliche Statistik'
      ,es: 'Estadísticas por hora'
      ,fr: 'Statistiques horaires'
      ,el: 'Ωριαία Στατιστικά'
      ,pt: 'Estatísticas por hora'
      ,sv: 'Timmstatistik'
      ,ro: 'Statistici orare'
      ,bg: 'Статистика по часове'
      ,hr: 'Statistika po satu'
      ,it: 'Statistiche per ore'
      ,ja: '１時間統計'
      ,dk: 'Timestatistik'
      ,fi: 'Tunneittainen tilasto'
      ,nb: 'Timestatistikk'
      ,he: 'סטטיסטיקה שעתית'
      ,pl: 'Statystyki godzinowe'
      ,ru: 'Почасовая статистика'
      ,sk: 'Hodinové štatistiky'
      ,nl: 'Statistieken per uur'
      ,ko: '시간대별 통계'
      ,tr: 'Saatlik istatistikler'
      ,zh_cn: '每小时状态'
      ,hu: 'Óránkra való szétosztás'
      }
    ,'netIOB stats': { // hourlystats.js
      nl: 'netIOB stats'
      ,sv: 'netIOB statistik'
      ,dk: 'netIOB statistik'
      ,he: 'netIOB סטטיסטיקת'
      ,de: 'netIOB Statistiken'
      ,fi: 'netIOB tilasto'
      ,bg: 'netIOB татистика'
      ,hr: 'netIOB statistika'
      , pl: 'Statystyki netIOP'
      ,ru: 'статистика нетто активн инс netIOB'
      ,tr: 'netIOB istatistikleri'
      ,hu: 'netIOB statisztika'
    }
    ,'temp basals must be rendered to display this report': { //hourlystats.js
      nl: 'tijdelijk basaal moet zichtbaar zijn voor dit rapport'
      ,sv: 'temp basal måste vara synlig för denna rapport'
      ,de: 'temporäre Basalraten müssen für diesen Report sichtbar sein'
      ,fi: 'tämä raportti vaatii, että basaalien piirto on päällä'
      ,bg: 'временните базали трябва да са показани за да се покаже тази това'
      ,hr: 'temp bazali moraju biti prikazani kako bi se vidio ovaj izvještaj'
      ,he: 'חובה לאפשר רמה בזלית זמנית כדי לרות דוח זה'
      , pl: 'Tymczasowa dawka podstawowa jest wymagana aby wyświetlić ten raport'
      ,ru: 'для этого отчета требуется прорисовка врем базалов'
      ,tr: 'Bu raporu görüntülemek için geçici bazal oluşturulmalıdır'
      ,hu: 'Az átmeneti bazálnak meg kell lennie jelenítve az adott jelentls megtekintéséhez'
    }
    ,'Weekly success' : {
      cs: 'Statistika po týdnech'
      ,de: 'Wöchentlicher Erfolg'
      ,es: 'Resultados semanales'
      ,fr: 'Résultat hebdomadaire'
      ,el: 'Εβδομαδιαία Στατιστικά'
      ,pt: 'Resultados semanais'
      ,ro: 'Rezultate săptămânale'
      ,bg: 'Седмичен успех'
      ,hr: 'Tjedni uspjeh'
      ,sv: 'Veckoresultat'
      ,it: 'Statistiche settimanali'
      ,ja: '１週間統計'
      ,dk: 'Uge resultat'
      ,fi: 'Viikkotilasto'
      ,he: 'הצלחה שבועית'
      ,nb: 'Ukeresultat'
      ,pl: 'Wyniki tygodniowe'
      ,ru: 'Результаты недели'
      ,sk: 'Týždenná úspešnosť'
      ,nl: 'Wekelijkse successen'
      ,ko: '주간 통계'
      ,tr: 'Haftalık başarı'
      ,zh_cn: '每周统计'
      ,hu: 'Heti sikeresség'
      }
    ,'No data available' : {
      cs: 'Žádná dostupná data'
      ,de: 'Keine Daten verfügbar'
      ,es: 'No hay datos disponibles'
      ,fr: 'Pas de données disponibles'
      ,el: 'Μη διαθέσιμα δεδομένα'
      ,pt: 'Não há dados'
      ,ro: 'Fără date'
      ,bg: 'Няма данни за показване'
      ,hr: 'Nema raspoloživih podataka'
      ,sv: 'Data saknas'
      ,it: 'Dati non disponibili'
      ,ja: '利用できるデータがありません'
      ,dk: 'Mangler data'
      ,fi: 'Tietoja ei saatavilla'
      ,nb: 'Mangler data'
      ,he: 'אין מידע זמין'
      ,pl: 'Brak danych'
      ,ru: 'Нет данных'
      ,sk: 'Žiadne dostupné dáta'
      ,nl: 'Geen gegevens beschikbaar'
      ,ko: '활용할 수 있는 데이터 없음'
      ,tr: 'Veri yok'
      ,zh_cn: '无可用数据'
      ,hu: 'Nincs elérhető adat'
      }
    ,'Low' : {
      cs: 'Nízká'
      ,de: 'Tief'
      ,es: 'Bajo'
      ,fr: 'Bas'
      ,el: 'Χαμηλά'
      ,pt: 'Baixo'
      ,sv: 'Låg'
      ,ro: 'Prea jos'
      ,bg: 'Ниска'
      ,hr: 'Nizak'
      ,it: 'Basso'
      ,ja: '目標血糖値低値'
      ,dk: 'Lav'
      ,fi: 'Matala'
      ,nb: 'Lav'
      ,he: 'נמוך'
      ,pl: 'Niski'
      ,ru: 'Низкая ГК'
      ,sk: 'Nízka'
      ,nl: 'Laag'
      ,ko: '낮음'
      ,tr: 'Düşük'
      ,zh_cn: '低血糖'
      ,hu: 'Alacsony'
      }
    ,'In Range' : {
      cs: 'V rozsahu'
      ,de: 'Im Zielbereich'
      ,es: 'En rango'
      ,fr: 'dans la norme'
      ,el: 'Στο στόχο'
      ,pt: 'Na meta'
      ,sv: 'Inom intervallet'
      ,ro: 'În interval'
      ,bg: 'В граници'
      ,hr: 'U rasponu'
      ,it: 'Nell\'intervallo'
      ,ja: '目標血糖値範囲内'
      ,dk: 'Indenfor intervallet'
      ,fi: 'Tavoitealueella'
      ,nb: 'Innenfor intervallet'
      ,he: 'בטווח'
      ,pl: 'W zakresie'
      ,ru: 'В диапазоне'
      ,sk: 'V rozsahu'
      ,nl: 'Binnen bereik'
      ,ko: '범위 안 '
      ,tr: 'Hedef alanında'
      ,zh_cn: '范围内'
      ,hu: 'Normális'
      }
    ,'Period' : {
      cs: 'Období'
      ,de: 'Zeitabschnitt'
      ,es: 'Periodo'
      ,fr: 'Période'
      ,el: 'Περίοδος'
      ,pt: 'Período'
      ,sv: 'Period'
      ,ro: 'Perioada'
      ,bg: 'Период'
      ,hr: 'Period'
      ,it: 'Periodo'
      ,ja: '期間'
      ,dk: 'Periode'
      ,fi: 'Aikaväli'
      ,nb: 'Periode'
      ,he: 'תקופה'
      ,pl: 'Okres'
      ,ru: 'Период'
      ,sk: 'Obdobie'
      ,nl: 'Periode'
      ,ko: '기간 '
      ,tr: 'Periyot'
      ,zh_cn: '期间'
      ,hu: 'Időszak'
      }
    ,'High' : {
      cs: 'Vysoká'
      ,de: 'Hoch'
      ,es: 'Alto'
      ,fr: 'Haut'
      ,el: 'Υψηλά'
      ,pt: 'Alto'
      ,sv: 'Hög'
      ,ro: 'Prea sus'
      ,bg: 'Висока'
      ,hr: 'Visok'
      ,it: 'Alto'
      ,ja: '目標血糖値高値'
      ,dk: 'Høj'
      ,fi: 'Korkea'
      ,nb: 'Høy'
      ,he: 'גבוה'
      ,pl: 'Wysoki'
      ,ru: 'Высокая ГК'
      ,sk: 'Vysoká'
      ,nl: 'Hoog'
      ,ko: '높음'
      ,tr: 'Yüksek'
      ,zh_cn: '高血糖'
      ,hu: 'Magas'
      }
    ,'Average' : {
      cs: 'Průměr'
      ,de: 'Mittelwert'
      ,es: 'Media'
      ,fr: 'Moyenne'
      ,el: 'Μέσος Όρος'
      ,pt: 'Média'
      ,sv: 'Genomsnittligt'
      ,ro: 'Media'
      ,bg: 'Средна'
      ,hr: 'Prosjek'
      ,it: 'Media'
      ,ja: '平均値'
      ,dk: 'Gennemsnit'
      ,fi: 'Keskiarvo'
      ,nb: 'Gjennomsnitt'
      ,he: 'ממוצע'
      ,pl: 'Średnia'
      ,ru: 'Средняя'
      ,sk: 'Priemer'
      ,nl: 'Gemiddeld'
      ,ko: '평균'
      ,tr: 'Ortalama'
      ,zh_cn: '平均'
      ,hu: 'Átlagos'
      }
    ,'Low Quartile' : {
      cs: 'Nízký kvartil'
      ,de: 'Unteres Quartil'
      ,es: 'Cuartil inferior'
      ,fr: 'Quartile inférieur'
      ,el: 'Τεταρτημόριο Χαμηλών'
      ,pt: 'Quartil inferior'
      ,ro: 'Pătrime inferioară'
      ,bg: 'Ниска четвъртинка'
      ,hr: 'Donji kvartil'
      ,sv: 'Nedre kvadranten'
      ,it: 'Quartile basso'
      ,ja: '下四分位値'
      ,dk: 'Nedre kvartil'
      ,fi: 'Alin neljäsosa'
      ,nb: 'Nedre kvartil'
      ,he: 'רבעון תחתון'
      ,pl: 'Dolny kwartyl'
      ,ru: 'Нижняя четверть'
      ,sk: 'Nizky kvartil'
      ,nl: 'Eerste kwartiel'
      ,ko: '낮은 4분위'
      ,tr: 'Alt Çeyrek'
      ,zh_cn: '下四分位数'
      ,hu: 'Alacsony kvartil'
      }
    ,'Upper Quartile' : {
      cs: 'Vysoký kvartil'
      ,de: 'Oberes Quartil'
      ,es: 'Cuartil superior'
      ,fr: 'Quartile supérieur'
      ,el: 'Τεταρτημόριο Υψηλών'
      ,pt: 'Quartil superior'
      ,ro: 'Pătrime superioară'
      ,bg: 'Висока четвъртинка'
      ,hr: 'Gornji kvartil'
      ,sv: 'Övre kvadranten'
      ,it: 'Quartile alto'
      ,ja: '高四分位値'
      ,dk: 'Øvre kvartil'
      ,fi: 'Ylin neljäsosa'
      ,nb: 'Øvre kvartil'
      ,he: 'רבעון עליון'
      ,pl: 'Górny kwartyl'
      ,ru: 'Верхняя четверть'
      ,sk: 'Vysoký kvartil'
      ,nl: 'Derde kwartiel'
      ,ko: '높은 4분위'
      ,tr: 'Üst Çeyrek'
      ,zh_cn: '上四分位数'
      ,hu: 'Magas kvartil'
      }
    ,'Quartile' : {
      cs: 'Kvartil'
      ,de: 'Quartil'
      ,es: 'Cuartil'
      ,fr: 'Quartile'
      ,el: 'Τεταρτημόριο'
      ,pt: 'Quartil'
      ,ro: 'Pătrime'
      ,bg: 'Четвъртинка'
      ,hr: 'Kvartil'
      ,sv: 'Kvadrant'
      ,it: 'Quartile'
      ,ja: '四分位値'
      ,dk: 'Kvartil'
      ,fi: 'Neljäsosa'
      ,nb: 'Kvartil'
      ,he: 'רבעון'
      ,pl: 'Kwartyl'
      ,ru: 'Четверть'
      ,sk: 'Kvartil'
      ,nl: 'Kwartiel'
      ,ko: '4분위'
      ,tr: 'Çeyrek'
      ,zh_cn: '四分位数'
      ,hu: 'Kvartil'
      }
    ,'Date' : {
      cs: 'Datum'
      ,de: 'Datum'
      ,es: 'Fecha'
      ,fr: 'Date'
      ,el: 'Ημερομηνία'
      ,pt: 'Data'
      ,sv: 'Datum'
      ,ro: 'Data'
      ,bg: 'Дата'
      ,hr: 'Datum'
      ,it: 'Data'
      ,ja: '日付'
      ,dk: 'Dato'
      ,fi: 'Päivämäärä'
      ,nb: 'Dato'
      ,he: 'תאריך'
      ,pl: 'Data'
      ,ru: 'Дата'
      ,sk: 'Dátum'
      ,nl: 'Datum'
      ,ko: '날짜'
      ,tr: 'Tarih'
      ,zh_cn: '日期'
      ,hu: 'Dátum'
      }
    ,'Normal' : {
      cs: 'Normální'
      ,de: 'Normal'
      ,es: 'Normal'
      ,fr: 'Normale'
      ,el: 'Εντός Στόχου'
      ,pt: 'Normal'
      ,sv: 'Normal'
      ,ro: 'Normal'
      ,bg: 'Нормалнa'
      ,hr: 'Normalno'
      ,it: 'Normale'
      ,ja: '通常'
      ,dk: 'Normal'
      ,fi: 'Normaali'
      ,nb: 'Normal'
      ,he: 'נורמלי'
      ,pl: 'Normalny'
      ,ru: 'Норма'
      ,sk: 'Normálny'
      ,nl: 'Normaal'
      ,ko: '보통'
      ,tr: 'Normal'
      ,zh_cn: '正常'
      ,hu: 'Normális'
      }
    ,'Median' : {
      cs: 'Medián'
      ,de: 'Median'
      ,es: 'Mediana'
      ,fr: 'Médiane'
      ,el: 'Διάμεσος'
      ,pt: 'Mediana'
      ,ro: 'Mediană'
      ,bg: 'Средно'
      ,hr: 'Srednje'
      ,sv: 'Median'
      ,it: 'Mediana'
      ,ja: '中央値'
      ,dk: 'Median'
      ,fi: 'Mediaani'
      ,nb: 'Median'
      ,he: 'חציון'
      ,pl: 'Mediana'
      ,ru: 'Усредненный'
      ,sk: 'Medián'
      ,nl: 'Mediaan'
      ,ko: '중간값'
      ,tr: 'Orta Değer'
      ,zh_cn: '中值'
      ,hu: 'Medián'
      }
    ,'Readings' : {
      cs: 'Záznamů'
      ,de: 'Messwerte'
      ,es: 'Valores'
      ,fr: 'Valeurs'
      ,el: 'Μετρήσεις'
      ,pt: 'Valores'
      ,sv: 'Avläsningar'
      ,ro: 'Valori'
      ,bg: 'Измервания'
      ,hr: 'Vrijednosti'
      ,it: 'Valori'
      ,ja: '読み込み'
      ,dk: 'Aflæsninger'
      ,fi: 'Lukemia'
      ,nb: 'Avlesning'
      ,he: 'קריאות'
      ,pl: 'Odczyty'
      ,ru: 'Значения'
      ,sk: 'Záznamy'
      ,nl: 'Metingen'
      ,ko: '혈당'
      ,tr: 'Ölçüm'
      ,zh_cn: '读数'
      ,hu: 'Értékek'
      }
    ,'StDev' : {
      cs: 'Směrodatná odchylka'
      ,de: 'Standardabweichung'
      ,es: 'Desviación estándar'
      ,fr: 'Déviation St.'
      ,el: 'Τυπική Απόκλιση'
      ,pt: 'DesvPadr'
      ,sv: 'StdDev'
      ,ro: 'Standarddeviation'
      ,bg: 'Стандартно отклонение'
      ,hr: 'Standardna devijacija'
      ,it: 'Dev.std'
      ,ja: '標準偏差'
      ,dk: 'Standard afvigelse'
      ,fi: 'Keskijakauma'
      ,nb: 'Standardavvik'
      ,he: 'סטיית תקן'
      ,pl: 'Stand. odchylenie'
      ,nl: 'Std. deviatie'
      ,ru: 'Стандартное отклонение'
      ,sk: 'Štand. odch.'
      ,ko: '표준 편차'
      ,tr: 'Standart Sapma'
      ,zh_cn: '标准偏差'
      ,hu: 'Standard eltérés'
      }
    ,'Daily stats report' : {
      cs: 'Denní statistiky'
      ,de: 'Tagesstatistik Bericht'
      ,es: 'Informe de estadísticas diarias'
      ,fr: 'Rapport quotidien'
      ,el: 'Ημερήσια Στατιστικά'
      ,pt: 'Relatório diário'
      ,ro: 'Raport statistică zilnică'
      ,bg: 'Дневна статистика'
      ,hr: 'Izvješće o dnevnim statistikama'
      ,sv: 'Dygnsstatistik'
      ,it: 'Statistiche giornaliere'
      ,ja: '１日ごとの統計のレポート'
      ,dk: 'Daglig statistik rapport'
      ,fi: 'Päivittäinen tilasto'
      ,nb: 'Daglig statistikkrapport'
      ,he: 'דוח סטטיסטיקה יומית'
      ,pl: 'Dzienne statystyki'
      ,ru: 'Суточная статистика'
      ,sk: 'Denné štatistiky'
      ,nl: 'Dagelijkse statistieken'
      ,ko: '일간 통계 보고서'
      ,tr: 'Günlük istatistikler raporu'
      ,zh_cn: '每日状态报表'
      ,hu: 'Napi statisztikák'
      }
    ,'Glucose Percentile report' : {
      cs: 'Tabulka percentil glykémií'
      ,de: 'Glukose-Perzentil Bericht'
      ,es: 'Informe de percetiles de glucemia'
      ,fr: 'Rapport percentiles Glycémie'
      ,el: 'Αναφορά Εκατοστημοριακής Κατάταξης τιμών Γλυκόζης'
      ,pt: 'Relatório de Percentis de Glicemia'
      ,sv: 'Glukosrapport i procent'
      ,ro: 'Raport percentile glicemii'
      ,bg: 'Графика на КЗ'
      ,hr: 'Izvješće o postotku GUK-a'
      ,it: 'Percentuale Glicemie'
      ,ja: 'グルコース(%)レポート'
      ,dk: 'Glukoserapport i procent'
      ,fi: 'Verensokeriarvojen jakauma'
      ,nb: 'Glukoserapport i prosent'
      ,he: 'דוח אחוזון סוכר'
      ,pl: 'Tabela centylowa glikemii'
      ,ru: 'Процентильная ГК'
      ,sk: 'Report percentilu glykémií'
      ,nl: 'Glucose percentiel rapport'
      ,ko: '혈당 백분위 보고서'
      ,tr: 'Glikoz Yüzdelik raporu'
      ,zh_cn: '血糖百分位报表'
      ,hu: 'Cukorszint percentil jelentés'
      }
    ,'Glucose distribution' : {
      cs: 'Rozložení glykémií'
      ,de: 'Glukose Verteilung'
      ,es: 'Distribución de glucemias'
      ,fr: 'Distribution glycémies'
      ,el: 'Κατανομή Τιμών Γλυκόζης'
      ,pt: 'Distribuição de glicemias'
      ,ro: 'Distribuție glicemie'
      ,bg: 'Разпределение на КЗ'
      ,hr: 'Distribucija GUK-a'
      ,sv: 'Glukosdistribution'
      ,it: 'Distribuzione glicemie'
      ,ja: '血糖値の分布'
      ,dk: 'Glukosefordeling'
      ,fi: 'Glukoosijakauma'
      ,nb: 'Glukosefordeling'
      ,he: 'התפלגות סוכר'
      ,pl: 'Rozkład glikemii'
      ,ru: 'Распределение ГК'
      ,sk: 'Rozloženie glykémie'
      ,nl: 'Glucose verdeling'
      ,ko: '혈당 분포'
      ,tr: 'Glikoz dağılımı'
      ,zh_cn: '血糖分布'
      ,hu: 'Cukorszint szétosztása'
      }
    ,'days total' : {
      cs: 'dní celkem'
      ,de: 'Gesamttage'
      ,es: 'Total de días'
      ,fr: 'jours totaux'
      ,el: 'ημέρες συνολικά'
      ,pt: 'dias no total'
      ,sv: 'antal dagar'
      ,ro: 'total zile'
      ,bg: 'общо за деня'
      ,hr: 'ukupno dana'
      ,it: 'Giorni totali'
      ,ja: '合計日数'
      ,dk: 'antal dage'
      ,fi: 'päivän arvio'
      ,nb: 'antall dager'
      ,he: 'מספר ימים'
      ,pl: 'dni łącznie'
      ,ru: 'всего дней'
      ,sk: 'dní celkom'
      ,nl: 'Totaal dagen'
      ,ko: '일 전체'
      ,tr: 'toplam gün'
      ,zh_cn: '天总计'
      ,hu: 'nap összesen'
      }
    ,'Total per day' : {
      cs: 'dní celkem'
      ,de: 'Gesamt pro Tag'
      ,es: 'Total de días'
      ,fr: 'Total journalier'
      ,el: 'ημέρες συνολικά'
      ,pt: 'dias no total'
      ,sv: 'antal dagar'
      ,ro: 'total zile'
      ,bg: 'общо за деня'
      ,hr: 'Ukupno po danu'
      ,it: 'Giorni totali'
      ,dk: 'antal dage'
      ,fi: 'päivän arvio'
      ,nb: 'antall dager'
      ,he: 'מספר ימים'
      ,pl: 'dni łącznie'
      ,ru: 'всего за сутки'
      ,sk: 'dní celkom'
      ,nl: 'Totaal dagen'
      ,ko: '하루 총량'
      ,tr: 'Günlük toplam'
      ,zh_cn: '天总计'
      ,hu: 'Naponta összesen'
      }
    ,'Overall' : {
      cs: 'Celkem'
      ,de: 'Insgesamt'
      ,es: 'General'
      ,fr: 'En général'
      ,el: 'Σύνολο'
      ,pt: 'Geral'
      ,sv: 'Genomsnitt'
      ,ro: 'General'
      ,bg: 'Общо'
      ,hr: 'Sveukupno'
      ,it: 'Generale'
      ,ja: '総合'
      ,dk: 'Gennemsnit'
      ,fi: 'Yhteenveto'
      ,nb: 'Generelt'
      ,he: 'סך הכל'
      ,pl: 'Ogółem'
      ,ru: 'Суммарно'
      ,sk: 'Súhrn'
      ,nl: 'Totaal'
      ,ko: '전체'
      ,tr: 'Tüm'
      ,zh_cn: '概览'
      ,hu: 'Összesen'
      }
    ,'Range' : {
      cs: 'Rozsah'
      ,de: 'Bereich'
      ,es: 'Intervalo'
      ,fr: 'Intervalle'
      ,el: 'Διάστημα'
      ,pt: 'intervalo'
      ,sv: 'Intervall'
      ,ro: 'Interval'
      ,bg: 'Диапазон'
      ,hr: 'Raspon'
      ,it: 'Intervallo'
      ,ja: '範囲'
      ,dk: 'Interval'
      ,fi: 'Alue'
      ,nb: 'Intervall'
      ,he: 'טווח'
      ,pl: 'Zakres'
      ,ru: 'Диапазон'
      ,sk: 'Rozsah'
      ,nl: 'Bereik'
      ,ko: '범위'
      ,tr: 'Alan'
      ,zh_cn: '范围'
      ,hu: 'Tartomány'
     }
    ,'% of Readings' : {
      cs: '% záznamů'
      ,de: '% der Messwerte'
      ,es: '% de valores'
      ,fr: '% de valeurs'
      ,el: '% των μετρήσεων'
      ,pt: '% de valores'
      ,sv: '% av avläsningar'
      ,ro: '% de valori'
      ,bg: '% от измервания'
      ,hr: '% očitanja'
      ,it: '% dei valori'
      ,ja: '%精度'
      ,dk: '% af aflæsningerne'
      ,fi: '% lukemista'
      ,nb: '% af avlesningene'
      ,he: 'אחוז קריאות'
      ,pl: '% Odczytów'
      ,ru: '% измерений'
      ,sk: '% záznamov'
      ,nl: '% metingen'
      ,ko: '수신된 혈당 비율(%)'
      ,tr: '% Okumaların'
      ,zh_cn: '%已读取'
      ,hu: '% az értékeknek'
      }
    ,'# of Readings' : {
      cs: 'počet záznamů'
      ,de: 'Anzahl der Messwerte'
      ,es: 'N° de valores'
      ,fr: 'nbr de valeurs'
      ,el: 'Πλήθος μετρήσεων'
      ,pt: 'N° de valores'
      ,sv: '# av avläsningar'
      ,ro: 'nr. de valori'
      ,bg: '№ от измервания'
      ,hr: 'broj očitanja'
      ,it: '# di valori'
      ,ja: '#精度'
      ,dk: 'Antal aflæsninger'
      ,fi: 'Lukemien määrä'
      ,nb: 'Antall avlesninger'
      ,he: 'מספר קריאות'
      ,pl: 'Ilość Odczytów'
      ,ru: 'кол-во измерений'
      ,sk: 'Počet záznamov'
      ,nl: 'Aantal metingen'
      ,ko: '수신된 혈당 개수(#)'
      ,tr: '# Okumaların'
      ,zh_cn: '#已读取'
      ,hu: 'Olvasott értékek száma'
      }
    ,'Mean' : {
      cs: 'Střední hodnota'
      ,de: 'Mittelwert'
      ,es: 'Media'
      ,fr: 'Moyenne'
      ,el: 'Μέσος Όρος'
      ,pt: 'Média'
      ,sv: 'Genomsnitt'
      ,ro: 'Medie'
      ,bg: 'Средна стойност'
      ,hr: 'Prosjek'
      ,it: 'Media'
      ,ja: '意味'
      ,dk: 'Gennemsnit'
      ,fi: 'Keskiarvo'
      ,nb: 'Gjennomsnitt'
      ,he: 'ממוצע'
      ,pl: 'Wartość średnia'
      ,ru: 'Среднее значение'
      ,sk: 'Stred'
      ,nl: 'Gemiddeld'
      ,ko: '평균'
      ,tr: 'ortalama'
      ,zh_cn: '平均'
      ,hu: 'Közép'
      }
    ,'Standard Deviation' : {
      cs: 'Standardní odchylka'
      ,de: 'Standardabweichung'
      ,es: 'Desviación estándar'
      ,fr: 'Déviation Standard'
      ,el: 'Τυπική Απόκλιση'
      ,pt: 'Desvio padrão'
      ,ro: 'Deviație standard'
      ,bg: 'Стандартно отклонение'
      ,hr: 'Standardna devijacija'
      ,sv: 'Standardavvikelse'
      ,it: 'Deviazione Standard'
      ,ja: '標準偏差'
      ,dk: 'Standardafvigelse'
      ,fi: 'Keskijakauma'
      ,nb: 'Standardavvik'
      ,he: 'סטיית תקן'
      ,pl: 'Standardowe odchylenie'
      ,ru: 'Стандартное отклонение'
      ,sk: 'Štandardná odchylka'
      ,nl: 'Standaard deviatie'
      ,ko: '표준 편차'
      ,tr: 'Standart Sapma'
      ,zh_cn: '标准偏差'
      ,hu: 'Átlagos eltérés'
      }
    ,'Max' : {
      cs: 'Max'
      ,de: 'Max'
      ,es: 'Max'
      ,fr: 'Max'
      ,el: 'Μέγιστο'
      ,pt: 'Máx'
      ,sv: 'Max'
      ,ro: 'Max'
      ,bg: 'Макс.'
      ,hr: 'Max'
      ,it: 'Max'
      ,ja: '最大値'
      ,dk: 'Max'
      ,fi: 'Maks'
      ,nb: 'Max'
      ,he: 'מקסימאלי'
      ,pl: 'Max'
      ,ru: 'Макс'
      ,sk: 'Max'
      ,nl: 'Max'
      ,ko: '최대값'
      ,tr: 'Max'
      ,zh_cn: '最大值'
      ,hu: 'Max'
      }
    ,'Min' : {
      cs: 'Min'
      ,de: 'Min'
      ,es: 'Min'
      ,fr: 'Min'
      ,el: 'Ελάχιστο'
      ,pt: 'Mín'
      ,sv: 'Min'
      ,ro: 'Min'
      ,bg: 'Мин.'
      ,hr: 'Min'
      ,it: 'Min'
      ,ja: '最小値'
      ,dk: 'Min'
      ,fi: 'Min'
      ,nb: 'Min'
      ,he: 'מינימאלי'
      ,pl: 'Min'
      ,ru: 'Мин'
      ,sk: 'Min'
      ,nl: 'Min'
      ,ko: '최소값'
      ,tr: 'Min'
      ,zh_cn: '最小值'
      ,hu: 'Min'
      }
    ,'A1c estimation*' : {
      cs: 'Předpokládané HBA1c*'
      ,de: 'Einschätzung HbA1c*'
      ,es: 'Estimación de HbA1c*'
      ,fr: 'Estimation HbA1c*'
      ,el: 'Εκτίμηση HbA1c'
      ,pt: 'HbA1c estimada*'
      ,ro: 'HbA1C estimată'
      ,bg: 'Очакван HbA1c'
      ,hr: 'Procjena HbA1c-a'
      ,sv: 'Beräknat A1c-värde '
      ,it: 'Stima A1c'
      ,ja: '予想HbA1c'
      ,dk: 'Beregnet A1c-værdi '
      ,fi: 'A1c arvio*'
      ,nb: 'Beregnet HbA1c'
      ,he: 'משוער A1c'
      ,pl: 'HbA1c przewidywany'
      ,ru: 'Ожидаемый HbA1c*'
      ,sk: 'Odhadované HbA1C*'
      ,nl: 'Geschatte HbA1C'
      ,ko: '예상 당화혈 색소'
      ,tr: 'Tahmini A1c *'
      ,zh_cn: '糖化血红蛋白估算'
      ,hu: 'Megközelítőleges HbA1c'
      }
    ,'Weekly Success' : {
      cs: 'Týdenní úspěšnost'
      ,de: 'Wöchtlicher Erfolg'
      ,es: 'Resultados semanales'
      ,fr: 'Réussite hebdomadaire'
      ,el: 'Εβδομαδιαία Στατιστικά'
      ,pt: 'Resultados semanais'
      ,ro: 'Rezultate săptămânale'
      ,bg: 'Седмичен успех'
      ,hr: 'Tjedni uspjeh'
      ,sv: 'Veckoresultat'
      ,it: 'Risultati settimanali'
      ,ja: '週間達成度'
      ,dk: 'Uge resultat'
      ,fi: 'Viikottainen tulos'
      ,nb: 'Ukeresultat'
      ,he: 'הצלחה שבועית'
      ,pl: 'Wyniki tygodniowe'
      ,ru: 'Итоги недели'
      ,sk: 'Týždenná úspešnosť'
      ,nl: 'Wekelijks succes'
      ,ko: '주간 통계'
      ,tr: 'Haftalık Başarı'
      ,zh_cn: '每周统计'
      ,hu: 'Heti sikeresség'
      }
    ,'There is not sufficient data to run this report. Select more days.' : {
      cs: 'Není dostatek dat. Vyberte delší časové období.'
      ,de: 'Für diesen Bericht sind nicht genug Daten verfügbar. Bitte weitere Tage auswählen.'
      ,es: 'No hay datos suficientes para generar este informe. Seleccione más días.'
      ,fr: 'Pas assez de données pour un rapport. Sélectionnez plus de jours.'
      ,el: 'Μη επαρκή δεδομένα για αυτή την αναφορά.Παρακαλώ επιλέξτε περισσότερες ημέρες'
      ,pt: 'Não há dados suficientes. Selecione mais dias'
      ,ro: 'Nu sunt sufieciente date pentru acest raport. Selectați mai multe zile.'
      ,bg: 'Няма достатъчно данни за показване. Изберете повече дни.'
      ,hr: 'Nema dovoljno podataka za izvođenje izvještaja. Odaberite još dana.'
      ,sv: 'Data saknas för att köra rapport. Välj fler dagar.'
      ,it: 'Non ci sono dati sufficienti per eseguire questo rapporto. Selezionare più giorni.'
      ,ja: 'レポートするためのデータが足りません。もっと多くの日を選択してください。'
      ,dk: 'Der er utilstrækkeligt data til at generere rapporten. Vælg flere dage.'
      ,fi: 'Raporttia ei voida luoda liian vähäisen tiedon vuoksi. Valitse useampia päiviä.'
      ,nb: 'Der er ikke nok data til å lage rapporten.  Velg flere dager.'
      ,he: 'לא נמצא מספיק מידע ליצירת הדוח. בחר ימים נוספים.'
      ,pl: 'Nie ma wystarczających danych dla tego raportu. Wybierz więcej dni.'
      ,ru: 'Для этого отчета недостаточно данных. Выберите больше дней.'
      ,sk: 'Nedostatok dát. Vyberte dlhšie časové obdobie.'
      ,nl: 'Er zijn niet genoeg gegevens voor dit rapport, selecteer meer dagen.'
      ,ko: '이 보고서를 실행하기 위한 데이터가 충분하지 않습니다. 더 많은 날들을 선택해 주세요.'
      ,tr: 'Bu raporu çalıştırmak için yeterli veri yok. Daha fazla gün seçin.'
      ,zh_cn: '没有足够的数据生成报表，请选择更长时间段。'
      ,hu: 'Nincs elég adat a jelentés elkészítéséhez. Válassz több napot.'
    }
    // food editor
    ,'Using stored API secret hash' : {
      cs: 'Používám uložený hash API hesla'
      ,he: 'משתמש בסיסמת ממשק תכנות יישומים הסודית '
      ,de: 'Gespeicherte API-Prüfsumme verwenden'
      ,es: 'Usando hash del API secreto pre-almacenado'
      ,fr: 'Utilisation du hash API existant'
      ,el: 'Χρηση αποθηκευμένου συνθηματικού'
      ,pt: 'Usando o hash de API existente'
      ,ro: 'Utilizez cheie API secretă'
      ,bg: 'Използване на запаметена API парола'
      ,hr: 'Koristi se pohranjeni API tajni hash'
      ,sv: 'Använd hemlig API-nyckel'
      ,it: 'Stai utilizzando API hash segreta'
      ,ja: '保存されたAPI secret hashを使用する'
      ,dk: 'Anvender gemt API-nøgle'
      ,fi: 'Tallennettu salainen API-tarkiste käytössä'
      ,nb: 'Bruker lagret API nøkkel'
      ,pl: 'Korzystając z zapisanego poufnego hasha API'
      ,ru: 'Применение сохраненного пароля API'
      ,sk: 'Používam uložený API hash heslo'
      ,nl: 'Gebruik opgeslagen geheime API Hash'
      ,ko: '저장된 API secret hash를 사용 중'
      ,tr: 'Kaydedilmiş API secret hash kullan'
      ,zh_cn: '使用已存储的API密钥哈希值'
      ,zh_tw: '使用已存儲的API密鑰哈希值'
      ,hu: 'Az elmentett API hash jelszót használom'
      }
    ,'No API secret hash stored yet. You need to enter API secret.' : {
      cs: 'Není uložený žádný hash API hesla. Musíte zadat API heslo.'
      ,de: 'Keine API-Prüfsumme gespeichert. Bitte API-Prüfsumme eingeben.'
      ,es: 'No se ha almacenado ningún hash todavía. Debe introducir su secreto API.'
      ,fr: 'Pas de secret API existant. Vous devez en entrer un.'
      ,el: 'Δεν υπάρχει αποθηκευμένο συνθηματικό API. Πρέπει να εισάγετε το συνθηματικό API'
      ,pt: 'Hash de segredo de API inexistente. Insira um segredo de API.'
      ,ro: 'Încă nu există cheie API secretă. Aceasta trebuie introdusă.'
      ,bg: 'Няма запаметена API парола. Tрябва да въведете API парола'
      ,hr: 'Nema pohranjenog API tajnog hasha. Unesite tajni API'
      ,sv: 'Hemlig api-nyckel saknas. Du måste ange API hemlighet'
      ,it: 'API hash segreto non è ancora memorizzato. È necessario inserire API segreto.'
      ,ja: 'API secret hashがまだ保存されていません。API secretの入力が必要です。'
      ,dk: 'Mangler API-nøgle. Du skal indtaste API nøglen'
      ,fi: 'Salainen API-tarkiste puuttuu. Syötä API tarkiste.'
      ,nb: 'Mangler API nøkkel. Du må skrive inn API hemmelighet.'
      ,he:'הכנס את הסיסמא הסודית של ה API'
      ,pl: 'Nie ma żadnego poufnego hasha API zapisanego. Należy wprowadzić poufny hash API.'
      ,ru: 'Пароля API нет в памяти. Введите пароль API'
      ,sk: 'Nieje uložené žiadne API hash heslo. Musíte zadať API heslo.'
      ,nl: 'Er is nog geen geheime API Hash opgeslagen. U moet eerst een geheime API code invoeren.'
      ,ko: 'API secret hash가 아직 저장되지 않았습니다. API secret를 입력해 주세요.'
      ,tr: 'Henüz bir API secret hash saklanmadı. API parolasını girmeniz gerekiyor.'
      ,zh_cn: '没有已存储的API密钥，请输入API密钥。'
      ,zh_tw: '沒有已存儲的API密鑰，請輸入API密鑰。'
      ,hu: 'Még nem lett a titkos API hash elmentve. Add meg a titkos API jelszót'
      }
    ,'Database loaded' : {
      cs: 'Databáze načtena'
      ,de: 'Datenbank geladen'
      ,es: 'Base de datos cargada'
      ,fr: 'Base de données chargée'
      ,el: 'Συνδέθηκε με τη Βάση Δεδομένων'
      ,pt: 'Banco de dados carregado'
      ,ro: 'Baza de date încărcată'
      ,bg: 'База с данни заредена'
      ,hr: 'Baza podataka je učitana'
      ,sv: 'Databas laddad'
      ,it: 'Database caricato'
      ,ja: 'データベースロード完了'
      ,dk: 'Database indlæst'
      ,fi: 'Tietokanta ladattu'
      ,nb: 'Database lest'
      ,he: 'בסיס נתונים נטען'
      ,pl: 'Baza danych załadowana'
      ,ru: 'База данных загружена'
      ,sk: 'Databáza načítaná'
      ,nl: 'Database geladen'
      ,ko: '데이터베이스 로드'
      ,tr: 'Veritabanı yüklendi'
      ,zh_cn: '数据库已载入'
      ,hu: 'Adatbázis betöltve'
      }
    ,'Error: Database failed to load' : {
      cs: 'Chyba při načítání databáze'
      ,de: 'Fehler: Datenbank konnte nicht geladen werden'
      ,es: 'Error: Carga de base de datos fallida'
      ,fr: 'Erreur: le chargement de la base de données a échoué'
      ,el: 'Σφάλμα:Αποτυχία σύνδεσης με τη Βάση Δεδομένων'
      ,pt: 'Erro: Banco de dados não carregado'
      ,ro: 'Eroare: Nu s-a încărcat baza de date'
      ,bg: 'ГРЕШКА. Базата с данни не успя да се зареди'
      ,hr: 'Greška: Baza podataka nije učitana'
      ,sv: 'Error: Databas kan ej laddas'
      ,it: 'Errore: database non è stato caricato'
      ,ja: 'エラー：データベースを読み込めません'
      ,dk: 'Fejl: Database kan ikke indlæses'
      ,fi: 'Virhe: Tietokannan lataaminen epäonnistui'
      ,nb: 'Feil: Database kan ikke leses'
      ,he: 'שגיאה: לא ניתן לטעון בסיס נתונים'
      ,pl: 'Błąd, baza danych nie może być załadowana'
      ,ru: 'Ошибка: Не удалось загрузить базу данных'
      ,sk: 'Chyba pri načítaní databázy'
      ,nl: 'FOUT: Database niet geladen'
      ,ko: '에러: 데이터베이스 로드 실패'
      ,tr: 'Hata: Veritabanı yüklenemedi'
      ,zh_cn: '错误：数据库载入失败'
      ,hu: 'Hiba: Az adatbázist nem sikerült betölteni'
      }
    ,'Error' : {
      cs: 'Error'
      ,he: 'Error'
      ,nb: 'Error'
      ,fr: 'Error'
      ,ro: 'Error'
      ,el: 'Error'
      ,de: 'Error'
      ,es: 'Error'
      ,dk: 'Error'
      ,sv: 'Error'
      ,bg: 'Error'
      ,hr: 'Greška'
      ,it: 'Error'
      ,fi: 'Error'
      ,pl: 'Error'
      ,pt: 'Error'
      ,ru: 'Ошибка'
      ,sk: 'Error'
      ,nl: 'Error'
      ,ko: 'Error'
      ,tr: 'Error'
      ,zh_cn: 'Error'
      ,zh_tw: 'Error'
      ,hu: 'Hiba'
      }
    ,'Create new record' : {
      cs: 'Vytvořit nový záznam'
      ,de: 'Erstelle neuen Datensatz'
      ,es: 'Crear nuevo registro'
      ,fr: 'Créer nouvel enregistrement'
      ,el: 'Δημιουργία νέας εγγραφής'
      ,pt: 'Criar novo registro'
      ,ro: 'Crează înregistrare nouă'
      ,bg: 'Създаване на нов запис'
      ,hr: 'Kreiraj novi zapis'
      ,sv: 'Skapa ny post'
      ,it: 'Crea nuovo registro'
      ,ja: '新しい記録を作る'
      ,dk: 'Opret ny post'
      ,fi: 'Luo uusi tallenne'
      ,nb: 'Lager ny registrering'
      ,he: 'צור רשומה חדשה'
      ,pl: 'Tworzenie nowego wpisu'
      ,ru: 'Создайте новую запись'
      ,sk: 'Vytovriť nový záznam'
      ,nl: 'Opslaan'
      ,ko: '새입력'
      ,tr: 'Yeni kayıt oluştur'
      ,zh_cn: '新增记录'
      ,hu: 'Új bejegyzés'
      }
    ,'Save record' : {
      cs: 'Uložit záznam'
      ,de: 'Speichere Datensatz'
      ,es: 'Guardar registro'
      ,fr: 'Sauver enregistrement'
      ,el: 'Αποθήκευση εγγραφής'
      ,pt: 'Salvar registro'
      ,ro: 'Salvează înregistrarea'
      ,bg: 'Запази запис'
      ,hr: 'Spremi zapis'
      ,sv: 'Spara post'
      ,it: 'Salva Registro'
      ,ja: '保存'
      ,dk: 'Gemmer post'
      ,fi: 'Tallenna'
      ,nb: 'Lagrer registrering'
      ,he: 'שמור רשומה'
      ,pl: 'Zapisz wpis'
      ,ru: 'Сохраните запись'
      ,sk: 'Uložiť záznam'
      ,nl: 'Sla op'
      ,ko: '저장'
      ,tr: 'Kayıtları kaydet'
      ,zh_cn: '保存记录'
      ,hu: 'Bejegyzés mentése'
      }
    ,'Portions' : {
      cs: 'Porcí'
      ,de: 'Portionen'
      ,es: 'Porciones'
      ,fr: 'Portions'
      ,el: 'Μερίδα'
      ,pt: 'Porções'
      ,ro: 'Porții'
      ,bg: 'Порции'
      ,hr: 'Dijelovi'
      ,sv: 'Portion'
      ,it: 'Porzioni'
      ,ja: '一食分'
      ,dk: 'Portioner'
      ,fi: 'Annokset'
      ,nb: 'Porsjoner'
      ,he: 'מנות'
      ,pl: 'Porcja'
      ,ru: 'Порции'
      ,sk: 'Porcií'
      ,nl: 'Porties'
      ,ko: '부분'
      ,tr: 'Porsiyonlar'
      ,zh_cn: '部分'
      ,hu: 'Porció'
      }
    ,'Unit' : {
      cs: 'Jedn'
      ,de: 'Einheit'
      ,es: 'Unidades'
      ,fr: 'Unités'
      ,el: 'Μονάδα'
      ,pt: 'Unidade'
      ,ro: 'Unități'
      ,bg: 'Единици'
      ,hr: 'Jedinica'
      ,sv: 'Enhet'
      ,it: 'Unità'
      ,ja: '単位'
      ,dk: 'Enheder'
      ,fi: 'Yksikkö'
      ,nb: 'Enhet'
      ,he: 'יחידות'
      ,pl: 'Jednostka'
      ,ru: 'Единица'
      ,sk: 'Jednot.'
      ,nl: 'Eenheid'
      ,ko: '단위'
      ,tr: 'Birim'
      ,zh_cn: '单位'
      ,hu: 'Egység'
      }
    ,'GI' : {
      cs: 'GI'
      ,he: 'GI'
      ,de: 'GI'
      ,es: 'IG'
      ,fr: 'IG'
      ,el: 'GI-Γλυκαιμικός Δείκτης'
      ,pt: 'IG'
      ,sv: 'GI'
      ,ro: 'CI'
      ,bg: 'ГИ'
      ,hr: 'GI'
      ,it: 'IG-Ind.Glic.'
      ,ja: 'GI'
      ,dk: 'GI'
      ,fi: 'GI'
      ,nb: 'GI'
      ,pl: 'IG'
      ,ru: 'гл индекс ГИ'
      ,sk: 'GI'
      ,nl: 'Glycemische index '
      ,ko: '혈당 지수'
      ,tr: 'GI-Glisemik İndeks'
      ,zh_cn: 'GI（血糖生成指数）'
      ,hu: 'GI'
      }
    ,'Edit record' : {
      cs: 'Upravit záznam'
      ,de: 'Bearbeite Datensatz'
      ,es: 'Editar registro'
      ,fr: 'Modifier enregistrement'
      ,el: 'Επεξεργασία εγγραφής'
      ,pt: 'Editar registro'
      ,ro: 'Editează înregistrarea'
      ,bg: 'Редактирай запис'
      ,hr: 'Uredi zapis'
      ,sv: 'Editera post'
      ,it: 'Modifica registro'
      ,ja: '記録編集'
      ,dk: 'Rediger post'
      ,fi: 'Muokkaa tallennetta'
      ,nb: 'Editere registrering'
      ,he: 'ערוך רשומה'
      ,pl: 'Edycja wpisu'
      ,ru: 'Редактировать запись'
      ,sk: 'Upraviť záznam'
      ,nl: 'Bewerk invoer'
      ,ko: '편집기록'
      ,tr: 'Kaydı düzenle'
      ,zh_cn: '编辑记录'
      ,hu: 'Bejegyzés szerkesztése'
      }
    ,'Delete record' : {
      cs: 'Smazat záznam'
      ,de: 'Lösche Datensatz'
      ,es: 'Borrar registro'
      ,fr: 'Effacer enregistrement'
      ,el: 'Διαγραφή εγγραφής'
      ,pt: 'Apagar registro'
      ,ro: 'Șterge înregistrarea'
      ,bg: 'Изтрий запис'
      ,hr: 'Izbriši zapis'
      ,sv: 'Radera post'
      ,it: 'Cancella registro'
      ,ja: '記録削除'
      ,dk: 'Slet post'
      ,fi: 'Tuhoa tallenne'
      ,nb: 'Slette registrering'
      ,he: 'מחק רשומה'
      ,pl: 'Usuń wpis'
      ,ru: 'Стереть запись'
      ,sk: 'Zmazať záznam'
      ,nl: 'Verwijder invoer'
      ,ko: '삭제기록'
      ,tr: 'Kaydı sil'
      ,zh_cn: '删除记录'
      ,hu: 'Bejegyzés törlése'
      }
    ,'Move to the top' : {
      cs: 'Přesuň na začátek'
      ,de: 'Gehe zum Anfang'
      ,es: 'Mover arriba'
      ,fr: 'Déplacer au sommet'
      ,el: 'Μετακίνηση πάνω'
      ,pt: 'Mover para o topo'
      ,sv: 'Gå till toppen'
      ,ro: 'Mergi la început'
      ,bg: 'Преместване в началото'
      ,hr: 'Premjesti na vrh'
      ,it: 'Spostare verso l\'alto'
      ,ja: 'トップ画面へ'
      ,dk: 'Gå til toppen'
      ,fi: 'Siirrä ylimmäksi'
      ,nb: 'Gå til toppen'
      ,he: 'עבור למעלה'
      ,pl: 'Przejdź do góry'
      ,ru: 'Переместить наверх'
      ,sk: 'Presunúť na začiatok'
      ,nl: 'Ga naar boven'
      ,ko: '맨처음으로 이동'
      ,tr: 'En üste taşı'
      ,zh_cn: '移至顶端'
      ,hu: 'Áthelyezni az elejére'
      }
    ,'Hidden' : {
      cs: 'Skrytý'
      ,de: 'Verborgen'
      ,es: 'Oculto'
      ,fr: 'Caché'
      ,el: 'Απόκρυψη'
      ,pt: 'Oculto'
      ,sv: 'Dold'
      ,ro: 'Ascuns'
      ,bg: 'Скрити'
      ,hr: 'Skriveno'
      ,it: 'Nascosto'
      ,ja: '隠す'
      ,dk: 'Skjult'
      ,fi: 'Piilotettu'
      ,nb: 'Skjult'
      ,he: 'מוסתר'
      ,pl: 'Ukryte'
      ,ru: 'Скрыт'
      ,sk: 'Skrytý'
      ,nl: 'Verborgen'
      ,ko: '숨김'
      ,tr: 'Gizli'
      ,zh_cn: '隐藏'
      ,hu: 'Elrejtett'
      }
    ,'Hide after use' : {
      cs: 'Skryj po použití'
      ,de: 'Verberge nach Gebrauch'
      ,es: 'Ocultar después de utilizar'
      ,fr: 'Cacher après utilisation'
      ,el: 'Απόκρυψη μετά τη χρήση'
      ,pt: 'Ocultar após uso'
      ,ro: 'Ascunde după folosireaa'
      ,bg: 'Скрий след употреба'
      ,hr: 'Sakrij nakon korištenja'
      ,sv: 'Dölj efter användning'
      ,it: 'Nascondi dopo l\'uso'
      ,ja: '使用後に隠す'
      ,dk: 'Skjul efter brug'
      ,fi: 'Piilota käytön jälkeen'
      ,nb: 'Skjul etter bruk'
      ,he: 'הסתר לאחר שימוש'
      ,pl: 'Ukryj po użyciu'
      ,ru: 'Скрыть после использования'
      ,sk: 'Skryť po použití'
      ,nl: 'Verberg na gebruik'
      ,ko: '사용 후 숨김'
      ,tr: 'Kullandıktan sonra gizle'
      ,zh_cn: '使用后隐藏'
      ,hu: 'Elrejteni használat után'
      }
    ,'Your API secret must be at least 12 characters long' : {
      cs: 'Vaše API heslo musí mít alespoň 12 znaků'
      ,he: 'הסיסמא חייבת להיות באורך 12 תווים לפחות'
      ,de: 'Deine API-Prüfsumme muss mindestens 12 Zeichen lang sein'
      ,es: 'Su API secreo debe contener al menos 12 carácteres'
      ,fr: 'Votre secret API doit contenir au moins 12 caractères'
      ,el: 'Το συνθηματικό πρέπει να είναι τουλάχιστον 12 χαρακτήρων'
      ,pt: 'Seu segredo de API deve conter no mínimo 12 caracteres'
      ,ro: 'Cheia API trebuie să aibă mai mult de 12 caractere'
      ,bg: 'Вашата АPI парола трябва да е дълга поне 12 символа'
      ,hr: 'Vaš tajni API mora sadržavati barem 12 znakova'
      ,sv: 'Hemlig API-nyckel måsta innehålla 12 tecken'
      ,it: 'il vostro API secreto deve essere lungo almeno 12 caratteri'
      ,ja: 'APIシークレットは12文字以上の長さが必要です'
      ,dk: 'Din API nøgle skal være mindst 12 tegn lang'
      ,fi: 'API-avaimen tulee olla ainakin 12 merkin mittainen'
      ,nb: 'Din API nøkkel må være minst 12 tegn lang'
      ,pl: 'Twój poufny klucz API musi zawierać co majmniej 12 znaków'
      ,ru: 'Ваш пароль API должен иметь не менее 12 знаков'
      ,sk: 'Vaše API heslo musí mať najmenej 12 znakov'
      ,nl: 'Uw API wachtwoord dient tenminste 12 karakters lang te zijn'
      ,ko: 'API secret는 최소 12자 이상이여야 합니다.'
      ,tr: 'PI parolanız en az 12 karakter uzunluğunda olmalıdır'
      ,zh_cn: 'API密钥最少需要12个字符'
      ,zh_tw: 'API密鑰最少需要12個字符'
      ,hu: 'Az API jelszó több mint 12 karakterből kell hogy álljon'
      }
    ,'Bad API secret' : {
      cs: 'Chybné API heslo'
      ,de: 'Fehlerhafte API-Prüfsumme'
      ,he: 'סיסמא שגויה'
      ,es: 'API secreto incorrecto'
      ,fr: 'Secret API erroné'
      ,el: 'Λάθος συνθηματικό'
      ,pt: 'Segredo de API incorreto'
      ,ro: 'Cheie API greșită'
      ,bg: 'Некоректна API парола'
      ,hr: 'Neispravan tajni API'
      ,sv: 'Felaktig API-nyckel'
      ,it: 'API secreto non corretto'
      ,ja: 'APIシークレットは正しくありません'
      ,dk: 'Forkert API-nøgle'
      ,fi: 'Väärä API-avain'
      ,nb: 'Ugyldig API nøkkel'
      ,pl: 'Błędny klucz API'
      ,ru: 'Плохой пароль API'
      ,sk: 'Nesprávne API heslo'
      ,nl: 'Onjuist API wachtwoord'
      ,ko: '잘못된 API secret'
      ,tr: 'Hatalı API parolası'
      ,zh_cn: 'API密钥错误'
      ,zh_tw: 'API密鑰錯誤'
      ,hu: 'Helytelen API jelszó'
      }
    ,'API secret hash stored' : {
      cs: 'Hash API hesla uložen'
      ,he: 'סיסמא אוכסנה'
      ,de: 'API-Prüfsumme gespeichert'
      ,es: 'Hash del API secreto guardado'
      ,fr: 'Hash API secret sauvegardé'
      ,el: 'Το συνθηματικό αποθηκεύτηκε'
      ,pt: 'Segredo de API guardado'
      ,ro: 'Cheie API înregistrată'
      ,bg: 'УРА! API парола запаметена'
      ,hr: 'API tajni hash je pohranjen'
      ,sv: 'Lagrad hemlig API-hash'
      ,it: 'Hash API secreto memorizzato'
      ,ja: 'APIシークレットを保存出来ました'
      ,dk: 'Hemmelig API-hash gemt'
      ,fi: 'API salaisuus talletettu'
      ,nb: 'API nøkkel lagret'
      ,pl: 'Poufne klucz API zapisane'
      ,ru: 'Хэш пароля API сохранен'
      ,sk: 'Hash API hesla uložený'
      ,nl: 'API wachtwoord opgeslagen'
      ,ko: 'API secret hash가 저장 되었습니다.'
      ,tr: 'API secret hash parolası saklandı'
      ,zh_cn: 'API密钥已存储'
      ,zh_tw: 'API密鑰已存儲'
      ,hu: 'API jelszó elmentve'
      }
    ,'Status' : {
      cs: 'Status'
      ,de: 'Status'
      ,es: 'Estado'
      ,fr: 'Statut'
      ,el: 'Κατάσταση'
      ,pt: 'Status'
      ,sv: 'Status'
      ,ro: 'Status'
      ,bg: 'Статус'
      ,hr: 'Status'
      ,it: 'Stato'
      ,ja: '統計'
      ,dk: 'Status'
      ,fi: 'Tila'
      ,nb: 'Status'
      ,he: 'מצב מערכת'
      ,pl: 'Status'
      ,ru: 'Статус'
      ,sk: 'Status'
      ,nl: 'Status'
      ,ko: '상태'
      ,tr: 'Durum'
      ,zh_cn: '状态'
      ,hu: 'Állapot'
      }
    ,'Not loaded' : {
      cs: 'Nenačtený'
      ,he: 'לא נטען'
      ,de: 'Nicht geladen'
      ,es: 'No cargado'
      ,fr: 'Non chargé'
      ,el: 'Δεν έγινε μεταφόρτωση'
      ,pt: 'Não carregado'
      ,ro: 'Neîncărcat'
      ,bg: 'Не е заредено'
      ,hr: 'Nije učitano'
      ,sv: 'Ej laddad'
      ,it: 'Non caricato'
      ,ja: '読み込めません'
      ,dk: 'Ikke indlæst'
      ,fi: 'Ei ladattu'
      ,nb: 'Ikke lest'
      ,pl: 'Nie załadowany'
      ,ru: 'Не загружено'
      ,sk: 'Nenačítaný'
      ,nl: 'Niet geladen'
      ,ko: '로드되지 않음'
      ,tr: 'Yüklü değil'
      ,zh_cn: '未载入'
      ,hu: 'Nincs betöltve'
      }
    ,'Food Editor' : {
      cs: 'Editor jídel'
      ,he: 'עורך המזון'
      ,de: 'Nahrungsmittel-Editor'
      ,es: 'Editor de alimentos'
      ,fr: 'Editeur aliments'
      ,el: 'Επεξεργασία Δεδομένων Φαγητών'
      ,pt: 'Editor de alimentos'
      ,ro: 'Editor alimente'
      ,bg: 'Редактор за храна'
      ,hr: 'Editor hrane'
      ,sv: 'Födoämneseditor'
      ,it: 'NS - Database Alimenti'
      ,ja: '食事編集'
      ,dk: 'Mad editor'
      ,fi: 'Muokkaa ruokia'
      ,nb: 'Mat editor'
      ,pl: 'Edytor posiłków'
      ,ru: 'Редактор продуктов'
      ,sk: 'Editor jedál'
      ,nl: 'Voeding beheer'
      ,ko: '음식 편집'
      ,tr: 'Gıda Editörü'
      ,zh_cn: '食物编辑器'
      ,hu: 'Étel szerkesztése'
      }
    ,'Your database' : {
      cs: 'Vaše databáze'
      ,de: 'Deine Datenbank'
      ,es: 'Su base de datos'
      ,fr: 'Votre base de données'
      ,el: 'Η Βάση Δεδομένων σας'
      ,pt: 'Seu banco de dados'
      ,sv: 'Din databas'
      ,ro: 'Baza de date'
      ,bg: 'Твоята база с данни'
      ,hr: 'Vaša baza podataka'
      ,it: 'Vostro database'
      ,ja: 'あなたのデータベース'
      ,dk: 'Din database'
      ,fi: 'Tietokantasi'
      ,nb: 'Din database'
      ,he: 'בסיס הנתונים שלך'
      ,pl: 'Twoja baza danych'
      ,ru: 'Ваша база данных '
      ,sk: 'Vaša databáza'
      ,nl: 'Uw database'
      ,ko: '당신의 데이터베이스'
      ,tr: 'Sizin Veritabanınız'
      ,zh_cn: '你的数据库'
      ,hu: 'Ön adatbázisa'
      }
    ,'Filter' : {
      cs: 'Filtr'
      ,de: 'Filter'
      ,es: 'Filtro'
      ,fr: 'Filtre'
      ,el: 'Φίλτρο'
      ,pt: 'Filtro'
      ,sv: 'Filter'
      ,ro: 'Filtru'
      ,bg: 'Филтър'
      ,hr: 'Filter'
      ,it: 'Filtro'
      ,ja: 'フィルター'
      ,dk: 'Filter'
      ,fi: 'Suodatin'
      ,nb: 'Filter'
      ,nl: 'Filter'
      ,he: 'סנן'
      ,pl: 'Filtr'
      ,ru: 'Фильтр'
      ,sk: 'Filter'
      ,ko: '필터'
      ,tr: 'Filtre'
      ,zh_cn: '过滤器'
      ,hu: 'Filter'
      }
    ,'Save' : {
      cs: 'Ulož'
      ,de: 'Speichern'
      ,es: 'Salvar'
      ,fr: 'Sauver'
      ,el: 'Αποθήκευση'
      ,pt: 'Salvar'
      ,ro: 'Salvează'
      ,bg: 'Запази'
      ,hr: 'Spremi'
      ,sv: 'Spara'
      ,it: 'Salva'
      ,ja: '保存'
      ,dk: 'Gem'
      ,fi: 'Tallenna'
      ,nb: 'Lagre'
      ,he: 'שמור'
      ,pl: 'Zapisz'
      ,ru: 'Сохранить'
      ,sk: 'Uložiť'
      ,nl: 'Opslaan'
      ,ko: '저장'
      ,tr: 'Kaydet'
      ,zh_cn: '保存'
      ,zh_tw: '保存'
      ,hu: 'Mentés'
      }
    ,'Clear' : {
      cs: 'Vymaž'
      ,de: 'Löschen'
      ,es: 'Limpiar'
      ,fr: 'Effacer'
      ,el: 'Καθαρισμός'
      ,pt: 'Apagar'
      ,ro: 'Inițializare'
      ,bg: 'Изчисти'
      ,hr: 'Očisti'
      ,sv: 'Rensa'
      ,it: 'Pulisci'
      ,ja: 'クリア'
      ,dk: 'Rense'
      ,fi: 'Tyhjennä'
      ,nb: 'Tøm'
      ,he: 'נקה'
      ,pl: 'Wyczyść'
      ,ru: 'Очистить'
      ,sk: 'Vymazať'
      ,nl: 'Leeg maken'
      ,ko: '취소'
      ,tr: 'Temizle'
      ,zh_cn: '清除'
      ,hu: 'Kitöröl'
      }
    ,'Record' : {
      cs: 'Záznam'
      ,de: 'Datensatz'
      ,es: 'Guardar'
      ,fr: 'Enregistrement'
      ,el: 'Εγγραφή'
      ,pt: 'Gravar'
      ,sv: 'Post'
      ,ro: 'Înregistrare'
      ,bg: 'Запиши'
      ,hr: 'Zapis'
      ,it: 'Registro'
      ,ja: '記録'
      ,dk: 'Post'
      ,fi: 'Tietue'
      ,nb: 'Registrering'
      ,he: 'רשומה'
      ,pl: 'Wpis'
      ,ru: 'Запись'
      ,sk: 'Záznam'
      ,nl: 'Toevoegen'
      ,ko: '기록'
      ,tr: 'Kayıt'
      ,zh_cn: '记录'
      ,hu: 'Bejegyzés'
      }
    ,'Quick picks' : {
      cs: 'Rychlý výběr'
      ,de: 'Schnellauswahl'
      ,es: 'Selección rápida'
      ,fr: 'Sélection rapide'
      ,el: 'Γρήγορη επιλογή'
      ,pt: 'Seleção rápida'
      ,ro: 'Selecție rapidă'
      ,bg: 'Бърз избор'
      ,hr: 'Brzi izbor'
      ,sv: 'Snabbval'
      ,it: 'Scelta rapida'
      ,ja: 'クイック選択'
      ,dk: 'Hurtig valg'
      ,fi: 'Nopeat valinnat'
      ,nb: 'Hurtigvalg'
      ,he: 'בחירה מהירה'
      ,pl: 'Szybki wybór'
      ,ru: 'Быстрый отбор'
      ,sk: 'Rýchly výber'
      ,nl: 'Snelkeuze'
      ,ko: '빠른 선택'
      ,tr: 'Hızlı seçim'
      ,zh_cn: '快速选择'
      ,hu: 'Gyors választás'
      }
    ,'Show hidden' : {
      cs: 'Zobraz skryté'
      ,de: 'Verborgenes zeigen'
      ,es: 'Mostrar ocultos'
      ,fr: 'Montrer cachés'
      ,el: 'Εμφάνιση κρυφών εγγραφών'
      ,pt: 'Mostrar ocultos'
      ,ro: 'Arată înregistrările ascunse'
      ,bg: 'Покажи скритото'
      ,hr: 'Prikaži skriveno'
      ,sv: 'Visa dolda'
      ,it: 'Mostra nascosto'
      ,ja: '表示する'
      ,dk: 'Vis skjulte'
      ,fi: 'Näytä piilotettu'
      ,nb: 'Vis skjulte'
      ,he: 'הצג נתונים מוסתרים'
      ,pl: 'Pokaż ukryte'
      ,ru: 'Показать скрытые'
      ,sk: 'Zobraziť skryté'
      ,nl: 'Laat verborgen zien'
      ,ko: '숨김 보기'
      ,tr: 'Gizli göster'
      ,zh_cn: '显示隐藏值'
      ,hu: 'Eltakart mutatása'
      }
    ,'Your API secret or token' : {
      fi: 'API salaisuus tai avain'
      ,pl: 'Twój hash API lub token'
      ,ru: 'Ваш пароль API или код доступа '
      ,de: 'Deine API-Prüfsumme oder Token'
      ,hu: 'Az API jelszo'
      }
    ,'Remember this device. (Do not enable this on public computers.)' : {
      fi: 'Muista tämä laite (Älä valitse julkisilla tietokoneilla)'
      , pl: 'Zapamiętaj to urządzenie (Nie używaj tej opcji korzystając z publicznych komputerów.)'
      ,ru: 'Запомнить это устройство (Не применяйте в общем доступе)'
      ,de: 'An dieses Gerät erinnern. (Nicht auf öffentlichen Geräten verwenden)'
      ,hu: 'A berendezés megjegyzése. (Csak saját berendezésen használd'
      }
    ,'Treatments' : {
      cs: 'Ošetření'
      ,de: 'Behandlungen'
      ,es: 'Tratamientos'
      ,fr: 'Traitements'
      ,el: 'Ενέργειες'
      ,pt: 'Procedimentos'
      ,sv: 'Behandling'
      ,ro: 'Tratamente'
      ,bg: 'Събития'
      ,hr: 'Tretmani'
      ,it: 'Somministrazioni'
      ,ja: '治療'
      ,dk: 'Behandling'
      ,fi: 'Hoitotoimenpiteet'
      ,nb: 'Behandlinger'
      ,he: 'טיפולים'
      ,pl: 'Leczenie'
      ,ru: 'Лечение'
      ,sk: 'Ošetrenie'
      ,nl: 'Behandelingen'
      ,ko: '관리'
      ,tr: 'Tedaviler'
      ,zh_cn: '操作'
      ,hu: 'Kezelések'
      }
    ,'Time' : {
      cs: 'Čas'
      ,de: 'Zeit'
      ,es: 'Hora'
      ,fr: 'Heure'
      ,el: 'Ώρα'
      ,pt: 'Hora'
      ,sv: 'Tid'
      ,ro: 'Ora'
      ,bg: 'Време'
      ,hr: 'Vrijeme'
      ,it: 'Tempo'
      ,ja: '時間'
      ,dk: 'Tid'
      ,fi: 'Aika'
      ,nb: 'Tid'
      ,he: 'זמן'
      ,pl: 'Czas'
      ,ru: 'Время'
      ,sk: 'Čas'
      ,nl: 'Tijd'
      ,ko: '시간'
      ,tr: 'Zaman'
      ,zh_cn: '时间'
      ,zh_tw: '時間'
      ,hu: 'Idő'
      }
    ,'Event Type' : {
      cs: 'Typ události'
      ,de: 'Ereignis-Typ'
      ,es: 'Tipo de evento'
      ,fr: 'Type d\'événement'
      ,el: 'Ενέργεια'
      ,pt: 'Tipo de evento'
      ,sv: 'Händelsetyp'
      ,ro: 'Tip eveniment'
      ,bg: 'Вид събитие'
      ,hr: 'Vrsta događaja'
      ,it: 'Tipo di evento'
      ,ja: 'イベント'
      ,dk: 'Hændelsestype'
      ,fi: 'Tapahtumatyyppi'
      ,nb: 'Type'
      ,he: 'סוג אירוע'
      ,pl: 'Typ zdarzenia'
      ,ru: 'Тип события'
      ,sk: 'Typ udalosti'
      ,nl: 'Soort'
      ,ko: '입력 유형'
      ,tr: 'Etkinlik tipi'
      ,zh_cn: '事件类型'
      ,hu: 'Esemény típusa'
      }
    ,'Blood Glucose' : {
      cs: 'Glykémie'
      ,de: 'Blutglukose'
      ,es: 'Glucemia'
      ,fr: 'Glycémie'
      ,el: 'Γλυκόζη Αίματος'
      ,pt: 'Glicemia'
      ,sv: 'Glukosvärde'
      ,ro: 'Glicemie'
      ,bg: 'Кръвна захар'
      ,hr: 'GUK'
      ,it: 'Glicemie'
      ,ja: '血糖値'
      ,dk: 'Glukoseværdi'
      ,fi: 'Verensokeri'
      ,nb: 'Blodsukker'
      ,he: 'סוכר בדם'
      ,pl: 'Glikemia z krwi'
      ,ru: 'Гликемия'
      ,sk: 'Glykémia'
      ,nl: 'Bloed glucose'
      ,ko: '혈당'
      ,tr: 'Kan Şekeri'
      ,zh_cn: '血糖值'
      ,hu: 'Vércukor szint'
      }
    ,'Entered By' : {
      cs: 'Zadal'
      ,de: 'Eingabe durch'
      ,es: 'Introducido por'
      ,fr: 'Entré par'
      ,el: 'Εισήχθη από'
      ,pt: 'Inserido por'
      ,sv: 'Inlagt av'
      ,ro: 'Introdus de'
      ,bg: 'Въведено от'
      ,hr: 'Unos izvršio'
      ,it: 'inserito da'
      ,ja: '入力者'
      ,dk: 'Indtastet af'
      ,fi: 'Tiedot syötti'
      ,nb: 'Lagt inn av'
      ,he: 'הוזן על-ידי'
      ,pl: 'Wprowadzono przez'
      ,ru: 'Внесено через'
      ,sk: 'Zadal'
      ,nl: 'Ingevoerd door'
      ,ko: '입력 내용'
      ,tr: 'Tarafından girildi'
      ,zh_cn: '输入人'
      ,hu: 'Beírta'
      }
    ,'Delete this treatment?' : {
      cs: 'Vymazat toto ošetření?'
      ,de: 'Diese Behandlung löschen?'
      ,es: '¿Borrar este tratamiento?'
      ,fr: 'Effacer ce traitement?'
      ,el: 'Διαγραφή ενέργειας'
      ,pt: 'Apagar este procedimento?'
      ,ro: 'Șterge acest eveniment?'
      ,bg: 'Изтрий това събитие'
      ,hr: 'Izbriši ovaj tretman?'
      ,sv: 'Ta bort händelse?'
      ,it: 'Eliminare questa somministrazione?'
      ,ja: 'この治療データを削除しますか？'
      ,dk: 'Slet denne hændelse?'
      ,fi: 'Tuhoa tämä hoitotoimenpide?'
      ,nb: 'Slett denne hendelsen?'
      ,he: 'למחוק רשומה זו?'
      ,pl: 'Usunąć te leczenie?'
      ,ru: 'Удалить это событие?'
      ,sk: 'Vymazať toto ošetrenie?'
      ,nl: 'Verwijder'
      ,ko: '이 대처를 지울까요?'
      ,tr: 'Bu tedaviyi sil?'
      ,zh_cn: '删除这个操作？'
      ,hu: 'Kezelés törlése?'
      }
    ,'Carbs Given' : {
      cs: 'Sacharidů'
      ,de: 'Kohlenhydratgabe'
      ,es: 'Hidratos de carbono dados'
      ,fr: 'Glucides donnés'
      ,el: 'Υδατάνθρακες'
      ,pt: 'Carboidratos'
      ,ro: 'Carbohidrați'
      ,bg: 'ВХ'
      ,hr: 'Količina UGH'
      ,sv: 'Antal kolhydrater'
      ,it: 'Carboidrati'
      ,ja: '摂取糖質量'
      ,dk: 'Antal kulhydrater'
      ,fi: 'Hiilihydraatit'
      ,nb: 'Karbo'
      ,he: 'פחמימות שנאכלו'
      ,pl: 'Węglowodany spożyte'
      ,ru: 'Дано углеводов'
      ,sk: 'Sacharidov'
      ,nl: 'Aantal koolhydraten'
      ,ko: '탄수화물 요구량'
      ,tr: 'Karbonhidrat Verilen'
      ,zh_cn: '碳水化合物量'
      ,hu: 'Szénhidrátok'
      }
    ,'Inzulin Given' : {
      cs: 'Inzulínu'
      ,de: 'Insulingabe'
      ,es: 'Insulina dada'
      ,fr: 'Insuline donnée'
      ,el: 'Ινσουλίνη'
      ,pt: 'Insulina'
      ,ro: 'Insulină administrată'
      ,bg: 'Инсулин'
      ,hr: 'Količina inzulina'
      ,sv: 'Insulin'
      ,it: 'Insulina'
      ,ja: 'インスリン投与量'
      ,dk: 'Insulin'
      ,fi: 'Insuliiniannos'
      ,nb: 'Insulin'
      ,he: 'אינסולין שניתן'
      ,pl: 'Insulina podana'
      ,ru: 'Дано инсулина'
      ,sk: 'Inzulínu'
      ,nl: 'Insuline'
      ,ko: '인슐린 요구량'
      ,tr: 'İnsülin Verilen'
      ,zh_cn: '胰岛素输注'
      ,hu: 'Beadott inzulin'
      }
    ,'Event Time' : {
      cs: 'Čas události'
      ,de: 'Ereignis-Zeit'
      ,es: 'Hora del evento'
      ,fr: 'Heure de l\'événement'
      ,el: 'Ώρα ενέργειας'
      ,pt: 'Hora do evento'
      ,sv: 'Klockslag'
      ,ro: 'Ora evenimentului'
      ,bg: 'Въвеждане'
      ,hr: 'Vrijeme događaja'
      ,it: 'Ora Evento'
      ,ja: 'イベント時間'
      ,dk: 'Tidspunkt for hændelsen'
      ,fi: 'Aika'
      ,nb: 'Tidspunkt for hendelsen'
      ,he: 'זמן האירוע'
      ,pl: 'Czas zdarzenia'
      ,ru: 'Время события'
      ,sk: 'Čas udalosti'
      ,nl: 'Tijdstip'
      ,ko: '입력 시간'
      ,tr: 'Etkinliğin zamanı'
      ,zh_cn: '事件时间'
      ,hu: 'Időpont'
      }
    ,'Please verify that the data entered is correct' : {
      cs: 'Prosím zkontrolujte, zda jsou údaje zadány správně'
      ,de: 'Bitte Daten auf Plausibilität prüfen'
      ,es: 'Por favor, verifique que los datos introducidos son correctos'
      ,el: 'Παρακαλώ ελέξτε ότι τα δεδομένα είναι σωστά'
      ,fr: 'Merci de vérifier la correction des données entrées'
      ,pt: 'Favor verificar se os dados estão corretos'
      ,ro: 'Verificați conexiunea datelor introduse'
      ,bg: 'Моля проверете, че датата е въведена правилно'
      ,hr: 'Molim Vas provjerite jesu li uneseni podaci ispravni'
      ,sv: 'Vänligen verifiera att inlagd data är korrekt'
      ,it: 'Si prega di verificare che i dati inseriti siano corretti'
      ,ja: '入力したデータが正しいか確認をお願いします。'
      ,dk: 'Venligst verificer at indtastet data er korrekt'
      ,fi: 'Varmista, että tiedot ovat oikein'
      ,nb: 'Vennligst verifiser at inntastet data er korrekt'
      ,he: 'נא לוודא שהמידע שהוזן הוא נכון ומדוייק'
      ,pl: 'Proszę sprawdzić, czy wprowadzone dane są prawidłowe'
      ,ru: 'Проверьте правильность вводимых данных'
      ,sk: 'Prosím, skontrolujte správnosť zadaných údajov'
      ,nl: 'Controleer uw invoer'
      ,ko: '입력한 데이터가 정확한지 확인해 주세요.'
      ,tr: 'Lütfen girilen verilerin doğru olduğunu kontrol edin.'
      ,zh_cn: '请验证输入的数据是否正确'
      ,hu: 'Kérlek ellenőrizd, hogy az adatok helyesek.'
      }
    ,'BG' : {
      cs: 'Glykémie'
      ,he: 'סוכר בדם'
      ,de: 'BG'
      ,es: 'Glucemia en sangre'
      ,fr: 'Glycémie'
      ,el: 'Τιμή Γλυκόζης Αίματος'
      ,pt: 'Glicemia'
      ,sv: 'BS'
      ,ro: 'Glicemie'
      ,bg: 'КЗ'
      ,hr: 'GUK'
      ,it: 'Glicemie'
      ,ja: 'BG'
      ,dk: 'BS'
      ,fi: 'VS'
      ,nb: 'BS'
      ,pl: 'BG'
      ,ru: 'ГК'
      ,sk: 'Glykémia'
      ,nl: 'BG'
      ,ko: '혈당'
      ,tr: 'KŞ'
      ,zh_cn: '血糖'
      ,hu: 'Cukorszint'
      }
    ,'Use BG correction in calculation' : {
      cs: 'Použij korekci na glykémii'
      ,he: 'השתמש ברמת סוכר בדם לצורך החישוב'
      ,de: 'Verwende BG-Korrektur zur Kalkulation'
      ,es: 'Usar la corrección de glucemia en los cálculos'
      ,fr: 'Utiliser la correction de glycémie dans les calculs'
      ,el: 'Χρήση τη διόρθωσης της τιμής γλυκόζης για τον υπολογισμό'
      ,pt: 'Usar correção de glicemia nos cálculos'
      ,ro: 'Folosește corecția de glicemie în calcule'
      ,bg: 'Използвай корекцията за КЗ в изчислението'
      ,hr: 'Koristi korekciju GUK-a u izračunu'
      ,sv: 'Använd BS-korrektion för beräkning'
      ,it: 'Utilizzare la correzione nei calcoli delle Glicemie'
      ,ja: 'ボーラス計算機能使用'
      ,dk: 'Anvend BS-korrektion i beregning'
      ,fi: 'Käytä korjausannosta laskentaan'
      ,nb: 'Bruk blodsukkerkorrigering i beregning'
      ,pl: 'Użyj BG w obliczeniach korekty'
      ,ru: 'При расчете учитывать коррекцию ГК'
      ,sk: 'Použite korekciu na glykémiu'
      ,nl: 'Gebruik BG in berekeningen'
      ,ko: '계산에 보정된 혈당을 사용하세요.'
      ,tr: 'Hesaplamada KŞ düzeltmesini kullan'
      ,zh_cn: '使用血糖值修正计算'
      ,hu: 'Használj korekciót a számításban'
      }
    ,'BG from CGM (autoupdated)' : {
      cs: 'Glykémie z CGM (automaticky aktualizovaná)'
      ,he: 'רמת סוכר מהחיישן , מעודכן אוטומטית'
      ,de: 'Blutglukose vom CGM (Auto-Update)'
      ,es: 'Glucemia del sensor (Auto-actualizado)'
      ,fr: 'Glycémie CGM (automatique)'
      ,el: 'Τιμή γλυκόζης από τον αισθητήρα (αυτόματο)'
      ,pt: 'Glicemia do sensor (Automático)'
      ,sv: 'BS från CGM (automatiskt)'
      ,ro: 'Glicemie în senzor (automat)'
      ,bg: 'КЗ от сензора (автоматично)'
      ,hr: 'GUK sa CGM-a (ažuriran automatski)'
      ,it: 'Glicemie da CGM (aggiornamento automatico)'
      ,ja: 'CGMグルコース値'
      ,dk: 'BS fra CGM (automatisk)'
      ,fi: 'VS sensorilta (päivitetty automaattisesti)'
      ,nb: 'BS fra CGM (automatisk)'
      ,pl: 'Wartość BG z CGM (automatycznie)'
      ,ru: 'ГК с сенсора (автообновление)'
      ,sk: 'Glykémia z CGM (automatická aktualizácia) '
      ,nl: 'BG van CGM (automatische invoer)'
      ,ko: 'CGM 혈당(자동 업데이트)'
      ,tr: 'CGM den KŞ (otomatik güncelleme)'
      ,zh_cn: 'CGM（连续血糖监测）测量的血糖值（自动更新）'
      ,hu: 'Cukorszint a CGM-ből (Automatikus frissítés)'
      }
    ,'BG from meter' : {
      cs: 'Glykémie z glukoměru'
      ,he: 'רמת סוכר ממד הסוכר'
      ,de: 'Blutzucker vom Messgerät'
      ,es: 'Glucemia del glucómetro'
      ,fr: 'Glycémie du glucomètre'
      ,el: 'Τιμή γλυκόζης από τον μετρητή'
      ,pt: 'Glicemia do glicosímetro'
      ,sv: 'BS från blodsockermätare'
      ,ro: 'Glicemie în glucometru'
      ,bg: 'КЗ от глюкомер'
      ,hr: 'GUK s glukometra'
      ,it: 'Glicemie da glucometro'
      ,ja: '血糖測定器使用グルコース値'
      ,dk: 'BS fra blodsukkerapperat'
      ,fi: 'VS mittarilta'
      ,nb: 'BS fra blodsukkerapparat'
      ,pl: 'Wartość BG z glukometru'
      ,ru: 'ГК по глюкометру'
      ,sk: 'Glykémia z glukomeru'
      ,nl: 'BG van meter'
      ,ko: '혈당 측정기에서의  혈당'
      ,tr: 'Glikometre KŞ'
      ,zh_cn: '血糖仪测量的血糖值'
      ,hu: 'Cukorszint a merőből'
      }
    ,'Manual BG' : {
      cs: 'Ručně zadaná glykémie'
      ,he: 'רמת סוכר ידנית'
      ,de: 'BG von Hand'
      ,es: 'Glucemia manual'
      ,fr: 'Glycémie manuelle'
      ,el: 'Χειροκίνητη εισαγωγή τιμής γλυκόζης'
      ,pt: 'Glicemia Manual'
      ,ro: 'Glicemie manuală'
      ,bg: 'Ръчно въведена КЗ'
      ,hr: 'Ručno unesen GUK'
      ,sv: 'Manuellt BS'
      ,it: 'Inserisci Glicemia'
      ,ja: '手動入力グルコース値'
      ,dk: 'Manuelt BS'
      ,fi: 'Käsin syötetty VS'
      ,nb: 'Manuelt BS'
      ,pl: 'Ręczne wprowadzenie BG'
      ,ru: 'ручной ввод ГК'
      ,sk: 'Ručne zadaná glykémia'
      ,nl: 'Handmatige BG'
      ,ko: '수동 입력 혈당'
      ,tr: 'Manuel KŞ'
      ,zh_cn: '手动输入的血糖值'
      ,hu: 'Kézi cukorszint'
      }
    ,'Quickpick' : {
      cs: 'Rychlý výběr'
      ,he: 'בחירה מהירה'
      ,de: 'Schnellauswahl'
      ,es: 'Selección rápida'
      ,fr: 'Sélection rapide'
      ,el: 'Γρήγορη εισαγωγή'
      ,pt: 'Seleção rápida'
      ,ro: 'Selecție rapidă'
      ,bg: 'Бърз избор'
      ,hr: 'Brzi izbor'
      ,sv: 'Snabbval'
      ,it: 'Scelta rapida'
      ,ja: 'クイック選択'
      ,dk: 'Hurtig valg'
      ,fi: 'Pikavalinta'
      ,nb: 'Hurtigvalg'
      ,pl: 'Szybki wybór'
      ,ru: 'Быстрый отбор'
      ,sk: 'Rýchly výber'
      ,nl: 'Snelkeuze'
      ,ko: '빠른 선택'
      ,tr: 'Hızlı seçim'
      ,zh_cn: '快速选择'
      ,hu: 'Gyors választás'
      }
    ,'or' : {
      cs: 'nebo'
      ,de: 'oder'
      ,es: 'o'
      ,fr: 'ou'
      ,el: 'ή'
      ,pt: 'ou'
      ,sv: 'Eller'
      ,ro: 'sau'
      ,bg: 'или'
      ,hr: 'ili'
      ,it: 'o'
      ,ja: 'または'
      ,dk: 'eller'
      ,fi: 'tai'
      ,nb: 'eller'
      ,he: 'או'
      ,pl: 'lub'
      ,ru: 'или'
      ,sk: 'alebo'
      ,nl: 'of'
      ,ko: '또는'
      ,tr: 'veya'
      ,zh_cn: '或'
      ,hu: 'vagy'
      }
    ,'Add from database' : {
      cs: 'Přidat z databáze'
      ,de: 'Ergänze aus Datenbank'
      ,es: 'Añadir desde la base de datos'
      ,fr: 'Ajouter à partir de la base de données'
      ,el: 'Επιλογή από τη Βάση Δεδομένων'
      ,pt: 'Adicionar do banco de dados'
      ,ro: 'Adaugă din baza de date'
      ,bg: 'Добави от базата с данни'
      ,hr: 'Dodaj iz baze podataka'
      ,sv: 'Lägg till från databas'
      ,it: 'Aggiungi dal database'
      ,ja: 'データベースから追加'
      ,dk: 'Tilføj fra database'
      ,fi: 'Lisää tietokannasta'
      ,nb: 'Legg til fra database'
      ,he: 'הוסף מבסיס נתונים'
      ,pl: 'Dodaj z bazy danych'
      ,ru: 'Добавить из базы данных'
      ,sk: 'Pridať z databázy'
      ,nl: 'Toevoegen uit database'
      ,ko: '데이터베이스로 부터 추가'
      ,tr: 'Veritabanından ekle'
      ,zh_cn: '从数据库增加'
      ,hu: 'Hozzáadás adatbázisból'
      }
    ,'Use carbs correction in calculation' : {
      cs: 'Použij korekci na sacharidy'
      ,he: 'השתמש בתיקון פחמימות במהלך החישוב'
      ,de: 'Verwende Kohlenhydrate-Korrektur zur Kalkulation'
      ,es: 'Usar la corrección de hidratos de carbono en los cálculos'
      ,fr: 'Utiliser la correction en glucides dans les calculs'
      ,el: 'Χρήση των νέων υδατανθράκων που εισήχθησαν για τον υπολογισμό'
      ,pt: 'Usar correção com carboidratos no cálculo'
      ,ro: 'Folosește corecția de carbohidrați în calcule'
      ,bg: 'Включи корекцията чрез ВХ в изчислението'
      ,hr: 'Koristi korekciju za UH u izračunu'
      ,sv: 'Använd kolhydratkorrektion för beräkning'
      ,it: 'Utilizzare la correzione dei carboidrati nel calcolo'
      ,ja: '糖質量計算機能を使用'
      ,dk: 'Benyt kulhydratkorrektion i beregning'
      ,fi: 'Käytä hiilihydraattikorjausta laskennassa'
      ,nb: 'Bruk karbohydratkorrigering i beregning'
      ,pl: 'Użyj wartość węglowodanów w obliczeniach korekty'
      ,ru: 'Пользоваться коррекцией на углеводы при расчете'
      ,sk: 'Použite korekciu na sacharidy'
      ,nl: 'Gebruik KH correctie in berekening'
      ,ko: '계산에 보정된 탄수화물을 사용하세요.'
      ,tr: 'Hesaplamada karbonhidrat düzeltmesini kullan'
      ,zh_cn: '使用碳水化合物修正计算结果'
      ,hu: 'Használj szénhidrát korekciót a számításban'
      }
    ,'Use COB correction in calculation' : {
      cs: 'Použij korekci na COB'
      ,he: 'השתמש בתיקון פחמימות בגוף במהלך החישוב'
      ,de: 'Verwende verzehrte Kohlenhydrate zur Kalkulation'
      ,es: 'Usar carbohidratos activos para los cálculos'
      ,fr: 'Utiliser les COB dans les calculs'
      ,el: 'Χρήση των υδατανθράκων που απομένουν για τον υπολογισμό'
      ,pt: 'Usar correção de COB no cálculo'
      ,ro: 'Folosește COB în calcule'
      ,bg: 'Включи активните ВХ в изчислението'
      ,hr: 'Koristi aktivne UGH u izračunu'
      ,sv: 'Använd aktiva kolhydrater för beräkning'
      ,it: 'Utilizzare la correzione COB nel calcolo'
      ,ja: 'COB補正計算を使用'
      ,dk: 'Benyt aktive kulhydrater i beregning'
      ,fi: 'Käytä aktiivisia hiilihydraatteja laskennassa'
      ,nb: 'Benytt aktive karbohydrater i beregning'
      ,pl: 'Użyj COB do obliczenia korekty'
      ,ru: 'Учитывать активные углеводы COB при расчете'
      ,sk: 'Použite korekciu na COB'
      ,nl: 'Gebruik ingenomen KH in berekening'
      ,ko: '계산에 보정된 COB를 사용하세요.'
      ,tr: 'Hesaplamada COB aktif karbonhidrat düzeltmesini kullan'
      ,zh_cn: '使用COB（活性碳水化合物）修正计算结果'
      ,hu: 'Használj COB korrekciót a számításban'
      }
    ,'Use IOB in calculation' : {
      cs: 'Použij IOB ve výpočtu'
      ,he: 'השתמש בתיקון אינסולין בגוף במהלך החישוב'
      ,de: 'Verwende gespritzes Insulin zur Kalkulation'
      ,es: 'Usar Insulina activa en los cálculos'
      ,fr: 'Utiliser l\'IOB dans les calculs'
      ,el: 'Χρήση της υπολογισθείσας ινσουλίνης που έχει απομείνει για τον υπολογισμό'
      ,pt: 'Usar IOB no cálculo'
      ,ro: 'Folosește IOB în calcule'
      ,bg: 'Включи активния инсулин в изчислението'
      ,hr: 'Koristi aktivni inzulin u izračunu'
      ,sv: 'Använd aktivt insulin för beräkning'
      ,it: 'Utilizzare la correzione IOB nel calcolo'
      ,ja: 'IOB計算を使用'
      ,dk: 'Benyt aktivt insulin i beregningen'
      ,fi: 'Käytä aktiviivista insuliinia laskennassa'
      ,nb: 'Bruk aktivt insulin i beregningen'
      ,pl: 'Użyj IOB w obliczeniach'
      ,ru: 'Учитывать активный инсулин IOB при расчете'
      ,sk: 'Použite IOB vo výpočte'
      ,nl: 'Gebruik IOB in berekening'
      ,ko: '계산에 IOB를 사용하세요.'
      ,tr: 'Hesaplamada IOB aktif insülin düzeltmesini kullan'
      ,zh_cn: '使用IOB（活性胰岛素）修正计算结果'
      ,hu: 'Használj IOB kalkulációt'
      }
    ,'Other correction' : {
      cs: 'Jiná korekce'
      ,he: 'תיקון אחר'
      ,de: 'Weitere Korrektur'
      ,es: 'Otra corrección'
      ,fr: 'Autre correction'
      ,el: 'Άλλη διόρθωση'
      ,pt: 'Outra correção'
      ,ro: 'Alte corecții'
      ,bg: 'Друга корекция'
      ,hr: 'Druga korekcija'
      ,sv: 'Övrig korrektion'
      ,it: 'Altre correzioni'
      ,ja: 'その他の補正'
      ,dk: 'Øvrig korrektion'
      ,fi: 'Muu korjaus'
      ,nb: 'Annen korrigering'
      ,pl: 'Inna korekta'
      ,ru: 'Иная коррекция'
      ,sk: 'Iná korekcia'
      ,nl: 'Andere correctie'
      ,ko: '다른 보정'
      ,tr: 'Diğer düzeltme'
      ,zh_cn: '其它修正'
      ,hu: 'Egyébb korrekció'
      }
    ,'Rounding' : {
      cs: 'Zaokrouhlení'
      ,he: 'עיגול'
      ,de: 'Gerundet'
      ,es: 'Redondeo'
      ,fr: 'Arrondi'
      ,el: 'Στρογγυλοποίηση'
      ,pt: 'Arredondamento'
      ,sv: 'Avrundning'
      ,ro: 'Rotunjire'
      ,bg: 'Закръгляне'
      ,hr: 'Zaokruživanje'
      ,it: 'Arrotondamento'
      ,ja: '端数処理'
      ,dk: 'Afrunding'
      ,fi: 'Pyöristys'
      ,nb: 'Avrunding'
      ,pl: 'Zaokrąglanie'
      ,ru: 'Округление'
      ,sk: 'Zaokrúhlenie'
      ,nl: 'Afgerond'
      ,ko: '라운딩'
      ,tr: 'yuvarlama'
      ,zh_cn: '取整'
      ,hu: 'Kerekítés'
      }
    ,'Enter insulin correction in treatment' : {
      cs: 'Zahrň inzulín do záznamu ošetření'
      ,he: 'הזן תיקון אינסולין בטיפול'
      ,de: 'Insulin Korrektur zur Behandlung eingeben'
      ,es: 'Introducir correción de insulina en tratamiento'
      ,fr: 'Entrer correction insuline dans le traitement'
      ,el: 'Υπολογισθείσα ποσότητα ινσουλίνης που απαιτείται'
      ,pt: 'Inserir correção com insulina no tratamento'
      ,ro: 'Introdu corecția de insulină în tratament'
      ,bg: 'Въведи корекция с инсулин като лечение'
      ,hr: 'Unesi korekciju inzulinom u tretman'
      ,sv: 'Ange insulinkorrektion för händelse'
      ,it: 'Inserisci correzione insulina nella somministrazione'
      ,ja: '治療にインスリン補正を入力する。'
      ,dk: 'Indtast insulinkorrektion'
      ,fi: 'Syötä insuliinikorjaus'
      ,nb: 'Task inn insulinkorrigering'
      ,pl: 'Wprowadź wartość korekty w leczeniu'
      ,ru: 'Внести коррекцию инсулина в лечение'
      ,sk: 'Zadajte korekciu inzulínu do ošetrenia'
      ,nl: 'Voer insuline correctie toe aan behandeling'
      ,ko: '대처를 위해 보정된 인슐린을 입력하세요.'
      ,tr: 'Tedavide insülin düzeltmesini girin'
      ,zh_cn: '在操作中输入胰岛素修正'
      ,hu: 'Add be az inzulin korrekciót a kezeléshez'
      }
    ,'Insulin needed' : {
      cs: 'Potřebný inzulín'
      ,de: 'Benötigtes Insulin'
      ,es: 'Insulina necesaria'
      ,fr: 'Insuline nécessaire'
      ,el: 'Απαιτούμενη Ινσουλίνη'
      ,pt: 'Insulina necessária'
      ,ro: 'Necesar insulină'
      ,bg: 'Необходим инсулин'
      ,hr: 'Potrebno inzulina'
      ,sv: 'Beräknad insulinmängd'
      ,it: 'Insulina necessaria'
      ,ja: '必要インスリン単位'
      ,dk: 'Insulin påkrævet'
      ,fi: 'Insuliinitarve'
      ,nb: 'Insulin nødvendig'
      ,he: 'אינסולין נדרש'
      ,pl: 'Wymagana insulina'
      ,ru: 'Требуется инсулин'
      ,sk: 'Potrebný inzulín'
      ,nl: 'Benodigde insuline'
      ,ko: '인슐린 필요'
      ,tr: 'İnsülin gerekli'
      ,zh_cn: '需要的胰岛素量'
      ,hu: 'Inzulin szükséges'
      }
    ,'Carbs needed' : {
      cs: 'Potřebné sach'
      ,de: 'Benötigte Kohlenhydrate'
      ,es: 'Hidratos de carbono necesarios'
      ,fr: 'Glucides nécessaires'
      ,el: 'Απαιτούμενοι Υδατάνθρακες'
      ,pt: 'Carboidratos necessários'
      ,ro: 'Necesar carbohidrați'
      ,bg: 'Необходими въглехидрати'
      ,hr: 'Potrebno UGH'
      ,sv: 'Beräknad kolhydratmängd'
      ,it: 'Carboidrati necessari'
      ,ja: '必要糖質量'
      ,dk: 'Kulhydrater påkrævet'
      ,fi: 'Hiilihydraattitarve'
      ,nb: 'Karbohydrater nødvendig'
      ,he: 'פחמימות נדרשות'
      ,pl: 'Wymagane węglowodany'
      ,ru: 'Требуются углеводы'
      ,sk: 'Potrebné sacharidy'
      ,nl: 'Benodigde koolhydraten'
      ,ko: '탄수화물 필요'
      ,tr: 'Karbonhidrat gerekli'
      ,zh_cn: '需要的碳水量'
      ,hu: 'Szenhidrát szükséges'
      }
    ,'Carbs needed if Insulin total is negative value' : {
      cs: 'Chybějící sacharidy v případě, že výsledek je záporný'
      ,he: 'פחמימות דרושות אם סך אינסולין הוא שלילי'
      ,de: 'Benötigte Kohlenhydrate sofern Gesamtinsulin einen negativen Wert aufweist'
      ,es: 'Carbohidratos necesarios si total insulina es un valor negativo'
      ,fr: 'Glucides nécessaires si insuline totale est un valeur négative'
      ,el: 'Απαιτούνται υδατάνθρακες εάν η συνολική ινσουλίνη έχει αρνητική τιμή'
      ,pt: 'Carboidratos necessários se Insulina total for negativa'
      ,ro: 'Carbohidrați când necesarul de insulină este negativ'
      ,bg: 'Необходими въглехидрати, ако няма инсулин'
      ,hr: 'Potrebno UGH ako je ukupna vrijednost inzulina negativna'
      ,sv: 'Nödvändig kolhydratmängd för angiven insulinmängd'
      ,it: 'Carboidrati necessari se l\'insulina totale è un valore negativo'
      ,ja: 'インスリン合計値がマイナスであればカーボ値入力が必要です。'
      ,dk: 'Kulhydrater er nødvendige når total insulin mængde er negativ'
      ,fi: 'Hiilihydraattitarve, jos yhteenlaskettu insuliini on negatiivinen'
      ,nb: 'Karbohydrater er nødvendige når total insulinmengde er negativ'
      ,pl: 'Wymagane węglowodany, jeśli łączna wartość Insuliny jest ujemna'
      ,ru: 'Требуются углеводы если суммарный инсулин отрицательная величина'
      ,sk: 'Potrebné sacharidy, ak je celkový inzulín záporná hodnota'
      ,nl: 'Benodigde KH als insuline een negatieve waarde geeft'
      ,ko: '인슐린 전체가 마이너스 값이면 탄수화물이 필요합니다.'
      ,tr: 'Toplam insülin negatif değer olduğunda karbonhidrat gereklidir'
      ,zh_cn: '如果胰岛素总量为负时所需的碳水化合物量'
      ,hu: 'Szénhidrát szükséges ha az összes inzulin negatív érték'
      }
    ,'Basal rate' : {
      cs: 'Bazál'
      ,he: 'קצב בזלי'
      ,de: 'Basalrate'
      ,es: 'Tasa basal'
      ,fr: 'Taux basal'
      ,el: 'Ρυθμός Βασικής Ινσουλίνης'
      ,pt: 'Taxa basal'
      ,ro: 'Rata bazală'
      ,bg: 'Базален инсулин'
      ,hr: 'Bazal'
      ,sv: 'Basaldos'
      ,it: 'Velocità basale'
      ,ja: '基礎インスリン割合'
      ,dk: 'Basal rate'
      ,fi: 'Perusannos'
      ,nb: 'Basal'
      ,pl: 'Dawka bazowa'
      ,ru: 'Скорость базала'
      ,sk: 'Bazál'
      ,nl: 'Basaal snelheid'
      ,ko: 'Basal 단위'
      ,tr: 'Basal oranı'
      ,zh_cn: '基础率'
      ,hu: 'Bazál arány'
      }
    ,'60 minutes earlier' : {
      cs: '60 min předem'
      ,he: 'שישים דקות מוקדם יותר'
      ,de: '60 Minuten früher'
      ,es: '60 min antes'
      ,fr: '60 min plus tôt'
      ,el: '60 λεπτά πριν'
      ,pt: '60 min antes'
      ,sv: '60 min tidigare'
      ,ro: 'acum 60 min'
      ,bg: 'Преди 60 минути'
      ,hr: 'Prije 60 minuta'
      ,it: '60 minuti prima'
      ,ja: '60分前'
      ,dk: '60 min tidligere'
      ,fi: '60 minuuttia aiemmin'
      ,nb: '60 min tidligere'
      ,pl: '60 minut wcześniej'
      ,ru: 'на 60 минут ранее'
      ,sk: '60 min. pred'
      ,nl: '60 minuten eerder'
      ,ko: '60분 더 일찍'
      ,tr: '60 dak. önce' //erken önce ???
      ,zh_cn: '60分钟前'
      ,hu: '60 perccel korábban'
      }
    ,'45 minutes earlier' : {
      cs: '45 min předem'
      ,he: 'ארבעים דקות מוקדם יותר'
      ,de: '45 Minuten früher'
      ,es: '45 min antes'
      ,fr: '45 min plus tôt'
      ,el: '45 λεπτά πριν'
      ,pt: '45 min antes'
      ,sv: '45 min tidigare'
      ,ro: 'acum 45 min'
      ,bg: 'Преди 45 минути'
      ,hr: 'Prije 45 minuta'
      ,it: '45 minuti prima'
      ,ja: '45分前'
      ,dk: '45 min tidligere'
      ,fi: '45 minuuttia aiemmin'
      ,nb: '45 min tidligere'
      ,pl: '45 minut wcześniej'
      ,ru: 'на 45 минут ранее'
      ,sk: '45 min. pred'
      ,nl: '45 minuten eerder'
      ,ko: '45분 더 일찍'
      ,tr: '45 dak. önce'
      ,zh_cn: '45分钟前'
      ,hu: '45 perccel korábban'
      }
    ,'30 minutes earlier' : {
      cs: '30 min předem'
      ,he: 'שלושים דקות מוקדם יותר'
      ,de: '30 Minuten früher'
      ,es: '30 min antes'
      ,fr: '30 min plus tôt'
      ,el: '30 λεπτά πριν'
      ,pt: '30 min antes'
      ,sv: '30 min tidigare'
      ,ro: 'acum 30 min'
      ,bg: 'Преди 30 минути'
      ,hr: 'Prije 30 minuta'
      ,it: '30 minuti prima'
      ,ja: '30分前'
      ,dk: '30 min tidigere'
      ,fi: '30 minuuttia aiemmin'
      ,nb: '30 min tidigere'
      ,pl: '30 minut wcześniej'
      ,ru: 'на 30 минут ранее'
      ,sk: '30 min. pred'
      ,nl: '30 minuten eerder'
      ,ko: '30분 더 일찍'
      ,tr: '30 dak. önce'
      ,zh_cn: '30分钟前'
      ,hu: '30 perccel korábban'
      }
    ,'20 minutes earlier' : {
      cs: '20 min předem'
      ,he: 'עשרים דקות מוקדם יותר'
      ,de: '20 Minuten früher'
      ,es: '20 min antes'
      ,fr: '20 min plus tôt'
      ,el: '20 λεπτά πριν'
      ,pt: '20 min antes'
      ,sv: '20 min tidigare'
      ,ro: 'acum 20 min'
      ,bg: 'Преди 20 минути'
      ,hr: 'Prije 20 minuta'
      ,it: '20 minuti prima'
      ,ja: '20分前'
      ,dk: '20 min tidligere'
      ,fi: '20 minuuttia aiemmin'
      ,nb: '20 min tidligere'
      ,pl: '20 minut wcześniej'
      ,ru: 'на 20 минут ранее'
      ,sk: '20 min. pred'
      ,nl: '20 minuten eerder'
      ,ko: '20분 더 일찍'
      ,tr: '20 dak. önce'
      ,zh_cn: '20分钟前'
      ,hu: '20 perccel korábban'
      }
    ,'15 minutes earlier' : {
      cs: '15 min předem'
      ,he: 'חמש עשרה דקות מוקדם יותר'
      ,de: '15 Minuten früher'
      ,es: '15 min antes'
      ,fr: '15 min plus tôt'
      ,el: '15 λεπτά πριν'
      ,pt: '15 min antes'
      ,sv: '15 min tidigare'
      ,ro: 'acum 15 min'
      ,bg: 'Преди 15 минути'
      ,hr: 'Prije 15 minuta'
      ,it: '15 minuti prima'
      ,ja: '15分前'
      ,dk: '15 min tidligere'
      ,fi: '15 minuuttia aiemmin'
      ,nb: '15 min tidligere'
      ,pl: '15 minut wcześniej'
      ,ru: 'на 15 минут ранее'
      ,sk: '15 min. pred'
      ,nl: '15 minuten eerder'
      ,ko: '15분 더 일찍'
      ,tr: '15 dak. önce'
      ,zh_cn: '15分钟前'
      ,hu: '15 perccel korábban'
      }
    ,'Time in minutes' : {
      cs: 'Čas v minutách'
      ,de: 'Zeit in Minuten'
      ,es: 'Tiempo en minutos'
      ,fr: 'Durée en minutes'
      ,el: 'Χρόνος σε λεπτά'
      ,pt: 'Tempo em minutos'
      ,sv: 'Tid i minuter'
      ,ro: 'Timp în minute'
      ,bg: 'Времето в минути'
      ,hr: 'Vrijeme u minutama'
      ,it: 'Tempo in minuti'
      ,dk: 'Tid i minutter'
      ,fi: 'Aika minuuteissa'
      ,nb: 'Tid i minutter'
      ,he: 'זמן בדקות'
      ,pl: 'Czas w minutach'
      ,ru: 'Время в минутах'
      ,sk: 'Čas v minútach'
      ,nl: 'Tijd in minuten'
      ,ko: '분'
      ,tr: 'Dakika cinsinden süre'
      ,zh_cn: '1分钟前'
      ,hu: 'Idő percekben'
      }
    ,'15 minutes later' : {
      cs: '15 min po'
      ,de: '15 Minuten später'
      ,es: '15 min más tarde'
      ,fr: '15 min plus tard'
      ,el: '15 λεπτά αργότερα'
      ,pt: '15 min depois'
      ,ro: 'după 15 min'
      ,bg: 'След 15 минути'
      ,hr: '15 minuta kasnije'
      ,sv: '15 min senare'
      ,it: '15 minuti più tardi'
      ,ja: '15分後'
      ,dk: '15 min senere'
      ,fi: '15 minuuttia myöhemmin'
      ,nb: '15 min senere'
      ,he: 'רבע שעה מאוחר יותר'
      ,pl: '15 minut później'
      ,ru: 'на 15 мин позже'
      ,sk: '15 min. po'
      ,nl: '15 minuten later'
      ,ko: '15분 더 나중에'
      ,tr: '15 dak. sonra'  //sonra daha sonra
      ,zh_cn: '15分钟后'
      ,hu: '15 perccel később'
      }
    ,'20 minutes later' : {
      cs: '20 min po'
      ,de: '20 Minuten später'
      ,es: '20 min más tarde'
      ,fr: '20 min plus tard'
      ,el: '20 λεπτά αργότερα'
      ,pt: '20 min depois'
      ,ro: 'după 20 min'
      ,bg: 'След 20 минути'
      ,hr: '20 minuta kasnije'
      ,sv: '20 min senare'
      ,it: '20 minuti più tardi'
      ,ja: '20分後'
      ,dk: '20 min senere'
      ,fi: '20 minuuttia myöhemmin'
      ,nb: '20 min senere'
      ,he: 'עשרים דקות מאוחר יותר'
      ,pl: '20 minut później'
      ,ru: 'на 20 мин позже'
      ,sk: '20 min. po'
      ,nl: '20 minuten later'
      ,ko: '20분 더 나중에'
      ,tr: '20 dak. sonra'
      ,zh_cn: '20分钟后'
      ,hu: '20 perccel később'
      }
    ,'30 minutes later' : {
      cs: '30 min po'
      ,de: '30 Minuten später'
      ,es: '30 min más tarde'
      ,fr: '30 min plus tard'
      ,el: '30 λεπτά αργότερα'
      ,pt: '30 min depois'
      ,ro: 'după 30 min'
      ,bg: 'След 30 минути'
      ,hr: '30 minuta kasnije'
      ,sv: '30 min senare'
      ,it: '30 minuti più tardi'
      ,ja: '30分後'
      ,dk: '30 min senere'
      ,fi: '30 minuuttia myöhemmin'
      ,nb: '30 min senere'
      ,he: 'חצי שעה מאוחר יותר'
      ,pl: '30 minut później'
      ,ru: 'на 30 мин позже'
      ,sk: '30 min. po'
      ,nl: '30 minuten later'
      ,ko: '30분 더 나중에'
      ,tr: '30 dak. sonra'
      ,zh_cn: '30分钟后'
      ,hu: '30 perccel kesőbb'
      }
    ,'45 minutes later' : {
      cs: '45 min po'
      ,de: '45 Minuten später'
      ,es: '45 min más tarde'
      ,fr: '45 min plus tard'
      ,el: '45 λεπτά αργότερα'
      ,pt: '45 min depois'
      ,ro: 'după 45 min'
      ,bg: 'След 45 минути'
      ,hr: '45 minuta kasnije'
      ,sv: '45 min senare'
      ,it: '45 minuti più tardi'
      ,ja: '45分後'
      ,dk: '45 min senere'
      ,fi: '45 minuuttia myöhemmin'
      ,nb: '45 min senere'
      ,he: 'שלושת רבעי שעה מאוחר יותר'
      ,pl: '45 minut później'
      ,ru: 'на 45 мин позже'
      ,sk: '45 min. po'
      ,nl: '45 minuten later'
      ,ko: '45분 더 나중에'
      ,tr: '45 dak. sonra'
      ,zh_cn: '45分钟后'
      ,hu: '45 perccel később'
      }
    ,'60 minutes later' : {
      cs: '60 min po'
      ,de: '60 Minuten später'
      ,es: '60 min más tarde'
      ,fr: '60 min plus tard'
      ,el: '60 λεπτά αργότερα'
      ,pt: '60 min depois'
      ,ro: 'după 60 min'
      ,bg: 'След 60 минути'
      ,hr: '60 minuta kasnije'
      ,sv: '60 min senare'
      ,it: '60 minuti più tardi'
      ,ja: '60分後'
      ,dk: '60 min senere'
      ,fi: '60 minuuttia myöhemmin'
      ,nb: '60 min senere'
      ,he: 'שעה מאוחר יותר'
      ,pl: '60 minut później'
      ,ru: 'на 60 мин позже'
      ,sk: '60 min. po'
      ,nl: '60 minuten later'
      ,ko: '60분 더 나중에'
      ,tr: '60 dak. sonra'
      ,zh_cn: '60分钟后'
      ,hu: '60 perccel később'
      }
    ,'Additional Notes, Comments' : {
      cs: 'Dalši poznámky, komentáře'
      ,de: 'Ergänzende Hinweise/Kommentare'
      ,es: 'Notas adicionales, Comentarios'
      ,fr: 'Notes additionnelles, commentaires'
      ,el: 'Επιπλέον σημειώσεις/σχόλια'
      ,pt: 'Notas adicionais e comentários'
      ,ro: 'Note adiționale, comentarii'
      ,bg: 'Допълнителни бележки, коментари'
      ,hr: 'Dodatne bilješke, komentari'
      ,sv: 'Notering, övrigt'
      ,it: 'Note aggiuntive, commenti'
      ,ja: '追加メモ、コメント'
      ,dk: 'Ekstra noter, kommentarer'
      ,fi: 'Lisähuomiot, kommentit'
      ,nb: 'Ekstra notater, kommentarer'
      ,he: 'הערות נוספות'
      ,pl: 'Uwagi dodatkowe'
      ,ru: 'Дополнительные примечания'
      ,sk: 'Ďalšie poznámky, komentáre'
      ,nl: 'Extra aantekeningen'
      ,ko: '추가 메모'
      ,tr: 'Ek Notlar, Yorumlar'
      ,zh_cn: '备注'
      ,hu: 'Feljegyzések, hozzászólások'
      }
    ,'RETRO MODE' : {
      cs: 'V MINULOSTI'
      ,he: 'מצב רטרו'
      ,de: 'Retro-Modus'
      ,es: 'Modo Retrospectivo'
      ,fr: 'MODE RETROSPECTIF'
      ,el: 'Αναδρομική Λειτουργία'
      ,pt: 'Modo Retrospectivo'
      ,sv: 'Retroläge'
      ,ro: 'MOD RETROSPECTIV'
      ,bg: 'МИНАЛО ВРЕМЕ'
      ,hr: 'Retrospektivni način'
      ,it: 'Modalità retrospettiva'
      ,ja: '振り返りモード'
      ,dk: 'Retro mode'
      ,fi: 'VANHENTUNEET TIEDOT'
      ,nb: 'Retro mode'
      ,nl: 'Retro mode'
      ,pl: 'Tryb RETRO'
      ,ru: 'режим РЕТРО'
      ,sk: 'V MINULOSTI'
      ,ko: 'PETRO MODE'
      ,tr: 'RETRO MODE'
      ,zh_cn: '历史模式'
      ,hu: 'RETRO mód'
      }
    ,'Now' : {
      cs: 'Nyní'
      ,de: 'Jetzt'
      ,es: 'Ahora'
      ,fr: 'Maintenant'
      ,el: 'Τώρα'
      ,pt: 'Agora'
      ,sv: 'Nu'
      ,ro: 'Acum'
      ,bg: 'Сега'
      ,hr: 'Sad'
      ,it: 'Ora'
      ,ja: '今'
      ,dk: 'Nu'
      ,fi: 'Nyt'
      ,nb: 'Nå'
      ,he: 'עכשיו'
      ,pl: 'Teraz'
      ,ru: 'Сейчас'
      ,sk: 'Teraz'
      ,nl: 'Nu'
      ,ko: '현재'
      ,tr: 'Şimdi'
      ,zh_cn: '现在'
      ,hu: 'Most'
      }
    ,'Other' : {
      cs: 'Jiný'
      ,de: 'Weitere'
      ,es: 'Otro'
      ,fr: 'Autre'
      ,el: 'Άλλο'
      ,pt: 'Outro'
      ,sv: 'Övrigt'
      ,ro: 'Altul'
      ,bg: 'Друго'
      ,hr: 'Drugo'
      ,it: 'Altro'
      ,ja: '他の'
      ,dk: 'Øvrige'
      ,fi: 'Muu'
      ,nb: 'Annet'
      ,he: 'אחר'
      ,pl: 'Inny'
      ,ru: 'Другое'
      ,sk: 'Iný'
      ,nl: 'Andere'
      ,ko: '다른'
      ,tr: 'Diğer'
      ,zh_cn: '其它'
      ,hu: 'Más'
      }
    ,'Submit Form' : {
      cs: 'Odeslat formulář'
      ,de: 'Formular absenden'
      ,es: 'Enviar formulario'
      ,fr: 'Suomettre le formulaire'
      ,el: 'Υποβολή Φόρμας'
      ,pt: 'Enviar formulário'
      ,sv: 'Överför händelse'
      ,ro: 'Trimite formularul'
      ,bg: 'Въвеждане на данните'
      ,hr: 'Spremi'
      ,it: 'Invia il modulo'
      ,ja: 'フォームを投稿する'
      ,dk: 'Gem hændelsen'
      ,fi: 'Lähetä tiedot'
      ,nb: 'Lagre'
      ,he: 'שמור'
      ,pl: 'Zatwierdź'
      ,ru: 'Ввести форму'
      ,sk: 'Odoslať formulár'
      ,nl: 'Formulier opslaan'
      ,ko: '양식 제출'
      ,tr: 'Formu gönder'
      ,zh_cn: '提交'
      ,hu: 'Elküldés'
      }
    ,'Profile Editor' : {
      cs: 'Editor profilu'
      ,de: 'Profil-Editor'
      ,es: 'Editor de perfil'
      ,fr: 'Editeur de profil'
      ,el: 'Επεξεργασία Προφίλ'
      ,pt: 'Editor de perfil'
      ,sv: 'Editera profil'
      ,ro: 'Editare profil'
      ,bg: 'Редактор на профила'
      ,hr: 'Editor profila'
      ,it: 'NS - Dati Personali'
      ,ja: 'プロフィール編集'
      ,dk: 'Profil editor'
      ,fi: 'Profiilin muokkaus'
      ,nb: 'Profileditor'
      ,he: 'ערוך פרופיל'
      ,pl: 'Edytor profilu'
      ,ru: 'Редактор профиля'
      ,sk: 'Editor profilu'
      ,nl: 'Profiel beheer'
      ,ko: '프로파일 편집'
      ,tr: 'Profil Düzenleyicisi'
      ,zh_cn: '配置文件编辑器'
      ,zh_tw: '配置文件編輯器'
      ,hu: 'Profil Szerkesztő'
      }
    ,'Reports' : {
      cs: 'Výkazy'
      ,he: 'דוחות'
      ,de: 'Berichte'
      ,es: 'Herramienta de informes'
      ,fr: 'Outil de rapport'
      ,el: 'Αναφορές'
      ,pt: 'Relatórios'
      ,sv: 'Rapportverktyg'
      ,ro: 'Instrumente raportare'
      ,bg: 'Статистика'
      ,hr: 'Izvještaji'
      ,it: 'NS - Statistiche'
      ,ja: '報告'
      ,dk: 'Rapporteringsværktøj'
      ,fi: 'Raportointityökalu'
      ,nb: 'Rapporteringsverktøy'
      ,pl: 'Raporty'
      ,ru: 'Отчеты'
      ,sk: 'Správy'
      ,nl: 'Rapporten'
      ,ko: '보고서'
      ,tr: 'Raporlar'
      ,zh_cn: '生成报表'
      ,zh_tw: '生成報表'
      ,hu: 'Jelentések'
      }
    ,'Add food from your database' : {
      cs: 'Přidat jidlo z Vaší databáze'
      ,he: 'הוסף אוכל מבסיס הנתונים שלך'
      ,de: 'Ergänze Nahrung aus Deiner Datenbank'
      ,es: 'Añadir alimento a su base de datos'
      ,fr: 'Ajouter aliment de votre base de données'
      ,el: 'Προσθήκη φαγητού από τη Βάση Δεδομένων'
      ,pt: 'Incluir alimento do seu banco de dados'
      ,ro: 'Adaugă alimente din baza de date'
      ,bg: 'Добави храна от твоята база с данни'
      ,hr: 'Dodajte hranu iz svoje baze podataka'
      ,sv: 'Lägg till livsmedel från databas'
      ,it: 'Aggiungere cibo al database'
      ,ja: 'データベースから食べ物を追加'
      ,dk: 'Tilføj mad fra din database'
      ,fi: 'Lisää ruoka tietokannasta'
      ,nb: 'Legg til mat fra din database'
      ,pl: 'Dodaj posiłek z twojej bazy danych'
      ,ru: 'Добавить продукт из вашей базы данных'
      ,sk: 'Pridať jedlo z Vašej databázy'
      ,nl: 'Voeg voeding toe uit uw database'
      ,ko: '데이터베이스에서 음식을 추가하세요.'
      ,tr: 'Veritabanınızdan yemek ekleyin'
      ,zh_cn: '从数据库增加食物'
      ,hu: 'Étel hozzáadása az adatbázisból'
      }
    ,'Reload database' : {
      cs: 'Znovu nahraj databázi'
      ,he: 'טען בסיס נתונים שוב'
      ,de: 'Datenbank nachladen'
      ,es: 'Recargar base de datos'
      ,fr: 'Recharger la base de données'
      ,el: 'Επαναφόρτωση Βάσης Δεδομένων'
      ,pt: 'Recarregar banco de dados'
      ,ro: 'Reîncarcă baza de date'
      ,bg: 'Презареди базата с данни'
      ,hr: 'Ponovo učitajte bazu podataka'
      ,sv: 'Ladda om databas'
      ,it: 'Ricarica database'
      ,ja: 'データベース再読み込み'
      ,dk: 'Genindlæs databasen'
      ,fi: 'Lataa tietokanta uudelleen'
      ,nb: 'Last inn databasen på nytt'
      ,pl: 'Odśwież bazę danych'
      ,ru: 'Перезагрузить базу данных'
      ,sk: 'Obnoviť databázu'
      ,nl: 'Database opnieuw laden'
      ,ko: '데이터베이스 재로드'
      ,tr: 'Veritabanını yeniden yükle'
      ,zh_cn: '重新载入数据库'
      ,hu: 'Adatbázis újratöltése'
      }
    ,'Add' : {
      cs: 'Přidej'
      ,he: 'הוסף'
      ,de: 'Hinzufügen'
      ,es: 'Añadir'
      ,fr: 'Ajouter'
      ,el: 'Προσθήκη'
      ,pt: 'Adicionar'
      ,ro: 'Adaugă'
      ,bg: 'Добави'
      ,hr: 'Dodaj'
      ,sv: 'Lägg till'
      ,it: 'Aggiungere'
      ,ja: '追加'
      ,dk: 'Tilføj'
      ,fi: 'Lisää'
      ,nb: 'Legg til'
      ,pl: 'Dodaj'
      ,ru: 'Добавить'
      ,sk: 'Pridať'
      ,nl: 'Toevoegen'
      ,ko: '추가'
      ,tr: 'Ekle'
      ,zh_cn: '增加'
      ,hu: 'Hozzáadni'
      }
    ,'Unauthorized' : {
      cs: 'Neautorizováno'
      ,he: 'אין אישור'
      ,de: 'Unbefugt'
      ,es: 'No autorizado'
      ,fr: 'Non autorisé'
      ,el: 'Χωρίς εξουσιοδότηση'
      ,pt: 'Não autorizado'
      ,sv: 'Ej behörig'
      ,ro: 'Neautorizat'
      ,bg: 'Неразрешен достъп'
      ,hr: 'Neautorizirano'
      ,it: 'Non Autorizzato'
      ,ja: '認証されていません'
      ,dk: 'Uautoriseret'
      ,fi: 'Et ole autentikoitunut'
      ,nb: 'Uautorisert'
      ,pl: 'Nieuwierzytelniono'
      ,ru: 'Не авторизовано'
      ,sk: 'Neautorizované'
      ,nl: 'Ongeauthoriseerd'
      ,ko: '미인증'
      ,tr: 'Yetkisiz'
      ,zh_cn: '未授权'
      ,zh_tw: '未授權'
      ,hu: 'Nincs autorizávla'
      }
    ,'Entering record failed' : {
      cs: 'Vložení záznamu selhalo'
      ,he: 'הוספת רשומה נכשלה'
      ,de: 'Eingabe Datensatz fehlerhaft'
      ,es: 'Entrada de registro fallida'
      ,fr: 'Entrée enregistrement a échoué'
      ,el: 'Η εισαγωγή της εγγραφής απέτυχε'
      ,pt: 'Entrada de registro falhou'
      ,ro: 'Înregistrare eșuată'
      ,bg: 'Въвеждане на записа не се осъществи'
      ,hr: 'Neuspjeli unos podataka'
      ,sv: 'Lägga till post nekas'
      ,it: 'Voce del Registro fallita'
      ,ja: '入力されたものは記録できませんでした'
      ,dk: 'Tilføjelse af post fejlede'
      ,fi: 'Tiedon tallentaminen epäonnistui'
      ,nb: 'Lagring feilet'
      ,pl: 'Wprowadzenie wpisu nie powiodło się'
      ,ru: 'Ввод записи не состоялся'
      ,sk: 'Zadanie záznamu zlyhalo'
      ,nl: 'Toevoegen mislukt'
      ,ko: '입력 실패'
      ,tr: 'Kayıt girişi başarısız oldu'
      ,zh_cn: '输入记录失败'
      ,hu: 'Hozzáadás nem sikerült'
      }
    ,'Device authenticated' : {
      cs: 'Zařízení ověřeno'
      ,he: 'התקן מאושר'
      ,de: 'Gerät authentifiziert'
      ,es: 'Dispositivo autorizado'
      ,fr: 'Appareil authentifié'
      ,el: 'Εξουσιοδοτημένη Συσκευή'
      ,pt: 'Dispositivo autenticado'
      ,sv: 'Enhet autentiserad'
      ,ro: 'Dispozitiv autentificat'
      ,bg: 'Устройстово е разпознато'
      ,hr: 'Uređaj autenticiran'
      ,it: 'Disp. autenticato'
      ,ja: '機器は認証されました。'
      ,dk: 'Enhed godkendt'
      ,fi: 'Laite autentikoitu'
      ,nb: 'Enhet godkjent'
      ,pl: 'Urządzenie uwierzytelnione'
      ,ru: 'Устройство авторизовано'
      ,sk: 'Zariadenie overené'
      ,nl: 'Apparaat geauthenticeerd'
      ,ko: '기기 인증'
      ,tr: 'Cihaz kimliği doğrulandı'
      ,zh_cn: '设备已认证'
      ,zh_tw: '設備已認證'
      ,hu: 'Berendezés hitelesítve'
      }
    ,'Device not authenticated' : {
      cs: 'Zařízení není ověřeno'
      ,he: 'התקן לא מאושר'
      ,de: 'Gerät nicht authentifiziert'
      ,es: 'Dispositivo no autorizado'
      ,fr: 'Appareil non authentifié'
      ,el: 'Συσκευή Μη Εξουσιοδοτημένη'
      ,pt: 'Dispositivo não autenticado'
      ,sv: 'Enhet EJ autentiserad'
      ,ro: 'Dispozitiv neautentificat'
      ,bg: 'Устройсройството не е разпознато'
      ,hr: 'Uređaj nije autenticiran'
      ,it: 'Disp. non autenticato'
      ,ja: '機器は認証されていません。'
      ,dk: 'Enhed ikke godkendt'
      ,fi: 'Laite ei ole autentikoitu'
      ,nb: 'Enhet ikke godkjent'
      ,pl: 'Urządzenie nieuwierzytelnione'
      ,ru: 'Устройство не авторизовано'
      ,sk: 'Zariadenie nieje overené'
      ,nl: 'Apparaat niet geauthenticeerd'
      ,ko: '미인증 기기'
      ,tr: 'Cihaz kimliği doğrulanmamış'
      ,zh_cn: '设备未认证'
      ,zh_tw: '設備未認證'
      ,hu: 'Berendezés nincs hitelesítve'
      }
    ,'Authentication status' : {
      cs: 'Stav ověření'
      ,he: 'סטטוס אימות'
      ,de: 'Authentifikationsstatus'
      ,es: 'Estado de autorización'
      ,fr: 'Status de l\'authentification'
      ,el: 'Κατάσταση Εξουσιοδότησης'
      ,pt: 'Status de autenticação'
      ,ro: 'Starea autentificării'
      ,bg:  'Статус на удостоверяване'
      ,hr: 'Status autentikacije'
      ,sv: 'Autentiseringsstatus'
      ,it: 'Stato di autenticazione'
      ,ja: '認証ステータス'
      ,dk: 'Godkendelsesstatus'
      ,fi: 'Autentikoinnin tila'
      ,nb: 'Autentiseringsstatus'
      ,pl: 'Status uwierzytelnienia'
      ,ru: 'Статус авторизации'
      ,sk: 'Stav overenia'
      ,nl: 'Authenticatie status'
      ,ko: '인증 상태'
      ,tr: 'Kimlik doğrulama durumu'
      ,zh_cn: '认证状态'
      ,zh_tw: '認證狀態'
      ,hu: 'Hitelesítés állapota'
      }
    ,'Authenticate' : {
      cs: 'Ověřit'
      ,he: 'אמת'
      ,de: 'Authentifizieren'
      ,es: 'Autentificar'
      ,fr: 'Authentifier'
      ,el: 'Πιστοποίηση'
      ,pt: 'Autenticar'
      ,sv: 'Autentisera'
      ,ro: 'Autentificare'
      ,bg: 'Удостоверяване'
      ,hr: 'Autenticirati'
      ,it: 'Autenticare'
      ,ja: '認証'
      ,dk: 'Godkende'
      ,fi: 'Autentikoi'
      ,nb: 'Autentiser'
      ,pl: 'Uwierzytelnienie'
      ,ru: 'Авторизуйте'
      ,sk: 'Overiť'
      ,nl: 'Authenticatie'
      ,ko: '인증'
      ,tr: 'Kimlik doğrulaması'
      ,zh_cn: '认证'
      ,zh_tw: '認證'
      ,hu: 'Hitelesítés'
      }
    ,'Remove' : {
      cs: 'Vymazat'
      ,he: 'הסר'
      ,de: 'Entfernen'
      ,es: 'Eliminar'
      ,fr: 'Retirer'
      ,el: 'Αφαίρεση'
      ,pt: 'Remover'
      ,ro: 'Șterge'
      ,bg: 'Премахни'
      ,hr: 'Ukloniti'
      ,sv: 'Ta bort'
      ,it: 'Rimuovere'
      ,ja: '除く'
      ,dk: 'Fjern'
      ,fi: 'Poista'
      ,nb: 'Slett'
      ,pl: 'Usuń'
      ,ru: 'Удалите'
      ,sk: 'Odstrániť'
      ,nl: 'Verwijder'
      ,ko: '삭제'
      ,tr: 'Kaldır'
      ,zh_cn: '取消'
      ,zh_tw: '取消'
      ,hu: 'Eltávolítani'
      }
    ,'Your device is not authenticated yet' : {
      cs: 'Toto zařízení nebylo dosud ověřeno'
      ,he: 'ההתקן שלך עדיין לא מאושר'
      ,de: 'Dein Gerät ist noch nicht authentifiziert'
      ,es: 'Su dispositivo no ha sido autentificado todavía'
      ,fr: 'Votre appareil n\'est pas encore authentifié'
      ,el: 'Η συκευή σας δεν έχει πιστοποιηθεί'
      ,pt: 'Seu dispositivo ainda não foi autenticado'
      ,ro: 'Dispozitivul nu este autentificat încă'
      ,bg: 'Вашето устройство все още не е удостоверено'
      ,hr: 'Vaš uređaj još nije autenticiran'
      ,sv: 'Din enhet är ej autentiserad'
      ,it: 'Il tuo dispositivo non è ancora stato autenticato'
      ,ja: '機器はまだ承認されていません。'
      ,dk: 'Din enhed er ikke godkendt endnu'
      ,fi: 'Laitettasi ei ole vielä autentikoitu'
      ,nb: 'Din enhet er ikke godkjent enda'
      ,pl: 'Twoje urządzenie nie jest jeszcze uwierzytelnione'
      ,ru: 'Ваше устройство еще не авторизовано '
      ,sk: 'Toto zariadenie zatiaľ nebolo overené'
      ,nl: 'Uw apparaat is nog niet geauthenticeerd'
      ,ko: '당신의 기기는 아직 인증되지 않았습니다.'
      ,tr: 'Cihazınız henüz doğrulanmamış'
      ,zh_cn: '此设备还未进行认证'
      ,zh_tw: '這個設備還未進行認證'
      ,hu: 'A berendezés még nincs hitelesítve'
      }
    ,'Sensor' : {
      cs: 'Senzor'
      ,de: 'Sensor'
      ,es: 'Sensor'
      ,fr: 'Senseur'
      ,el: 'Αισθητήρας'
      ,pt: 'Sensor'
      ,sv: 'Sensor'
      ,ro: 'Senzor'
      ,bg: 'Сензор'
      ,hr: 'Senzor'
      ,it: 'Sensore'
      ,ja: 'センサー'
      ,dk: 'Sensor'
      ,fi: 'Sensori'
      ,nb: 'Sensor'
      ,he: 'חיישן סוכר'
      ,pl: 'Sensor'
      ,ru: 'Сенсор'
      ,sk: 'Senzor'
      ,nl: 'Sensor'
      ,ko: '센서'
      ,tr: 'Sensor'
      ,zh_cn: 'CGM探头'
      ,hu: 'Szenzor'
      }
    ,'Finger' : {
      cs: 'Glukoměr'
      ,de: 'Finger'
      ,es: 'Dedo'
      ,fr: 'Doigt'
      ,el: 'Δάχτυλο'
      ,pt: 'Ponta de dedo'
      ,sv: 'Finger'
      ,ro: 'Deget'
      ,bg: 'От пръстта'
      ,hr: 'Prst'
      ,it: 'Dito'
      ,ja: '指'
      ,dk: 'Finger'
      ,fi: 'Sormi'
      ,nb: 'Finger'
      ,he: 'אצבע'
      ,pl: 'Glukometr'
      ,ru: 'Палец'
      ,sk: 'Glukomer'
      ,nl: 'Vinger'
      ,ko: '손가락'
      ,tr: 'Parmak'
      ,zh_cn: '手指'
      ,hu: 'Új'
      }
    ,'Manual' : {
      cs: 'Ručně'
      ,de: 'Von Hand'
      ,es: 'Manual'
      ,fr: 'Manuel'
      ,el: 'Χειροκίνητο'
      ,pt: 'Manual'
      ,sv: 'Manuell'
      ,ro: 'Manual'
      ,bg: 'Ръчно'
      ,hr: 'Ručno'
      ,it: 'Manuale'
      ,ja: '手動入力'
      ,dk: 'Manuel'
      ,fi: 'Manuaalinen'
      ,nb: 'Manuell'
      ,he: 'ידני'
      ,pl: 'Ręcznie'
      ,ru: 'Вручную'
      ,sk: 'Ručne'
      ,nl: 'Handmatig'
      ,ko: '수'
      ,tr: 'Elle'
      ,zh_cn: '手动'
      ,hu: 'Kézi'
      }
    ,'Scale' : {
      cs: 'Měřítko'
      ,he: 'סקלה'
      ,de: 'Skalierung'
      ,es: 'Escala'
      ,fr: 'Echelle'
      ,el: 'Κλίμακα'
      ,pt: 'Escala'
      ,ro: 'Scală'
      ,bg: 'Скала'
      ,hr: 'Skala'
      ,sv: 'Skala'
      ,it: 'Scala'
      ,ja: 'グラフ縦軸'
      ,dk: 'Skala'
      ,fi: 'Skaala'
      ,nb: 'Skala'
      ,pl: 'Skala'
      ,ru: 'Масштаб'
      ,sk: 'Mierka'
      ,nl: 'Schaal'
      ,ko: '스케일'
      ,tr: 'Ölçek'
      ,zh_cn: '函数'
      ,zh_tw: '函數'
      ,hu: 'Mérték'
      }
    ,'Linear' : {
      cs: 'Lineární'
      ,de: 'Linear'
      ,es: 'Lineal'
      ,fr: 'Linéaire'
      ,el: 'Γραμμική'
      ,pt: 'Linear'
      ,sv: 'Linjär'
      ,ro: 'Liniar'
      ,bg: 'Линейна'
      ,hr: 'Linearno'
      ,it: 'Lineare'
      ,ja: '線形軸表示'
      ,dk: 'Lineær'
      ,fi: 'Lineaarinen'
      ,nb: 'Lineær'
      ,he: 'לינארי'
      ,pl: 'Liniowa'
      ,ru: 'Линейный'
      ,sk: 'Lineárne'
      ,nl: 'Lineair'
      ,ko: 'Linear'
      ,tr: 'Doğrusal'  //çizgisel
      ,zh_cn: '线性'
      ,zh_tw: '線性'
      ,hu: 'Lineáris'
      }
    ,'Logarithmic' : {
      cs: 'Logaritmické'
      ,de: 'Logarithmisch'
      ,es: 'Logarítmica'
      ,fr: 'Logarithmique'
      ,el: 'Λογαριθμική'
      ,pt: 'Logarítmica'
      ,sv: 'Logaritmisk'
      ,ro: 'Logaritmic'
      ,bg: 'Логоритмична'
      ,hr: 'Logaritamski'
      ,it: 'Logaritmica'
      ,ja: '対数軸表示'
      ,dk: 'Logaritmisk'
      ,fi: 'Logaritminen'
      ,nb: 'Logaritmisk'
      ,he: 'לוגריתמי'
      ,pl: 'Logarytmiczna'
      ,ru: 'Логарифмический'
      ,sk: 'Logaritmické'
      ,nl: 'Logaritmisch'
      ,ko: 'Logarithmic'
      ,tr: 'Logaritmik'
      ,zh_cn: '对数'
      ,zh_tw: '對數'
      ,hu: 'Logaritmikus'
      }
    ,'Logarithmic (Dynamic)' : {
      cs: 'Logaritmické (Dynamické)'
      ,he: 'לוגריטמי - דינמי'
      ,de: 'Logaritmisch (dynamisch)'
      ,es: 'Logarítmo (Dinámico)'
      ,dk: 'Logaritmisk (Dynamisk)'
      ,it: 'Logaritmica (Dinamica)'
      ,ja: '対数軸(動的)表示'
      ,fr: 'Logarithmique (Dynamique)'
      ,el: 'Λογαριθμική (Δυναμική)'
      ,ro: 'Logaritmic (Dinamic)'
      ,bg: 'Логоритмична (Динамична)'
      ,hr: 'Logaritamski (Dinamički)'
      ,nb: 'Logaritmisk (Dynamisk)'
      ,fi: 'Logaritminen (Dynaaminen)'
      ,sv: 'Logaritmisk (Dynamisk)'
      ,pl: 'Logarytmiczna (Dynamiczna)'
      ,pt: 'Logarítmica (Dinâmica)'
      ,ru: 'Логарифмический (Динамический)'
      ,sk: 'Logaritmické (Dynamické)'
      ,nl: 'Logaritmisch (Dynamisch)'
      ,ko: '다수(동적인)'
      ,tr: 'Logaritmik (Dinamik)'
      ,zh_cn: '对数（动态）'
      ,zh_tw: '對數（動態）'
      ,hu: 'Logaritmikus (Dinamikus)'
      }
    ,'Insulin-on-Board' : {
      cs: 'IOB'
      ,he: 'אינסולין בגוף'
      ,de: 'Aktives Bolus-Insulin'
      ,es: 'Insulina activa'
      ,fr: 'Insuline à bord'
      ,dk: 'Aktivt insulin (IOB)'
      ,it: 'IOB-Insulina a Bordo'
      ,ja: 'IOB・残存インスリン'
      ,nb: 'AI'
      ,el: 'Ενεργή Ινσουλίνη (IOB)'
      ,ro: 'IOB-Insulină activă'
      ,bg: 'Активен инсулин'
      ,hr: 'Aktivni inzulin'
      ,fi: 'Aktiivinen insuliini (IOB)'
      ,sv: 'Aktivt insulin (IOB)'
      ,pl: 'Aktywna insulina (IOB)'
      ,pt: 'Insulina ativa'
      ,ru: 'Активный инсулин IOB'
      ,sk: 'Aktívny inzulín (IOB)'
      ,nl: 'Actieve insuline (IOB)'
      ,ko: 'IOB'
      ,tr: 'Aktif İnsülin (IOB)'
      ,zh_cn: '活性胰岛素（IOB）'
      ,zh_tw: '活性胰島素（IOB）'
      ,hu: 'Aktív inzulin (IOB)'
      }
    ,'Carbs-on-Board' : {
      cs: 'COB'
      ,he: 'פחמימות בגוף'
      ,de: 'Aktiv wirksame Kohlenhydrate'
      ,es: 'Carbohidratos activos'
      ,fr: 'Glucides à bord'
      ,dk: 'Aktive kulhydrater (COB)'
      ,it: 'COB-Carboidrati a Bordo'
      ,ja: 'COB・残存カーボ'
      ,nb: 'AK'
      ,el: 'Ενεργοί Υδατάνθρακες (COB)'
      ,ro: 'COB-Carbohidrați activi'
      ,bg: 'Активни въглехидрати'
      ,hr: 'Aktivni ugljikohidrati'
      ,fi: 'Aktiivinen hiilihydraatti (COB)'
      ,sv: 'Aktiva kolhydrater (COB)'
      ,pl: 'Aktywne wglowodany (COB)'
      ,pt: 'Carboidratos ativos'
      ,ru: 'Активные углеводы COB'
      ,sk: 'Aktívne sacharidy (COB)'
      ,nl: 'Actieve koolhydraten (COB)'
      ,ko: 'COB'
      ,tr: 'Aktif Karbonhidrat (COB)'
      ,zh_cn: '活性碳水化合物（COB）'
      ,zh_tw: '活性碳水化合物（COB）'
      ,hu: 'Aktív szénhidrát (COB)'
      }
    ,'Bolus Wizard Preview' : {
      cs: 'BWP-Náhled bolusového kalk.'
      ,he: 'סקירת אשף הבולוס'
      ,de: 'Bolus-Kalkulator Vorschau (BWP)'
      ,es: 'Vista previa del cálculo del bolo'
      ,fr: 'Prévue du Calculatuer de bolus'
      ,dk: 'Bolus Wizard (BWP)'
      ,it: 'BWP-Calcolatore di bolo'
      ,ja: 'BWP・ボーラスウィザード参照'
      ,nb: 'Boluskalkulator'
      ,el: 'Εργαλείο Εκτίμησης Επάρκειας Ινσουλίνης (BWP)'
      ,ro: 'BWP-Sugestie de bolusare'
      ,bg: 'Болус калкулатор'
      ,hr: 'Pregled bolus čarobnjaka'
      ,fi: 'Aterialaskurin Esikatselu (BWP)'
      ,sv: 'Boluskalkylator (BWP)'
      ,pl: 'Kalkulator Bolusa (BWP)'
      ,pt: 'Ajuda de bolus'
      ,ru: 'Калькулятор болюса'
      ,sk: 'Bolus Wizard'
      ,nl: 'Bolus Wizard Preview (BWP)'
      ,ko: 'Bolus 마법사 미리보기'
      ,tr: 'Bolus hesaplama Sihirbazı Önizlemesi (BWP)' //İnsülin etkinlik süresi hesaplaması
      ,zh_cn: '大剂量向导预览（BWP）'
      ,zh_tw: '大劑量嚮導預覽（BWP）'
      ,hu: 'Bolus Varázsló'
      }
    ,'Value Loaded' : {
      cs: 'Hodnoty načteny'
      ,he: 'ערך נטען'
      ,de: 'Geladener Wert'
      ,es: 'Valor cargado'
      ,fr: 'Valeur chargée'
      ,dk: 'Værdi indlæst'
      ,it: 'Valori Caricati'
      ,ja: '数値読み込み完了'
      ,nb: 'Verdi lastet'
      ,el: 'Τιμή ανακτήθηκε'
      ,ro: 'Valoare încărcată'
      ,bg: 'Стойност заредена'
      ,hr: 'Vrijednost učitana'
      ,fi: 'Arvo ladattu'
      ,sv: 'Laddat värde'
      ,pl: 'Wartości wczytane'
      ,pt: 'Valores carregados'
      ,ru: 'Величина загружена'
      ,nl: 'Waarde geladen'
      ,sk: 'Hodnoty načítané'
      ,ko: '데이터가 로드됨'
      ,tr: 'Yüklenen Değer'
      ,zh_cn: '数值已读取'
      ,zh_tw: '數值已讀取'
      ,hu: 'Érték betöltve'
      }
    ,'Cannula Age' : {
      cs: 'CAGE-Stáří kanyly'
      ,he: 'גיל הקנולה'
      ,de: 'Kanülenalter'
      ,es: 'Antigüedad cánula'
      ,fr: 'Age de la canule'
      ,dk: 'Indstik alder (CAGE)'
      ,it: 'CAGE-Cambio Ago'
      ,ja: 'CAGE・カニューレ使用日数'
      ,el: 'Ημέρες Χρήσης Κάνουλας (CAGE)'
      ,nb: 'Nålalder'
      ,ro: 'CAGE-Vechime canulă'
      ,bg: 'Възраст на канюлата'
      ,hr: 'Starost kanile'
      ,fi: 'Kanyylin ikä (CAGE)'
      ,sv: 'Kanylålder (CAGE)'
      ,pl: 'Czas wkłucia (CAGE)'
      ,pt: 'Idade da Cânula (ICAT)'
      ,ru: 'Катетер проработал'
      ,sk: 'Zavedenie kanyly (CAGE)'
      ,nl: 'Canule leeftijd (CAGE)'
      ,ko: '캐뉼라 사용기간'
      ,tr: 'Kanül yaşı'
      ,zh_cn: '管路使用时间（CAGE）'
      ,zh_tw: '管路使用時間（CAGE）'
      ,hu: 'Kanula élettartalma (CAGE)'
      }
    ,'Basal Profile' : {
      cs: 'Bazál'
      ,he: 'פרופיל רמה בזלית'
      ,de: 'Basalraten-Profil'
      ,es: 'Perfil Basal'
      ,fr: 'Profil Basal'
      ,dk: 'Basal profil'
      ,it: 'BASAL-Profilo Basale'
      ,ja: 'ベーサルプロフィール'
      ,el: 'Προφίλ Βασικής Ινσουλίνης (BASAL)'
      ,nb: 'Basalprofil'
      ,ro: 'Profil bazală'
      ,bg: 'Базален профил'
      ,hr: 'Bazalni profil'
      ,fi: 'Basaaliprofiili'
      ,sv: 'Basalprofil'
      ,pl: 'Profil dawki bazowej'
      ,pt: 'Perfil de Basal'
      ,ru: 'Профиль Базала'
      ,sk: 'Bazál'
      ,nl: 'Basaal profiel'
      ,ko: 'Basal 프로파일'
      ,tr: 'Bazal Profil'
      ,zh_cn: '基础率配置文件'
      ,zh_tw: '基礎率配置文件'
      ,hu: 'Bazál profil'
      }
    ,'Silence for 30 minutes' : {
      cs: 'Ztlumit na 30 minut'
      ,he: 'השתק לשלושים דקות'
      ,de: 'Stille für 30 Minuten'
      ,es: 'Silenciar durante 30 minutos'
      ,fr: 'Silence pendant 30 minutes'
      ,el: 'Σίγαση για 30 λεπτά'
      ,pt: 'Silenciar por 30 minutos'
      ,ro: 'Ignoră pentru 30 minute'
      ,bg: 'Заглуши за 30 минути'
      ,hr: 'Tišina 30 minuta'
      ,sv: 'Tyst i 30 min'
      ,it: 'Silenzio per 30 minuti'
      ,ja: '30分静かにする'
      ,dk: 'Stilhed i 30 min'
      ,fi: 'Hiljennä 30 minuutiksi'
      ,nb: 'Stille i 30 min'
      ,pl: 'Wycisz na 30 minut'
      ,ru: 'Тишина 30 минут'
      ,sk: 'Stíšiť na 30 minút'
      ,nl: 'Sluimer 30 minuten'
      ,ko: '30분간 무음'
      ,tr: '30 dakika sessizlik'
      ,zh_cn: '静音30分钟'
      ,zh_tw: '靜音30分鐘'
      ,hu: 'Lehalkítás 30 percre'
      }
    ,'Silence for 60 minutes' : {
      cs: 'Ztlumit na 60 minut'
      ,he: 'השתק לששים דקות'
      ,de: 'Stille für 60 Minuten'
      ,es: 'Silenciar durante 60 minutos'
      ,fr: 'Silence pendant 60 minutes'
      ,el: 'Σίγαση για 60 λεπτά'
      ,pt: 'Silenciar por 60 minutos'
      ,ro: 'Ignoră pentru 60 minute'
      ,bg: 'Заглуши за 60 минути'
      ,hr: 'Tišina 60 minuta'
      ,sv: 'Tyst i 60 min'
      ,it: 'Silenzio per 60 minuti'
      ,ja: '60分静かにする'
      ,dk: 'Stilhed i 60 min'
      ,fi: 'Hiljennä tunniksi'
      ,nb: 'Stille i 60 min'
      ,pl: 'Wycisz na 60 minut'
      ,ru: 'Тишина 60 минут'
      ,sk: 'Stíšiť na 60 minút'
      ,nl: 'Sluimer 60 minuten'
      ,ko: '60분간 무음'
      ,tr: '60 dakika sessizlik'
      ,zh_cn: '静音60分钟'
      ,zh_tw: '靜音60分鐘'
      ,hu: 'Lehalkítás 60 percre'
    }
    ,'Silence for 90 minutes' : {
      cs: 'Ztlumit na 90 minut'
      ,he: 'השתק לתשעים דקות'
      ,de: 'Stille für 90 Minuten'
      ,es: 'Silenciar durante 90 minutos'
      ,fr: 'Silence pendant 90 minutes'
      ,el: 'Σίγαση για 90 λεπτά'
      ,pt: 'Silenciar por 90 minutos'
      ,ro: 'Ignoră pentru 90 minure'
      ,bg: 'Заглуши за 90 минути'
      ,hr: 'Tišina 90 minuta'
      ,sv: 'Tyst i 90 min'
      ,it: 'Silenzio per 90 minuti'
      ,ja: '90分静かにする'
      ,dk: 'Stilhed i 90 min'
      ,fi: 'Hiljennä 1.5 tunniksi'
      ,nb: 'Stille i 90 min'
      ,pl: 'Wycisz na 90 minut'
      ,ru: 'Тишина 90 минут'
      ,sk: 'Stíšiť na 90 minút'
      ,nl: 'Sluimer 90 minuten'
      ,ko: '90분간 무음'
      ,tr: '90 dakika sessizlik'
      ,zh_cn: '静音90分钟'
      ,zh_tw: '靜音90分鐘'
      ,hu: 'Lehalkítás 90 percre'
    }
    ,'Silence for 120 minutes' : {
      cs: 'Ztlumit na 120 minut'
      ,he: 'השתק לשעתיים'
      ,de: 'Stille für 120 Minuten'
      ,es: 'Silenciar durante 120 minutos'
      ,fr: 'Silence pendant 120 minutes'
      ,el: 'Σίγαση για 120 λεπτά'
      ,pt: 'Silenciar por 120 minutos'
      ,ro: 'Ignoră pentru 120 minute'
      ,bg: 'Заглуши за 120 минути'
      ,hr: 'Tišina 120 minuta'
      ,sv: 'Tyst i 120 min'
      ,it: 'Silenzio per 120 minuti'
      ,ja: '120分静かにする'
      ,dk: 'Stilhed i 120 min'
      ,fi: 'Hiljennä 2 tunniksi'
      ,nb: 'Stile i 120 min'
      ,pl: 'Wycisz na 120 minut'
      ,ru: 'Тишина 120 минут'
      ,sk: 'Stíšiť na 120 minút'
      ,nl: 'Sluimer 2 uur'
      ,ko: '120분간 무음'
      ,tr: '120 dakika sessizlik'
      ,zh_cn: '静音2小时'
      ,zh_tw: '靜音2小時'
      ,hu: 'Lehalkítás 120 percre'
    }
    ,'Settings' : {
      cs: 'Nastavení'
      ,he: 'הגדרות'
      ,de: 'Einstellungen'
      ,es: 'Ajustes'
      ,fr: 'Paramètres'
      ,el: 'Ρυθμίσεις'
      ,pt: 'Ajustes'
      ,sv: 'Inställningar'
      ,ro: 'Setări'
      ,bg: 'Настройки'
      ,hr: 'Postavke'
      ,it: 'Impostazioni'
      ,ja: '設定'
      ,dk: 'Indstillinger'
      ,fi: 'Asetukset'
      ,nb: 'Innstillinger'
      ,pl: 'Ustawienia'
      ,ru: 'Настройки'
      ,sk: 'Nastavenia'
      ,nl: 'Instellingen'
      ,ko: '설정'
      ,tr: 'Ayarlar'
      ,zh_cn: '设置'
      ,zh_tw: '設置'
      ,hu: 'Beállítások'
      }
    ,'Units' : {
      cs: 'Jednotky'
      ,he: 'יחידות'
      ,de: 'Einheiten'
      ,es: 'Unidades'
      ,fr: 'Unités'
      ,el: 'Μονάδες'
      ,pt: 'Unidades'
      ,ro: 'Unități'
      ,bg: 'Единици'
      ,hr: 'Jedinice'
      ,sv: 'Enheter'
      ,it: 'Unità'
      ,dk: 'Enheder'
      ,fi: 'Yksikköä'
      ,nb: 'Enheter'
      ,pl: 'Jednostki'
      ,ru: 'Единицы'
      ,sk: 'Jednotky'
      ,nl: 'Eenheden'
      ,ko: '단위'
      ,tr: 'Birim'    //Birim Ünite
      ,zh_cn: '计量单位'
      ,zh_tw: '计量單位'
      ,hu: 'Egységek'
      }
    ,'Date format' : {
      cs: 'Formát datumu'
      ,he: 'פורמט תאריך'
      ,de: 'Datumsformat'
      ,es: 'Formato de fecha'
      ,fr: 'Format Date'
      ,el: 'Προβολή Ώρας'
      ,pt: 'Formato de data'
      ,sv: 'Datumformat'
      ,ro: 'Formatul datei'
      ,bg: 'Формат на датата'
      ,hr: 'Format datuma'
      ,it: 'Formato data'
      ,ja: '日数初期化'
      ,dk: 'Dato format'
      ,fi: 'Aikamuoto'
      ,nb: 'Datoformat'
      ,pl: 'Format daty'
      ,ru: 'Формат даты'
      ,sk: 'Formát času'
      ,nl: 'Datum formaat'
      ,ko: '날짜 형식'
      ,tr: 'Veri formatı'
      ,zh_cn: '时间格式'
      ,zh_tw: '時間格式'
      ,hu: 'Időformátum'
      }
    ,'12 hours' : {
      cs: '12 hodin'
      ,he: 'שתים עשרה שעות'
      ,de: '12 Stunden'
      ,es: '12 horas'
      ,fr: '12 heures'
      ,el: '12ωρο'
      ,pt: '12 horas'
      ,sv: '12-timmars'
      ,ro: '12 ore'
      ,bg: '12 часа'
      ,hr: '12 sati'
      ,it: '12 ore'
      ,ja: '12時間制'
      ,dk: '12 timer'
      ,fi: '12 tuntia'
      ,nb: '12 timer'
      ,pl: '12 godzinny'
      ,ru: '12 часов'
      ,sk: '12 hodín'
      ,nl: '12 uur'
      ,ko: '12 시간'
      ,tr: '12 saat'
      ,zh_cn: '12小时制'
      ,zh_tw: '12小時制'
      ,hu: '12 óra'
      }
    ,'24 hours' : {
      cs: '24 hodin'
      ,he: 'עשרים וארבע שעות'
      ,de: '24 Stunden'
      ,es: '24 horas'
      ,fr: '24 heures'
      ,el: '24ωρο'
      ,pt: '24 horas'
      ,sv: '24-timmars'
      ,ro: '24 ore'
      ,bg: '24 часа'
      ,hr: '24 sata'
      ,it: '24 ore'
      ,ja: '24時間制'
      ,dk: '24 timer'
      ,fi: '24 tuntia'
      ,nb: '24 timer'
      ,pl: '24 godzinny'
      ,ru: '24 часа'
      ,sk: '24 hodín'
      ,nl: '24 uur'
      ,ko: '24 시간'
      ,tr: '24 saat'
      ,zh_cn: '24小时制'
      ,zh_tw: '24小時制'
      ,hu: '24 óra'
      }
    ,'Log a Treatment' : {
      cs: 'Záznam ošetření'
      ,de: 'Behandlung eingeben'
      ,es: 'Apuntar un tratamiento'
      ,fr: 'Entrer un traitement'
      ,el: 'Καταγραφή Ενέργειας'
      ,pt: 'Registre um tratamento'
      ,ro: 'Înregistrează un eveniment'
      ,bg: 'Въвеждане на събитие'
      ,hr: 'Evidencija tretmana'
      ,sv: 'Ange händelse'
      ,it: 'Somministrazioni'
      ,ja: '治療を記録'
      ,dk: 'Log en hændelse'
      ,fi: 'Tallenna tapahtuma'
      ,nb: 'Logg en hendelse'
      ,he: 'הזן רשומה'
      ,pl: 'Wprowadź leczenie'
      ,ru: 'Записать лечение'
      ,sk: 'Záznam ošetrenia'
      ,nl: 'Registreer een behandeling'
      ,ko: 'Treatment 로그'
      ,tr: 'Tedaviyi günlüğe kaydet'
      ,zh_cn: '记录操作'
      ,hu: 'Kezelés bejegyzése'
      }
    ,'BG Check' : {
      cs: 'Kontrola glykémie'
      ,de: 'BG-Messung'
      ,es: 'Control de glucemia'
      ,fr: 'Contrôle glycémie'
      ,el: 'Έλεγχος Γλυκόζης'
      ,pt: 'Medida de glicemia'
      ,sv: 'Blodsockerkontroll'
      ,ro: 'Verificare glicemie'
      ,bg: 'Проверка на КЗ'
      ,hr: 'Kontrola GUK-a'
      ,it: 'Controllo Glicemia'
      ,ja: 'BG測定'
      ,dk: 'BS kontrol'
      ,fi: 'Verensokerin tarkistus'
      ,nb: 'Blodsukkerkontroll'
      ,he: 'בדיקת סוכר'
      ,pl: 'Pomiar glikemii'
      ,ru: 'Контроль ГК'
      ,sk: 'Kontrola glykémie'
      ,nl: 'Bloedglucose check'
      ,ko: '혈당 체크'
      ,tr: 'KŞ Kontol'
      ,zh_cn: '测量血糖'
      ,hu: 'Cukorszint ellenőrzés'
      }
    ,'Meal Bolus' : {
      cs: 'Bolus na jídlo'
      ,de: 'Mahlzeiten-Bolus'
      ,es: 'Bolo de comida'
      ,fr: 'Bolus repas'
      ,el: 'Ινσουλίνη Γέυματος'
      ,pt: 'Bolus de refeição'
      ,ro: 'Bolus masă'
      ,bg: 'Болус-основно хранене'
      ,hr: 'Bolus za obrok'
      ,sv: 'Måltidsbolus'
      ,it: 'Bolo Pasto'
      ,ja: '食事ボーラス'
      ,dk: 'Måltidsbolus'
      ,fi: 'Ruokailubolus'
      ,nb: 'Måltidsbolus'
      ,he: 'בולוס ארוחה'
      ,pl: 'Bolus do posiłku'
      ,ru: 'Болюс на еду'
      ,sk: 'Bolus na jedlo'
      ,nl: 'Maaltijd bolus'
      ,ko: '식사 인슐린'
      ,tr: 'Yemek bolus'
      ,zh_cn: '正餐大剂量'
      ,hu: 'Étel bólus'
      }
    ,'Snack Bolus' : {
      cs: 'Bolus na svačinu'
      ,de: 'Snack-Bolus'
      ,es: 'Bolo de aperitivo'
      ,fr: 'Bolus friandise'
      ,el: 'Ινσουλίνη Σνακ'
      ,pt: 'Bolus de lanche'
      ,sv: 'Mellanmålsbolus'
      ,ro: 'Bolus gustare'
      ,bg: 'Болус-лека закуска'
      ,hr: 'Bolus za užinu'
      ,it: 'Bolo Merenda'
      ,ja: '間食ボーラス'
      ,dk: 'Mellemmåltidsbolus'
      ,fi: 'Ruokakorjaus'
      ,nb: 'Mellommåltidsbolus'
      ,he: 'בולוס ארוחת ביניים'
      ,pl: 'Bolus przekąskowy'
      ,ru: 'Болюс на перекус'
      ,sk: 'Bolus na desiatu/olovrant'
      ,nl: 'Snack bolus'
      ,ko: '스넥 인슐린'
      ,tr: 'Aperatif (Snack) Bolus'
      ,zh_cn: '加餐大剂量'
      ,hu: 'Tízórai/Uzsonna bólus'
      }
    ,'Correction Bolus' : {
      cs: 'Bolus na glykémii'
      ,de: 'Korrektur-Bolus'
      ,es: 'Bolo corrector'
      ,fr: 'Bolus de correction'
      ,el: 'Διόρθωση με Ινσουλίνη'
      ,pt: 'Bolus de correção'
      ,ro: 'Bolus corecție'
      ,bg: 'Болус корекция'
      ,hr: 'Korekcija'
      ,sv: 'Korrektionsbolus'
      ,it: 'Bolo Correttivo'
      ,ja: '補正ボーラス'
      ,dk: 'Korrektionsbolus'
      ,fi: 'Korjausbolus'
      ,nb: 'Korreksjonsbolus'
      ,he: 'בולוס תיקון'
      ,pl: 'Bolus korekcyjny'
      ,ru: 'Болюс на коррекцию'
      ,sk: 'Korekčný bolus'
      ,nl: 'Correctie bolus'
      ,ko: '수정 인슐린'
      ,tr: 'Düzeltme Bolusu'
      ,zh_cn: '临时大剂量'
      ,hu: 'Korrekciós bólus'
      }
    ,'Carb Correction' : {
      cs: 'Přídavek sacharidů'
      ,de: 'Kohlenhydrate Korrektur'
      ,es: 'Carbohidratos de corrección'
      ,fr: 'Correction glucide'
      ,el: 'Διόρθωση με Υδατάνθρακεςς'
      ,pt: 'Correção com carboidrato'
      ,ro: 'Corecție de carbohidrați'
      ,bg: 'Корекция чрез въглехидрати'
      ,hr: 'Bolus za hranu'
      ,sv: 'Kolhydratskorrektion'
      ,it: 'Carboidrati Correttivi'
      ,ja: 'カーボ治療'
      ,dk: 'Kulhydratskorrektion'
      ,fi: 'Hiilihydraattikorjaus'
      ,nb: 'Karbohydratkorrigering'
      ,he: 'בולוס פחמימות'
      ,pl: 'Węglowodany korekcyjne'
      ,ru: 'Углеводы на коррекцию'
      ,sk: 'Prídavok sacharidov'
      ,nl: 'Koolhydraat correctie'
      ,ko: '탄수화물 수정'
      ,tr: 'Karbonhidrat Düzeltme'
      ,zh_cn: '碳水修正'
      ,hu: 'Szénhidrát korrekció'
      }
    ,'Note' : {
      cs: 'Poznámka'
      ,de: 'Bemerkung'
      ,es: 'Nota'
      ,fr: 'Note'
      ,el: 'Σημείωση'
      ,pt: 'Nota'
      ,ro: 'Notă'
      ,sv: 'Notering'
      ,bg: 'Бележка'
      ,hr: 'Bilješka'
      ,it: 'Nota'
      ,ja: 'メモ'
      ,dk: 'Note'
      ,fi: 'Merkintä'
      ,nb: 'Notat'
      ,he: 'הערה'
      ,pl: 'Uwagi'
      ,ru: 'Примечания'
      ,sk: 'Poznámka'
      ,nl: 'Notitie'
      ,ko: '메모'
      ,tr: 'Not'
      ,zh_cn: '备忘'
      ,hu: 'Jegyzet'
      }
    ,'Question' : {
      cs: 'Otázka'
      ,de: 'Frage'
      ,es: 'Pregunta'
      ,fr: 'Question'
      ,el: 'Ερώτηση'
      ,pt: 'Pergunta'
      ,ro: 'Întrebare'
      ,sv: 'Fråga'
      ,bg: 'Въпрос'
      ,hr: 'Pitanje'
      ,it: 'Domanda'
      ,ja: '質問'
      ,dk: 'Spørgsmål'
      ,fi: 'Kysymys'
      ,nb: 'Spørsmål'
      ,he: 'שאלה'
      ,pl: 'Pytanie'
      ,ru: 'Вопрос'
      ,sk: 'Otázka'
      ,nl: 'Vraag'
      ,ko: '질문'
      ,tr: 'Soru'
      ,zh_cn: '问题'
      ,hu: 'Kérdés'
     }
    ,'Exercise' : {
      cs: 'Cvičení'
      ,de: 'Bewegung'
      ,es: 'Ejercicio'
      ,fr: 'Exercice physique'
      ,el: 'Άσκηση'
      ,pt: 'Exercício'
      ,sv: 'Aktivitet'
      ,ro: 'Activitate fizică'
      ,bg: 'Спорт'
      ,hr: 'Aktivnost'
      ,it: 'Esercizio Fisico'
      ,ja: '運動'
      ,dk: 'Træning'
      ,fi: 'Fyysinen harjoitus'
      ,nb: 'Trening'
      ,he: 'פעילות גופנית'
      ,pl: 'Wysiłek'
      ,ru: 'Нагрузка'
      ,sk: 'Cvičenie'
      ,nl: 'Beweging / sport'
      ,ko: '운동'
      ,tr: 'Egzersiz'
      ,zh_cn: '运动'
      ,hu: 'Edzés'
      }
    ,'Pump Site Change' : {
      cs: 'Výměna setu'
      ,de: 'Pumpen-Katheter Wechsel'
      ,es: 'Cambio de catéter'
      ,fr: 'Changement de site pompe'
      ,el: 'Αλλαγή σημείου αντλίας'
      ,pt: 'Troca de catéter'
      ,sv: 'Pump/nålbyte'
      ,ro: 'Schimbare loc pompă'
      ,bg: 'Смяна на сет'
      ,hr: 'Promjena seta'
      ,it: 'CAGE-Cambio Ago'
      ,ja: 'CAGE・ポンプ注入場所変更'
      ,dk: 'Skift insulin infusionssted'
      ,fi: 'Kanyylin vaihto'
      ,nb: 'Pumpebytte'
      ,he: 'החלפת צינורית משאבה'
      ,pl: 'Zmiana miejsca wkłucia pompy'
      ,ru: 'Смена катетера помпы'
      ,sk: 'Výmena setu'
      ,nl: 'Nieuwe pomp infuus'
      ,ko: '펌프 위치 변경'
      ,tr: 'Pompa Kanül değişimi'
      ,zh_cn: '更换胰岛素输注部位'
      ,hu: 'Pumpa szett csere'
      }
    ,'CGM Sensor Start' : {
      cs: 'Spuštění sensoru'
      ,de: 'CGM Sensor Start'
      ,es: 'Inicio de sensor'
      ,fr: 'Démarrage senseur'
      ,el: 'Εκκίνηση αισθητήρα γλυκόζης'
      ,pt: 'Início de sensor'
      ,sv: 'Sensorstart'
      ,ro: 'Start senzor'
      ,bg: 'Ре/Стартиране на сензор'
      ,hr: 'Start senzora'
      ,it: 'CGM Avvio sensore'
      ,ja: 'CGMセンサー開始'
      ,dk: 'Sensorstart'
      ,fi: 'Sensorin aloitus'
      ,nb: 'Sensorstart'
      ,he: 'אתחול חיישן סוכר'
      ,pl: 'Start nowego sensora'
      ,ru: 'Старт сенсора'
      ,sk: 'Spustenie senzoru'
      ,nl: 'CGM Sensor start'
      ,ko: 'CGM 센서 시작'
      ,tr: 'CGM Sensörü Başlat'
      ,zh_cn: '启动CGM（连续血糖监测）探头'
      ,hu: 'CGM Szenzor Indítása'
      }
    ,'CGM Sensor Stop' : {
      cs: 'CGM Sensor Stop'
      ,de: 'CGM Sensor Stop'
      ,es: 'CGM Sensor Stop'
      ,fr: 'CGM Sensor Stop'
      ,el: 'CGM Sensor Stop'
      ,pt: 'CGM Sensor Stop'
      ,sv: 'CGM Sensor Stop'
      ,ro: 'CGM Sensor Stop'
      ,bg: 'CGM Sensor Stop'
      ,hr: 'CGM Sensor Stop'
      ,it: 'CGM Sensor Stop'
      ,ja: 'CGM Sensor Stop'
      ,dk: 'CGM Sensor Stop'
      ,fi: 'CGM Sensor Stop'
      ,nb: 'CGM Sensor Stop'
      ,he: 'CGM Sensor Stop'
      ,pl: 'CGM Sensor Stop'
      ,ru: 'Стоп сенсор'
      ,sk: 'CGM Sensor Stop'
      ,nl: 'CGM Sensor Stop'
      ,ko: 'CGM Sensor Stop'
      ,tr: 'CGM Sensor Stop'
      ,zh_cn: 'CGM Sensor Stop'
      ,hu: 'CGM Szenzor Leállítása'
      }
    ,'CGM Sensor Insert' : {
      cs: 'Výměna sensoru'
      ,de: 'CGM Sensor Wechsel'
      ,es: 'Cambio de sensor'
      ,fr: 'Changement senseur'
      ,el: 'Τοποθέτηση αισθητήρα γλυκόζης'
      ,pt: 'Troca de sensor'
      ,sv: 'Sensorbyte'
      ,ro: 'Schimbare senzor'
      ,bg: 'Смяна на сензор'
      ,hr: 'Promjena senzora'
      ,it: 'CGM Cambio sensore'
      ,ja: 'CGMセンサー挿入'
      ,dk: 'Sensor ombytning'
      ,fi: 'Sensorin vaihto'
      ,nb: 'Sensorbytte'
      ,he: 'החלפת חיישן סוכר'
      ,pl: 'Zmiana sensora'
      ,ru: 'Установка сенсора'
      ,sk: 'Výmena senzoru'
      ,nl: 'CGM sensor wissel'
      ,ko: 'CGM 센서 삽입'
      ,tr: 'CGM Sensor yerleştir'
      ,zh_cn: '植入CGM（连续血糖监测）探头'
      ,hu: 'CGM Szenzor Csere'
      }
    ,'Dexcom Sensor Start' : {
      cs: 'Spuštění sensoru'
      ,de: 'Dexcom Sensor Start'
      ,es: 'Inicio de sensor Dexcom'
      ,fr: 'Démarrage senseur Dexcom'
      ,el: 'Εκκίνηση αισθητήρα Dexcom'
      ,pt: 'Início de sensor'
      ,sv: 'Dexcom sensorstart'
      ,ro: 'Pornire senzor Dexcom'
      ,bg: 'Ре/Стартиране на Декском сензор'
      ,hr: 'Start Dexcom senzora'
      ,it: 'Avvio sensore Dexcom'
      ,ja: 'Dexcomセンサー開始'
      ,dk: 'Dexcom sensor start'
      ,fi: 'Sensorin aloitus'
      ,nb: 'Dexcom sensor start'
      ,he: 'אתחול חיישן סוכר של דקסקום'
      ,pl: 'Start sensora DEXCOM'
      ,ru: 'Старт сенсора'
      ,sk: 'Spustenie senzoru DEXCOM'
      ,nl: 'Dexcom sensor start'
      ,ko: 'Dexcom 센서 시작'
      ,tr: 'Dexcom Sensör Başlat'
      ,zh_cn: '启动Dexcom探头'
      ,hu: 'Dexcom Szenzor Indítása'
    }
    ,'Dexcom Sensor Change' : {
      cs: 'Výměna sensoru'
      ,de: 'Dexcom Sensor Wechsel'
      ,es: 'Cambio de sensor Dexcom'
      ,fr: 'Changement senseur Dexcom'
      ,el: 'Αλλαγή αισθητήρα Dexcom'
      ,pt: 'Troca de sensor'
      ,sv: 'Dexcom sensorbyte'
      ,ro: 'Schimbare senzor Dexcom'
      ,bg: 'Смяна на Декском сензор'
      ,hr: 'Promjena Dexcom senzora'
      ,it: 'Cambio sensore Dexcom'
      ,ja: 'Dexcomセンサー挿入'
      ,dk: 'Dexcom sensor ombytning'
      ,fi: 'Sensorin vaihto'
      ,nb: 'Dexcom sensor bytte'
      ,he: 'החלפת חיישן סוכר של דקסקום'
      ,pl: 'Zmiana sensora DEXCOM'
      ,ru: 'Замена сенсора Декском'
      ,sk: 'Výmena senzoru DEXCOM'
      ,nl: 'Dexcom sensor wissel'
      ,ko: 'Dexcom 센서 교체'
      ,tr: 'Dexcom Sensör değiştir'
      ,zh_cn: '更换Dexcom探头'
      ,hu: 'Dexcom Szenzor Csere'
    }
    ,'Insulin Cartridge Change' : {
      cs: 'Výměna inzulínu'
      ,de: 'Insulin Ampullenwechsel'
      ,es: 'Cambio de reservorio de insulina'
      ,fr: 'Changement cartouche d\'insuline'
      ,el: 'Αλλαγή αμπούλας ινσουλίνης'
      ,pt: 'Troca de reservatório de insulina'
      ,sv: 'Insulinreservoarbyte'
      ,ro: 'Schimbare cartuș insulină'
      ,bg: 'Смяна на резервоар'
      ,hr: 'Promjena spremnika inzulina'
      ,it: 'Cambio cartuccia insulina'
      ,ja: 'インスリンリザーバー交換'
      ,dk: 'Skift insulin beholder'
      ,fi: 'Insuliinisäiliön vaihto'
      ,nb: 'Skifte insulin beholder'
      ,he: 'החלפת מחסנית אינסולין'
      ,pl: 'Zmiana zbiornika z insuliną'
      ,ru: 'Замена картриджа инсулина'
      ,sk: 'Výmena inzulínu'
      ,nl: 'Insuline cartridge wissel'
      ,ko: '인슐린 카트리지 교체'
      ,tr: 'İnsülin rezervuar değişimi'
      ,zh_cn: '更换胰岛素储液器'
      ,hu: 'Inzulin Tartály Csere'
      }
    ,'D.A.D. Alert' : {
      cs: 'D.A.D. Alert'
      ,de: 'Diabeteswarnhund-Alarm'
      ,es: 'Alerta de perro de alerta diabética'
      ,fr: 'Wouf! Wouf! Chien d\'alerte diabète'
      ,el: 'Προειδοποίηση σκύλου υποστήριξης (Diabetes Alert Dog)'
      ,pt: 'Alerta de cão sentinela de diabetes'
      ,sv: 'Diabeteshundlarm (Duktig vovve!)'
      ,ro: 'Alertă câine de serviciu'
      ,bg: 'Сигнал от обучено куче'
      ,hr: 'Obavijest dijabetičkog psa'
      ,it: 'Allarme D.A.D.(Diabete Alert Dog)'
      ,ja: 'メディカルアラート犬（ＤＡＤ）の知らせ'
      ,dk: 'Vuf Vuf! (Diabeteshundealarm!)'
      ,fi: 'Diabeteskoirahälytys'
      ,nb: 'Diabeteshundalarm'
      ,he: 'ווף! ווף! התראת גשג'
      ,pl: 'Psi Alarm cukrzycowy'
      ,ru: 'Сигнал служебной собаки'
      ,sk: 'Upozornenie signálneho psa'
      ,nl: 'Hulphond waarschuwing'
      ,ko: 'D.A.D(Diabetes Alert Dog) 알림'
      ,tr: 'D.A.D(Diabetes Alert Dog)'
      ,zh_cn: 'D.A.D（低血糖通报犬）警告'
      ,hu: 'D.A.D Figyelmeztetés'
      }
    ,'Glucose Reading' : {
      cs: 'Hodnota glykémie'
      ,de: 'Glukosemesswert'
      ,es: 'Valor de glucemia'
      ,fr: 'Valeur de glycémie'
      ,el: 'Τιμή Γλυκόζης'
      ,pt: 'Valor de glicemia'
      ,sv: 'Glukosvärde'
      ,ro: 'Valoare glicemie'
      ,bg: 'Кръвна захар'
      ,hr: 'Vrijednost GUK-a'
      ,it: 'Lettura glicemie'
      ,dk: 'Blodsukker aflæsning'
      ,fi: 'Verensokeri'
      ,nb: 'Blodsukkermåling'
      ,he: 'מדידת סוכר'
      ,pl: 'Odczyt glikemii'
      ,ru: 'Значение ГК'
      ,sk: 'Hodnota glykémie'
      ,nl: 'Glucose meting'
      ,ko: '혈당 읽기'
      ,tr: 'Glikoz Değeri'
      ,zh_cn: '血糖数值'
      ,hu: 'Vércukorszint Érték'
      }
    ,'Measurement Method' : {
      cs: 'Metoda měření'
      ,de: 'Messmethode'
      ,es: 'Método de medida'
      ,fr: 'Méthode de mesure'
      ,el: 'Μέθοδος Μέτρησης'
      ,pt: 'Método de medida'
      ,sv: 'Mätmetod'
      ,ro: 'Metodă măsurare'
      ,bg: 'Метод на измерване'
      ,hr: 'Metoda mjerenja'
      ,it: 'Metodo di misurazione'
      ,ja: '測定方法'
      ,dk: 'Målemetode'
      ,fi: 'Mittaustapa'
      ,nb: 'Målemetode'
      ,he: 'אמצעי מדידה'
      ,pl: 'Sposób pomiaru'
      ,ru: 'Способ замера'
      ,sk: 'Metóda merania'
      ,nl: 'Meetmethode'
      ,ko: '측정 방법'
      ,tr: 'Ölçüm Metodu'
      ,zh_cn: '测量方法'
      ,hu: 'Cukorszint mérés metódusa'
      }
    ,'Meter' : {
      cs: 'Glukoměr'
      ,de: 'BZ-Messgerät'
      ,fr: 'Glucomètre'
      ,el: 'Μετρητής'
      ,pt: 'Glicosímetro'
      ,sv: 'Mätare'
      ,ro: 'Glucometru'
      ,es: 'Glucómetro'
      ,bg: 'Глюкомер'
      ,hr: 'Glukometar'
      ,it: 'Glucometro'
      ,ja: '血糖測定器'
      ,dk: 'Blodsukkermåler'
      ,fi: 'Sokerimittari'
      ,nb: 'Måleapparat'
      ,he: 'מד סוכר'
      ,pl: 'Glukometr'
      ,ru: 'Глюкометр'
      ,sk: 'Glukomer'
      ,nl: 'Glucosemeter'
      ,ko: '혈당 측정기'
      ,tr: 'Glikometre'
      ,zh_cn: '血糖仪'
      ,hu: 'Cukorszint mérő'
      }
    ,'Insulin Given' : {
      cs: 'Inzulín'
      ,de: 'Insulingabe'
      ,es: 'Insulina'
      ,fr: 'Insuline donnée'
      ,el: 'Ινσουλίνη'
      ,pt: 'Insulina'
      ,sv: 'Insulindos'
      ,ro: 'Insulină administrată'
      ,bg: 'Инсулин'
      ,hr: 'Količina iznulina'
      ,it: 'Insulina'
      ,ja: '投与されたインスリン'
      ,dk: 'Insulin dosis'
      ,fi: 'Insuliiniannos'
      ,nb: 'Insulin'
      ,he: 'אינסולין שניתן'
      ,pl: 'Podana insulina'
      ,ru: 'Введен инсулин'
      ,sk: 'Podaný inzulín'
      ,nl: 'Toegediende insuline'
      ,ko: '인슐린 요구량'
      ,tr: 'Verilen İnsülin'
      ,zh_cn: '胰岛素输注量'
      ,hu: 'Inzulin Beadva'
      }
    ,'Amount in grams' : {
      cs: 'Množství v gramech'
      ,de: 'Menge in Gramm'
      ,es: 'Cantidad en gramos'
      ,fr: 'Quantité en grammes'
      ,el: 'Ποσότητα σε γρ'
      ,pt: 'Quantidade em gramas'
      ,sv: 'Antal gram'
      ,ro: 'Cantitate în grame'
      ,bg: 'К-во в грамове'
      ,hr: 'Količina u gramima'
      ,it: 'Quantità in grammi'
      ,ja: 'グラム換算'
      ,dk: 'Antal gram'
      ,fi: 'Määrä grammoissa'
      ,nb: 'Antall gram'
      ,he: 'כמות בגרמים'
      ,pl: 'Wartość w gramach'
      ,ru: 'Количество в граммах'
      ,sk: 'Množstvo v gramoch'
      ,nl: 'Hoeveelheid in gram'
      ,ko: '합계(grams)'
      ,tr: 'Gram cinsinden miktar'
      ,zh_cn: '总量（g）'
      ,hu: 'Adag grammokban (g)'
      }
    ,'Amount in units' : {
      cs: 'Množství v jednotkách'
      ,de: 'Anzahl in Einheiten'
      ,es: 'Cantidad en unidades'
      ,fr: 'Quantité en unités'
      ,el: 'Ποσότητα σε μονάδες'
      ,pt: 'Quantidade em unidades'
      ,ro: 'Cantitate în unități'
      ,bg: 'К-во в единици'
      ,hr: 'Količina u jedinicama'
      ,sv: 'Antal enheter'
      ,it: 'Quantità in unità'
      ,ja: '単位換算'
      ,dk: 'Antal enheder'
      ,fi: 'Annos yksiköissä'
      ,nb: 'Antall enheter'
      ,he: 'כמות ביחידות'
      ,pl: 'Wartość w jednostkach'
      ,ru: 'Количество в ед.'
      ,sk: 'Množstvo v jednotkách'
      ,nl: 'Aantal in eenheden'
      ,ko: '합계(units)'
      ,tr: 'Birim miktarı'
      ,zh_cn: '总量（U）'
      ,hu: 'Adag egységekben'
      }
    ,'View all treatments' : {
      cs: 'Zobraz všechny ošetření'
      ,de: 'Zeige alle Behandlungen'
      ,es: 'Visualizar todos los tratamientos'
      ,fr: 'Voir tous les traitements'
      ,el: 'Προβολή όλων των ενεργειών'
      ,pt: 'Visualizar todos os procedimentos'
      ,sv: 'Visa behandlingar'
      ,ro: 'Vezi toate evenimentele'
      ,bg: 'Преглед на всички събития'
      ,hr: 'Prikaži sve tretmane'
      ,it: 'Visualizza tutti le somministrazioni'
      ,ja: '全治療内容を参照'
      ,dk: 'Vis behandlinger'
      ,fi: 'Katso kaikki hoitotoimenpiteet'
      ,nb: 'Vis behandlinger'
      ,he: 'הצג את כל הטיפולים'
      ,pl: 'Pokaż całość leczenia'
      ,ru: 'Показать все события'
      ,sk: 'Zobraziť všetky ošetrenia'
      ,nl: 'Bekijk alle behandelingen'
      ,ko: '모든 treatments 보기'
      ,tr: 'Tüm tedavileri görüntüle'
      ,zh_cn: '查看所有操作'
      ,hu: 'Összes kezelés mutatása'
      }
    ,'Enable Alarms' : {
      cs: 'Povolit alarmy'
      ,de: 'Alarme einschalten'
      ,es: 'Activar las alarmas'
      ,fr: 'Activer les alarmes'
      ,el: 'Ενεργοποίηση όλων των ειδοποιήσεων'
      ,pt: 'Ativar alarmes'
      ,sv: 'Aktivera larm'
      ,ro: 'Activează alarmele'
      ,bg: 'Активни аларми'
      ,hr: 'Aktiviraj alarme'
      ,it: 'Attiva Allarme'
      ,ja: 'アラームを有効にする'
      ,dk: 'Aktivere alarmer'
      ,fi: 'Aktivoi hälytykset'
      ,nb: 'Aktiver alarmer'
      ,he: 'הפעל התראות'
      ,pl: 'Włącz alarmy'
      ,ru: 'Активировать сигналы'
      ,sk: 'Aktivovať alarmy'
      ,nl: 'Alarmen aan!'
      ,ko: '알람 켜기'
      ,tr: 'Alarmları Etkinleştir'
      ,zh_cn: '启用报警'
      ,zh_tw: '啟用報警'
      ,hu: 'Figyelmeztetők bekapcsolása'
      }
    ,'Pump Battery Change' : {
       nl: 'Pompbatterij vervangen'
       ,sv: 'Byte av pumpbatteri'
       ,dk: 'Udskift pumpebatteri'
       ,de: 'Pumpenbatterie wechseln'
       ,fi: 'Pumpun patterin vaihto'
       ,bg: 'Смяна на батерия на помпата'
      ,hr: 'Zamjena baterije pumpe'
      ,ja: 'ポンプバッテリー交換'
       ,pl: 'Zmiana baterii w pompie'
       ,ru: 'замена батареи помпы'
       ,tr: 'Pompa pil değişimi'
       ,hu: 'Pumpa elem csere'
    }
    ,'Pump Battery Low Alarm' : {
       nl: 'Pompbatterij bijna leeg Alarm'
       ,sv: 'Pumpbatteri lågt Alarm'
       ,dk: 'Pumpebatteri lav Alarm'
       ,de: 'Pumpenbatterie niedrig Alarm'
       ,fi: 'Varoitus! Pumpun patteri loppumassa'
       ,bg: 'Аларма за слаба батерия на помпата'
      ,hr: 'Upozorenje slabe baterije pumpe'
       ,ja: 'ポンプバッテリーが低下'
       ,pl: 'Alarm! Niski poziom baterii w pompie'
       ,ru: 'Внимание! низкий заряд батареи помпы'
       ,tr: 'Pompa Düşük pil alarmı'
       ,hu: 'Alacsony pumpa töltöttség figyelmeztetés'
    }
    ,'Pump Battery change overdue!' : {  // batteryage.js
       nl: 'Pompbatterij moet vervangen worden!'
       ,sv: 'Pumpbatteriet måste bytas!'
       ,dk: 'Pumpebatteri skal skiftes!'
       ,de: 'Pumpenbatterie Wechsel überfällig!'
       ,fi: 'Pumpun patterin vaihto myöhässä!'
       ,bg: 'Смяната на батерията на помпата - наложителна'
      ,hr: 'Prošao je rok za zamjenu baterije pumpe!'
      ,ja: 'ポンプバッテリー交換期限切れてます！'
      , pl: 'Bateria pompy musi być wymieniona!'
       ,ru: 'пропущен срок замены батареи!'
      ,tr:  'Pompa pil değişimi gecikti!'
      ,hu:  'A pumpa eleme cserére szorul'
    }
    ,'When enabled an alarm may sound.' : {
      cs: 'Při povoleném alarmu zní zvuk'
      ,de: 'Sofern eingeschaltet ertönt ein Alarm'
      ,es: 'Cuando estén activas, una alarma podrá sonar'
      ,fr: 'Si activée, un alarme peut sonner.'
      ,el: 'Όταν ενεργοποιηθεί, μία ηχητική ειδοποίηση ενδέχεται να ακουστεί'
      ,pt: 'Quando ativado, um alarme poderá soar'
      ,ro: 'Când este activ, poate suna o alarmă.'
      ,sv: 'När markerad är ljudlarm aktivt'
      ,bg: 'Когато е активирано, алармата ще има звук'
      ,hr: 'Kad je aktiviran, alarm se može oglasiti'
      ,it: 'Quando si attiva un allarme acustico.'
      ,ja: '有効にすればアラームが鳴動します。'
      ,dk: 'Når aktiveret kan alarm lyde'
      ,fi: 'Aktivointi mahdollistaa äänihälytykset'
      ,nb: 'Når aktivert er alarmer aktive'
      ,he: 'כשמופעל התראות יכולות להישמע.'
      ,pl: 'Sygnalizacja dzwiękowa przy włączonym alarmie'
      ,ru: 'При активации может звучать сигнал'
      ,sk: 'Pri aktivovanom alarme znie zvuk '
      ,nl: 'Als ingeschakeld kan alarm klinken'
      ,ko: '알림을 활성화 하면 알람이 울립니다.'
      ,tr: 'Etkinleştirilirse, alarm çalar.'
      ,zh_cn: '启用后可发出声音报警'
      ,zh_tw: '啟用後可發出聲音報警'
      ,hu: 'Bekapcsoláskor hang figyelmeztetés várható'
      }
    ,'Urgent High Alarm' : {
      cs: 'Urgentní vysoká glykémie'
      ,de: 'Achtung Hoch Alarm'
      ,es: 'Alarma de glucemia alta urgente'
      ,fr: 'Alarme hyperglycémie urgente'
      ,el: 'Ειδοποίηση επικίνδυνα υψηλής γλυκόζης'
      ,pt: 'URGENTE: Alarme de glicemia alta'
      ,sv: 'Brådskande högt larmvärde'
      ,ro: 'Alarmă urgentă hiper'
      ,bg: 'Много висока КЗ'
      ,hr: 'Hitni alarm za hiper'
      ,it: 'Urgente:Glicemia Alta'
      ,ja: '緊急高血糖アラーム'
      ,dk: 'Kritisk høj grænse overskredet'
      ,fi: 'Kriittinen korkea'
      ,nb: 'Kritisk høy alarm'
      ,he: 'התראת גבוה דחופה'
      ,pl: 'Uwaga: Alarm hiperglikemii'
      ,ru: 'Внимание: высокая гликемия '
      ,sk: 'Naliehavý alarm vysokej glykémie'
      ,nl: 'Urgent Alarm Hoge BG'
      ,ko: '긴급 고혈당 알람'
      ,tr: 'Acil Yüksek Alarm'  //Dikkat yüksek alarm
      ,zh_cn: '血糖过高报警'
      ,zh_tw: '血糖過高報警'
      ,hu: 'Nagyon magas cukorszint figyelmeztetés'
      }
    ,'High Alarm' : {
      cs: 'Vysoká glykémie'
      ,de: 'Hoch Alarm'
      ,es: 'Alarma de glucemia alta'
      ,fr: 'Alarme hyperglycémie'
      ,el: 'Ειδοποίηση υψηλής γλυκόζης'
      ,pt: 'Alarme de glicemia alta'
      ,sv: 'Högt larmvärde'
      ,ro: 'Alarmă hiper'
      ,bg: 'Висока КЗ'
      ,hr: 'Alarm za hiper'
      ,it: 'Glicemia Alta'
      ,ja: '高血糖アラーム'
      ,dk: 'Høj grænse overskredet'
      ,fi: 'Korkea verensokeri'
      ,nb: 'Høy alarm'
      ,he: 'התראת גבוה'
      ,pl: 'Alarm hiperglikemii'
      ,ru: 'Высокая гликемия'
      ,sk: 'Alarm vysokej glykémie'
      ,nl: 'Alarm hoge BG'
      ,ko: '고혈당 알람'
      ,tr: 'Yüksek Alarmı'
      ,zh_cn: '高血糖报警'
      ,zh_tw: '高血糖報警'
      ,hu: 'Magas cukorszint fegyelmeztetés'
      }
    ,'Low Alarm' : {
      cs: 'Nízká glykémie'
      ,de: 'Niedrig Alarm'
      ,es: 'Alarma de glucemia baja'
      ,fr: 'Alarme hypoglycémie'
      ,el: 'Ειδοποίηση χαμηλής γλυκόζης'
      ,pt: 'Alarme de glicemia baixa'
      ,sv: 'Lågt larmvärde'
      ,ro: 'Alarmă hipo'
      ,bg: 'Ниска КЗ'
      ,hr: 'Alarm za hipo'
      ,it: 'Glicemia bassa'
      ,ja: '低血糖アラーム'
      ,dk: 'Lav grænse overskredet'
      ,fi: 'Matala verensokeri'
      ,nb: 'Lav alarm'
      ,he: 'התראת נמוך'
      ,pl: 'Alarm hipoglikemii'
      ,ru: 'Низкая гликемия'
      ,sk: 'Alarm nízkej glykémie'
      ,nl: 'Alarm lage BG'
      ,ko: '저혈당 알람'
      ,tr: 'Düşük Alarmı'
      ,zh_cn: '低血糖报警'
      ,zh_tw: '低血糖報警'
      ,hu: 'Alacsony cukorszint figyelmeztetés'
      }
    ,'Urgent Low Alarm' : {
      cs: 'Urgentní nízká glykémie'
      ,de: 'Achtung Niedrig Alarm'
      ,es: 'Alarma de glucemia baja urgente'
      ,fr: 'Alarme hypoglycémie urgente'
      ,el: 'Ειδοποίηση επικίνδυνα χαμηλής γλυκόζης'
      ,pt: 'URGENTE: Alarme de glicemia baixa'
      ,sv: 'Brådskande lågt larmvärde'
      ,ro: 'Alarmă urgentă hipo'
      ,bg: 'Много ниска КЗ'
      ,hr: 'Hitni alarm za hipo'
      ,it: 'Urgente:Glicemia Bassa'
      ,ja: '緊急低血糖アラーム'
      ,dk: 'Advarsel: Lav'
      ,fi: 'Kriittinen matala'
      ,nb: 'Kritisk lav alarm'
      ,he: 'התראת נמוך דחופה'
      ,pl: 'Uwaga: Alarm hipoglikemii'
      ,ru: 'Внимание: низкая гликемия'
      ,sk: 'Naliehavý alarm nízkej glykémie'
      ,nl: 'Urgent Alarm lage BG'
      ,ko: '긴급 저혈당 알람'
      ,tr: 'Acil Düşük Alarmı'
      ,zh_cn: '血糖过低报警'
      ,zh_tw: '血糖過低報警'
      ,hu: 'Nagyon alacsony cukorszint figyelmeztetés'
      }
    ,'Stale Data: Warn' : {
      cs: 'Zastaralá data'
      ,he: 'אזהרה-נתונים ישנים'
      ,de: 'Warnung: Daten nicht mehr gültig'
      ,es: 'Datos obsoletos: aviso'
      ,fr: 'Données échues: avertissement'
      ,el: 'Έλλειψη πρόσφατων δεδομένων: Προειδοποίηση'
      ,pt: 'Dados antigos: alerta'
      ,sv: 'Förfluten data: Varning!'
      ,ro: 'Date învechite: alertă'
      ,bg: 'Стари данни'
      ,hr: 'Pažnja: Stari podaci'
      ,it: 'Notifica Dati'
      ,ja: '注意：古いデータ'
      ,dk: 'Advarsel: Gamle data'
      ,fi: 'Vanhat tiedot: varoitus'
      ,nb: 'Advarsel: Gamle data'
      ,pl: 'Ostrzeżenie: brak odczytów'
      ,ru: 'Предупреждение: старые данные'
      ,sk: 'Varovanie: Zastaralé dáta'
      ,nl: 'Waarschuwing Oude gegevens na'
      ,ko: '손실 데이터 : 경고'
      ,tr: 'Eski Veri: Uyarı'  //Uyarı: veri artık geçerli değil
      ,zh_cn: '数据过期：提醒'
      ,zh_tw: '數據過期：提醒'
      ,hu: 'Figyelmeztetés: Az adatok öregnek tűnnek'
      }
    ,'Stale Data: Urgent' : {
      cs: 'Zastaralá data urgentní'
      ,he: 'דחוף-נתונים ישנים'
      ,de: 'Achtung: Daten nicht mehr gültig'
      ,es: 'Datos obsoletos: Urgente'
      ,fr: 'Données échues: avertissement urgent'
      ,el: 'Έλλειψη πρόσφατων δεδομένων: ΕΠΕΙΓΟΝ'
      ,pt: 'Dados antigos: Urgente'
      ,sv: 'Brådskande varning, inaktuell data'
      ,ro: 'Date învechite: urgent'
      ,bg: 'Много стари данни'
      ,hr: 'Hitno: Stari podaci'
      ,it: 'Notifica:Urgente'
      ,ja: '緊急：古いデータ'
      ,dk: 'Kritisk: Gamle data'
      ,fi: 'Vanhat tiedot: hälytys'
      ,nb: 'Advarsel: Veldig gamle data'
      ,pl: 'Uwaga: brak odczytów'
      ,ru: 'Внимание: старые данные'
      ,sk: 'Naliehavé: Zastaralé dáta'
      ,nl: 'Urgente Waarschuwing Oude gegevens na'
      ,ko: '손실 데이터 : 긴급'
      ,tr: 'Eski Veri: Acil'
      ,zh_cn: '数据过期：警告'
      ,zh_tw: '數據過期：警告'
      ,hu: 'Figyelmeztetés: Az adatok nagyon öregnek tűnnek'
      }
    ,'mins' : {
      cs: 'min'
      ,he: 'דקות'
      ,de: 'Minuten'
      ,es: 'min'
      ,fr: 'mins'
      ,el: 'λεπτά'
      ,pt: 'min'
      ,sv: 'min'
      ,ro: 'min'
      ,bg: 'мин'
      ,hr: 'min'
      ,it: 'min'
      ,ja: '分'
      ,dk: 'min'
      ,fi: 'minuuttia'
      ,nb: 'min'
      ,pl: 'min'
      ,ru: 'мин'
      ,sk: 'min.'
      ,nl: 'minuten'
      ,ko: '분'
      ,tr: 'dk.'
      ,zh_cn: '分'
      ,zh_tw: '分'
      ,hu: 'perc'
      }
    ,'Night Mode' : {
      cs: 'Noční mód'
      ,he: 'מצב לילה'
      ,de: 'Nacht Modus'
      ,es: 'Modo nocturno'
      ,fr: 'Mode nocturne'
      ,el: 'Λειτουργία Νυκτός'
      ,pt: 'Modo noturno'
      ,sv: 'Nattläge'
      ,ro: 'Mod nocturn'
      ,bg: 'Нощен режим'
      ,hr: 'Noćni način'
      ,it: 'Modalità Notte'
      ,ja: '夜間モード'
      ,dk: 'Nat tilstand'
      ,fi: 'Yömoodi'
      ,nb: 'Nattmodus'
      ,pl: 'Tryb nocny'
      ,ru: 'Режим: ночь'
      ,sk: 'Nočný mód'
      ,nl: 'Nachtstand'
      ,ko: '나이트 모드'
      ,tr: 'Gece Modu'
      ,zh_cn: '夜间模式'
      ,zh_tw: '夜間模式'
      ,hu: 'Éjjeli üzemmód'
      }
    ,'When enabled the page will be dimmed from 10pm - 6am.' : {
      cs: 'Když je povoleno, obrazovka je ztlumena 22:00 - 6:00'
      ,he: 'במצב זה המסך יעומעם בין השעות עשר בלילה לשש בבוקר'
      ,de: 'Sofern aktiviert wird die Anzeige von 22h - 6h gedimmt'
      ,es: 'Cuando esté activo, el brillo de la página bajará de 10pm a 6am.'
      ,fr: 'Si activé, la page sera assombrie de 22:00 à 6:00'
      ,el: 'Όταν ενεργοποιηθεί, η φωτεινότητα της οθόνης θα μειωθεί μεταξύ 22.00 - 6.00'
      ,pt: 'Se ativado, a página será escurecida entre 22h e 6h'
      ,sv: 'När aktiverad dimmas sidan mellan 22:00 - 06:00'
      ,ro: 'La activare va scădea iluminarea între 22 și 6'
      ,bg: 'Когато е активирано, страницата ще е затъмнена от 22-06ч'
      ,hr: 'Kad je uključen, stranica će biti zatamnjena od 22-06'
      ,it: 'Attivandola, la pagina sarà oscurata dalle 22:00-06:00.'
      ,ja: '有効にすると、ページは 夜22時から 朝6時まで単色表示になります。'
      ,dk: 'Når aktiveret vil denne side nedtones fra 22:00-6:00'
      ,fi: 'Aktivoituna sivu himmenee kello 22 ja 06 välillä'
      ,nb: 'Når aktivert vil denne siden nedtones fra 22:00-06:00'
      ,pl: 'Po włączeniu strona będzie przyciemniona od 22 wieczorem do 6 nad ranem.'
      ,ru: 'При активации страница затемнена с 22.00 до 06.00'
      ,sk: 'Keď je povolený, obrazovka bude stlmená od 22:00 do 6:00.'
      ,nl: 'Scherm dimmen tussen 22:00 en 06:00'
      ,ko: '페이지를 켜면 오후 10시 부터 오전 6시까지 비활성화 될 것이다.'
      ,tr: 'Etkinleştirildiğinde, ekran akşam 22\'den sabah 6\'ya kadar kararır.'
      ,zh_cn: '启用后将在夜间22点至早晨6点降低页面亮度'
      ,zh_tw: '啟用後將在夜間22點至早晨6點降低頁面亮度'
      ,hu: 'Ezt bekapcsolva a képernyő halványabb lesz 22-től 6-ig'
      }
    ,'Enable' : {
      cs: 'Povoleno'
      ,he: 'אפשר'
      ,de: 'Aktivieren'
      ,es: 'Activar'
      ,fr: 'Activer'
      ,el: 'Ενεργοποίηση'
      ,pt: 'Ativar'
      ,sv: 'Aktivera'
      ,ro: 'Activează'
      ,bg: 'Активен'
      ,hr: 'Aktiviraj'
      ,it: 'Permettere'
      ,ja: '有効'
      ,dk: 'Aktivere'
      ,fi: 'Aktivoi'
      ,nb: 'Aktiver'
      ,pl: 'Włącz'
      ,ru: 'Активировать'
      ,sk: 'Povoliť'
      ,nl: 'Activeren'
      ,ko: '활성화'
      ,tr: 'Etkinleştir'
      ,zh_cn: '启用'
      ,zh_tw: '啟用'
      ,hu: 'Engedélyezve'
      }
    ,'Show Raw BG Data' : {
      cs: 'Zobraz RAW data'
      ,he: 'הראה את רמת הסוכר ללא עיבוד'
      ,de: 'Zeige Roh-BG Daten'
      ,es: 'Mostrat datos en glucemia en crudo'
      ,fr: 'Montrer les données BG brutes'
      ,el: 'Εμφάνιση αυτούσιων δεδομένων αισθητήρα'
      ,pt: 'Mostrar dados de glicemia não processados'
      ,sv: 'Visa RAW-data'
      ,ro: 'Afișează date primare glicemie'
      ,bg: 'Показвай RAW данни'
      ,hr: 'Prikazuj sirove podatke o GUK-u'
      ,it: 'Mostra dati Raw BG'
      ,ja: '素のBGデータを表示する'
      ,dk: 'Vis rå BS data'
      ,fi: 'Näytä raaka VS tieto'
      ,nb: 'Vis rådata'
      ,pl: 'Wyświetl surowe dane BG'
      ,ru: 'Показывать необработанные данные RAW'
      ,sk: 'Zobraziť RAW dáta'
      ,nl: 'Laat ruwe data zien'
      ,ko: 'Raw 혈당 데이터 보기'
      ,tr: 'Ham KŞ verilerini göster'
      ,zh_cn: '显示原始血糖数据'
      ,zh_tw: '顯示原始血糖數據'
      ,hu: 'Nyers BG adatok mutatása'
      }
    ,'Never' : {
      cs: 'Nikdy'
      ,he: 'אף פעם'
      ,de: 'Nie'
      ,es: 'Nunca'
      ,fr: 'Jamais'
      ,el: 'Ποτέ'
      ,pt: 'Nunca'
      ,sv: 'Aldrig'
      ,ro: 'Niciodată'
      ,bg: 'Никога'
      ,hr: 'Nikad'
      ,it: 'Mai'
      ,ja: '決して'
      ,dk: 'Aldrig'
      ,fi: 'Ei koskaan'
      ,nb: 'Aldri'
      ,pl: 'Nigdy'
      ,ru: 'Никогда'
      ,sk: 'Nikdy'
      ,nl: 'Nooit'
      ,ko: '보지 않기'
      ,tr: 'Hiçbir zaman' //Asla
      ,zh_cn: '不显示'
      ,zh_tw: '不顯示'
      ,hu: 'Soha'
      }
    ,'Always' : {
      cs: 'Vždy'
      ,he: 'תמיד'
      ,de: 'Immer'
      ,es: 'Siempre'
      ,fr: 'Toujours'
      ,el: 'Πάντα'
      ,pt: 'Sempre'
      ,sv: 'Alltid'
      ,ro: 'Întotdeauna'
      ,bg: 'Винаги'
      ,hr: 'Uvijek'
      ,it: 'Sempre'
      ,ja: 'いつも'
      ,dk: 'Altid'
      ,fi: 'Aina'
      ,nb: 'Alltid'
      ,pl: 'Zawsze'
      ,ru: 'Всегда'
      ,sk: 'Vždy'
      ,nl: 'Altijd'
      ,ko: '항상'
      ,tr: 'Her zaman'
      ,zh_cn: '一直显示'
      ,zh_tw: '一直顯示'
      ,hu: 'Mindíg'
      }
    ,'When there is noise' : {
      cs: 'Při šumu'
      ,he: 'בנוכחות רעש'
      ,de: 'Sofern Rauschen vorhanden'
      ,es: 'Cuando hay ruido'
      ,fr: 'Quand il y a du bruit'
      ,el: 'Όταν υπάρχει θόρυβος'
      ,pt: 'Quando houver ruído'
      ,sv: 'Endast vid brus'
      ,ro: 'Atunci când este diferență'
      ,bg: 'Когато има шум'
      ,hr: 'Kad postoji šum'
      ,it: 'Quando vi è rumore'
      ,ja: '測定不良があった時'
      ,dk: 'Når der er støj'
      ,fi: 'Signaalihäiriöiden yhteydessä'
      ,nb: 'Når det er støy'
      ,pl: 'Gdy sygnał jest zakłócony'
      ,ru: 'Когда есть шумовой фон'
      ,sk: 'Pri šume'
      ,nl: 'Bij ruis'
      ,ko: '노이즈가 있을 때'
      ,tr: 'Gürültü olduğunda'
      ,zh_cn: '当有噪声时显示'
      ,zh_tw: '當有噪聲時顯示'
      ,hu: 'Ha zavar van:'
      }
    ,'When enabled small white dots will be displayed for raw BG data' : {
      cs: 'Když je povoleno, malé tečky budou zobrazeny pro RAW data'
      ,he: 'במצב זה,רמות סוכר לפני עיבוד יוצגו כנקודות לבנות קטנות'
      ,de: 'Bei Aktivierung erscheinen kleine weiße Punkte für Roh-BG Daten'
      ,es: 'Cuando esté activo, pequeños puntos blancos mostrarán los datos en crudo'
      ,el: 'Όταν εργοποιηθεί, μικρές λευκές κουκίδες θα αναπαριστούν τα αυτούσια δεδομένα του αισθητήρα'
      ,fr: 'Si activé, des points blancs représenteront les données brutes'
      ,pt: 'Se ativado, pontos brancos representarão os dados de glicemia não processados'
      ,sv: 'När aktiverad visar de vita punkterna RAW-blodglukosevärden'
      ,ro: 'La activare vor apărea puncte albe reprezentând citirea brută a glicemiei'
      ,bg: 'Когато е активно, малки бели точки ще показват RAW данните'
      ,hr: 'Kad je omogućeno, male bijele točkice će prikazivati sirove podatke o GUK-u.'
      ,it: 'Quando lo abiliti, visualizzerai piccoli puntini bianchi (raw BG data)'
      ,ja: '有効にすると、小さい白ドットが素のBGデータ用に表示されます'
      ,dk: 'Ved aktivering vil små hvide prikker blive vist for rå BG tal'
      ,fi: 'Aktivoituna raaka VS tieto piirtyy aikajanalle valkoisina pisteinä'
      ,nb: 'Ved aktivering vil små hvite prikker bli vist for rå BG tall'
      ,pl: 'Gdy funkcja jest włączona, małe białe kropki pojawią się na surowych danych BG'
      ,ru: 'При активации данные RAW будут видны как мелкие белые точки'
      ,sk: 'Keď je povolené, malé bodky budú zobrazovať RAW dáta.'
      ,nl: 'Indien geactiveerd is ruwe data zichtbaar als witte punten'
      ,ko: '활성화 하면 작은 흰점들이 raw 혈당 데이터를 표시하게 될 것이다.'
      ,tr: 'Etkinleştirildiğinde, ham KŞ verileri için küçük beyaz noktalar görüntülenecektir.'
      ,zh_cn: '启用后将使用小白点标注原始血糖数据'
      ,zh_tw: '啟用後將使用小白點標註原始血糖數據'
      ,hu: 'Bekapcsolasnál kis fehért pontok fogják jelezni a nyers BG adatokat'
      }
    ,'Custom Title' : {
      cs: 'Vlastní název stránky'
      ,he: 'כותרת מותאמת אישית'
      ,de: 'Benutzerdefinierter Titel'
      ,es: 'Título personalizado'
      ,fr: 'Titre personalisé'
      ,el: 'Επιθυμητός τίτλος σελίδας'
      ,pt: 'Customizar Título'
      ,sv: 'Egen titel'
      ,ro: 'Titlu particularizat'
      ,bg: 'Име на страницата'
      ,hr: 'Vlastiti naziv'
      ,it: 'Titolo personalizzato'
      ,ja: 'カスタムタイトル'
      ,dk: 'Valgfri titel'
      ,fi: 'Omavalintainen otsikko'
      ,nb: 'Egen tittel'
      ,pl: 'Własny tytuł strony'
      ,ru: 'Произвольное название'
      ,sk: 'Vlastný názov stránky'
      ,ko: '사용자 정의 제목'
      ,nl:  'Eigen titel'
      ,tr: 'Özel Başlık'
      ,zh_cn: '自定义标题'
      ,zh_tw: '自定義標題'
      ,hu: 'Saját Cím'
      }
    ,'Theme' : {
      cs: 'Téma'
      ,he: 'נושא'
      ,de: 'Aussehen'
      ,es: 'Tema'
      ,fr: 'Thème'
      ,el: 'Θέμα απεικόνισης'
      ,pt: 'tema'
      ,ro: 'Temă'
      ,bg: 'Тема'
      ,hr: 'Tema'
      ,sv: 'Tema'
      ,it: 'Tema'
      ,ja: 'テーマ'
      ,dk: 'Tema'
      ,fi: 'Teema'
      ,nb: 'Tema'
      ,pl: 'Wygląd'
      ,ru: 'Тема'
      ,sk: 'Vzhľad'
      ,nl: 'Thema'
      ,ko: '테마'
      ,tr: 'Tema'
      ,zh_cn: '主题'
      ,zh_tw: '主題'
      ,hu: 'Téma'
      }
    ,'Default' : {
      cs: 'Výchozí'
      ,he: 'בְּרִירַת מֶחדָל'
      ,de: 'Standard'
      ,es: 'Por defecto'
      ,fr: 'Par défaut'
      ,el: 'Κύριο'
      ,pt: 'Padrão'
      ,sv: 'Standard'
      ,ro: 'Implicită'
      ,bg: 'Черно-бяла'
      ,hr: 'Default'
      ,it: 'Predefinito'
      ,ja: 'デフォルト'
      ,dk: 'Standard'
      ,fi: 'Oletus'
      ,nb: 'Standard'
      ,pl: 'Domyślny'
      ,ru: 'По умолчанию'
      ,sk: 'Predvolený'
      ,nl: 'Standaard'
      ,ko: '초기설정'
      ,tr: 'Varsayılan'
      ,zh_cn: '默认'
      ,zh_tw: '默認'
      ,hu: 'Alap'
      }
    ,'Colors' : {
      cs: 'Barevné'
      ,he: 'צבעים'
      ,de: 'Farben'
      ,es: 'Colores'
      ,fr: 'Couleurs'
      ,el: 'Χρώματα'
      ,pt: 'Colorido'
      ,sv: 'Färg'
      ,ro: 'Colorată'
      ,bg: 'Цветна'
      ,hr: 'Boje'
      ,it: 'Colori'
      ,ja: '色付き'
      ,dk: 'Farver'
      ,fi: 'Värit'
      ,nb: 'Farger'
      ,pl: 'Kolorowy'
      ,ru: 'Цветная'
      ,sk: 'Farebný'
      ,nl: 'Kleuren'
      ,ko: '색상'
      ,tr: 'Renkler'
      ,zh_cn: '彩色'
      ,zh_tw: '彩色'
      ,hu: 'Színek'
      }
    ,'Colorblind-friendly colors' : {
      cs: 'Pro barvoslepé'
      ,he: 'צבעים ידידותיים לעוורי צבעים'
      ,de: 'Farbenblind-freundliche Darstellung'
      ,es: 'Colores para Daltónicos'
      ,fr: 'Couleurs pour daltoniens'
      ,dk: 'Farveblindvenlige farver'
      ,nb: 'Fargeblindvennlige farger'
      ,el: 'Χρώματα συμβατά για αχρωματοψία'
      ,pt: 'Cores para daltônicos'
      ,sv: 'Högkontrastfärger'
      ,sk: 'Farby vhodné pre farboslepých'
      ,nl: 'Kleurenblind vriendelijke kleuren'
      ,ro: 'Culori pentru cei cu deficiențe de vedere'
      ,ko: '색맹 친화적인 색상'
      ,bg: 'Цветове за далтонисти'
      ,hr: 'Boje za daltoniste'
      ,it: 'Colori per daltonici'
      ,ja: '色覚異常の方向けの色'
      ,fi: 'Värisokeille sopivat värit'
      ,zh_cn: '色盲患者可辨识的颜色'
      ,zh_tw: '色盲患者可辨識的顏色'
      ,pl: 'Kolory dla niedowidzących'
      ,tr: 'Renk körü dostu görünüm'
      ,ru: 'Цветовая гамма для людей с нарушениями восприятия цвета'
      ,hu: 'Beállítás színvakok számára'
      }
    ,'Reset, and use defaults' : {
      cs: 'Vymaž a nastav výchozí hodnoty'
      ,he: 'איפוס ושימוש ברירות מחדל'
      ,de: 'Zurücksetzen und Voreinstellungen verwenden'
      ,es: 'Inicializar y utilizar los valores por defecto'
      ,fr: 'Remettre à zéro et utiliser les valeurs par défaut'
      ,el: 'Αρχικοποίηση και χρήση των προκαθορισμένων ρυθμίσεων'
      ,pt: 'Zerar e usar padrões'
      ,sv: 'Återställ standardvärden'
      ,ro: 'Resetează și folosește setările implicite'
      ,bg: 'Нулирай и използвай стандартните настройки'
      ,hr: 'Resetiraj i koristi defaultne vrijednosti'
      ,it: 'Resetta le impostazioni predefinite'
      ,ja: 'リセットしてデフォルト設定を使用'
      ,dk: 'Returner til standardopsætning'
      ,fi: 'Palauta oletusasetukset'
      ,nb: 'Gjenopprett standardinnstillinger'
      ,pl: 'Reset i powrót do domyślnych ustawień'
      ,ru: 'Сбросить и использовать настройки по умолчанию'
      ,sk: 'Resetovať do pôvodného nastavenia'
      ,nl: 'Herstel standaard waardes'
      ,ko: '초기화 그리고 초기설정으로 사용'
      ,tr: 'Sıfırla ve varsayılanları kullan'
      ,zh_cn: '使用默认值重置'
      ,zh_tw: '使用默認值重置'
      ,hu: 'Visszaállítás a kiinduló állapotba'
      }
    ,'Calibrations' : {
      cs: 'Kalibrace'
      ,he: 'כיולים'
      ,de: 'Kalibrierung'
      ,es: 'Calibraciones'
      ,fr: 'Calibration'
      ,el: 'Βαθμονόμηση'
      ,pt: 'Calibraçôes'
      ,sv: 'Kalibreringar'
      ,ro: 'Calibrări'
      ,bg: 'Калибрации'
      ,hr: 'Kalibriranje'
      ,it: 'Calibrazioni'
      ,ja: '較生'
      ,dk: 'Kalibrering'
      ,fi: 'Kalibraatiot'
      ,nb: 'Kalibreringer'
      ,pl: 'Kalibracja'
      ,ru: 'Калибровки'
      ,sk: 'Kalibrácie'
      ,nl: 'Kalibraties'
      ,ko: '보정'
      ,tr: 'Kalibrasyon'
      ,zh_cn: '校准'
      ,hu: 'Kalibráció'
      }
    ,'Alarm Test / Smartphone Enable' : {
      cs: 'Test alarmu'
      ,he: 'אזעקה מבחן / הפעל טלפון'
      ,de: 'Alarm Test / Smartphone aktivieren'
      ,es: 'Test de Alarma / Activar teléfono'
      ,fr: 'Test alarme / Activer Smartphone'
      ,el: 'Τεστ ηχητικής ειδοποίησης'
      ,pt: 'Testar Alarme / Ativar Smartphone'
      ,sv: 'Testa alarm / Aktivera Smatphone'
      ,ro: 'Teste alarme / Activează pe smartphone'
      ,bg: 'Тестване на алармата / Активно за мобилни телефони'
      ,hr: 'Alarm test / Aktiviraj smartphone'
      ,it: 'Test Allarme / Abilita Smartphone'
      ,ja: 'アラームテスト/スマートフォンを有効にする'
      ,dk: 'Alarm test / Smartphone aktiveret'
      ,fi: 'Hälytyksien testaus / Älypuhelimien äänet päälle'
      ,nb: 'Alarmtest / Smartphone aktivering'
      ,pl: 'Test alarmu / Smartphone aktywny'
      ,ru: 'Проверка зв. уведомлений / смартфон активен'
      ,sk: 'Test alarmu'
      ,nl: 'Alarm test / activeer Smartphone'
      ,ko: '알람 테스트 / 스마트폰 활성화'
      ,tr: 'Alarm Testi / Akıllı Telefon için Etkin'
      ,zh_cn: '报警测试/智能手机启用'
      ,zh_tw: '報警測試/智能手機啟用'
      ,hu: 'Figyelmeztetés teszt / Mobiltelefon aktiválása'
      }
    ,'Bolus Wizard' : {
      cs: 'Bolusový kalkulátor'
      ,he: 'אשף בולוס'
      ,de: 'Bolus-Kalkulator'
      ,es: 'Calculo Bolos sugeridos'
      ,fr: 'Calculateur de bolus'
      ,el: 'Εργαλείο υπολογισμού ινσουλίνης'
      ,pt: 'Ajuda de bolus'
      ,sv: 'Boluskalkylator'
      ,ro: 'Calculator sugestie bolus'
      ,bg: 'Болус съветник '
      ,hr: 'Bolus wizard'
      ,it: 'BW-Calcolatore di Bolo'
      ,ja: 'ボーラスウィザード'
      ,dk: 'Bolusberegner'
      ,fi: 'Annosopas'
      ,nb: 'Boluskalkulator'
      ,pl: 'Kalkulator bolusa'
      ,ru: 'калькулятор болюса'
      ,sk: 'Bolusový kalkulátor'
      ,nl: 'Bolus calculator'
      ,ko: 'Bolus 마법사'
      ,tr: 'Bolus Hesaplayıcısı'
      ,zh_cn: '大剂量向导'
      ,zh_tw: '大劑量嚮導'
      ,hu: 'Bólus Varázsló'
      }
    ,'in the future' : {
      cs: 'v budoucnosti'
      ,he: 'בעתיד'
      ,de: 'in der Zukunft'
      ,es: 'en el futuro'
      ,fr: 'dans le futur'
      ,el: 'στο μέλλον'
      ,pt: 'no futuro'
      ,sv: 'framtida'
      ,ro: 'în viitor'
      ,bg: 'в бъдещето'
      ,hr: 'U budućnosti'
      ,it: 'nel futuro'
      ,ja: '先の時間'
      ,dk: 'i fremtiden'
      ,fi: 'tulevaisuudessa'
      ,nb: 'fremtiden'
      ,pl: 'w przyszłości'
      ,ru: 'в будущем'
      ,sk: 'v budúcnosti'
      ,nl: 'In de toekomst'
      ,ko: '미래'
      ,tr: 'gelecekte'
      ,zh_cn: '在未来'
      ,zh_tw: '在未來'
      ,hu: 'a jövőben'
      }
    ,'time ago' : {
      cs: 'min zpět'
      ,he: 'פעם'
      ,de: 'seit Kurzem'
      ,es: 'tiempo atrás'
      ,fr: 'temps avant'
      ,el: 'χρόνο πριν'
      ,pt: 'tempo atrás'
      ,sv: 'förfluten tid'
      ,ro: 'în trecut'
      ,bg: 'преди време'
      ,hr: 'prije'
      ,it: 'tempo fa'
      ,ja: '時間前'
      ,dk: 'tid siden'
      ,fi: 'aikaa sitten'
      ,nb: 'tid siden'
      ,pl: 'czas temu'
      ,ru: 'времени назад'
      ,sk: 'čas pred'
      ,nl: 'tijd geleden'
      ,ko: '시간 전'
      ,tr: 'süre önce'  //yakın zamanda
      ,zh_cn: '在过去'
      ,zh_tw: '在過去'
      ,hu: 'idő elött'
      }
    ,'hr ago' : {
      cs: 'hod zpět'
      ,he: 'לפני שעה'
      ,de: 'Stunde her'
      ,es: 'hr atrás'
      ,fr: 'hr avant'
      ,el: 'ώρα πριν'
      ,pt: 'h atrás'
      ,sv: 'timmar sedan'
      ,ro: 'oră în trecut'
      ,bg: 'час по-рано'
      ,hr: 'sat unazad'
      ,it: 'ora fa'
      ,ja: '時間前'
      ,dk: 'time siden'
      ,fi: 'tunti sitten'
      ,nb: 'Time siden'
      ,pl: 'godzina temu'
      ,ru: 'час назад'
      ,sk: 'hod. pred'
      ,nl: 'uur geleden'
      ,ko: '시간 전'
      ,tr: 'saat önce'
      ,zh_cn: '小时前'
      ,zh_tw: '小時前'
      ,hu: 'óra elött'
      }
    ,'hrs ago' : {
      cs: 'hod zpět'
      ,he: 'לפני שעות'
      ,de: 'Stunden her'
      ,es: 'hrs atrás'
      ,fr: 'hrs avant'
      ,el: 'ώρες πριν'
      ,pt: 'h atrás'
      ,sv: 'Timmar sedan'
      ,ro: 'h în trecut'
      ,bg: 'часа по-рано'
      ,hr: 'sati unazad'
      ,it: 'ore fa'
      ,ja: '時間前'
      ,dk: 'timer siden'
      ,fi: 'tuntia sitten'
      ,nb: 'Timer siden'
      ,pl: 'godzin temu'
      ,ru: 'часов назад'
      ,sk: 'hod. pred'
      ,nl: 'uren geleden'
      ,ko: '시간 전'
      ,tr: 'saat önce'
      ,zh_cn: '小时前'
      ,zh_tw: '小時前'
      ,hu: 'órája'
      }
    ,'min ago' : {
      cs: 'min zpět'
      ,he: 'לפני דקה'
      ,de: 'Minute her'
      ,es: 'min atrás'
      ,fr: 'min avant'
      ,el: 'λεπτό πριν'
      ,pt: 'min atrás'
      ,sv: 'minut sedan'
      ,ro: 'minut în trecut'
      ,bg: 'мин. по-рано'
      ,hr: 'minuta unazad'
      ,it: 'minuto fa'
      ,ja: '分前'
      ,dk: 'minut siden'
      ,fi: 'minuutti sitten'
      ,nb: 'minutter siden'
      ,pl: 'minuta temu'
      ,ru: 'мин назад'
      ,sk: 'min. pred'
      ,nl: 'minuut geleden'
      ,ko: '분 전'
      ,tr: 'dk. önce'
      ,zh_cn: '分钟前'
      ,zh_tw: '分鐘前'
      ,hu: 'perce'
      }
    ,'mins ago' : {
      cs: 'min zpět'
      ,he: 'לפני דקות'
      ,de: 'Minuten her'
      ,es: 'mins atrás'
      ,fr: 'mins avant'
      ,el: 'λεπτά πριν'
      ,pt: 'min atrás'
      ,sv: 'minuter sedan'
      ,ro: 'minute în trecut'
      ,bg: 'мин. по-рано'
      ,hr: 'minuta unazad'
      ,it: 'minuti fa'
      ,ja: '分前'
      ,dk: 'minutter siden'
      ,fi: 'minuuttia sitten'
      ,nb: 'minutter siden'
      ,pl: 'minut temu'
      ,ru: 'минут назад'
      ,sk: 'min. pred'
      ,nl: 'minuten geleden'
      ,ko: '분 전'
      ,tr: 'dakika önce'
      ,zh_cn: '分钟前'
      ,zh_tw: '分鐘前'
      ,hu: 'perce'
      }
    ,'day ago' : {
      cs: 'den zpět'
      ,he: 'לפני יום'
      ,de: 'Tag her'
      ,es: 'día atrás'
      ,fr: 'jour avant'
      ,el: 'ημέρα πριν'
      ,pt: 'dia atrás'
      ,sv: 'dag sedan'
      ,ro: 'zi în trecut'
      ,bg: 'ден по-рано'
      ,hr: 'dan unazad'
      ,it: 'Giorno fa'
      ,ja: '日前'
      ,dk: 'dag siden'
      ,fi: 'päivä sitten'
      ,nb: 'dag siden'
      ,pl: 'dzień temu'
      ,ru: 'дн назад'
      ,sk: 'deň pred'
      ,nl: 'dag geleden'
      ,ko: '일 전'
      ,tr: 'gün önce'
      ,zh_cn: '天前'
      ,zh_tw: '天前'
      ,hu: 'napja'
      }
    ,'days ago' : {
      cs: 'dnů zpět'
      ,he: 'לפני ימים'
      ,de: 'Tage her'
      ,es: 'días atrás'
      ,fr: 'jours avant'
      ,el: 'ημέρες πριν'
      ,pt: 'dias atrás'
      ,sv: 'dagar sedan'
      ,ro: 'zile în trecut'
      ,bg: 'дни по-рано'
      ,hr: 'dana unazad'
      ,it: 'giorni fa'
      ,ja: '日前'
      ,dk: 'dage siden'
      ,fi: 'päivää sitten'
      ,nb: 'dager siden'
      ,pl: 'dni temu'
      ,ru: 'дней назад'
      ,sk: 'dni pred'
      ,nl: 'dagen geleden'
      ,ko: '일 전'
      ,tr: 'günler önce'
      ,zh_cn: '天前'
      ,zh_tw: '天前'
      ,hu: 'napja'
      }
    ,'long ago' : {
      cs: 'dlouho zpět'
      ,he: 'לפני הרבה זמן'
      ,de: 'lange her'
      ,es: 'Hace mucho tiempo'
      ,fr: 'il y a longtemps'
      ,el: 'χρόνο πριν'
      ,pt: 'muito tempo atrás'
      ,sv: 'länge sedan'
      ,ro: 'timp în trecut'
      ,bg: 'преди много време'
      ,hr: 'prije dosta vremena'
      ,it: 'Molto tempo fa'
      ,ja: '前の期間'
      ,dk: 'længe siden'
      ,fi: 'Pitkän aikaa sitten'
      ,nb: 'lenge siden'
      ,pl: 'dawno'
      ,ru: 'давно'
      ,sk: 'veľmi dávno'
      ,nl: 'lang geleden'
      ,ko: '기간 전'
      ,tr: 'uzun zaman önce'
      ,zh_cn: '很长时间前'
      ,zh_tw: '很長時間前'
      ,hu: 'nagyon régen'
      }
    ,'Clean' : {
      cs: 'Čistý'
      ,he: 'נקה'
      ,de: 'Löschen'
      ,es: 'Limpio'
      ,fr: 'Propre'
      ,el: 'Καθαρισμός'
      ,pt: 'Limpo'
      ,sv: 'Rent'
      ,ro: 'Curat'
      ,bg: 'Чист'
      ,hr: 'Čisto'
      ,it: 'Pulito'
      ,ja: 'なし'
      ,dk: 'Rent'
      ,fi: 'Puhdas'
      ,nb: 'Rent'
      ,pl: 'Czysty'
      ,ru: 'Чисто'
      ,sk: 'Čistý'
      ,nl: 'Schoon'
      ,ko: 'Clean'
      ,tr: 'Temiz'
      ,zh_cn: '无'
      ,zh_tw: '無'
      ,hu: 'Tiszta'
      }
    ,'Light' : {
      cs: 'Lehký'
      ,he: 'אוֹר'
      ,de: 'Leicht'
      ,es: 'Ligero'
      ,fr: 'Léger'
      ,el: 'Ελαφρά'
      ,pt: 'Leve'
      ,sv: 'Lätt'
      ,ro: 'Ușor'
      ,bg: 'Лек'
      ,hr: 'Lagano'
      ,it: 'Leggero'
      ,ja: '軽い'
      ,dk: 'Let'
      ,fi: 'Kevyt'
      ,nb: 'Lite'
      ,pl: 'Niski'
      ,ru: 'Слабый'
      ,sk: 'Nízky'
      ,nl: 'Licht'
      ,ko: 'Light'
      ,tr: 'Kolay'
      ,zh_cn: '轻度'
      ,zh_tw: '輕度'
      ,hu: 'Könnyű'
      }
    ,'Medium' : {
      cs: 'Střední'
      ,he: 'בינוני'
      ,de: 'Mittel'
      ,es: 'Medio'
      ,fr: 'Moyen'
      ,el: 'Μέση'
      ,pt: 'Médio'
      ,sv: 'Måttligt'
      ,ro: 'Mediu'
      ,bg: 'Среден'
      ,hr: 'Srednje'
      ,it: 'Medio'
      ,ja: '中間'
      ,dk: 'Middel'
      ,fi: 'Keskiverto'
      ,nb: 'Middels'
      ,pl: 'Średni'
      ,ru: 'Средний'
      ,sk: 'Stredný'
      ,nl: 'Gemiddeld'
      ,ko: '보통'
      ,tr: 'Orta'
      ,zh_cn: '中度'
      ,zh_tw: '中度'
      ,hu: 'Közepes'
      }
    ,'Heavy' : {
      cs: 'Velký'
      ,de: 'Stark'
      ,es: 'Fuerte'
      ,fr: 'Important'
      ,el: 'Βαριά'
      ,pt: 'Pesado'
      ,sv: 'Rikligt'
      ,ro: 'Puternic'
      ,bg: 'Висок'
      ,hr: 'Teško'
      ,it: 'Pesante'
      ,ja: '重たい'
      ,dk: 'Meget'
      ,fi: 'Raskas'
      ,nb: 'Mye'
      ,pl: 'Wysoki'
      ,ru: 'Сильный'
      ,sk: 'Veľký'
      ,nl: 'Zwaar'
      ,ko: '심한'
      ,tr: 'Ağır'
      ,zh_cn: '重度'
      ,zh_tw: '嚴重'
      ,he: 'כבד'
      ,hu: 'Nehéz'
      }
    ,'Treatment type' : {
      cs: 'Typ ošetření'
      ,de: 'Behandlungstyp'
      ,es: 'Tipo de tratamiento'
      ,fr: 'Type de traitement'
      ,el: 'Τύπος Ενέργειας'
      ,pt: 'Tipo de tratamento'
      ,sv: 'Behandlingstyp'
      ,ro: 'Tip tratament'
      ,bg: 'Вид събитие'
      ,hr: 'Vrsta tretmana'
      ,it: 'Somministrazione'
      ,ja: '治療タイプ'
      ,dk: 'Behandlingstype'
      ,fi: 'Hoidon tyyppi'
      ,nb: 'Behandlingstype'
      ,pl: 'Rodzaj leczenia'
      ,ru: 'Вид события'
      ,sk: 'Typ ošetrenia'
      ,nl: 'Type behandeling'
      ,ko: 'Treatment 타입'
      ,tr: 'Tedavi tipi'
      ,zh_cn: '操作类型'
      ,he: 'סוג הטיפול'
      ,hu: 'Kezelés típusa'
    }
    ,'Raw BG' : {
      cs: 'Glykémie z RAW dat'
      ,de: 'Roh-BG'
      ,es: 'Glucemia en crudo'
      ,fr: 'Glycémie brute'
      ,el: 'Αυτούσιες τιμές γλυκόζης'
      ,pt: 'Glicemia sem processamento'
      ,sv: 'RAW-BS'
      ,ro: 'Citire brută a glicemiei'
      ,bg: 'Непреработена КЗ'
      ,hr: 'Sirovi podaci o GUK-u'
      ,it: 'Raw BG'
      ,dk: 'Råt BS'
      ,fi: 'Raaka VS'
      ,nb: 'RAW-BS'
      ,pl: 'Raw BG'
      ,ru: 'необработанные данные ГК'
      ,sk: 'RAW dáta glykémie'
      ,nl: 'Ruwe BG data'
      ,ko: 'Raw 혈당'
      ,tr: 'Ham KŞ'
      ,zh_cn: '原始血糖'
      ,hu: 'Nyers BG'
    }
    ,'Device' : {
      cs: 'Zařízení'
      ,de: 'Gerät'
      ,es: 'Dispositivo'
      ,fr: 'Appareil'
      ,el: 'Συσκευή'
      ,pt: 'Dispositivo'
      ,ro: 'Dispozitiv'
      ,bg: 'Устройство'
      ,hr: 'Uređaj'
      ,sv: 'Enhet'
      ,it: 'Dispositivo'
      ,ja: '機器'
      ,dk: 'Enhed'
      ,fi: 'Laite'
      ,nb: 'Enhet'
      ,pl: 'Urządzenie'
      ,ru: 'Устройство'
      ,sk: 'Zariadenie'
      ,nl: 'Apparaat'
      ,ko: '기기'
      ,tr: 'Cihaz'
      ,zh_cn: '设备'
      ,he: 'התקן'
      ,hu: 'Berendezés'
    }
    ,'Noise' : {
      cs: 'Šum'
      ,he: 'רַעַשׁ'
      ,de: 'Rauschen'
      ,es: 'Ruido'
      ,fr: 'Bruit'
      ,el: 'Θόρυβος'
      ,pt: 'Ruído'
      ,sv: 'Brus'
      ,ro: 'Zgomot'
      ,bg: 'Шум'
      ,hr: 'Šum'
      ,it: 'Rumore'
      ,ja: '測定不良'
      ,dk: 'Støj'
      ,fi: 'Kohina'
      ,nb: 'Støy'
      ,pl: 'Szum'
      ,ru: 'Шумовой фон'
      ,sk: 'Šum'
      ,nl: 'Ruis'
      ,ko: '노이즈'
      ,tr: 'parazit'   // gürültü
      ,zh_cn: '噪声'
      ,hu: 'Zavar'
      }
    ,'Calibration' : {
      cs: 'Kalibrace'
      ,he: 'כִּיוּל'
      ,de: 'Kalibrierung'
      ,es: 'Calibración'
      ,fr: 'Calibration'
      ,el: 'Βαθμονόμηση'
      ,pt: 'Calibração'
      ,sv: 'Kalibrering'
      ,ro: 'Calibrare'
      ,bg: 'Калибрация'
      ,hr: 'Kalibriranje'
      ,it: 'Calibratura'
      ,ja: '較正'
      ,dk: 'Kalibrering'
      ,fi: 'Kalibraatio'
      ,nb: 'Kalibrering'
      ,pl: 'Kalibracja'
      ,ru: 'Калибровка'
      ,sk: 'Kalibrácia'
      ,nl: 'Kalibratie'
      ,ko: '보정'
      ,tr: 'Kalibrasyon'
      ,zh_cn: '校准'
      ,hu: 'Kalibráció'
      }
    ,'Show Plugins' : {
      cs: 'Zobrazuj pluginy'
      ,he: 'הצג תוספים'
      ,de: 'Zeige Plugins'
      ,es: 'Mostrar Plugins'
      ,fr: 'Montrer Plugins'
      ,el: 'Πρόσθετα Συστήματος'
      ,pt: 'Mostrar les Plugins'
      ,ro: 'Arată plugin-urile'
      ,bg: 'Покажи добавките'
      ,hr: 'Prikaži plugine'
      ,sv: 'Visa tillägg'
      ,it: 'Mostra Plugin'
      ,ja: 'プラグイン表示'
      ,dk: 'Vis plugins'
      ,fi: 'Näytä pluginit'
      ,nb: 'Vis plugins'
      ,pl: 'Pokaż rozszerzenia'
      ,ru: 'Показать расширения'
      ,sk: 'Zobraziť pluginy'
      ,nl: 'Laat Plug-Ins zien'
      ,ko: '플러그인 보기'
      ,tr: 'Eklentileri Göster'
      ,zh_cn: '校准'
      ,hu: 'Mutasd a kiegészítőket'
      }
    ,'About' : {
      cs: 'O aplikaci'
      ,he: 'על אודות'
      ,de: 'Über'
      ,es: 'Sobre'
      ,fr: 'À propos de'
      ,el: 'Σχετικά'
      ,pt: 'Sobre'
      ,ro: 'Despre'
      ,bg: 'Относно'
      ,hr: 'O aplikaciji'
      ,sv: 'Om'
      ,it: 'Informazioni'
      ,ja: '約'
      ,dk: 'Om'
      ,fi: 'Nightscoutista'
      ,nb: 'Om'
      ,pl: 'O aplikacji'
      ,ru: 'О приложении'
      ,sk: 'O aplikácii'
      ,nl: 'Over'
      ,ko: '정보'
      ,tr: 'Hakkında'
      ,zh_cn: '关于'
      ,zh_tw: '關於'
      ,hu: 'Az aplikációról'
      }
    ,'Value in' : {
      cs: 'Hodnota v'
      ,he: 'ערך'
      ,de: 'Wert in'
      ,es: 'Valor en'
      ,fr: 'Valeur en'
      ,el: 'Τιμή'
      ,pt: 'Valor em'
      ,ro: 'Valoare în'
      ,bg: 'Стойност в'
      ,hr: 'Vrijednost u'
      ,sv: 'Värde om'
      ,it: 'Valore in'
      ,ja: '数値'
      ,dk: 'Værdi i'
      ,fi: 'Arvo yksiköissä'
      ,nb: 'Verdi i'
      ,pl: 'Wartości w'
      ,ru: 'Значения в'
      ,sk: 'Hodnota v'
      ,nl: 'Waarde in'
      ,ko: '값'
      ,tr: 'Değer cinsinden'
      ,zh_cn: '数值'
      ,hu: 'Érték'
      }
    ,'Carb Time' : {
      cs: 'Čas jídla'
      ,de: 'Kohlenhydrat-Zeit'
      ,es: 'Momento de la ingesta'
      ,fr: 'Moment de l\'ingestion de Glucides'
      ,el: 'Στιγμή χορηγησης υδ/κων'
      ,pt: 'Hora do carboidrato'
      ,ro: 'Ora carbohidrați'
      ,bg: 'Ядене след'
      ,hr: 'Vrijeme unosa UGH'
      ,sv: 'Kolhydratstid'
      ,it: 'Tempo'
      ,ja: 'カーボ時間'
      ,dk: 'Kulhydratstid'
      ,fi: 'Syöntiaika'
      ,nb: 'Karbohydrattid'
      ,he: 'זמן פחמימה'
      ,pl: 'Czas posiłku'
      ,ru: 'Время приема углеводов'
      ,sk: 'Čas jedla'
      ,nl: 'Koolhydraten tijd'
      ,ko: '탄수화물 시간'
      ,tr: 'Karbonhidratların alım zamanı'
      ,zh_cn: '数值'
      ,hu: 'Étkezés ideje'
      }
    ,'Language' : {
      cs: 'Jazyk'
      ,he: 'שפה'
      ,de: 'Sprache'
      ,es: 'Lenguaje'
      ,fr: 'Langue'
      ,dk: 'Sprog'
      ,sv: 'Språk'
      ,nb: 'Språk'
      ,el: 'Γλώσσα'
      ,fi: 'Kieli'
      ,pt: 'Língua'
      ,ro: 'Limba'
      ,bg: 'Език'
      ,hr: 'Jezik'
      ,pl: 'Język'
      ,it: 'Lingua'
      ,ja: '言語'
      ,ru: 'Язык'
      ,sk: 'Jazyk'
      ,nl: 'Taal'
      ,ko: '언어'
      ,tr: 'Dil'
      ,zh_cn: '语言'
      ,zh_tw: '語言'
      ,hu: 'Nyelv'
      }
    ,'Add new' : {
      cs: 'Přidat nový'
      ,he: 'הוסף חדש'
      ,de: 'Neu hinzufügen'
      ,es: 'Añadir nuevo'
      ,fr: 'Ajouter nouveau'
      ,dk: 'Tilføj ny'
      ,sv: 'Lägg till ny'
      ,ro: 'Adaugă nou'
      ,el: 'Προσθήκη'
      ,bg: 'Добави нов'
      ,hr: 'Dodaj novi'
      ,nb: 'Legg til ny'
      ,fi: 'Lisää uusi'
      ,pl: 'Dodaj nowy'
      ,pt: 'Adicionar novo'
      ,ru: 'Добавить новый'
      ,sk: 'Pridať nový'
      ,nl: 'Voeg toe'
      ,ko: '새입력'
      ,it: 'Aggiungi nuovo'
      ,ja: '新たに加える'
      ,tr: 'Yeni ekle'
      ,zh_cn: '新增'
      ,hu: 'Új hozzáadása'
      }
    ,'g' : {   // grams shortcut
      cs: 'g'
      ,he: 'גרמים'
      ,de: 'g'
      ,es: 'gr'
      ,fr: 'g'
      ,dk: 'g'
      ,sv: 'g'
      ,ro: 'g'
      ,bg: 'гр'
      ,hr: 'g'
      ,nb: 'g'
      ,fi: 'g'
      ,pl: 'g'
      ,pt: 'g'
      ,ru: 'г'
      ,sk: 'g'
      ,nl: 'g'
      ,ko: 'g'
      ,it: 'g'
      ,ja: 'g'
      ,tr: 'g'
      ,zh_cn: '克'
      ,zh_tw: '克'
      ,hu: 'g'
    }
    ,'ml' : {   // milliliters shortcut
      cs: 'ml'
      ,he: 'מיליטרים'
      ,de: 'ml'
      ,es: 'ml'
      ,fr: 'ml'
      ,dk: 'ml'
      ,sv: 'ml'
      ,ro: 'ml'
      ,bg: 'мл'
      ,hr: 'ml'
      ,nb: 'ml'
      ,fi: 'ml'
      ,pl: 'ml'
      ,pt: 'mL'
      ,ru: 'мл'
      ,sk: 'ml'
      ,nl: 'ml'
      ,ko: 'ml'
      ,it: 'ml'
      ,ja: 'ml'
      ,tr: 'ml'
      ,zh_cn: '毫升'
      ,zh_tw: '克'
      ,hu: 'ml'
      }
    ,'pcs' : {   // pieces shortcut
      cs: 'ks'
      ,he: 'יחידות'
      ,de: 'Stk.'
      ,es: 'pcs'
      ,fr: 'pcs'
      ,dk: 'stk'
      ,sv: 'st'
      ,ro: 'buc'
      ,bg: 'бр'
      ,hr: 'kom'
      ,nb: 'stk'
      ,fi: 'kpl'
      ,pl: 'cz.'
      ,pt: 'pcs'
      ,ru: 'шт'
      ,sk: 'ks'
      ,nl: 'stk'
      ,ko: '조각 바로 가기(pieces shortcut)'
      ,it: 'pz'
      ,ja: 'pcs'
      ,tr: 'parça'
      ,zh_cn: '件'
      ,zh_tw: '件'
      ,hu: 'db'
      }
    ,'Drag&drop food here' : {
      cs: 'Sem táhni & pusť jídlo'
      ,he: 'גרור ושחרר אוכל כאן'
      ,de: 'Mahlzeit hierher verschieben'
      ,es: 'Arrastre y suelte aquí alimentos'
      ,fr: 'Glisser et déposer repas ici '
      ,dk: 'Træk og slip mad her'
      ,sv: 'Dra&Släpp mat här'
      ,ro: 'Drag&drop aliment aici'
      ,el: 'Σύρετε εδώ φαγητό'
      ,bg: 'Хвани и премести храна тук'
      ,hr: 'Privuci hranu ovdje'
      ,nb: 'Dra og slipp mat her'
      ,fi: 'Pudota ruoka tähän'
      ,pl: 'Tutaj przesuń/upuść jedzenie'
      ,pt: 'Arraste e coloque alimentos aqui'
      ,ru: 'Перетащите еду сюда'
      ,sk: 'Potiahni a pusti jedlo sem'
      ,ko: '음식을 여기에 드래그&드랍 해주세요.'
      ,it: 'Trascina&rilascia cibo qui'
      ,tr: 'Yiyecekleri buraya sürükle bırak'
      ,zh_cn: '拖放食物到这'
      ,nl: 'Maaltijd naar hier verplaatsen'
      ,hu: 'Húzd ide és ereszd el az ételt'
      }
    ,'Care Portal' : {
      cs: 'Portál ošetření'
      ,he: 'פורטל טיפולים'
      ,sv: 'Care Portal'
      ,fr: 'Care Portal'
      ,it: 'Somministrazioni'
      ,de: 'Behandlungs-Portal'
      ,es: 'Portal cuidador'
      ,dk: 'Omsorgsportal'
      ,ro: 'Care Portal'
      ,bg: 'Въвеждане на данни'
      ,hr: 'Care Portal'
      ,nb: 'Omsorgsportal'
      ,fi: 'Hoidot'
      ,pl: 'Care Portal'
      ,pt: 'Care Portal'
      ,ru: 'Портал лечения'
      ,sk: 'Portál starostlivosti'
      ,nl: 'Zorgportaal'
      ,ko: 'Care Portal'
      ,tr: 'Care Portal'
      ,zh_cn: '服务面板'
      ,zh_tw: '服務面板'
      ,hu: 'Care portál'
      }
    ,'Medium/Unknown' : { // GI of food
      cs: 'Střední/Neznámá'
      ,he: 'בינוני/לא ידוע'
      ,sv: 'Medium/Okänt'
      ,de: 'Mittel/Unbekannt'
      ,es: 'Medio/Desconocido'
      ,fr: 'Moyen/Inconnu'
      ,dk: 'Medium/Ukendt'
      ,ro: 'Mediu/Necunoscut'
      ,el: 'Μέσος/Άγνωστος'
      ,bg: 'Среден/неизвестен'
      ,hr: 'Srednji/Nepoznat'
      ,nb: 'Medium/ukjent'
      ,fi: 'Keskiarvo/Ei tiedossa'
      ,pl: 'Średni/nieznany'
      ,pt: 'Médio/Desconhecido'
      ,ru: 'Средний/Неизвестный'
      ,sk: 'Stredný/Neznámi'
      ,nl: 'Medium/Onbekend'
      ,ko: '보통/알려지지 않은'
      ,it: 'Media/Sconosciuto'
      ,tr: 'Orta/Bilinmeyen'
      ,zh_cn: '中等/不知道'
      ,hu: 'Átlagos/Ismeretlen'
      }
    ,'IN THE FUTURE' : {
      cs: 'V BUDOUCNOSTI'
      ,he: 'בעתיד'
      ,fr: 'dans le futur'
      ,sv: 'Framtida'
      ,ro: 'ÎN VIITOR'
      ,de: 'IN DER ZUKUNFT'
      ,es: 'EN EL FUTURO'
      ,dk: 'I fremtiden'
      ,el: 'ΣΤΟ ΜΕΛΛΟΝ'
      ,bg: 'В БЪДЕШЕТО'
      ,hr: 'U BUDUĆNOSTI'
      ,nb: 'I fremtiden'
      ,fi: 'TULEVAISUUDESSA'
      ,pl: 'W PRZYSZŁOŚCI'
      ,pt: 'NO FUTURO'
      ,ru: 'В БУДУЩЕМ'
      ,sk: 'V BUDÚCNOSTI'
      ,nl: 'IN DE TOEKOMST'
      ,ko: '미래'
      ,it: 'NEL FUTURO'
      ,tr: 'GELECEKTE'
      ,zh_cn: '在未来'
      ,hu: 'A JÖVŐBEN'
      }
    ,'Update' : { // Update button
      cs: 'Aktualizovat'
      ,he: 'עדכן'
      ,de: 'Aktualisieren'
      ,es: 'Actualizar'
      ,dk: 'Opdater'
      ,sv: 'Uppdatera'
      ,fr: 'Mise à jour'
      ,nb: 'Oppdater'
      ,pt: 'Atualizar'
      ,el: 'Ενημέρωση'
      ,ro: 'Actualizare'
      ,bg: 'Актуализирай'
      ,hr: 'Osvježi'
      ,it: 'Aggiornamento'
      ,pl: 'Aktualizacja'
      ,fi: 'Tallenna'
      ,ru: 'Обновить'
      ,sk: 'Aktualizovať'
      ,nl: 'Update'
      ,ko: '업데이트'
      ,tr: 'Güncelleştirme'
      ,zh_cn: '更新认证状态'
      ,zh_tw: '更新認證狀態'
      ,hu: 'Frissítés'
      }
    ,'Order' : {
      cs: 'Pořadí'
      ,he: 'סֵדֶר'
      ,de: 'Reihenfolge'
      ,es: 'Ordenar'
      ,fr: 'Mise en ordre'
      ,dk: 'Sorter'
      ,sv: 'Sortering'
      ,nb: 'Sortering'
      ,el: 'Σειρά κατάταξης'
      ,ro: 'Sortare'
      ,bg: 'Ред'
      ,hr: 'Sortiranje'
      ,it: 'Ordina'
      ,pl: 'Kolejność'
      ,pt: 'Ordenar'
      ,fi: 'Järjestys'
      ,ru: 'Сортировать'
      ,sk: 'Usporiadať'
      ,nl: 'Sortering'
      ,ko: '순서'
      ,tr: 'Sıra'
      ,zh_cn: '排序'
      ,hu: 'Sorrend'
      }
    ,'oldest on top' : {
      cs: 'nejstarší nahoře'
      ,he: 'העתיק ביותר למעלה'
      ,de: 'älteste oben'
      ,es: 'Más antiguo arriba'
      ,fr: 'Plus vieux en tête de liste'
      ,dk: 'ældste først'
      ,sv: 'Äldst först'
      ,nb: 'Eldste først'
      ,el: 'τα παλαιότερα πρώτα'
      ,ro: 'mai vechi primele'
      ,bg: 'Старите най-отгоре'
      ,hr: 'najstarije na vrhu'
      ,it: 'più vecchio in alto'
      ,pl: 'Najstarszy na górze'
      ,pt: 'mais antigos no topo'
      ,fi: 'vanhin ylhäällä'
      ,ru: 'Старые наверху'
      ,sk: 'najstaršie hore'
      ,nl: 'Oudste boven'
      ,ko: '오래된 것 부터'
      ,tr: 'en eski üste'
      ,zh_cn: '按时间升序排列'
      ,hu: 'legöregebb a telejére'
    }
    ,'newest on top' : {
      cs: 'nejnovější nahoře'
      ,he: 'החדש ביותר למעלה'
      ,sv: 'Nyast först'
      ,de: 'neueste oben'
      ,es: 'Más nuevo arriba'
      ,fr: 'Nouveaux en tête de liste'
      ,dk: 'nyeste først'
      ,nb: 'Nyeste først'
      ,el: 'τα νεότερα πρώτα'
      ,ro: 'mai noi primele'
      ,bg: 'Новите най-отгоре'
      ,hr: 'najnovije na vrhu'
      ,it: 'più recente in alto'
      ,pl: 'Najnowszy na górze'
      ,pt: 'Mais recentes no topo'
      ,fi: 'uusin ylhäällä'
      ,ru: 'Новые наверху'
      ,sk: 'najnovšie hore'
      ,nl: 'Nieuwste boven'
      ,ko: '새로운 것 부터'
      ,tr: 'en yeni üste'
      ,zh_cn: '按时间降序排列'
      ,hu: 'legújabb a tetejére'
      }
    ,'All sensor events' : {
      cs: 'Všechny události sensoru'
      ,he: 'כל האירועים חיישן'
      ,sv: 'Alla sensorhändelser'
      ,dk: 'Alle sensorhændelser'
      ,nb: 'Alle sensorhendelser'
      ,de: 'Alle Sensor-Ereignisse'
      ,es: 'Todos los eventos del sensor'
      ,fr: 'Tous les événement senseur'
      ,dl: 'Alle sensor begivenheder'
      ,el: 'Όλα τα συμβάντα του αισθητήρα'
      ,ro: 'Evenimente legate de senzor'
      ,bg: 'Всички събития от сензора'
      ,hr: 'Svi događaji senzora'
      ,it: 'Tutti gli eventi del sensore'
      ,fi: 'Kaikki sensorin tapahtumat'
      ,pl: 'Wszystkie zdarzenia sensora'
      ,pt: 'Todos os eventos de sensor'
      ,ru: 'Все события сенсора'
      ,sk: 'Všetky udalosti senzoru'
      ,nl: 'Alle sensor gegevens'
      ,ko: '모든 센서 이벤트'
      ,tr: 'Tüm sensör olayları'
      ,zh_cn: '所有探头事件'
      ,hu: 'Az összes szenzor esemény'
      }
    ,'Remove future items from mongo database' : {
      cs: 'Odebrání položek v budoucnosti z Mongo databáze'
      ,he: 'הסרת פריטים עתידיים ממסד הנתונים מונגו'
      ,nb: 'Fjern fremtidige elementer fra mongo database'
      ,fr: 'Effacer les éléments futurs de la base de données mongo'
      ,el: 'Αφαίρεση μελλοντικών εγγραφών από τη βάση δεδομένων'
      ,de: 'Entferne zukünftige Objekte aus Mongo-Datenbank'
      ,es: 'Remover elementos futuros desde basedatos Mongo'
      ,dk: 'Fjern fremtidige værdier fra mongo databasen'
      ,ro: 'Șterge date din viitor din baza de date mongo'
      ,sv: 'Ta bort framtida händelser från mongodatabasen'
      ,bg: 'Премахни бъдещите точки от Монго базата с данни'
      ,hr: 'Obriši buduće zapise iz baze podataka'
      ,it: 'Rimuovere gli oggetti dal database di mongo in futuro'
      ,fi: 'Poista tapahtumat mongo-tietokannasta'
      ,pl: 'Usuń przyszłe/błędne wpisy z bazy mongo'
      ,pt: 'Remover itens futuro da base de dados mongo'
      ,ru: 'Удалить будущие данные из базы Монго'
      ,sk: 'Odobrať budúce položky z Mongo databázy'
      ,nl: 'Verwijder items die in de toekomst liggen uit database'
      ,ko: 'mongo DB에서 미래 항목들을 지우세요.'
      ,tr: 'Gelecekteki öğeleri mongo veritabanından kaldır'
      ,zh_cn: '从数据库中清除所有未来条目'
      ,zh_tw: '從數據庫中清除所有未來條目'
      ,hu: 'Töröld az összes jövőben lévő adatot az adatbázisból'
      }
    ,'Find and remove treatments in the future' : {
      cs: 'Najít a odstranit záznamy ošetření v budoucnosti'
      ,he:'מצא ולהסיר טיפולים בעתיד'
      ,nb: 'Finn og fjern fremtidige behandlinger'
      ,fr: 'Chercher et effacer les élément dont la date est dans le futur'
      ,el: 'Εύρεση και αφαίρεση μελλοντικών ενεργειών από τη βάση δεδομένων'
      ,de: 'Finde und entferne zukünftige Behandlungen'
      ,es: 'Encontrar y eliminar tratamientos futuros'
      ,dk: 'Find og fjern fremtidige behandlinger'
      ,ro: 'Caută și elimină tratamente din viitor'
      ,sv: 'Hitta och ta bort framtida behandlingar'
      ,bg: 'Намери и премахни събития в бъдещето'
      ,hr: 'Nađi i obriši tretmane u budućnosti'
      ,it: 'Individuare e rimuovere le somministrazioni in futuro'
      ,fi: 'Etsi ja poista tapahtumat joiden aikamerkintä on tulevaisuudessa'
      ,pt: 'Encontrar e remover tratamentos futuros'
      ,pl: 'Znajdź i usuń przyszłe/błędne zabiegi'
      ,ru: 'Найти и удалить будущие назначения'
      ,sk: 'Nájsť a odstrániť záznamy ošetrenia v budúcnosti'
      ,nl: 'Zoek en verwijder behandelingen met datum in de toekomst'
      ,ko: '미래에 treatments를 검색하고 지우세요.'
      ,tr: 'Gelecekte tedavileri bulun ve kaldır'
      ,zh_cn: '查找并清除所有未来的操作'
      ,zh_tw: '查找並清除所有未來的操作'
      ,hu: 'Töröld az összes kezelést a jövőben az adatbázisból'
      }
    ,'This task find and remove treatments in the future.' : {
      cs: 'Tento úkol najde a odstraní ošetření v budoucnosti.'
      ,he: 'משימה זו למצוא ולהסיר טיפולים בעתיד'
      ,nb: 'Finn og fjern fremtidige behandlinger'
      ,de: 'Finde und entferne Behandlungen in der Zukunft.'
      ,es: 'Este comando encuentra y elimina tratamientos futuros.'
      ,fr: 'Cette tâche cherche et efface les éléments dont la date est dans le futur'
      ,dk: 'Denne handling finder og fjerner fremtidige behandlinger.'
      ,el: 'Αυτή η ενέργεια αφαιρεί μελλοντικές ενέργειες από τη βάση δεδομένων'
      ,ro: 'Acest instrument curăță tratamentele din viitor.'
      ,sv: 'Denna uppgift hittar och rensar framtida händelser'
      ,bg: 'Тази опция намира и премахва събития в бъдещето.'
      ,hr: 'Ovo nalazi i briše tretmane u budućnosti'
      ,it: 'Trovare e rimuovere le somministrazioni in futuro'
      ,fi: 'Tämä työkalu poistaa tapahtumat joiden aikamerkintä on tulevaisuudessa.'
      ,pl: 'To narzędzie znajduje i usuwa przyszłe/błędne zabiegi'
      ,pt: 'Este comando encontra e remove tratamentos futuros'
      ,ru: 'Эта опция найдет и удалит данные из будущего'
      ,sk: 'Táto úloha nájde a odstáni záznamy ošetrenia v budúcnosti.'
      ,nl: 'Dit commando zoekt en verwijdert behandelingen met datum in de toekomst'
      ,ko: '이 작업은 미래에 treatments를 검색하고 지우는 것입니다.'
      ,tr: 'Bu görev gelecekte tedavileri bul ve kaldır.'
      ,zh_cn: '此功能查找并清除所有未来的操作。'
      ,zh_tw: '此功能查找並清除所有未來的操作。'
      ,hu: 'Ez a feladat megkeresi és eltávolítja az összes jövőben lévő kezelést'
      }
    ,'Remove treatments in the future' : {
      cs: 'Odstraň ošetření v budoucnosti'
      ,he: 'הסר טיפולים בעתיד'
      ,nb: 'Fjern fremtidige behandlinger'
      ,de: 'Entferne Behandlungen in der Zukunft'
      ,es: 'Elimina tratamientos futuros'
      ,fr: 'Efface les traitements ayant un date dans le futur'
      ,dk: 'Fjern behandlinger i fremtiden'
      ,el: 'Αφαίρεση μελλοντικών ενεργειών'
      ,ro: 'Șterge tratamentele din viitor'
      ,sv: 'Ta bort framtida händelser'
      ,bg: 'Премахни събитията в бъдешето'
      ,hr: 'Obriši tretmane u budućnosti'
      ,it: 'Rimuovere somministrazioni in futuro'
      ,fi: 'Poista tapahtumat'
      ,pl: 'Usuń zabiegi w przyszłości'
      ,pt: 'Remover tratamentos futuros'
      ,ru: 'Удалить назначения из будущего'
      ,sk: 'Odstrániť záznamy ošetrenia v budúcnosti'
      ,nl: 'Verwijder behandelingen met datum in de toekomst'
      ,ko: '미래 treatments 지우기'
      ,tr: 'Gelecekte tedavileri kaldır'
      ,zh_cn: '清除未来操作'
      ,zh_tw: '清除未來操作'
      ,hu: 'Jövőbeli kerelések eltávolítésa'
      }
    ,'Find and remove entries in the future' : {
      cs: 'Najít a odstranit CGM data v budoucnosti'
      ,he: 'מצא והסר רשומות בעתיד'
      ,de: 'Finde und entferne Einträge in der Zukunft'
      ,es: 'Encuentra y elimina entradas futuras'
      ,fr: 'Cherche et efface les événements dans le futur'
      ,dk: 'Find og fjern indgange i fremtiden'
      ,nb: 'Finn og fjern fremtidige hendelser'
      ,el: 'Εύρεση και αφαίρεση μελλοντικών εγγραφών από τη βάση δεδομένων'
      ,bg: 'Намери и премахни данни от сензора в бъдещето'
      ,hr: 'Nađi i obriši zapise u budućnosti'
      ,ro: 'Caută și elimină valorile din viitor'
      ,sv: 'Hitta och ta bort framtida händelser'
      ,it: 'Trovare e rimuovere le voci in futuro'
      ,fi: 'Etsi ja poista tapahtumat'
      ,pl: 'Znajdź i usuń wpisy z przyszłości'
      ,pt: 'Encontrar e remover entradas futuras'
      ,ru: 'Найти и удалить данные сенсора из будущего'
      ,sk: 'Nájsť a odstrániť CGM dáta v budúcnosti'
      ,nl: 'Zoek en verwijder behandelingen met datum in de toekomst'
      ,ko: '미래에 입력을 검색하고 지우세요.'
      ,tr: 'Gelecekteki girdileri bul ve kaldır'
      ,zh_cn: '查找并清除所有的未来的记录'
      ,zh_tw: '查找並清除所有的未來的記錄'
      ,hu: 'Jövőbeli bejegyzések eltávolítása'
      }
    ,'This task find and remove CGM data in the future created by uploader with wrong date/time.' : {
      cs: 'Tento úkol najde a odstraní CGM data v budoucnosti vzniklé špatně nastaveným datem v uploaderu.'
      ,he: 'משימה זו מוצאת נתונים של סנסור סוכר ומסירה אותם בעתיד, שנוצרו על ידי העלאת נתונים עם תאריך / שעה שגויים'
      ,nb: 'Finn og fjern fremtidige cgm data lastet opp med feil dato/tid'
      ,fr: 'Cet outil cherche et efface les valeurs CGM dont la date est dans le futur'
      ,el: 'Αυτή η ενέργεια αφαιρεί δεδομένα αιθητήρα τα οποία εισήχθησαν με λάθος ημερομηνία και ώρα, από τη βάση δεδομένων'
      ,de: 'Finde und entferne CGM-Daten in der Zukunft, die vom Uploader mit falschem Datum/Uhrzeit erstellt wurden.'
      ,es: 'Este comando encuentra y elimina datos del sensor futuros creados por actualizaciones con errores en fecha/hora'
      ,dk: 'Denne handling finder og fjerner CGM data i fremtiden forårsaget af indlæsning med forkert dato/tid.'
      ,bg: 'Тази опция ще намери и премахне данни от сензора в бъдещето, създадени поради грешна дата/време.'
      ,hr: 'Ovo nalazi i briše podatke sa senzora u budućnosti nastalih s uploaderom sa krivim datumom/vremenom'
      ,ro: 'Instrument de căutare și eliminare a datelor din viitor, create de uploader cu ora setată greșit'
      ,sv: 'Denna uppgift hittar och tar bort framtida CGM-data skapad vid felaktig tidsinställning'
      ,it: 'Trovare e rimuovere i dati CGM in futuro creato da uploader/xdrip con data/ora sbagliato.'
      ,fi: 'Tämä työkalu etsii ja poistaa sensorimerkinnät joiden aikamerkintä sijaitsee tulevaisuudessa.'
      ,pl: 'To narzędzie odnajduje i usuwa dane CGM utworzone przez uploader w przyszłości - ze złą datą/czasem.'
      ,pt: 'Este comando procura e remove dados de sensor futuros criados por um uploader com data ou horário errados.'
      ,ru: 'Эта опция найдет и удалит данные сенсора созданные загрузчиком с неверными датой/временем'
      ,sk: 'Táto úloha nájde a odstráni CGM dáta v budúcnosti vzniknuté zle nastaveným časom uploaderu.'
      ,nl: 'Dit commando zoekt en verwijdert behandelingen met datum in de toekomst'
      ,ko: '이 작업은 잘못된 날짜/시간으로 업로드 되어 생성된 미래의 CGM 데이터를 검색하고 지우는 것입니다.'
      ,tr: 'Yükleyicinin oluşturduğu gelecekteki CGM verilerinin yanlış tarih/saat olanlarını bul ve kaldır.'
      ,zh_cn: '此功能查找并清除所有上传时日期时间错误导致生成在未来时间的CGM数据。'
      ,zh_tw: '此功能查找並清除所有上傳時日期時間錯誤導致生成在未來時間的CGM數據。'
      ,hu: 'Ez a feladat megkeresi és eltávolítja az összes CGM adatot amit a feltöltő rossz idővel-dátummal töltött fel'
      }
    ,'Remove entries in the future' : {
      cs: 'Odstraň CGM data v budoucnosti'
      ,he: 'הסר רשומות בעתיד'
      ,nb: 'Fjern fremtidige hendelser'
      ,de: 'Entferne Einträge in der Zukunft'
      ,es: 'Elimina entradas futuras'
      ,fr: 'Efface les événement dans le futur'
      ,dk: 'Fjern indgange i fremtiden'
      ,el: 'Αφαίρεση μελλοντικών ενεργειών'
      ,bg: 'Премахни данните от сензора в бъдещето'
      ,hr: 'Obriši zapise u budućnosti'
      ,ro: 'Elimină înregistrările din viitor'
      ,sv: 'Ta bort framtida händelser'
      ,it: 'Rimuovere le voci in futuro'
      ,fi: 'Poista tapahtumat'
      ,pl: 'Usuń wpisy w przyszłości'
      ,pt: 'Remover entradas futuras'
      ,ru: 'Удалить данные из будущего'
      ,sk: 'Odstrániť CGM dáta v budúcnosti'
      ,nl: 'Verwijder invoer met datum in de toekomst'
      ,ko: '미래의 입력 지우기'
      ,tr: 'Gelecekteki girdileri kaldır'
      ,zh_cn: '清除未来记录'
      ,zh_tw: '清除未來記錄'
      ,hu: 'Jövőbeli bejegyzések törlése'
      }
    ,'Loading database ...' : {
      cs: 'Nahrávám databázi ...'
      ,he: 'טוען מסד נתונים ... '
      ,nb: 'Leser database ...'
      ,de: 'Lade Datenbank ...'
      ,es: 'Cargando base de datos ...'
      ,fr: 'Charge la base de données...'
      ,dk: 'Indlæser database ...'
      ,el: 'Φόρτωση Βάσης Δεδομένων'
      ,bg: 'Зареждане на базата с данни ...'
      ,hr: 'Učitavanje podataka'
      ,ro: 'Încarc baza de date'
      ,sv: 'Laddar databas ...'
      ,it: 'Carica Database ...'
      ,fi: 'Lataan tietokantaa...'
      ,pl: 'Wczytuje baze danych'
      ,pt: 'Carregando banco de dados ...'
      ,ru: 'Загрузка базы данных'
      ,sk: 'Nahrávam databázu...'
      ,nl: 'Database laden ....'
      ,ko: '데이터베이스 로딩'
      ,tr: 'Veritabanı yükleniyor ...'
      ,zh_cn: '载入数据库...'
      ,zh_tw: '載入數據庫...'
      ,hu: 'Adatbázis betöltése...'
      }
    ,'Database contains %1 future records' : {
      cs: 'Databáze obsahuje %1 záznamů v budoucnosti'
      ,he: ' מסד הנתונים מכיל% 1 רשומות עתידיות '
      ,nb: 'Databasen inneholder %1 fremtidige hendelser'
      ,fr: 'La base de données contient %1 valeurs futures'
      ,el: 'Η Βάση Δεδομένων περιέχει 1% μελλοντικές εγγραφές'
      ,ro: 'Baza de date conține %1 înregistrări din viitor'
      ,sv: 'Databas innehåller %1 framtida händelser'
      ,de: 'Datenbank enthält %1 zukünftige Einträge'
      ,es: 'Base de datos contiene %1 registros futuros'
      ,dk: 'Databasen indeholder %1 fremtidige indgange'
      ,bg: 'Базата с дани съдържа %1 бъдещи записи'
      ,hr: 'Baza sadrži %1 zapisa u budućnosti'
      ,it: 'Contiene Database %1 record futuri'
      ,fi: 'Tietokanta sisältää %1 merkintää tulevaisuudessa'
      ,pl: 'Baza danych zawiera %1 przyszłych rekordów'
      ,pt: 'O banco de dados contém %1 registros futuros'
      ,ru: 'База данных содержит %1 записей в будущем'
      ,sk: 'Databáza obsahuje %1 záznamov v budúcnosti'
      ,nl: 'Database bevat %1 toekomstige data'
      ,ko: '데이터베이스는 미래 기록을 %1 포함하고 있습니다.'
      ,tr: 'Veritabanı %1 gelecekteki girdileri içeriyor'
      ,zh_cn: '数据库包含%1条未来记录'
      ,zh_tw: '數據庫包含%1條未來記錄'
      ,hu: 'Az adatbázis %1 jövöbeli adatot tartalmaz'
      }
    ,'Remove %1 selected records?' : {
      cs: 'Odstranit %1 vybraných záznamů'
      ,he: 'האם להסיר% 1 רשומות שנבחרו? '
      ,nb: 'Fjern %1 valgte elementer?'
      ,fr: 'Effacer %1 valeurs choisies?'
      ,el: 'Αφαίρεση των επιλεγμένων εγγραφών?'
      ,de: 'Lösche ausgewählten %1 Eintrag?'
      ,es: 'Eliminar %1 registros seleccionados?'
      ,dk: 'Fjern %1 valgte indgange?'
      ,ro: 'Șterg %1 înregistrări selectate?'
      ,sv: 'Ta bort %1 valda händelser'
      ,bg: 'Премахване на %1 от избраните записи?'
      ,hr: 'Obriši %1 odabrani zapis?'
      ,it: 'Rimuovere %1 record selezionati?'
      ,fi: 'Poista %1 valittua merkintää?'
      ,pl: 'Usunąć %1 wybranych rekordów?'
      ,pt: 'Remover os %1 registros selecionados?'
      ,ru: 'Удалить %1 выбранных записей?'
      ,sk: 'Odstrániť %1 vybraných záznamov'
      ,nl: 'Verwijder %1 geselecteerde data?'
      ,ko: '선택된 기록 %1를 지우시겠습니까?'
      ,tr: 'Seçilen %1 kayıtlar kaldırılsın? '
      ,zh_cn: '清除%1条选择的记录？'
      ,zh_tw: '清除%1條選擇的記錄？'
      ,hu: 'Kitöröljük a %1 kiválasztott adatot?'
      }
    ,'Error loading database' : {
      cs: 'Chyba při nahrávání databáze'
      ,he: 'שגיאה בטעינת מסד הנתונים '
      ,nb: 'Feil udner lasting av database'
      ,fr: 'Erreur chargement de la base de données'
      ,de: 'Fehler beim Laden der Datenbank'
      ,es: 'Error al cargar base de datos'
      ,dk: 'Fejl ved indlæsning af database'
      ,el: 'Σφάλμα στη φόρτωση της βάσης δεδομένων'
      ,ro: 'Eroare la încărcarea bazei de date'
      ,sv: 'Fel vid laddning av databas'
      ,bg: 'Грешка при зареждане на базата с данни'
      ,hr: 'Greška pri učitavanju podataka'
      ,it: 'Errore di caricamento del database'
      ,fi: 'Ongelma tietokannan lataamisessa'
      ,pl: 'Błąd wczytywania bazy danych'
      ,pt: 'Erro ao carregar danco de dados'
      ,ru: 'Ошибка загрузки базы данных'
      ,sk: 'Chyba pri nahrávanií databázy'
      ,nl: 'Fout bij het laden van database'
      ,ko: '데이터베이스 로딩 에러'
      ,tr: 'Veritabanını yüklerken hata oluştu'
      ,zh_cn: '载入数据库错误'
      ,zh_tw: '載入數據庫錯誤'
      ,hu: 'Hiba az adatbázis betöltése közben'
      }
    ,'Record %1 removed ...' : {
      cs: 'Záznam %1 odstraněn ...'
      ,he: 'רשומה% 1 הוסרה ... '
      ,nb: 'Element %1 fjernet'
      ,de: 'Eintrag %1 entfernt'
      ,es: 'Registro %1 eliminado ...'
      ,fr: 'Événement %1 effacé'
      ,dk: 'Indgang %1 fjernet ...'
      ,el: 'Οι εγγραφές αφαιρέθηκαν'
      ,ro: 'Înregistrarea %1 a fost ștearsă...'
      ,sv: 'Händelse %1 borttagen ...'
      ,bg: '%1 записи премахнати'
      ,hr: 'Zapis %1 obrisan...'
      ,it: 'Record %1 rimosso ...'
      ,fi: 'Merkintä %1 poistettu ...'
      ,pl: '%1 rekordów usunięto ...'
      ,pt: 'Registro %1 removido ...'
      ,ru: 'запись %1 удалена'
      ,sk: '%1 záznamov bolo odstránených...'
      ,nl: 'Data %1 verwijderd '
      ,ko: '기록 %1가 삭제되었습니다.'
      ,tr: '%1 kaydı silindi ...'
      ,zh_cn: '%1条记录已清除'
      ,zh_tw: '%1條記錄已清除'
      ,hu: 'A %1 bejegyzés törölve...'
      }
    ,'Error removing record %1' : {
      cs: 'Chyba při odstaňování záznamu %1'
      ,he: 'שגיאה בהסרת הרשומה% 1 '
      ,nb: 'Feil under fjerning av element %1'
      ,de: 'Fehler beim Entfernen des Eintrags %1'
      ,es: 'Error al eliminar registro %1'
      ,fr: 'Echec d\'effacement de l\'événement %1'
      ,dk: 'Fejl ved fjernelse af indgang %1'
      ,el: 'Σφάλμα αφαίρεσης εγγραφών'
      ,ro: 'Eroare la ștergerea înregistrării %1'
      ,sv: 'Fel vid borttagning av %1'
      ,bg: 'Грешка при премахването на %1 от записите'
      ,hr: 'Greška prilikom brisanja zapisa %1'
      ,it: 'Errore rimozione record %1'
      ,fi: 'Virhe poistaessa merkintää numero %1'
      ,pl: 'Błąd przy usuwaniu rekordu %1'
      ,pt: 'Erro ao remover registro %1'
      ,ru: 'Ошибка удаления записи %1'
      ,sk: 'Chyba pri odstraňovaní záznamu %1'
      ,nl: 'Fout bij het verwijderen van %1 data'
      ,ko: '기록 %1을 삭제하는 중에 에러가 발생했습니다.'
      ,tr: '%1 kayıt kaldırılırken hata oluştu'
      ,zh_cn: '%1条记录清除出错'
      ,zh_tw: '%1條記錄清除出錯'
      ,hu: 'Hiba lépett fel a %1 bejegyzés törlése közben'
      }
    ,'Deleting records ...' : {
      cs: 'Odstraňování záznamů ...'
      ,he: 'מוחק רשומות ... '
      ,nb: 'Fjerner elementer...'
      ,fr: 'Effacement dévénements...'
      ,ro: 'Se șterg înregistrările...'
      ,el: 'Αφαίρεση Εγγραφών'
      ,de: 'Entferne Einträge ...'
      ,es: 'Eliminando registros ...'
      ,dk: 'Sletter indgange ...'
      ,sv: 'Tar bort händelser ...'
      ,bg: 'Изтриване на записите...'
      ,hr: 'Brisanje zapisa'
      ,it: 'Elimino dei record ...'
      ,fi: 'Poistan merkintöjä'
      ,pl: 'Usuwanie rekordów ...'
      ,pt: 'Apagando registros ...'
      ,ru: 'Записи удаляются'
      ,sk: 'Odstraňovanie záznamov...'
      ,nl: 'Verwijderen van data .....'
      ,ko: '기록 삭제 중'
      ,tr: 'Kayıtlar siliniyor ...'
      ,zh_cn: '正在删除记录...'
      ,zh_tw: '正在刪除記錄...'
      ,hu: 'Bejegyzések törlése...'
      }
    ,'%1 records deleted' : {
      hr: 'obrisano %1 zapisa'
      ,de: '%1 Einträge gelöscht'
      , pl: '%1 rekordów zostało usuniętych'
      ,ru: '% записей удалено'
      ,hu: '%1 bejegyzés törölve'
      }
    ,'Clean Mongo status database' : {
      cs: 'Vyčištění Mongo databáze statusů'
      ,he: 'נקי מסד הנתונים מצב מונגו '
      ,nb: 'Slett Mongo status database'
      ,ro: 'Curăță tabela despre status din Mongo'
      ,el: 'Καθαρισμός βάσης δεδομένων Mongo'
      ,fr: 'Nettoyage de la base de donées Mongo'
      ,de: 'Bereinige Mongo Status-Datenbank'
      ,es: 'Limpiar estado de la base de datos Mongo'
      ,dk: 'Slet Mongo status database'
      ,sv: 'Rensa Mongo status databas'
      ,bg: 'Изчисти статуса на Монго базата с данни'
      ,hr: 'Obriši bazu statusa'
      ,it: 'Pulisci database di Mongo'
      ,fi: 'Siivoa statustietokanta'
      ,pl: 'Oczyść status bazy danych Mongo'
      ,pt: 'Limpar banco de dados de status no Mongo'
      ,ru: 'Очистить статус базы данных Mongo'
      ,sk: 'Vyčistiť Mongo databázu statusov'
      ,nl: 'Ruim Mongo database status op'
      ,ko: 'Mongo 상태 데이터베이스를 지우세요.'
      ,tr: 'Mongo durum veritabanını temizle'
      ,zh_cn: '清除状态数据库'
      ,hu: 'Mongo állapot (status) adatbázis tisztítása'
      }
    ,'Delete all documents from devicestatus collection' : {
      cs: 'Odstranění všech záznamů z kolekce devicestatus'
      ,he: 'מחק את כל המסמכים מאוסף סטטוס המכשיר '
      ,nb: 'Fjern alle dokumenter fra device status tabell'
      ,de: 'Lösche alle Dokumente der Gerätestatus-Sammlung'
      ,es: 'Borrar todos los documentos desde colección devicesatatus'
      ,fr: 'Effacer tous les documents de la collection devicestatus'
      ,dk: 'Fjerne alle dokumenter fra device status tabellen'
      ,el: 'Διαγραφή όλων των δεδομένων σχετικών με κατάσταση της συσκευής ανάγνωσης του αισθητήρα'
      ,ro: 'Șterge toate documentele din colecția de status dispozitiv'
      ,sv: 'Ta bort alla dokument i devicestatus collektionen'
      ,bg: 'Изтрий всички документи от папката статус-устройство'
      ,hr: 'Obriši sve zapise o statusima'
      ,it: 'Eliminare tutti i documenti dalla collezione "devicestatus"'
      ,fi: 'Poista kaikki tiedot statustietokannasta'
      ,pl: 'Usuń wszystkie dokumenty z kolekcji devicestatus'
      ,pt: 'Apagar todos os documentos da coleção devicestatus'
      ,ru: 'Стереть все документы из коллекции статус устройства'
      ,sk: 'Odstránenie všetkých záznamov z kolekcie "devicestatus"'
      ,ko: 'devicestatus 수집에서 모든 문서들을 지우세요'
      ,tr: 'Devicestatus koleksiyonundan tüm dokümanları sil'
      ,zh_cn: '从设备状态采集删除所有文档'
      ,nl: 'Verwijder alle documenten uit "devicestatus" database'
      ,hu: 'Az összes "devicestatus" dokumentum törlése'
      }
    ,'This task removes all documents from devicestatus collection. Useful when uploader battery status is not properly updated.' : {
      cs: 'Tento úkol odstraní všechny dokumenty z kolekce devicestatus. Je to vhodné udělat, pokud se ukazatel stavu baterie neobnovuje správně.'
      ,nb: 'Denne funksjonen fjerner alle dokumenter fra device status tabellen.  Nyttig når status for opplaster batteri ikke blir opppdatert'
      ,fr: 'Efface tous les documents de la collection devicestatus. Utile lorsque l\'indicateur de chargement de la batterie du Smartphone n\'est pas affichée correctement'
      ,el: 'Αυτή η ενέργεια διαγράφει όλα τα δεδομένα της κατάστασης της συσκευής ανάγνωσης. Χρήσιμη όταν η κατάσταση της συσκευής ανάγνωσης δεν ανανεώνεται σωστά.'
      ,de: 'Diese Aufgabe entfernt alle Dokumente aus der Gerätestatus-Sammlung. Nützlich wenn der Uploader-Batteriestatus sich nicht aktualisiert.'
      ,es: 'Este comando elimina todos los documentos desde la colección devicestatus. Útil cuando el estado de la batería cargadora no se actualiza correctamente'
      ,dk: 'Denne handling fjerner alle dokumenter fra device status tabellen. Brugbart når uploader batteri status ikke opdateres korrekt.'
      ,ro: 'Acest instrument șterge toate documentele din colecția devicestatus. Se folosește când încărcarea bateriei nu se afișează corect.'
      ,sv: 'Denna uppgift tar bort alla dokument från devicestatuskollektionen. Användbart när batteristatus ej uppdateras'
      ,bg: 'Тази опция премахва всички документи от папката статус-устройство. Полезно е, когато статусът на батерията не се обновява.'
      ,hr: 'Ovo briše sve zapise o statusima. Korisno kada se status baterije uploadera ne osvježava ispravno.'
      ,it: 'Questa attività elimina tutti i documenti dalla collezione "devicestatus". Utile quando lo stato della batteria uploader/xdrip non si aggiorna.'
      ,fi: 'Tämä työkalu poistaa kaikki tiedot statustietokannasta, mikä korjaa tilanteen, jossa puhelimen akun lataustilanne ei näy oikein.'
      ,pl: 'To narzędzie usuwa wszystkie dokumenty z kolekcji devicestatus. Przydatne, gdy status baterii uploadera nie jest aktualizowany.'
      ,pt: 'Este comando remove todos os documentos da coleção devicestatus. Útil quando o status da bateria do uploader não é atualizado corretamente.'
      ,ru: 'Эта опция удаляет все документы из коллекции статус устройства. Полезно когда состояние батвреи загрузчика не обновляется'
      ,sk: 'Táto úloha vymaže všetky záznamy z kolekcie "devicestatus". Je to vhodné keď sa stav batérie nezobrazuje správne.'
      ,ko: '이 작업은 모든 문서를 devicestatus 수집에서 지웁니다. 업로더 배터리 상태가 적절하게 업데이트 되지 않을 때 유용합니다.'
      ,tr: 'Bu görev tüm durumları Devicestatus koleksiyonundan kaldırır. Yükleyici pil durumu güncellenmiyorsa kullanışlıdır.'
      ,zh_cn: '此功能从设备状态采集中删除所有文档。适用于上传设备电量信息不能正常同步时使用。'
      ,nl: 'Dit commando verwijdert alle documenten uit "devicestatus" database. Handig wanneer de batterij status niet correct wordt geupload.'
      ,hu: 'Ez a feladat kitörli az összes "devicestatus" dokumentumot. Hasznos ha a feltöltő elem állapota nem jelenik meg helyesen.'
      }
    ,'Delete all documents' : {
      cs: 'Odstranit všechny dokumenty'
      ,he: 'מחק את כל המסמכים '
      ,fr: 'Effacer toutes les données'
      ,nb: 'Fjern alle dokumenter'
      ,ro: 'Șterge toate documentele'
      ,el: 'Διαγραφή όλων των δεδομένων'
      ,de: 'Lösche alle Dokumente'
      ,es: 'Borra todos los documentos'
      ,dk: 'Slet alle dokumenter'
      ,sv: 'Ta bort alla dokument'
      ,bg: 'Изтрий всички документи'
      ,hr: 'Obriši sve zapise'
      ,it: 'Eliminare tutti i documenti'
      ,fi: 'Poista kaikki tiedot'
      ,pl: 'Usuń wszystkie dokumenty'
      ,pt: 'Apagar todos os documentos'
      ,ru: 'Удалить все документы'
      ,sk: 'Zmazať všetky záznamy'
      ,nl: 'Verwijder alle documenten'
      ,ko: '모든 문서들을 지우세요'
      ,tr: 'Tüm Belgeleri sil'
      ,zh_cn: '删除所有文档'
      ,hu: 'Az összes dokumentum törlése'
      }
    ,'Delete all documents from devicestatus collection?' : {
      cs: 'Odstranit všechny dokumenty z kolekce devicestatus?'
      ,he: 'מחק את כל המסמכים מרשימת סטטוס ההתקנים '
      ,nb: 'Fjern alle dokumenter fra device status tabellen?'
      ,fr: 'Effacer toutes les données de la collection devicestatus ?'
      ,el: 'Διαγραφή όλων των δεδομένων της κατάστασης της συσκευής ανάγνωσης?'
      ,de: 'Löschen aller Dokumente der Gerätestatus-Sammlung?'
      ,es: 'Borrar todos los documentos desde la colección devicestatus?'
      ,dk: 'Fjern alle dokumenter fra device status tabellen'
      ,ro: 'Șterg toate documentele din colecția devicestatus?'
      ,sv: 'Ta bort alla dokument från devicestatuscollektionen'
      ,bg: 'Изтриване на всички документи от папката статус-устройство?'
      ,hr: 'Obriši sve zapise statusa?'
      ,it: 'Eliminare tutti i documenti dalla collezione devicestatus?'
      ,fi: 'Poista tiedot statustietokannasta?'
      ,pl: 'Czy na pewno usunąć wszystkie dokumenty z kolekcji devicestatus?'
      ,pt: 'Apagar todos os documentos da coleção devicestatus?'
      ,ru: 'Удалить все документы коллекции статус устройства?'
      ,sk: 'Zmazať všetky záznamy z kolekcie "devicestatus"?'
      ,ko: 'devicestatus 수집의 모든 문서들을 지우세요.'
      ,tr: 'Tüm Devicestatus koleksiyon belgeleri silinsin mi?'
      ,zh_cn: '从设备状态采集删除所有文档？'
      ,nl: 'Wil je alle data van "devicestatus" database verwijderen?'
      ,hu: 'Az összes dokumentum törlése a "devicestatus" gyűjteményből?'
      }
    ,'Database contains %1 records' : {
      cs: 'Databáze obsahuje %1 záznamů'
      ,he: 'מסד נתונים מכיל %1 רשומות '
      ,nb: 'Databasen inneholder %1 elementer'
      ,de: 'Datenbank enthält %1 Einträge'
      ,es: 'La Base de datos contiene %1 registros'
      ,fr: 'La base de donées contient %1 événements'
      ,dk: 'Databasen indeholder %1 indgange'
      ,el: 'Η βάση δεδομένων περιέχει 1% εγγραφές'
      ,ro: 'Baza de date conține %1 înregistrări'
      ,sv: 'Databasen innehåller %1 händelser'
      ,bg: 'Базата с данни съдържа %1 записи'
      ,hr: 'Baza sadrži %1 zapisa'
      ,it: 'Contiene Database %1 record'
      ,fi: 'Tietokanta sisältää %1 merkintää'
      ,pl: 'Baza danych zawiera %1 rekordów'
      ,pt: 'O banco de dados contém %1 registros'
      ,ru: 'База данных содержит %1 записей'
      ,sk: 'Databáza obsahuje %1 záznamov'
      ,nl: 'Database bevat %1 gegevens'
      ,ko: '데이터베이스는 %1 기록을 포함합니다.'
      ,tr: 'Veritabanı %1 kayıt içeriyor'
      ,zh_cn: '数据库包含%1条记录'
      ,hu: 'Az adatbázis %1 bejegyzést tartalmaz'
      }
    ,'All records removed ...' : {
      cs: 'Všechny záznamy odstraněny ...'
      ,he: 'כל הרשומות נמחקו '
      ,nb: 'Alle elementer fjernet ...'
      ,de: 'Alle Einträge entfernt...'
      ,es: 'Todos los registros eliminados ...'
      ,fr: 'Toutes les valeurs ont été effacées'
      ,dk: 'Alle hændelser fjernet ...'
      ,el: 'Έγινε διαγραφή όλων των δεδομένων'
      ,ro: 'Toate înregistrările au fost șterse.'
      ,sv: 'Alla händelser raderade ...'
      ,bg: 'Всички записи премахнати ...'
      ,hr: 'Svi zapisi obrisani'
      ,it: 'Tutti i record rimossi ...'
      ,fi: 'Kaikki merkinnät poistettu ...'
      ,pl: 'Wszystkie rekordy usunięto'
      ,pt: 'Todos os registros foram removidos ...'
      ,ru: 'Все записи удалены'
      ,sk: 'Všetky záznamy boli zmazané...'
      ,nl: 'Alle gegevens verwijderd'
      ,ko: '모든 기록들이 지워졌습니다.'
      ,tr: 'Tüm kayıtlar kaldırıldı ...'
      ,zh_cn: '所有记录已经被清除'
      ,hu: 'Az összes bejegyzés törölve...'
      }
    ,'Delete all documents from devicestatus collection older than 30 days' : {
      hr: 'Obriši sve statuse starije od 30 dana'
      ,ru: 'Удалить все записи коллекции devicestatus старше 30 дней'
      ,de: 'Alle Dokumente der Gerätestatus-Sammlung löschen, die älter als 30 Tage sind'
      , pl: 'Usuń wszystkie dokumenty z kolekcji devicestatus starsze niż 30 dni'
      , hu: 'Az összes "devicestatus" dokumentum törlése ami 30 napnál öregebb'
      }
    ,'Number of Days to Keep:' : {
      hr: 'Broj dana za sačuvati:'
      ,ru: 'Оставить дней'
      ,de: 'Daten löschen, die älter sind (in Tagen) als:'
      , pl: 'Ilość dni do zachowania:'
      , hu: 'Mentés ennyi napra:'
      }
    ,'This task removes all documents from devicestatus collection that are older than 30 days. Useful when uploader battery status is not properly updated.' : {
      hr: 'Ovo uklanja sve statuse starije od 30 dana. Korisno kada se status baterije uploadera ne osvježava ispravno.'
      , pl: 'To narzędzie usuwa wszystkie dokumenty z kolekcji devicestatus starsze niż 30 dni. Przydatne, gdy status baterii uploadera nie jest aktualizowany.'
      ,ru: 'Это удалит все документы коллекции devicestatus которым более 30 дней. Полезно, когда статус батареи не обновляется или обновляется неверно.'
      ,de: 'Diese Aufgabe entfernt alle Dokumente aus der Gerätestatus-Sammlung, die älter sind als 30 Tage. Nützlich wenn der Uploader-Batteriestatus sich nicht aktualisiert.'
      ,hu: 'Ez a feladat törli az összes "devicestatus" dokumentumot a gyűjteményből ami 30 napnál öregebb. Hasznos ha a feltöltő elem állapota nem jelenik meg rendesen. '
      }
    ,'Delete old documents from devicestatus collection?' : {
      hr: 'Obriši stare statuse'
      ,de: 'Alte Dokumente aus der Gerätestatus-Sammlung entfernen?'
      , pl: 'Czy na pewno chcesz usunąć stare dokumenty z kolekcji devicestatus?'
      ,ru: 'Удалить старыые документы коллекции devicestatus'
      ,hu: 'Kitorli az öreg dokumentumokat a "devicestatus" gyűjteményből?'
      }
    ,'Clean Mongo entries (glucose entries) database' : {
      hr: 'Obriši GUK zapise iz baze'
      ,de: 'Mongo-Einträge (Glukose-Einträge) Datenbank bereinigen'
      , pl: 'Wyczyść bazę wpisów (wpisy glukozy) Mongo'
      ,ru: 'Очистить записи данных в базе Mongo'
      ,hu: 'Mongo bejegyzés adatbázis tisztítása'
      }
    ,'Delete all documents from entries collection older than 180 days' : {
      hr: 'Obriši sve zapise starije od 180 dana'
      ,de: 'Alle Dokumente aus der Einträge-Sammlung löschen, die älter sind als 180 Tage'
      , pl: 'Usuń wszystkie dokumenty z kolekcji wpisów starsze niż 180 dni'
      ,ru: 'Удалить все документы коллекции entries старше 180 дней '
      ,hu: 'Az összes bejegyzés gyűjtemény törlése ami 180 napnál öregebb'
      }
    ,'This task removes all documents from entries collection that are older than 180 days. Useful when uploader battery status is not properly updated.' : {
      hr: 'Ovo briše sve zapise starije od 180 dana. Korisno kada se status baterije uploadera ne osvježava.'
      ,de: 'Diese Aufgabe entfernt alle Dokumente aus der Einträge-Sammlung, die älter sind als 180 Tage. Nützlich wenn der Uploader-Batteriestatus sich nicht aktualisiert.'
      , pl: 'To narzędzie usuwa wszystkie dokumenty z kolekcji wpisów starsze niż 180 dni. Przydatne, gdy status baterii uploadera nie jest aktualizowany.'
      ,ru: 'Это удалит все документы коллекции entries старше 180 дней. Полезно, когда статус батареи загрузчика должным образом не обновляется'
      ,hu: 'A feladat kitörli az összes bejegyzésekből álló dokumentumot ami 180 napnál öregebb. Hasznos ha a feltöltő elem állapota nem jelenik meg helyesen.'
      }
    ,'Delete old documents' : {
      hr: 'Obriši stare zapise'
      ,de: 'Alte Dokumente löschen'
      , pl: 'Usuń stare dokumenty'
      ,ru: 'Удалить старые документы'
      ,hu: 'Öreg dokumentumok törlése'
      }
    ,'Delete old documents from entries collection?' : {
      hr: 'Obriši stare zapise?'
      ,de: 'Alte Dokumente aus der Einträge-Sammlung entfernen?'
      , pl: 'Czy na pewno chcesz usunąć stare dokumenty z kolekcji wpisów?'
      ,ru: 'Удалить старые документы коллекции entries?'
      ,hu: 'Az öreg dokumentumok törlése a bejegyzések gyűjteményéből?'
      }
    ,'%1 is not a valid number' : {
      hr: '%1 nije valjan broj'
      ,de: '%1 ist keine gültige Zahl'
      ,he: 'זה לא מיספר %1'
      , pl: '%1 nie jest poprawną liczbą'
      ,ru: '% не является допустимым значением'
      ,hu: 'A %1 nem érvényes szám'
      }
    ,'%1 is not a valid number - must be more than 2' : {
      hr: '%1 nije valjan broj - mora biti veći od 2'
      ,de: '%1 ist keine gültige Zahl - Eingabe muss größer als 2 sein'
      , pl: '%1 nie jest poprawną liczbą - musi być większe od 2'
      ,ru: '% не является допустимым значением - должно быть больше 2'
      ,hu: 'A %1 nem érvényes szám - nagyobb számnak kell lennie mint a 2'
      }
    ,'Clean Mongo treatments database' : {
      hr: 'Obriši tretmane iz baze'
      ,de: 'Mongo-Behandlungsdatenbank bereinigen'
      , pl: 'Wyczyść bazę leczenia Mongo'
      ,ru: 'Очистить базу лечения Mongo'
      ,hu: 'Mondo kezelési datbázisának törlése'
      }
    ,'Delete all documents from treatments collection older than 180 days' : {
      hr: 'Obriši tretmane starije od 180 dana iz baze'
      ,de: 'Alle Dokumente aus der Behandlungs-Sammlung löschen, die älter sind als 180 Tage'
      , pl: 'Usuń wszystkie dokumenty z kolekcji leczenia starsze niż 180 dni'
      ,ru: 'Удалить все документы коллекции treatments старше 180 дней'
      ,hu: 'Töröld az összes 180 napnál öregebb kezelési dokumentumot'
      }
    ,'This task removes all documents from treatments collection that are older than 180 days. Useful when uploader battery status is not properly updated.' : {
      hr: 'Ovo briše sve tretmane starije od 180 dana iz baze. Korisno kada se status baterije uploadera ne osvježava.'
      ,de: 'Diese Aufgabe entfernt alle Dokumente aus der Behandlungs-Sammlung, die älter sind als 180 Tage. Nützlich wenn der Uploader-Batteriestatus sich nicht aktualisiert.'
      , pl: 'To narzędzie usuwa wszystkie dokumenty z kolekcji leczenia starsze niż 180 dni. Przydatne, gdy status baterii uploadera nie jest aktualizowany.'
      ,ru: 'Это удалит все документы коллекции treatments старше 180 дней. Полезно, когда статус батареи загрузчика не обновляется должным образом'
      ,hu: 'A feladat eltávolítja az összes kezelési dokumentumot ami öregebb 180 napnál. Hasznos ha a feltöltő elem állapota nem jelenik meg helyesen.'
      }
    ,'Delete old documents from treatments collection?' : {
      hr: 'Obriši stare tretmane?'
      ,ru: 'Удалить старые документы из коллекции treatments?'
      ,de: 'Alte Dokumente aus der Behandlungs-Sammlung entfernen?'
      , pl: 'Czy na pewno chcesz usunąć stare dokumenty z kolekcji leczenia?'
      , hu: 'Törölni az összes doumentumot a kezelési gyűjteményből?'
      }
    ,'Admin Tools' : {
      cs: 'Nástroje pro správu'
      ,he: 'כלי אדמיניסטרציה '
      ,nb: 'Administrasjonsoppgaver'
      ,ro: 'Instrumente de administrare'
      ,de: 'Administrator-Werkzeuge'
      ,es: 'Herramientas Administrativas'
      ,fr: 'Outils d\'administration'
      ,dl: 'Administrator opgaver'
      ,el: 'Εργαλεία Διαχειριστή'
      ,sv: 'Adminverktyg'
      ,dk: 'Administrations værktøj'
      ,bg: 'Настройки на администратора'
      ,hr: 'Administracija'
      ,it: 'NS - Dati Mongo'
      ,fi: 'Ylläpitotyökalut'
      ,pl: 'Narzędzia administratora'
      ,pt: 'Ferramentas de administração'
      ,ru: 'Инструменты администратора'
      ,sk: 'Nástroje pre správu'
      ,nl: 'Admin tools'
      ,ko: '관리 도구'
      ,tr: 'Yönetici araçları'
      ,zh_cn: '管理工具'
      ,zh_tw: '管理工具'
      ,hu: 'Adminisztrációs eszközök'
      }
    ,'Nightscout reporting' : {
      cs: 'Nightscout - Výkazy'
      ,he: 'דוחות נייססקאוט'
      ,nb: 'Nightscout - rapporter'
      ,ro: 'Rapoarte Nightscout'
      ,fr: 'Rapports Nightscout'
      ,el: 'Αναφορές'
      ,de: 'Nightscout-Berichte'
      ,es: 'Informes - Nightscout'
      ,dk: 'Nightscout - rapporter'
      ,sv: 'Nightscout - Statistik'
      ,bg: 'Найтскаут статистика'
      ,hr: 'Nightscout izvješća'
      ,it: 'Nightscout - Statistiche'
      ,fi: 'Nightscout raportointi'
      ,pl: 'Nightscout - raporty'
      ,pt: 'Relatórios do Nightscout'
      ,ru: 'Статистика  Nightscout'
      ,sk: 'Nightscout výkazy'
      ,nl: 'Nightscout rapportages'
      ,ko: 'Nightscout 보고서'
      ,tr: 'NightScout raporları'
      ,zh_cn: 'Nightscout报表生成器'
      ,hu: 'Nightscout jelentések'
      }
    ,'Cancel' : {
      cs: 'Zrušit'
      ,he: 'בטל '
      ,de: 'Abbruch'
      ,es: 'Cancelar'
      ,fr: 'Interrompre'
      ,dk: 'Annuller'
      ,nb: 'Avbryt'
      ,el: 'Ακύρωση'
      ,ro: 'Renunță'
      ,sv: 'Avbryt'
      ,bg: 'Откажи'
      ,hr: 'Odustani'
      ,it: 'Cancellare'
      ,ja: '中止'
      ,fi: 'Peruuta'
      ,pl: 'Anuluj'
      ,pt: 'Cancelar'
      ,ru: 'Отмена'
      ,sk: 'Zrušiť'
      ,nl: 'Annuleer'
      ,ko: '취소'
      ,tr: 'İptal'
      ,zh_cn: '取消'
      ,hu: 'Vissza'
      }
    ,'Edit treatment' : {
      cs: 'Upravit ošetření'
      ,he: 'ערוך טיפול '
      ,nb: 'Editer behandling'
      ,ro: 'Modifică înregistrarea'
      ,de: 'Bearbeite Behandlung'
      ,es: 'Editar tratamiento'
      ,fr: 'Modifier un traitement'
      ,dk: 'Rediger behandling'
      ,el: 'Επεξεργασία Εγγραφής'
      ,sv: 'Redigera behandling'
      ,bg: 'Редакция на събитие'
      ,hr: 'Uredi tretman'
      ,it: 'Modifica Somministrazione'
      ,fi: 'Muuta merkintää'
      ,pl: 'Edytuj zabieg'
      ,pt: 'Editar tratamento'
      ,ru: 'Редактировать событие'
      ,sk: 'Upraviť ošetrenie'
      ,nl: 'Bewerkt behandeling'
      ,ko: 'Treatments 편집'
      ,tr: 'Tedaviyi düzenle'
      ,zh_cn: '编辑操作'
      ,hu: 'Kezelés szerkesztése'
      }
    ,'Duration' : {
      cs: 'Doba trvání'
      ,he: 'משך '
      ,ro: 'Durata'
      ,de: 'Dauer'
      ,es: 'Duración'
      ,fr: 'Durée'
      ,dk: 'Varighed'
      ,el: 'Διάρκεια'
      ,sv: 'Varaktighet'
      ,bg: 'Времетраене'
      ,hr: 'Trajanje'
      ,it: 'Tempo'
      ,nb: 'Varighet'
      ,fi: 'Kesto'
      ,pl: 'Czas trwania'
      ,pt: 'Duração'
      ,ru: 'Продолжительность'
      ,sk: 'Trvanie'
      ,nl: 'Duur'
      ,ko: '기간'
      ,tr: 'süre'
      ,zh_cn: '持续'
      ,hu: 'Behelyezés óta eltelt idő'
      }
    ,'Duration in minutes' : {
      cs: 'Doba trvání v minutách'
      ,he: 'משך בדקות '
      ,de: 'Dauer in Minuten'
      ,es: 'Duración en minutos'
      ,fr: 'Durée en minutes'
      ,dk: 'Varighed i minutter'
      ,ro: 'Durata în minute'
      ,el: 'Διάρκεια σε λεπτά'
      ,sv: 'Varaktighet i minuter'
      ,bg: 'Времетраене в мин.'
      ,hr: 'Trajanje u minutama'
      ,it: 'Tempo in minuti'
      ,nb: 'Varighet i minutter'
      ,fi: 'Kesto minuuteissa'
      ,pl: 'Czas trwania w minutach'
      ,pt: 'Duração em minutos'
      ,ru: 'Продолжительность в мин'
      ,sk: 'Trvanie v minútach'
      ,nl: 'Duur in minuten'
      ,ko: '분당 지속 기간'
      ,tr: 'Süre dakika cinsinden'
      ,zh_cn: '持续时间（分钟）'
      ,hu: 'Idő percekben'
      }
    ,'Temp Basal' : {
      cs: 'Dočasný bazál'
      ,he: 'רמה בזלית זמנית'
      ,de: 'Temporäre Basalrate'
      ,es: 'Tasa basal temporal'
      ,fr: 'Débit basal temporaire'
      ,dk: 'Midlertidig basal'
      ,ro: 'Bazală temporară'
      ,sv: 'Temporär basal'
      ,bg: 'Временен базал'
      ,hr: 'Privremeni bazal'
      ,it: 'Basale Temp'
      ,nb: 'Midlertidig basal'
      ,fi: 'Tilapäinen basaali'
      ,pl: 'Tymczasowa dawka podstawowa'
      ,pt: 'Basal Temporária'
      ,ru: 'Временный базал'
      ,sk: 'Dočasný bazál'
      ,nl: 'Tijdelijke basaal'
      ,ko: '임시 basal'
      ,tr: 'Geçici Bazal Oranı'
      ,zh_cn: '临时基础率'
      ,hu: 'Átmeneti bazál'
      }
    ,'Temp Basal Start' : {
      cs: 'Dočasný bazál začátek'
      ,he: 'התחלת רמה בזלית זמנית '
      ,ro: 'Start bazală temporară'
      ,sv: 'Temporär basalstart'
      ,de: 'Start Temporäre Basalrate'
      ,es: 'Inicio Tasa Basal temporal'
      ,fr: 'Début du débit basal temporaire'
      ,dk: 'Midlertidig basal start'
      ,bg: 'Начало на временен базал'
      ,hr: 'Početak privremenog bazala'
      ,it: 'Inizio Basale Temp'
      ,nb: 'Midlertidig basal start'
      ,fi: 'Tilapäinen basaali alku'
      ,pl: 'Początek tymczasowej dawki podstawowej'
      ,pt: 'Início da Basal Temporária'
      ,ru: 'Начало временного базала'
      ,sk: 'Začiatok dočasného bazálu'
      ,nl: 'Start tijdelijke basaal'
      ,ko: '임시 basal 시작'
      ,tr: 'Geçici Bazal Oranını Başlanğıcı'
      ,zh_cn: '临时基础率开始'
      ,hu: 'Átmeneti bazál Kezdete'
      }
    ,'Temp Basal End' : {
      cs: 'Dočasný bazál konec'
      ,he: 'סיום רמה בזלית זמנית'
      ,ro: 'Sfârșit bazală temporară'
      ,fr: 'Fin du débit basal temporaire'
      ,sv: 'Temporär basalavslut'
      ,bg: 'Край на временен базал'
      ,hr: 'Kraj privremenog bazala'
      ,it: 'Fine Basale Temp'
      ,de: 'Ende Temporäre Basalrate'
      ,es: 'Fin Tasa Basal temporal'
      ,dk: 'Midlertidig basal slut'
      ,nb: 'Midlertidig basal stopp'
      ,fi: 'Tilapäinen basaali loppu'
      ,pl: 'Koniec tymczasowej dawki podstawowej'
      ,pt: 'Fim de Basal Temporária'
      ,ru: 'Окончание временного базала'
      ,sk: 'Koniec dočasného bazálu'
      ,nl: 'Einde tijdelijke basaal'
      ,ko: '임시 basal 종료'
      ,tr: 'Geçici bazal oranını Bitişi'
      ,zh_cn: '临时基础率结束'
      ,hu: 'Átmeneti bazál Vége'
      }
    ,'Percent' : { // value in % for temp basal
      cs: 'Procenta'
      ,he: 'אחוז '
      ,ro: 'Procent'
      ,de: 'Prozent'
      ,es: 'Porcentaje'
      ,fr: 'Pourcent'
      ,dk: 'Procent'
      ,el: 'Επι τοις εκατό'
      ,sv: 'Procent'
      ,bg: 'Процент'
      ,hr: 'Postotak'
      ,it: 'Percentuale'
      ,nb: 'Prosent'
      ,fi: 'Prosentti'
      ,pl: 'Procent'
      ,pt: 'Porcento'
      ,ru: 'Процент'
      ,sk: 'Percent'
      ,nl: 'Procent'
      ,ko: '퍼센트'
      ,tr: 'Yüzde'
      ,zh_cn: '百分比'
      ,hu: 'Százalék'
      }
    ,'Basal change in %' : {
      cs: 'Změna bazálu v %'
      ,he: 'שינוי קצב בזלי באחוזים '
      ,ro: 'Bazală schimbată în %'
      ,fr: 'Changement du débit basal en %'
      ,sv: 'Basaländring i %'
      ,bg: 'Промяна на базала с %'
      ,hr: 'Promjena bazala u %'
      ,de: 'Basalratenänderung in %'
      ,es: 'Basal modificado en %'
      ,dk: 'Basal ændring i %'
      ,it: 'Variazione basale in %'
      ,nb: 'Basal endring i %'
      ,fi: 'Basaalimuutos prosenteissa'
      ,pl: 'Zmiana dawki podstawowej w %'
      ,pt: 'Mudança de basal em %'
      ,ru: 'Изменение базала в %'
      ,sk: 'Zmena bazálu v %'
      ,nl: 'Basaal aanpassing in %'
      ,ko: '% 이내의 basal 변경'
      ,tr: 'Bazal değişimi % cinsinden'
      ,zh_cn: '基础率变化百分比'
      ,hu: 'Bazál változása %'
      }
    ,'Basal value' : { // absolute value for temp basal
      cs: 'Hodnota bazálu'
      ,he: 'ערך בזלי'
      ,ro: 'Valoare bazală'
      ,fr: 'Valeur du débit basal'
      ,sv: 'Basalvärde'
      ,de: 'Basalrate'
      ,es: 'Valor basal'
      ,dk: 'Basalværdi'
      ,bg: 'Временен базал'
      ,hr: 'Vrijednost bazala'
      ,it: 'Valore Basale'
      ,nb: 'Basalverdi'
      ,fi: 'Basaalin määrä'
      ,pl: 'Dawka podstawowa'
      ,pt: 'Valor da basal'
      ,ru: 'величина временного базала'
      ,sk: 'Hodnota bazálu'
      ,nl: 'Basaal snelheid'
      ,ko: 'Basal'
      ,tr: 'Bazal değeri'
      ,zh_cn: '基础率值'
      ,hu: 'Bazál értéke'
      }
    ,'Absolute basal value' : {
      cs: 'Hodnota bazálu'
      ,he: 'ערך בזלי מוחלט '
      ,bg: 'Базална стойност'
      ,hr: 'Apsolutna vrijednost bazala'
      ,it: 'Valore Basale Assoluto'
      ,fr: 'Débit basal absolu'
      ,de: 'Absoluter Basalratenwert'
      ,es: 'Valor basal absoluto'
      ,dk: 'Absolut basalværdi'
      ,nb: 'Absolutt basalverdi'
      ,ro: 'Valoare absolută bazală'
      ,sv: 'Absolut basalvärde'
      ,fi: 'Absoluuttinen basaalimäärä'
      ,pl: 'Bezwględna wartość dawki podstawowej'
      ,pt: 'Valor absoluto da basal'
      ,ru: 'Абсолютная величина базала'
      ,sk: 'Absolútna hodnota bazálu'
      ,nl: 'Absolute basaal waarde'
      ,ko: '절대적인 basal'
      ,tr: 'Mutlak bazal değeri'
      ,zh_cn: '绝对基础率值'
      ,hu: 'Abszolút bazál érték'
      }
    ,'Announcement' : {
      cs: 'Oznámení'
      ,bg: 'Известяване'
      ,hr: 'Objava'
      ,de: 'Ankündigung'
      ,es: 'Aviso'
      ,fr: 'Annonce'
      ,dk: 'Meddelelse'
      ,ro: 'Anunț'
      ,el: 'Ανακοίνωση'
      ,sv: 'Avisering'
      ,he: 'הודעה'
      ,it: 'Annuncio'
      ,nb: 'Kunngjøring'
      ,fi: 'Tiedote'
      ,pl: 'Powiadomienie'
      ,pt: 'Aviso'
      ,ru: 'Оповещение'
      ,sk: 'Oznámenia'
      ,nl: 'Mededeling'
      ,ko: '공지'
      ,tr: 'Duyuru'
      ,zh_cn: '通告'
      ,hu: 'Közlemény'
      }
    ,'Loading temp basal data' : {
      cs: 'Nahrávám dočasné bazály'
      ,he: 'טוען ערך בזלי זמני '
      ,it: 'Caricamento basale temp'
      ,fr: 'Chargement des données de débit basal'
      ,ro: 'Se încarcă date bazală temporară'
      ,sv: 'Laddar temporär basaldata'
      ,de: 'Lade temporäre Basaldaten'
      ,es: 'Cargando datos tasa basal temporal'
      ,dk: 'Indlæser midlertidig basal data'
      ,nb: 'Laster verdier for midlertidig basal'
      ,fi: 'Lataan tilapäisten basaalien tietoja'
      ,bg: 'Зареждане на данни за временния базал'
      ,hr: 'Učitavanje podataka o privremenom bazalu'
      ,pl: 'Wczytuje dane tymczasowej dawki podstawowej'
      ,pt: 'Carregando os dados de basal temporária'
      ,ru: 'Загрузка данных временного базала'
      ,sk: 'Nahrávam dáta dočasného bazálu'
      ,nl: 'Laden tijdelijke basaal gegevens'
      ,ko: '임시 basal 로딩'
      ,tr: 'Geçici bazal verileri yükleniyor'
      ,zh_cn: '载入临时基础率数据'
      ,hu: 'Az étmeneti bazál adatainak betöltése'
      }
    ,'Save current record before changing to new?' : {
      cs: 'Uložit současný záznam před změnou na nový?'
      ,he: 'שמור רשומה נוכחית לפני שעוברים לרשומה חדשה? '
      ,ro: 'Salvez înregistrarea curentă înainte de a trece mai departe?'
      ,sv: 'Spara aktuell data innan skifte till ny?'
      ,fr: 'Sauvegarder l\'événement actuel avant d\'avancer au suivant ?'
      ,nb: 'Lagre før bytte til ny?'
      ,el: 'Αποθήκευση τρεχουσας εγγραφής πριν δημιουργήσουμε νέα?'
      ,de: 'Aktuelle Einträge speichern?'
      ,es: 'Grabar datos actuales antes de cambiar por nuevos?'
      ,dk: 'Gem aktuelle hændelse før der skiftes til ny?'
      ,fi: 'Tallenna nykyinen merkintä ennen vaihtoa uuteen?'
      ,bg: 'Запази текущият запис преди да промениш новия '
      ,hr: 'Spremi trenutni zapis prije promjene na idući?'
      ,pl: 'Zapisać bieżący rekord przed zamianą na nowy?'
      ,pt: 'Salvar o registro atual antes de mudar para um novo?'
      ,ru: 'Сохранить текущие данные перед переходом к новым?'
      ,sk: 'Uložiť súčastny záznam pred zmenou na nový?'
      ,nl: 'Opslaan voor verder te gaan?'
      ,ko: '새 데이터로 변경하기 전에 현재의 기록을 저장하시겠습니까?'
      ,it: 'Salvare i dati correnti prima di cambiarli?'
      ,tr: 'Yenisine geçmeden önce mevcut girişleri kaydet?'
      ,zh_cn: '在修改至新值前保存当前记录？'
      ,hu: 'Elmentsem az aktuális adatokat mielőtt újra váltunk?'
      }
    ,'Profile Switch' : {
      cs: 'Přepnutí profilu'
      ,he: 'החלף פרופיל '
      ,ro: 'Schimbă profilul'
      ,sv: 'Ny profil'
      ,fr: 'Changement de profil'
      ,el: 'Εναλλαγή προφίλ'
      ,de: 'Profil wechseln'
      ,es: 'Cambiar Perfil'
      ,dk: 'Skift profil'
      ,nb: 'Bytt profil'
      ,fi: 'Vaihda profiilia'
      ,bg: 'Смяна на профил'
      ,hr: 'Promjena profila'
      ,pl: 'Przełączenie profilu'
      ,pt: 'Troca de perfil'
      ,ru: 'Изменить профиль'
      ,sk: 'Zmena profilu'
      ,nl: 'Profiel wissel'
      ,ko: '프로파일 변경'
      ,it: 'Cambio profilo'
      ,tr: 'Profil Değiştir'
      ,zh_cn: '切换配置文件'
      ,hu: 'Profil csere'
      }
    ,'Profile' : {
      cs: 'Profil'
      ,he: 'פרופיל '
      ,de: 'Profil'
      ,es: 'Perfil'
      ,dk: 'Profil'
      ,el: 'Προφίλ'
      ,fr: 'Profil'
      ,it: 'Profilo'
      ,ro: 'Profil'
      ,sv: 'Profil'
      ,nb: 'Profil'
      ,fi: 'Profiili'
      ,bg: 'Профил'
      ,hr: 'Profil'
      ,pl: 'Profil'
      ,pt: 'Perfil'
      ,ru: 'Профиль'
      ,sk: 'Profil'
      ,nl: 'Profiel'
      ,ko: '프로파일'
      ,tr: 'Profil'
      ,zh_cn: '配置文件'
      ,hu: 'Profil'
      }
    ,'General profile settings' : {
      cs: 'Obecná nastavení profilu'
      ,he: 'הגדרות פרופיל כלליות'
      ,de: 'Allgemeine Profileinstellungen'
      ,es: 'Configuración perfil genérico'
      ,fr: 'Réglages principaus du profil'
      ,dk: 'Generelle profil indstillinger'
      ,el: 'Γενικές Ρυθμίσεις Προφίλ'
      ,ro: 'Setări generale profil'
      ,sv: 'Allmän profilinställning'
      ,nb: 'Profilinstillinger'
      ,fi: 'Yleiset profiiliasetukset'
      ,bg: 'Основни настройки на профила'
      ,hr: 'Opće postavke profila'
      ,pl: 'Ogólne ustawienia profilu'
      ,pt: 'Configurações de perfil gerais'
      ,ru: 'Общие настройки профиля'
      ,sk: 'Obecné nastavenia profilu'
      ,nl: 'Profiel instellingen'
      ,ko: '일반 프로파일 설정'
      ,it: 'Impostazioni generali profilo'
      ,tr: 'Genel profil ayarları'
      ,zh_cn: '通用配置文件设置'
      ,hu: 'Általános profil beállítások'
      }
    ,'Title' : {
      cs: 'Název'
      ,he: 'כותרת '
      ,ro: 'Titlu'
      ,sv: 'Titel'
      ,fr: 'Titre'
      ,el: 'Τίτλος'
      ,de: 'Überschrift'
      ,es: 'Titulo'
      ,dk: 'Overskrift'
      ,nb: 'Tittel'
      ,fi: 'Otsikko'
      ,bg: 'Заглавие'
      ,hr: 'Naslov'
      ,pl: 'Nazwa'
      ,pt: 'Título'
      ,ru: 'Название'
      ,sk: 'Názov'
      ,nl: 'Titel'
      ,ko: '제목'
      ,it: 'Titolo'
      ,tr: 'Başlık'
      ,zh_cn: '标题'
      ,hu: 'Elnevezés'
      }
    ,'Database records' : {
      cs: 'Záznamy v databázi'
      ,he: 'רשומות '
      ,ro: 'Înregistrări'
      ,fr: 'Entrées de la base de données'
      ,sv: 'Databashändelser'
      ,el: 'Εγραφές Βάσης Δεδομένων'
      ,nb: 'Databaseverdier'
      ,de: 'Datenbankeinträge'
      ,es: 'Registros grabados en base datos'
      ,dk: 'Database hændelser'
      ,fi: 'Tietokantamerkintöjä'
      ,bg: 'Записи в базата с данни'
      ,hr: 'Zapisi u bazi'
      ,pl: 'Rekordy bazy danych'
      ,pt: 'Registros do banco de dados'
      ,ru: 'Записи в базе данных'
      ,sk: 'Záznamy databázi'
      ,ko: '데이터베이스 기록'
      ,it: 'Record del database'
      ,tr: 'Veritabanı kayıtları'
      ,zh_cn: '数据库记录'
      ,nl: 'Database gegevens'
      ,hu: 'Adatbázis bejegyzések'
      }
    ,'Add new record' : {
      cs: 'Přidat nový záznam'
      ,he: 'הוסף רשומה חדשה '
      ,ro: 'Adaugă înregistrare nouă'
      ,fr: 'Ajouter une nouvelle entrée'
      ,sv: 'Lägg till ny händelse'
      ,el: 'Προσθήκη νέας εγγραφής'
      ,nb: 'Legg til ny rad'
      ,de: 'Neuen Eintrag hinzufügen'
      ,es: 'Añadir nuevo registro'
      ,dk: 'Tilføj ny hændelse'
      ,fi: 'Lisää uusi merkintä'
      ,bg: 'Добави нов запис'
      ,hr: 'Dodaj zapis'
      ,pl: 'Dodaj nowy rekord'
      ,pt: 'Adicionar novo registro'
      ,ru: 'Добавить новую запись'
      ,sk: 'Pridať nový záznam'
      ,nl: 'Toevoegen'
      ,ko: '새 기록 추가'
      ,it: 'Aggiungi un nuovo record'
      ,ja: '新しい記録を加える'
      ,tr: 'Yeni kayıt ekle'
      ,zh_cn: '新增记录'
      ,hu: 'Új bejegyzés hozzáadása'
      }
    ,'Remove this record' : {
      cs: 'Vymazat tento záznam'
      ,he: 'מחק רשומה זו '
      ,ro: 'Șterge această înregistrare'
      ,fr: 'Supprimer cette entrée'
      ,sv: 'Ta bort denna händelse'
      ,el: 'Αφαίρεση εγγραφής'
      ,nb: 'Fjern denne raden'
      ,de: 'Diesen Eintrag löschen'
      ,es: 'Eliminar este registro'
      ,fk: 'Fjern denne indgang'
      ,fi: 'Poista tämä merkintä'
      ,bg: 'Премахни този запис'
      ,hr: 'Obriši ovaj zapis'
      ,pl: 'Usuń ten rekord'
      ,pt: 'Remover este registro'
      ,ru: 'Удалить эту запись'
      ,sk: 'Zmazať záznam'
      ,nl: 'Verwijder'
      ,ko: '이 기록 삭'
      ,it: 'Rimuovi questo record'
      ,ja: 'この記録を除く'
      ,tr: 'Bu kaydı kaldır'
      ,zh_cn: '删除记录'
      ,hu: 'Bejegyzés törlése'
      }
    ,'Clone this record to new' : {
      cs: 'Zkopíruj tento záznam do nového'
      ,he: 'שכפל רשומה זו לרשומה חדשה '
      ,ro: 'Duplică această înregistrare'
      ,fr: 'Dupliquer cette entrée'
      ,el: 'Αντιγραφή σε νέα'
      ,sv: 'Kopiera denna händelse till ny'
      ,es: 'Duplicar este registro como nuevo'
      ,nb: 'Kopier til ny rad'
      ,de: 'Diesen Eintrag duplizieren'
      ,dk: 'Kopier denne hændelse til ny'
      ,fi: 'Kopioi tämä merkintä uudeksi'
      ,bg: 'Копирай този запис като нов'
      ,hr: 'Kloniraj zapis'
      ,pl: 'Powiel ten rekord na nowy'
      ,pt: 'Duplicar este registro como novo'
      ,ru: 'Клонировать эту запись в новый'
      ,sk: 'Skopírovať záznam do nového'
      ,nl: 'Kopieer deze invoer naar nieuwe'
      ,ko: '이 기록을 새기록으로 복제하기'
      ,it: 'Clona questo record in uno nuovo'
      ,tr: 'Bu kaydı yeniden kopyala'
      ,zh_cn: '复制记录'
      ,hu: 'A kiválasztott bejegyzés másolása'
      }
    ,'Record valid from' : {
      cs: 'Záznam platný od'
      ,he: 'רשומה תקפה מ '
      ,ro: 'Înregistare validă de la'
      ,fr: 'Entrée valide à partir de'
      ,sv: 'Händelse giltig från'
      ,el: 'Ισχύει από'
      ,de: 'Eintrag gültig ab'
      ,es: 'Registro válido desde'
      ,dk: 'Hændelse gyldig fra'
      ,nb: 'Rad gyldig fra'
      ,fi: 'Merkintä voimassa alkaen'
      ,bg: 'Записът е валиден от '
      ,hr: 'Zapis vrijedi od'
      ,pl: 'Rekord ważny od'
      ,pt: 'Registro válido desde'
      ,ru: 'Запись действительна от'
      ,sk: 'Záznam platný od'
      ,nl: 'Geldig van'
      ,ko: '기록을 시작한 날짜'
      ,it: 'Record valido da'
      ,tr: 'Kayıt itibaren geçerli'
      ,zh_cn: '有效记录，从'
      ,hu: 'Bejegyzés érvényessége'
      }
    ,'Stored profiles' : {
      cs: 'Uložené profily'
      ,he: 'פרופילים מאוכסנים '
      ,fr: 'Profils sauvegardés'
      ,ro: 'Profile salvate'
      ,sv: 'Lagrad profil'
      ,el: 'Αποθηκευμένα προφίλ'
      ,de: 'Gesicherte Profile'
      ,es: 'Perfiles guardados'
      ,dk: 'Gemte profiler'
      ,nb: 'Lagrede profiler'
      ,fi: 'Tallennetut profiilit'
      ,bg: 'Запаметени профили'
      ,hr: 'Pohranjeni profili'
      ,pl: 'Zachowane profile'
      ,pt: 'Perfis guardados'
      ,ru: 'Сохраненные профили'
      ,sk: 'Uložené profily'
      ,nl: 'Opgeslagen profielen'
      ,ko: '저장된 프로파일'
      ,it: 'Profili salvati'
      ,tr: 'Kaydedilmiş profiller'  //Kayıtlı profiller,Saklanan profiller
      ,zh_cn: '配置文件已存储'
      ,hu: 'Tárolt profilok'
      }
    ,'Timezone' : {
      cs: 'Časová zóna'
      ,he: 'אזור זמן '
      ,de: 'Zeitzone'
      ,es: 'Zona horaria'
      ,fr: 'Zone horaire'
      ,dk: 'Tidszone'
      ,ro: 'Fus orar'
      ,el: 'Ζώνη Ώρας'
      ,sv: 'Tidszon'
      ,nb: 'Tidssone'
      ,fi: 'Aikavyöhyke'
      ,bg: 'Часова зона'
      ,hr: 'Vremenska zona'
      ,pl: 'Strefa czasowa'
      ,pt: 'Fuso horário'
      ,ru: 'Часовой пояс'
      ,sk: 'Časové pásmo'
      ,nl: 'Tijdzone'
      ,ko: '타임존'
      ,it: 'Fuso orario'
      ,tr: 'Saat dilimi'
      ,zh_cn: '时区'
      ,hu: 'Időzóna'
      }
    ,'Duration of Insulin Activity (DIA)' : {
      cs: 'Doba působnosti inzulínu (DIA)'
      ,he: 'משך פעילות האינסולין '
      ,ro: 'Durata de acțiune a insulinei'
      ,fr: 'Durée d\'action de l\'insuline'
      ,el: 'Διάρκεια Δράσης Ινσουλίνης(DIA)'
      ,sv: 'Verkningstid för insulin (DIA)'
      ,nb: 'Insulin varighet'
      ,de: 'Dauer der Insulinaktivität (DIA)'
      ,es: 'Duración Insulina Activa (DIA)'
      ,dk: 'Varighed af insulin aktivitet (DIA)'
      ,fi: 'Insuliinin vaikutusaika (DIA)'
      ,bg: 'Продължителност на инсулиновата активност DIA'
      ,hr: 'Trajanje aktivnosti inzulina (DIA)'
      ,pl: 'Czas trwania aktywnej insuliny (DIA)'
      ,pt: 'Duração da Atividade da Insulina (DIA)'
      ,ru: 'Время действия инсулина (DIA)'
      ,sk: 'Doba pôsobenia inzulínu (DIA)'
      ,nl: 'Werkingsduur insuline (DIA)'
      ,ko: '활성 인슐린 지속 시간(DIA)'
      ,it: 'Durata Attività Insulinica (DIA)'
      ,tr: 'İnsülin Etki Süresi (DIA)'
      ,zh_cn: '胰岛素作用时间（DIA）'
      ,hu: 'Inzulin aktivitás időtartalma'
      }
    ,'Represents the typical duration over which insulin takes effect.  Varies per patient and per insulin type. Typically 3-4 hours for most pumped insulin and most patients. Sometimes also called insulin lifetime.' : {
      cs: 'Představuje typickou dobu, po kterou inzulín působí. Bývá různá podle pacienta a inzulínu. Typicky 3-4 hodiny pro pacienty s pumpou.'
      ,he: 'מייצג את משך הטיפוסי שבו האינסולין נכנס לתוקף. משתנה לכל חולה וסוג האינסולין. בדרך כלל 3-4 שעות עבור רוב אינסולין שאיבה רוב המטופלים. לפעמים נקרא גם אורך חיי אינסולין. '
      ,ro: 'Reprezintă durata tipică pentru care insulina are efect. Este diferită la fiecare pacient și pentru fiecare tip de insulină'
      ,el: 'Αντιπροσωπευει την τυπική διάρκεια δράσης της χορηγηθείσας ινσουλίνης.'
      ,es: 'Representa la duración típica durante la cual la insulina tiene efecto. Varía por paciente y por tipo de insulina. Típicamente 3-4 horas para la mayoría de la insulina bombeada y la mayoría de los pacientes. A veces también se llama insulina de por vida '
      ,fr: 'Représente la durée d\'action typique de l\'insuline. Varie individuellement et selon le type d\'insuline. Typiquement 3-4 heures pour les insulines utilisées dans les pompes.'
      ,de: 'Entspricht der typischen Dauer in der das Insulin wirkt. Variiert je nach Patient und Insulintyp. Häufig 3-4 Stunden für die meisten Pumpeninsuline und die meisten Patienten. Manchmal auch Insulin-Wirkungsdauer genannt.'
      ,dk: 'Representerer den typiske tid hvor insulinen virker. Varierer per patient og per insulin type. Typisk 3-4 timer for de fleste pumpe insulin og for de fleste patienter. Nogle gange kaldes dette også insulin-livs-tid.'
      ,sv: 'Beskriver under hur lång tid insulinet verkar. Varierar mellan patienter. Typisk varaktighet 3-4 timmar.'
      ,nb: 'Representerer typisk insulinvarighet. Varierer per pasient og per insulin type. Vanligvis 3-4 timer for de fleste typer insulin og de fleste pasientene. Noen ganger også kalt insulinlevetid.'
      ,fi: 'Kertoo insuliinin tyypillisen vaikutusajan. Vaihtelee potilaan ja insuliinin tyypin mukaan. Tyypillisesti 3-4 tuntia pumpuissa käytettävällä insuliinilla.'
      ,bg: 'Представя типичната продължителност на действието на инсулина. Варира между отделните пациенти и различни инсулини. Обикновено е 3-4 часа за пациентите с помпа. Нарича се още живот на инсулина '
      ,hr: 'Predstavlja uobičajeno trajanje djelovanje inzulina. Varira po vrstama inzulina i osobama. Tipično je to 3-4 sata za inzuline u pumpama za većinu osoba. Ponekad se naziva i vijek inzulina' 
      ,pl: 'Odzwierciedla czas działania insuliny. Może różnić się w zależności od chorego i rodzaju insuliny. Zwykle są to 3-4 godziny dla insuliny podawanej pompą u większości chorych. Inna nazwa to czas trwania insuliny.'
      ,pt: 'Representa a tempo típico durante o qual a insulina tem efeito. Varia de acordo com o paciente e tipo de insulina. Tipicamente 3-4 horas para a maioria das insulinas usadas em bombas e dos pacientes. Algumas vezes chamada de tempo de vida da insulina'
      ,ru: 'Отражает типичную продолжительность действия инсулина. Зависит от пациента и от типа инсулина. Обычно 3-4 часа для большинства помповых инсулинов и большинства пациентов'
      ,sk: 'Predstavuje typickú dobu počas ktorej inzulín pôsobí. Býva rôzna od pacienta a od typu inzulínu. Zvyčajne sa pohybuje medzi 3-4 hodinami u pacienta s pumpou.'
      ,nl: 'Geeft de werkingsduur van de insuline in het lichaam aan. Dit verschilt van patient tot patient er per soort insuline, algemeen gemiddelde is 3 tot 4 uur. '
      ,ko: '인슐린이 작용하는 지속시간을 나타냅니다. 사람마다 그리고 인슐린 종류에 따라 다르고 일반적으로 3~4시간간 동안 지속되며 인슐린 작용 시간(Insulin lifetime)이라고 불리기도 합니다.'
      ,it: 'Rappresenta la durata tipica nel quale l\'insulina ha effetto. Varia in base al paziente ed al tipo d\'insulina. Tipicamente 3-4 ore per la maggior parte dei microinfusori e dei pazienti. Chiamata anche durata d\'azione insulinica.'
      ,tr: 'İnsülinin etki ettiği tipik süreye karşılık gelir. Hastaya ve insülin tipine göre değişir. Çoğu pompa insülini ve çoğu hasta için genellikle 3-4 saattir. Bazen de insülin etki süresi de denir.'
      ,zh_cn: '体现典型的胰岛素活性持续时间。 通过百分比和胰岛素类型体现。对于大多数胰岛素和患者来说是3至4个小时。也称为胰岛素生命周期。'
      ,hu: 'Kimutatja hogy általában meddig hat az inzulin. Változó különböző pácienseknél és inzulinoknál. Általában 3-4 óra között mozog. Néha inzulin élettartalomnak is nevezik.'
      }
    ,'Insulin to carb ratio (I:C)' : {
      cs: 'Inzulíno-sacharidový poměr (I:C).'
      ,he: 'יחס אינסולין פחמימות '
      ,ro: 'Rată insulină la carbohidrați (ICR)'
      ,sv: 'Insulin-Kolhydratskvot'
      ,fr: 'Rapport Insuline-Glucides (I:C)'
      ,el: 'Αναλογία Ινσουλίνης/Υδατάνθρακα (I:C)'
      ,nb: 'IKH forhold'
      ,de: 'Insulin/Kohlenhydrate-Verhältnis (I:KH)'
      ,es: 'Relación Insulina/Carbohidratos (I:C)'
      ,dk: 'Insulin til kulhydrat forhold også kaldet Kulhydrat-insulinratio (I:C)'
      ,fi: 'Insuliiniannoksen hiilihydraattisuhde (I:HH)'
      ,bg: 'Съотношение инсулин/въглехидратите ICR I:C И:ВХ'
      ,hr: 'Omjer UGH:Inzulin (I:C)'
      ,pl: 'Współczynnik insulina/węglowodany (I:C)'
      ,pt: 'Relação Insulina-Carboidrato (I:C)'
      ,ru: 'Соотношение инсулин/углеводы I:C'
      ,sk: 'Inzulín-sacharidový pomer (I:C)'
      ,nl: 'Insuline - Koolhydraat ratio (I:C)'
      ,ko: '인슐린에 대한 탄수화물 비율(I:C)'
      ,it: 'Rapporto Insulina-Carboidrati (I:C)'
      ,tr: 'İnsülin/Karbonhidrat oranı (I:C)'
      ,zh_cn: '碳水化合物系数（ICR）'
      ,hu: 'Inzulin-szénhidrát arány'
      }
    ,'Hours:' : {
      cs: 'Hodin:'
      ,he: 'שעות:'
      ,ro: 'Ore:'
      ,el: 'ώρες:'
      ,fr: 'Heures:'
      ,de: 'Stunden:'
      ,es: 'Horas:'
      ,dk: 'Timer:'
      ,sv: 'Timmar:'
      ,nb: 'Timer:'
      ,fi: 'Tunnit:'
      ,bg: 'часове:'
      ,hr: 'Sati:'
      ,pl: 'Godziny:'
      ,pt: 'Horas:'
      ,ru: 'час:'
      ,sk: 'Hodiny:'
      ,nl: 'Uren:'
      ,ko: '시간:'
      ,it: 'Ore:'
      ,tr: 'Saat:'
      ,zh_cn: '小时:'
      ,hu: 'Óra:'
      }
    ,'hours' : {
      cs: 'hodin'
      ,he: 'שעות '
      ,ro: 'ore'
      ,el: 'ώρες'
      ,fr: 'heures'
      ,de: 'Stunden'
      ,es: 'horas'
      ,dk: 'timer'
      ,sv: 'timmar'
      ,nb: 'timer'
      ,fi: 'tuntia'
      ,bg: 'часове'
      ,hr: 'sati'
      ,pl: 'godziny'
      ,pt: 'horas'
      ,ru: 'час'
      ,sk: 'hodiny'
      ,nl: 'Uren'
      ,ko: '시간'
      ,it: 'ore'
      ,tr: 'saat'
      ,zh_cn: '小时'
      ,hu: 'óra'
      }
    ,'g/hour' : {
      cs: 'g/hod'
      ,he: 'גרם לשעה '
      ,ro: 'g/oră'
      ,fr: 'g/heure'
      ,sv: 'g/timme'
      ,nb: 'g/t'
      ,de: 'g/Std'
      ,es: 'gr/hora'
      ,dk: 'g/time'
      ,fi: 'g/tunti'
      ,bg: 'гр/час'
      ,hr: 'g/h'
      ,pl: 'g/godzine'
      ,pt: 'g/hora'
      ,ru: 'г/час'
      ,sk: 'g/hod'
      ,nl: 'g/uur'
      ,ko: 'g/시간'
      ,it: 'g/ora'
      ,tr: 'g/saat'
      ,zh_cn: 'g/小时'
      ,hu: 'g/óra'
      }
    ,'g carbs per U insulin. The ratio of how many grams of carbohydrates are offset by each U of insulin.' : {
      cs: 'gramy na jednotku inzulínu. Poměr, jaké množství sacharidů pokryje jednotku inzulínu.'
      ,he: 'היחס בין כמה גרם של פחמימות מקוזז על ידי כל יחידת אינסולין '
      ,ro: 'g carbohidrați pentru o unitate de insulină. Câte grame de carbohidrați sunt acoperite de 1U insulină.'
      ,fr: 'g de glucides par Uninté d\'insuline. Le rapport représentant la quantité de glucides compensée par une unité d\'insuline.'
      ,el: 'Γραμμάρια (g) υδατάνθρακα ανά μονάδα (U) ινσουλίνης. Πόσα γραμμάρια υδατάνθρακα αντιστοιχούν σε μία μονάδα ινσουλίνης'
      ,sv: 'gram kolhydrater per enhet insulin. Antal gram kolhydrater varje enhet insulin sänker'
      ,dk: 'gram kulhydrater per enhed insulin. (Kulhydrat-insulinratio) Den mængde kulhydrat, som dækkes af en enhed insulin.'
      ,es: 'gr. de carbohidratos por unidad de insulina. La proporción de cuántos gramos de carbohidratos se consumen por unidad de insulina.'
      ,de: 'g Kohlenhydrate pro Einheit Insulin. Das Verhältnis wie viele Gramm Kohlenhydrate je Einheit Insulin verbraucht werden.'
      ,nb: 'g karbohydrater per enhet insulin.  Beskriver hvor mange gram karbohydrater som hånderes av en enhet insulin.'
      ,fi: 'g hiilihydraattia / yksikkö insuliinia. Suhde, joka kertoo montako grammaa hiilihydraattia vastaa yhtä yksikköä insuliinia.'
      ,bg: 'грам въглехидрат към 1 единица инсулин. Съотношението колко грама въглехидрат се покриват от 1 единица инсулин.'
      ,hr: 'grama UGH po jedinici inzulina. Omjer koliko grama UGH pokriva jedna jedinica inzulina.'
      ,pl: 'g węglowodanów na 1j insuliny. Współczynnik ilości gram weglowodanów równoważonych przez 1j insuliny.'
      ,pt: 'g de carboidrato por unidade de insulina. A razão de quantos gramas de carboidrato são processados por cada unidade de insulina.'
      ,ru: 'г углеводов на ед инсулина. Соотношение показывает количество гр углеводов компенсируемого единицей инсулина.'
      ,sk: 'gramy sacharidov na jednotku inzulínu. Pomer udáva aké množstvo sacharidov pokryje jednotka inzulínu.'
      ,ko: '인슐린 단위 당 탄수화물 g. 인슐린 단위에 대한 탄수화물의 양의 비율을 나타냅니다.'
      ,it: 'g carbo per U di insulina. Il rapporto tra quanti grammi di carboidrati sono compensati da ogni U di insulina.'
      ,tr: 'İnsülin ünite başına g karbonhidrat. İnsülin ünite başına kaç gram karbonhidrat tüketildiği oranıdır.'
      ,zh_cn: '克碳水每单位胰岛素。每单位胰岛素可以抵消的碳水化合物克值比例。'
      ,nl: 'G KH per Eh insuline. De verhouding tussen hoeveel grammen koohlhydraten er verwerkt kunnen worden per eenheid insuline.'
      ,hu: 'g szénhidrát per egység inzulin. Az arány, hogy hány gramm szénhidrát fed le bizonyos egységnyi inzulint'
      }
    ,'Insulin Sensitivity Factor (ISF)' : {
      cs: 'Citlivost inzulínu (ISF)'
      ,he: 'גורם רגישות לאינסולין '
      ,ro: 'Factor de sensilibtate la insulină (ISF)'
      ,de: 'Insulinsensibilitätsfaktor (ISF)'
      ,es: 'Factor Sensibilidad a la Insulina (ISF)'
      ,fr: 'Facteur de sensibilité à l\'insuline (ISF)'
      ,el: 'Ευαισθησία στην Ινοσυλίνη (ISF)'
      ,sv: 'Insulinkänslighetsfaktor (ISF)'
      ,dk: 'Insulinfølsomhedsfaktor (ISF)'
      ,nb: 'Insulinfølsomhetsfaktor'
      ,fi: 'Insuliiniherkkyys (ISF)'
      ,bg: 'Фактор на инсулинова чувствителност ISF '
      ,hr: 'Faktor inzulinske osjetljivosti (ISF)'
      ,pl: 'Współczynnik wrażliwosci na insulinę (ISF)'
      ,pt: 'Fator de Sensibilidade da Insulina (ISF)'
      ,ru: 'Фактор чувствительности к инсулину ISF'
      ,sk: 'Citlivosť na inzulín (ISF)'
      ,nl: 'Insuline gevoeligheid (ISF)'
      ,ko: '인슐린 민감도(ISF)'
      ,it: 'Fattore di Sensibilità Insulinica (ISF)'
      ,tr: '(ISF) İnsülin Duyarlılık Faktörü'
      ,zh_cn: '胰岛素敏感系数（ISF）'
      ,hu: 'Inzulin Érzékenységi Faktor (ISF)'
      }
    ,'mg/dL or mmol/L per U insulin. The ratio of how much BG changes with each U of corrective insulin.' : {
      cs: 'mg/dL nebo mmol/L na jednotku inzulínu. Poměr, jak se změní glykémie po podaní jednotky inzulínu'
      ,he: 'כמה סוכר בדם משתנה עם כל יחידת אינסולין '
      ,ro: 'mg/dL sau mmol/L pentru 1U insulină. Cât de mult influențează glicemia o corecție de o unitate de insulină.'
      ,fr: 'mg/dL ou mmol/l par unité d\'insuline. Le rapport représentant la modification de la glycémie résultant de l\'administration d\'une unité d\'insuline.'
      ,el: 'mg/dl ή mmol/L ανά μονάδα U ινσουλίνης. Το πόσες μονάδες ρίχνει τη γλυκόζη αίματος μία μονάδα U ινσουλίνης'
      ,de: 'mg/dL oder mmol/L pro Einheit Insulin. Verhältnis von BG-Veränderung je Einheit Korrekturinsulin.'
      ,es: 'mg/dl o mmol/L por unidad Insulina. La relación de la caída de glucosa y cada unidad de insulina de corrección administrada.'
      ,sv: 'mg/dl eller mmol per enhet insulin. Hur varje enhet insulin sänker blodsockret'
      ,dk: 'mg/dl eller mmol per enhed insulin. Beskriver hvor mange mmol eller mg/dl blodsukkeret sænkes per enhed insulin (Insulinfølsomheden)'
      ,nb: 'mg/dl eller mmol/l per enhet insulin.  Beskriver hvor mye blodsukkeret senkes per enhet insulin.'
      ,fi: 'mg/dL tai mmol/L / 1 yksikkö insuliinia. Suhde, joka kertoo montako yksikköä verensokeria yksi yksikkö insuliinia laskee.'
      ,bg: 'мг/дл или ммол към 1 единица инсулин. Съотношението как се променя кръвната захар със всяка единица инсулинова корекция'
      ,hr: 'md/dL ili mmol/L po jedinici inzulina. Omjer koliko se GUK mijenja sa jednom jedinicom korekcije inzulina.'
      ,pl: 'mg/dl lub mmol/L na 1j insuliny. Współczynnik obniżenia poziomu glukozy przez 1j insuliny'
      ,pt: 'mg/dL ou mmol/L por unidade de insulina. A razão entre queda glicêmica e cada unidade de insulina de correção administrada.'
      ,ru: 'мг/дл или ммол/л на единицу инсулина. Насколько меняется СК с каждой единицей коррегирующего инсулина'
      ,sk: 'mg/dL alebo mmol/L na jednotku inzulínu. Pomer udáva o koľko sa zmení hodnota glykémie po podaní jednotky inzulínu.'
      ,nl: 'mg/dL of mmol/L per E insuline. Ratio daling BG waarde per eenheid correctie'
      ,ko: '인슐린 단위당 mg/dL 또는 mmol/L. 인슐린 단위당 얼마나 많은 혈당 변화가 있는지의 비율을 나타냅니다.'
      ,it: 'mg/dL o mmol/L per U insulina. Il rapporto di quanto la glicemia varia per ogni U di correzione insulinica.'
      ,tr: 'Ünite insülin başına mg/dL veya mmol/L. Her bir Ünite düzeltme insülin ile KŞ\'nin ne kadar değiştiğini gösteren orandır.'
      ,zh_cn: 'mg/dL或mmol/L每单位胰岛素。每单位输入胰岛素导致血糖变化的比例'
      ,hu: 'mg/dL vagy mmol/L per inzulin egység. Az aránya annak hogy mennyire változik a cukorszint bizonyos egység inzulintól'
      }
    ,'Carbs activity / absorption rate' : {
      cs: 'Rychlost absorbce sacharidů'
      ,he: 'פעילות פחמימות / קצב קליטה '
      ,ro: 'Rata de absorbție'
      ,el: 'Ρυθμός απορρόφησης υδατανθράκων'
      ,fr: 'Activité glucidique / vitesse d\'absorption'
      ,de: 'Kohlenhydrataktivität / Aufnahme Kohlenhydrate'
      ,es: 'Actividad de carbohidratos / tasa de absorción'
      ,sv: 'Kolhydratstid'
      ,dk: 'Kulhydrattid / optagelsestid'
      ,nb: 'Karbohydrattid'
      ,fi: 'Hiilihydraattiaktiivisuus / imeytymisnopeus'
      ,bg: 'Активност на въглехидратите  / време за абсорбиране'
      ,hr: 'UGH aktivnost / omjer apsorpcije'
      ,pl: 'Aktywność węglowodanów / współczynnik absorpcji'
      ,pt: 'Atividade dos carboidratos / taxa de absorção'
      ,ru: 'Активность углеводов / скорость усвоения'
      ,sk: 'Rýchlosť vstrebávania sacharidov'
      ,nl: 'Koolhydraat opname snelheid'
      ,ko: '활성 탄수화물/흡수율'
      ,it: 'Attività carboidrati / Velocità di assorbimento'
      ,tr: 'Karbonhidrat aktivitesi / emilim oranı'
      ,zh_cn: '碳水化合物活性/吸收率'
      ,hu: 'Szénhidrátok felszívódásának gyorsasága'
      }
    ,'grams per unit time. Represents both the change in COB per unit of time, as well as the amount of carbs that should take effect over that time.  Carb absorption / activity curves are less well understood than insulin activity, but can be approximated using an initial delay followed by a constant rate of absorption (g/hr).' : {
      cs: 'gramy za jednotku času. Reprezentuje jak změnu COB za jednoku času, tak množství sacharidů, které se za tu dobu projevily. Křivka absorbce sacharidů je mnohem méně pochopitelná než IOB, ale může být aproximována počáteční pauzou následovanou konstantní hodnotou absorbce (g/hod).'
      ,he: 'גרם ליחידת זמן. מייצג הן את השינוי בפחמימות ליחידת זמן והן את כמות הפחמימות אשר אמורות להיכנס לתוקף במהלך אותה תקופה. ספיגת הפחמימות / פעילות הם פחות מובן מאשר פעילות האינסולין, אך ניתן להתקרב באמצעות עיכוב ראשוני ואחריו שיעור קבוע של קליטה (g / hr) '
      ,el: 'Γραμμάρια ανά μονάδα χρόνου. Αναπαριστά τόσο την μεταβολή του COB στη μονάδα του χρόνου. Οι καμπύλες της απορρόφησης υδατανθράκων και της άσκησης δεν έχουν κατανοηθεί πλήρως από την επιστημονική κοινότητα, αλλά μπορούν να προσεγγιστούν βάζοντας μία αρχική καθυστέρηση ακολουθούμενη από έναν σταθερό ρυθμό απορρόφησης (g/hr).'
      ,fr: 'grammes par unité de  temps. Représente l\'augmentation de COB par unité de temps et la quantité de glucides agissant durant cette période. L\'absorption des glucides est imprécise et est évaluée en moyenne. L\'unité est grammes par heure (g/h).'
      ,ro: 'grame pe unitatea de timp. Reprezintă atât schimbarea COB pe unitatea de timp, cât și cantitatea de carbohidrați care ar influența în perioada de timp. Graficele ratei de absorbție sunt mai puțin înțelese decât senzitivitatea la insulină, dar se poate aproxima folosind o întârziere implicită și apoi o rată constantă de aborbție (g/h).'
      ,sv: 'gram per tidsenhet. Representerar både ändring i aktiva kolhydrater per tidsenhet som mängden kolhydrater som tas upp under denna tid. Kolhydratsupptag / aktivitetskurvor är svårare att förutspå än aktivt insulin men kan beräknas genom att använda en startfördröjning följd av en konstant absorbtionsgrad (g/timme) '
      ,dk: 'gram per tidsenhed. Repræsentere både ændring i aktive kulhydrater per tidsenhed, samt mængden af kulhydrater der indtages i dette tidsrum. Kulhydratsoptag / aktivitetskurver er svære at forudse i forhold til aktiv insulin, men man kan lave en tilnærmet beregning, ved at inkludere en forsinkelse i beregningen, sammen med en konstant absorberingstid (g/time).'
      ,de: 'Gramm pro Zeiteinheit. Bedeutet sowohl die Änderung in COB je Zeiteinheit, als auch die Menge an Kohlenhydraten die über diese Zeit wirken sollten. Kohlenhydrat-Absorption / Aktivitätskurven werden weniger genau verstanden als Insulinaktivität, aber sie können angenähert werden indem eine Anfangsverzögerung mit konstanter Aufnahme (g/Std.) verwendet wird.'
      ,es: 'gramos por unidad de tiempo. Representa tanto el cambio en carbohidratos activos por unidad de tiempo como la cantidad de carbohidratos absorbidos durante este tiempo. Las curvas de captación / actividad de carbohidratos son más difíciles de predecir que la insulina activa, pero se pueden calcular utilizando un retraso de inicio seguido de una tasa de absorción constante (gr/h)'
      ,nb: 'gram per tidsenhet. Representerer både endringen i COB per tidsenhet, såvel som mengden av karbohydrater som blir tatt opp i løpet av den tiden. Carb absorpsjon / virkningskurver er mindre forstått enn insulinaktivitet, men kan tilnærmes ved hjelp av en forsinkelse fulgt av en konstant hastighet av absorpsjon ( g / time ) .'
      ,fi: 'grammaa / aika. Kertoo tyypillisen nopeuden, jolla hiilihydraatit imeytyvät syömisen jälkeen. Imeytyminen tunnetaan jokseenkin huonosti, mutta voidaan arvioida keskimääräisesti. Yksikkönä grammaa tunnissa (g/h).'
      ,bg: 'грам за единица време. Представлява както промяната в COB за единица време, така и количеството ВХ които биха се усвоили за това време.'
      ,hr: 'grama po jedinici vremena. Predstavlja promjenu aktivnih UGH u jedinici vremena, kao i količinu UGH koja bi trebala utjecati kroz to vrijeme.'
      ,pl: 'g na jednostkę czasu. Odzwierciedla zmianę COB na jednostkę czasu oraz ilość węglowodanów mających przynieść efekt w czasie. Krzywe absorpcji / aktywnosci węglowodanów są mniej poznane niż aktywności insuliny ale mogą być oszacowane przez ocenę opóźnienia wchłaniania przy stałym współczynniku absorpcji (g/h).'
      ,pt: 'Gramas por unidade de tempo. Representa a mudança em COB por unidade de tempo, bem como a quantidade de carboidratos que deve ter efeito durante esse período de tempo. Absorção de carboidrato / curvas de atividade são menos conhecidas que atividade de insulina, mas podem ser aproximadas usando um atraso inicial seguido de uma taxa de absorção constante (g/h). '
      ,ru: 'грамм на ед времени. Представляет изменение кол-ва углеводов в организме (COB)за единицу времени a также количество активных углеводов'
      ,sk: 'gramy za jednotku času. Reprezentuje súčasne zmenu COB za jednotku času, ako aj množstvo sacharidov ktoré sa za tú dobu prejavili. Krivka vstrebávania sacharidov je omnoho menej pochopiteľná ako pôsobenie inzulínu (IOB), ale môže byť približne s použitím počiatočného oneskorenia a následne s konštantným vstrebávaním (g/hod).  '
      ,ko: '단위 시간당 그램. 시간당 작용하는 탄수화물의 총량 뿐 아니라 시간 단위당 COB의 변화를 나타냅니다. 탄수화물 흡수율/활성도 곡선은 인슐린 활성도보다 이해가 잘 되지는 않지만 지속적인 흡수율(g/hr)에 따른 초기 지연을 사용하여 근사치를 구할 수 있습니다.'
      ,it: 'grammi per unità di tempo. Rappresentano sia il cambio di COB per unità di tempo, sia la quantità di carboidrati che faranno effetto nel tempo. Assorbimento di carboidrati / curva di attività sono meno conosciute rispetto all\'attività insulinica, ma possono essere approssimate usando un ritardo iniziale seguito da un rapporto costante di assorbimento (g/hr).'
      ,tr: 'Ur birim zaman başına gram. Birim zamanda (COB) Aktif Krabonhidratdaki değişimin yanı sıra o zaman üzerinde etki etmesi gereken karbonhidrat miktarını ifade eder. Karbonhidrat emme/aktivite eğrileri, insülin aktivitesinden daha zor anlaşılmaktadır, ancak bir başlangıç gecikmesi ve ardından sabit bir emilim oranı (g/hr) kullanılarak yaklaşık olarak tahmin edilebilmektedir.'
      ,zh_cn: '克每单位时间。表示每单位时间COB（活性碳水化合物）的变化，以及在该时间应该生效的碳水化合物的量。碳水化合物活性/吸收曲线比胰岛素活性难理解，但可以使用初始延迟，接着恒定吸收速率（克/小时）来近似模拟。'
      ,nl: 'grammen per tijdseenheid. Geeft de wijzigingen in COB per tijdseenheid alsookde hoeveelheid KH dat impact zou moeten hebben over deze tijdspanne. KH absorbtie / activiteits curveszijn minder eenvoudig uitzetbaar, maar kunnen geschat worden door gebruik te maken van een startvertreging en daarna een constante curve van absorbtie (g/u).'
      ,hu: 'gramm per idő egység. Kifejezi a COB változását es a szénhidrátok felszívódását bizonyos idő elteltével. A szénhidrát felszívódásának tengelye nehezebben értelmezhető mint az inzulin aktivitás (IOB), de hasonló lehet a kezdeti késedelemhez és a felszívódáshoz (g/óra). '
      }
    ,'Basal rates [unit/hour]' : {
      cs: 'Bazály [U/hod].'
      ,he: 'קצב בסיסי (יחידה לשעה) '
      ,ro: 'Rata bazală [unitate/oră]'
      ,el: 'Ρυθμός Βασικ΄ς ινσουλίνης [U/hour]'
      ,de: 'Basalraten [Einheit/h]'
      ,dk: 'Basal [enhed/t]'
      ,es: 'Tasas basales [Unidad/hora]'
      ,fr: 'Débit basal (Unités/ heure)'
      ,sv: 'Basal [enhet/t]'
      ,nb: 'Basal [enhet/t]'
      ,fi: 'Basaali [yksikköä/tunti]'
      ,bg: 'Базална стойност [единица/час]'
      ,hr: 'Bazali [jedinica/sat]'
      ,pl: 'Dawka podstawowa [j/h]'
      ,pt: 'Taxas de basal [unidades/hora]'
      ,ru: 'Базал [unit/hour]'
      ,sk: 'Bazál [U/hod]'
      ,nl: 'Basaal snelheid [eenheden/uur]'
      ,ko: 'Basal 비율[unit/hour]'
      ,it: 'Basale [unità/ora]'
      ,tr: 'Bazal oranı [ünite/saat]'
      ,zh_cn: '基础率 [U/小时]'
      ,hu: 'Bazál [egység/óra]'
      }
    ,'Target BG range [mg/dL,mmol/L]' : {
      cs: 'Cílový rozsah glykémií [mg/dL,mmol/L]'
      ,he: 'יעד טווח גלוקוז בדם '
      ,ro: 'Intervalul țintă al glicemiei [mg/dL, mmol/L]'
      ,el: 'Στόχος Γλυκόζης Αίματος [mg/dl , mmol/l]'
      ,de: 'Blutzucker-Zielbereich [mg/dL, mmol/L]'
      ,dk: 'Ønsket blodsukkerinterval [mg/dl,mmol]'
      ,es: 'Intervalo glucemia dentro del objetivo [mg/dL,mmol/L]'
      ,fr: 'Cible d\'intervalle de glycémie'
      ,sv: 'Önskvärt blodsockerintervall [mg/dl,mmol]'
      ,nb: 'Ønsket blodsukkerintervall [mg/dl,mmmol/l]'
      ,fi: 'Tavoitealue [mg/dL tai mmol/L]'
      ,bg: 'Целеви диапазон на КЗ [мг/дл , ммол]'
      ,hr: 'Ciljani raspon GUK [mg/dL,mmol/L]'
      ,pl: 'Docelowy przedział glikemii [mg/dl, mmol/L])'
      ,pt: 'Meta de glicemia [mg/dL, mmol/L]'
      ,ru: 'Целевой диапазон СК [mg/dL,mmol/L]'
      ,sk: 'Rozsah cieľovej glykémie [mg/dL,mmol/L]'
      ,nl: 'Doel BG waarde in [mg/dl,mmol/L'
      ,ko: '목표 혈당 범위 [mg/dL,mmol/L]'
      ,it: 'Obiettivo d\'intervallo glicemico [mg/dL,mmol/L]'
      ,tr: 'Hedef KŞ aralığı [mg / dL, mmol / L]'
      ,zh_cn: '目标血糖范围 [mg/dL,mmol/L]'
      ,hu: 'Cukorszint választott tartomány [mg/dL,mmol/L]'
      }
    ,'Start of record validity' : {
      cs: 'Začátek platnosti záznamu'
      ,he: 'תחילת תוקף הרשומה '
      ,ro: 'De când este valabilă înregistrarea'
      ,fr: 'Début de validité des données'
      ,el: 'Ισχύει από'
      ,sv: 'Starttid för händelse'
      ,de: 'Beginn der Aufzeichnungsgültigkeit'
      ,dk: 'Starttid for gyldighed'
      ,es: 'Inicio de validez de datos'
      ,nb: 'Starttidspunkt for gyldighet'
      ,fi: 'Merkinnän alkupäivämäärä'
      ,bg: 'Начало на записа'
      ,hr: 'Trenutak važenja zapisa'
      ,pl: 'Początek ważnych rekordów'
      ,pt: 'Início da validade dos dados'
      ,ru: 'Начало валидности записей'
      ,sk: 'Začiatok platnosti záznamu'
      ,nl: 'Start geldigheid'
      ,ko: '기록 유효기간의 시작일'
      ,it: 'Inizio di validità del dato'
      ,tr: 'Kayıt geçerliliği başlangıcı'
      ,zh_cn: '有效记录开始'
      ,hu: 'Bejegyzés kezdetének érvényessége'
      }
    ,'Icicle' : {
      cs: 'Rampouch'
      ,he: 'קרחון '
      ,it: 'Inverso'
      ,fr: 'Stalactite'
      ,de: 'Eiszapfen'
      ,dk: 'Istap'
      ,es: 'Inverso'
      ,ro: 'Țurțure'
      ,sv: 'Istapp'
      ,nb: 'Istapp'
      ,fi: 'Jääpuikko'
      ,bg: 'Висящ'
      ,hr: 'Padajuće'
      ,pl: 'Odwrotność'
      ,pt: 'Inverso'
      ,nl: 'Ijspegel'
      ,ru: 'Силуэт сосульки'
      ,sk: 'Inverzne'
      ,ko: '고드름 방향'
      ,tr: 'Buzsaçağı' //Sarkıt
      ,zh_cn: 'Icicle'
      ,zh_tw: 'Icicle'
      ,hu: 'Inverzió'
      }
    ,'Render Basal' : {
      cs: 'Zobrazení bazálu'
      ,he: 'הראה רמה בזלית '
      ,it: 'Grafico Basale'
      ,fr: 'Afficher le débit basal'
      ,el: 'Απεικόνιση Βασικής Ινσουλίνης'
      ,ro: 'Afișează bazala'
      ,sv: 'Generera Basal'
      ,de: 'Basalraten-Darstellung'
      ,dk: 'Generer Basal'
      ,es: 'Representación Basal'
      ,nb: 'Basalgraf'
      ,fi: 'Näytä basaali'
      ,bg: 'Базал'
      ,hr: 'Iscrtaj bazale'
      ,pl: 'Zmiana dawki bazowej'
      ,pt: 'Renderizar basal'
      ,ru: 'Отображать базал'
      ,sk: 'Zobrazenie bazálu'
      ,nl: 'Toon basaal'
      ,ko: 'Basal 사용하기'
      ,tr: 'Bazal Grafik'
      ,zh_cn: '使用基础率'
      ,zh_tw: '使用基礎率'
      ,hu: 'Bazál megjelenítése'
      }
    ,'Profile used' : {
      cs: 'Použitý profil'
      ,he: 'פרופיל בשימוש '
      ,ro: 'Profil folosit'
      ,de: 'Verwendetes Profil'
      ,dk: 'Profil brugt'
      ,es: 'Perfil utilizado'
      ,fr: 'Profil utilisé'
      ,el: 'Προφίλ σε χρήση'
      ,sv: 'Vald profil'
      ,nb: 'Brukt profil'
      ,fi: 'Käytetty profiili'
      ,bg: 'Използван профил'
      ,hr: 'Korišteni profil'
      ,pl: 'Profil wykorzystywany'
      ,pt: 'Perfil utilizado'
      ,ru: 'Используемый профиль'
      ,sk: 'Použitý profil'
      ,nl: 'Gebruikt profiel'
      ,ko: '프로파일이 사용됨'
      ,it: 'Profilo usato'
      ,tr: 'Kullanılan profil'
      ,zh_cn: '配置文件已使用'
      ,hu: 'Használatban lévő profil'
      }
    ,'Calculation is in target range.' : {
      cs: 'Kalkulace je v cílovém rozsahu.'
      ,he: 'חישוב הוא בטווח היעד '
      ,ro: 'Calculul dă o valoare în intervalul țintă.'
      ,fr: 'La valeur calculée est dans l\'intervalle cible'
      ,el: 'Ο υπολογισμός είναι εντός στόχου'
      ,de: 'Berechnung ist innerhalb des Zielbereichs'
      ,dk: 'Beregning er i målområdet'
      ,es: 'El cálculo está dentro del rango objetivo'
      ,sv: 'Inom intervallområde'
      ,nb: 'Innenfor målområde'
      ,fi: 'Laskettu arvo on tavoitealueella'
      ,bg: 'Калкулацията е в граници'
      ,hr: 'Izračun je u ciljanom rasponu.'
      ,pl: 'Obliczenie mieści się w zakresie docelowym'
      ,pt: 'O cálculo está dentro da meta'
      ,ru: 'Расчет в целевом диапазоне'
      ,sk: 'Výpočet je v cieľovom rozsahu.'
      ,nl: 'Berekening valt binnen doelwaards'
      ,ko: '계산은 목표 범위 안에 있습니다.'
      ,it: 'Calcolo all\'interno dell\'intervallo'
      ,tr: 'Hesaplama hedef aralıktadır.'
      ,zh_cn: '预计在目标范围内'
      ,hu: 'A számítás a cél tartományban található'
      }
    ,'Loading profile records ...' : {
      cs: 'Nahrávám profily ...'
      ,he: 'טוען רשומות פרופיל '
      ,nb: 'Leser profiler'
      ,fr: 'Chargement des profils...'
      ,el: 'Φόρτωση δεδομένων προφίλ'
      ,de: 'Lade Profilaufzeichnungen ...'
      ,dk: 'Henter profildata ...'
      ,es: 'Cargando datos perfil ....'
      ,ro: 'Se încarcă datele profilului...'
      ,sv: 'Laddar profildata ...'
      ,fi: 'Ladataan profiileja ...'
      ,bg: 'Зареждане на профили'
      ,hr: 'Učitavanje profila...'
      ,pl: 'Wczytywanie rekordów profilu'
      ,pt: 'Carregando dados do perfil ...'
      ,ru: 'Загрузка записей профиля'
      ,sk: 'Nahrávam záznamy profilov'
      ,nl: 'Laden profiel gegevens'
      ,ko: '프로파일 기록 로딩'
      ,it: 'Caricamento dati del profilo ...'
      ,tr: 'Profil kayıtları yükleniyor ...'
      ,zh_cn: '载入配置文件记录...'
      ,hu: 'Profil bejegyzéseinek betöltése...'
      }
    ,'Values loaded.' : {
      cs: 'Data nahrána.'
      ,he: 'ערכים נטענו '
      ,nb: 'Verdier lastet'
      ,fr: 'Valeurs chargées'
      ,el: 'Δεδομένα φορτώθηκαν'
      ,de: 'Werte geladen.'
      ,dk: 'Værdier hentes'
      ,es: 'Valores cargados'
      ,ro: 'Valorile au fost încărcate.'
      ,sv: 'Värden laddas'
      ,fi: 'Arvot ladattu'
      ,bg: 'Стойностите за заредени.'
      ,hr: 'Vrijednosti učitane.'
      ,pl: 'Wartości wczytane.'
      ,pt: 'Valores carregados.'
      ,ru: 'Данные загружены'
      ,sk: 'Hodnoty načítané.'
      ,nl: 'Gegevens geladen'
      ,ko: '값이 로드됨'
      ,it: 'Valori caricati.'
      ,tr: 'Değerler yüklendi.'
      ,zh_cn: '已载入数值'
      ,hu: 'Értékek betöltése.'
      }
    ,'Default values used.' : {
      cs: 'Použity výchozí hodnoty.'
      ,he: 'משתמשים בערכי בררת המחדל '
      ,nb: 'Standardverdier brukt'
      ,el: 'Χρήση προκαθορισμένων τιμών'
      ,fr: 'Valeurs par défault utilisées'
      ,de: 'Standardwerte werden verwendet.'
      ,dk: 'Standardværdier brugt'
      ,es: 'Se usan valores predeterminados'
      ,ro: 'Se folosesc valorile implicite.'
      ,sv: 'Standardvärden valda'
      ,fi: 'Oletusarvot ladattu'
      ,bg: 'Стойностите по подразбиране са използвани.'
      ,hr: 'Koriste se zadane vrijednosti.'
      ,pl: 'Używane domyślne wartości.'
      ,pt: 'Valores padrão em uso.'
      ,ru: 'Используются значения по умолчанию'
      ,sk: 'Použité východzie hodnoty.'
      ,nl: 'Standaard waardes gebruikt'
      ,ko: '초기 설정 값이 사용됨'
      ,it: 'Valori standard usati.'
      ,tr: 'Varsayılan değerler kullanıldı.'
      ,zh_cn: '已使用默认值'
      ,hu: 'Alap értékek használva.'
      }
    ,'Error. Default values used.' : {
      cs: 'CHYBA: Použity výchozí hodnoty.'
      ,he: 'משתמשים בערכי בררת המחדל שגיאה - '
      ,nb: 'Feil. Standardverdier brukt.'
      ,fr: 'Erreur! Valeurs par défault utilisées.'
      ,el: 'Σφάλμα: Χρήση προκαθορισμένων τιμών'
      ,de: 'Fehler. Standardwerte werden verwendet.'
      ,dk: 'Fejl. Standardværdier brugt.'
      ,es: 'Error. Se usan valores predeterminados.'
      ,ro: 'Eroare. Se folosesc valorile implicite.'
      ,sv: 'Error. Standardvärden valda.'
      ,fi: 'Virhe! Käytetään oletusarvoja.'
      ,bg: 'Грешка. Стойностите по подразбиране са използвани.'
      ,hr: 'Pogreška. Koristiti će se zadane vrijednosti.'
      ,pl: 'Błąd. Używane domyślne wartości.'
      ,pt: 'Erro. Valores padrão em uso.'
      ,ru: 'Ошибка. Используются значения по умолчанию'
      ,sk: 'CHYBA! Použité východzie hodnoty.'
      ,nl: 'FOUT: Standaard waardes gebruikt'
      ,ko: '에러. 초기 설정 값이 사용됨'
      ,it: 'Errore. Valori standard usati.'
      ,tr: 'Hata. Varsayılan değerler kullanıldı.'
      ,zh_cn: '错误，已使用默认值'
      ,hu: 'Hiba: Alap értékek használva'
      }
    ,'Time ranges of target_low and target_high don\'t  match. Values are restored to defaults.' : {
      cs: 'Rozsahy časů pro limity glykémií si neodpovídají. Budou nastaveny výchozí hodnoty.'
      ,he: 'טווחי יעד נמוכים ויעדים גבוהים אינם תואמים. הערכים משוחזרים לברירות המחדל '
      ,nb: 'Tidsintervall for målområde lav og høy stemmer ikke.  Standardverdier er brukt.'
      ,fr: 'Les intervalles de temps pour la cible glycémique supérieure et inférieure diffèrent. Les valeurs par défault sont restaurées.'
      ,el: 'Το χρονικό διάστημα του χαμηλού ορίου/στόχου και του υψηλού, δεν συμπίπτουν. Γίνεται επαναφορά στις προκαθορισμένες τιμές.'
      ,de: 'Zeitspanne vom untersten und obersten Wert wird nicht berücksichtigt. Werte auf Standard zurückgesetzt.'
      ,dk: 'Tidsinterval for målområde lav og høj stemmer ikke overens. Standardværdier er brugt'
      ,es: 'Los marcos temporales para objetivo inferior y objetivo superior no coinciden. Los valores predeterminados son usados.'
      ,ro: 'Intervalele temporale pentru țintă_inferioară și țintă_superioară nu se potrivesc. Se folosesc valorile implicite.'
      ,sv: 'Tidsintervall för målområde låg och hög stämmer ej'
      ,fi: 'Matalan ja korkean tavoitteen aikarajat eivät täsmää. Arvot on vaihdettu oletuksiin.'
      ,bg: 'Времевите интервали за долна граница на кз и горна граница на кз не съвпадат. Стойностите са възстановени по подразбиране.'
      ,hr: 'Vremenski rasponi donje ciljane i gornje ciljane vrijednosti nisu ispravni. Vrijednosti vraćene na zadano.'
      ,pl: 'Zakres czasu w docelowo niskim i wysokim przedziale nie są dopasowane. Przywrócono wartości domyślne'
      ,pt: 'Os intervalos de tempo da meta inferior e da meta superior não conferem. Os valores padrão serão restaurados.'
      ,ru: 'Диапазоны времени нижних и верхних целевых значений не совпадают. Восстановлены значения по умолчанию'
      ,sk: 'Časové rozsahy pre cieľové glykémie sa nezhodujú. Hodnoty nastavené na východzie.'
      ,ko: '설정한 저혈당과 고혈당의 시간 범위와 일치하지 않습니다. 값은 초기 설정값으로 다시 저장 될 것입니다.'
      ,it: 'Intervalli di tempo della glicemia obiettivo inferiore e superiore non corretti. Valori ripristinati a quelli standard.'
      ,nl: 'Tijdspanne van laag en hoog doel zijn niet correct. Standaard waarden worden gebruikt'
      ,tr: 'Target_low ve target_high öğelerinin zaman aralıkları eşleşmiyor. Değerler varsayılanlara geri yüklendi.'
      ,zh_cn: '时间范围内的目标高低血糖值不匹配。已恢复使用默认值。'
      ,hu: 'A cukorszint-cél időtartománya nem egyezik. Visszaállítás az alapértékekre'
      }
    ,'Valid from:' : {
      cs: 'Platné od:'
      ,he: 'בתוקף מ: '
      ,de: 'Gültig ab:'
      ,dk: 'Gyldig fra:'
      ,es: 'Válido desde:'
      ,fr: 'Valide à partir de:'
      ,nb: 'Gyldig fra:'
      ,el: 'Ισχύει από:'
      ,ro: 'Valid de la:'
      ,sv: 'Giltig från:'
      ,fi: 'Alkaen:'
      ,bg: 'Валиден от'
      ,hr: 'Vrijedi od:'
      ,pl: 'Ważne od:'
      ,pt: 'Válido desde:'
      ,ru: 'Действительно с'
      ,sk: 'Platné od:'
      ,nl: 'Geldig van:'
      ,ko: '유효'
      ,it: 'Valido da:'
      ,tr: 'Tarihinden itibaren geçerli'
      ,zh_cn: '生效从：'
      ,hu: 'Érvényes:'
      }
    ,'Save current record before switching to new?' : {
      cs: 'Uložit současný záznam před přepnutím na nový?'
      ,he: 'שמור את הרשומה הנוכחית לפני המעבר ל חדש? '
      ,nb: 'Lagre før nytte til ny?'
      ,fr: 'Sauvegarder cetter entrée avant de procéder à l\'entrée suivante?'
      ,el: 'Αποθήκευση αλλαγών πριν γίνει εναλλαγή στο νέο προφίλ? '
      ,de: 'Aktuellen Datensatz speichern?'
      ,dk: 'Gem nuværende data inden der skiftes til nyt?'
      ,es: 'Grabar datos actuales antes cambiar a uno nuevo?'
      ,ro: 'Salvez valoarea curentă înainte de a trece la una nouă?'
      ,sv: 'Spara före byte till nytt?'
      ,fi: 'Tallenna nykyinen merkintä ennen vaihtamista uuteen?'
      ,bg: 'Запазване текущият запис преди превключване на нов?'
      ,hr: 'Spremi trenutni zapis prije prelaska na novi?'
      ,pl: 'Nagrać bieżący rekord przed przełączeniem na nowy?'
      ,pt: 'Salvar os dados atuais antes de mudar para um novo?'
      ,ru: 'Сохранить текущие записи перед переходом к новым?'
      ,sk: 'Uložiť súčastný záznam pred prepnutím na nový?'
      ,nl: 'Opslaan voor verder te gaan?'
      ,ko: '새 데이터로 변환하기 전에 현재의 기록을 저장하겠습니까?'
      ,it: 'Salvare il dato corrente prima di passare ad uno nuovo?'
      ,tr: 'Yenisine geçmeden önce mevcut kaydı kaydet'
      ,zh_cn: '切换至新记录前保存当前记录？'
      ,hu: 'Elmentsem az aktuális adatokat mielőtt újra válunk?'
    }
    ,'Add new interval before' : {
      cs: 'Přidat nový interval před'
      ,he: 'הוסף מרווח חדש לפני '
      ,nb: 'Legg til nytt intervall før'
      ,fr: 'Ajouter un intervalle de temps avant'
      ,el: 'Προσθήκη νέου διαστήματος, πριν'
      ,de: 'Neues Intervall vorher hinzufügen'
      ,dk: 'Tilføj nyt interval før'
      ,es: 'Agregar nuevo intervalo antes'
      ,ro: 'Adaugă un nou interval înainte'
      ,sv: 'Lägg till nytt intervall före'
      ,fi: 'Lisää uusi aikaväli ennen'
      ,bg: 'Добави интервал преди'
      ,hr: 'Dodaj novi interval iznad'
      ,pl: 'Dodaj nowy przedział przed'
      ,pt: 'Adicionar novo intervalo antes de'
      ,ru: 'Добавить интервал перед'
      ,sk: 'Pridať nový interval pred'
      ,nl: 'Voeg interval toe voor'
      ,ko: '새로운 구간을 추가하세요'
      ,it: 'Aggiungere prima un nuovo intervallo'
      ,tr: 'Daha önce yeni aralık ekle'
      ,zh_cn: '在此前新增区间'
      ,hu: 'Új intervallum hozzáadása elötte'
      }
    ,'Delete interval' : {
      cs: 'Smazat interval'
      ,he: 'בטל מרווח '
      ,nb: 'Slett intervall'
      ,fr: 'Effacer l\'intervalle'
      ,el: 'Διαγραφή διαστήματος'
      ,de: 'Intervall löschen'
      ,dk: 'Slet interval'
      ,es: 'Borrar intervalo'
      ,ro: 'Șterge interval'
      ,sv: 'Ta bort intervall'
      ,fi: 'Poista aikaväli'
      ,bg: 'Изтрий интервал'
      ,hr: 'Obriši interval'
      ,pl: 'Usuń przedział'
      ,pt: 'Apagar intervalo'
      ,ru: 'Удалить интервал'
      ,sk: 'Zmazať interval'
      ,nl: 'Verwijder interval'
      ,ko: '구간을 지우세요.'
      ,it: 'Elimina intervallo'
      ,tr: 'Aralığı sil'
      ,zh_cn: '删除区间'
      ,hu: 'Intervallum törlése'
      }
    ,'I:C' : {
      cs: 'I:C'
      ,he:  'יחס אינסולין לפחמימות '
      ,nb: 'IKH'
      ,fr: 'I:C'
      ,ro: 'ICR'
      ,de: 'IE:KH'
      ,dk: 'I:C'
      ,es: 'I:C'
      ,sv: 'I:C'
      ,fi: 'I:HH'
      ,bg: 'И:ВХ'
      ,hr: 'I:C'
      ,pl: 'I:C'
      ,pt: 'I:C'
      ,ru: 'Инс:Углев'
      ,sk: 'I:C'
      ,nl: 'I:C'
      ,ko: 'I:C'
      ,it: 'I:C'
      ,tr: 'İ:K'
      ,zh_cn: 'ICR'
      ,hu: 'I:C'
      }
    ,'ISF' : {
      cs: 'ISF'
      ,he: 'ISF '
      ,nb: 'ISF'
      ,ro: 'ISF'
      ,de: 'ISF'
      ,dk: 'ISF'
      ,es: 'ISF'
      ,fr: 'ISF'
      ,sv: 'ISF'
      ,fi: 'ISF'
      ,bg: 'Инсулинова чувствителност'
      ,hr: 'ISF'
      ,pl: 'ISF'
      ,pt: 'ISF'
      ,nl: 'ISF'
      ,ru: 'Чувств к инс ISF'
      ,sk: 'ISF'
      ,ko: 'ISF'
      ,it: 'ISF'
      ,tr: 'ISF'
      ,zh_cn: 'ISF'
      ,hu: 'ISF'
      }
    ,'Combo Bolus' : {
      cs: 'Kombinovaný bolus'
      ,he: 'בולוס קומבו '
      ,pl: 'Bolus złożony'
      ,de: 'Verzögerter Bolus'
      ,dk: 'Kombineret bolus'
      ,es: 'Combo-Bolo'
      ,fr: 'Bolus Duo/Combo'
      ,el: ''
      ,ro: 'Bolus extins'
      ,sv: 'Combo-bolus'
      ,nb: 'Kombinasjonsbolus'
      ,bg: 'Двоен болус'
      ,hr: 'Dual bolus'
      ,fi: 'Yhdistelmäbolus'
      ,pt: 'Bolus duplo'
      ,ru: 'Комбинир болюс'
      ,sk: 'Kombinovaný bolus'
      ,nl: 'Pizza Bolus'
      ,ko: 'Combo Bolus'
      ,it: 'Combo Bolo'
      ,tr: 'Kombo (Yayma) Bolus'
      ,zh_cn: '双波'
      ,hu: 'Kombinált bólus'
      }
    ,'Difference' : {
      cs: 'Rozdíl'
      ,he: 'הבדל '
      ,de: 'Unterschied'
      ,dk: 'Forskel'
      ,es: 'Diferencia'
      ,fr: 'Différence'
      ,ro: 'Diferență'
      ,el: 'Διαφορά'
      ,sv: 'Skillnad'
      ,nb: 'Forskjell'
      ,bg: 'Разлика'
      ,hr: 'Razlika'
      ,fi: 'Ero'
      ,ru: 'Разность'
      ,sk: 'Rozdiel'
      ,pl: 'Różnica'
      ,pt: 'Diferença'
      ,nl: 'Verschil'
      ,ko: '차이'
      ,it: 'Differenza'
      ,tr: 'fark'
      ,zh_cn: '差别'
      ,hu: 'Különbség'
      }
    ,'New time' : {
      cs: 'Nový čas'
      ,he: 'זמן חדש '
      ,de: 'Neue Zeit'
      ,dk: 'Ny tid'
      ,es: 'Nueva hora'
      ,fr: 'Nouveau temps'
      ,ro: 'Oră nouă'
      ,el: 'Νέα ώρα'
      ,sv: 'Ny tid'
      ,nb: 'Ny tid'
      ,bg: 'Ново време'
      ,hr: 'Novo vrijeme'
      ,fi: 'Uusi aika'
      ,ru: 'Новое время'
      ,sk: 'Nový čas'
      ,pl: 'Nowy czas'
      ,pt: 'Novo horário'
      ,nl: 'Nieuwe tijd'
      ,ko: '새로운 시간'
      ,it: 'Nuovo Orario'
      ,tr: 'Yeni zaman'
      ,zh_cn: '新时间'
      ,hu: 'Új idő:'
      }
    ,'Edit Mode' : {
      cs: 'Editační mód'
      ,he: 'מצב עריכה '
      ,ro: 'Mod editare'
      ,fr: 'Mode Édition'
      ,de: 'Bearbeitungsmodus'
      ,dk: 'Redigerings tilstand'
      ,es: 'Modo edición'
      ,sv: 'Editeringsläge'
      ,el: 'Λειτουργία Επεξεργασίας'
      ,nb: 'Editeringsmodus'
      ,bg: 'Редактиране'
      ,hr: 'Uređivanje'
      ,fi: 'Muokkausmoodi'
      ,ru: 'Режим редактирования'
      ,sk: 'Editačný mód'
      ,pl: 'Tryb edycji'
      ,pt: 'Modo de edição'
      ,ko: '편집 모드'
      ,it: 'Modalità di Modifica'
      ,ja: '編集モード'
      ,nl: 'Wijzigen uitvoeren'
      ,tr: 'Düzenleme Modu'
      ,zh_cn: '编辑模式'
      ,zh_tw: '編輯模式'
      ,hu: 'Szerkesztési mód'
      }
    ,'When enabled icon to start edit mode is visible' : {
      cs: 'Pokud je povoleno, ikona pro vstup do editačního módu je zobrazena'
      ,he: 'כאשר הסמל מופעל כדי להתחיל במצב עריכה גלוי '
      ,ro: 'La activare, butonul de editare este vizibil'
      ,fr: 'Lorsqu\'activé, l\'icône du Mode Édition devient visible'
      ,el: 'Όταν ενεργοποιηθεί, το εικονίδιο της λειτουργίας επεξεργασίας είναι ορατό'
      ,de: 'Wenn aktiviert wird das Icons zum Start des Bearbeitungsmodus sichtbar'
      ,dk: 'Ikon vises når redigering er aktivt'
      ,es: 'Si está activado, el icono estará visible al inicio del modo de edición'
      ,sv: 'Ikon visas när editeringsläge är aktivt'
      ,nb: 'Ikon vises når editeringsmodus er aktivert'
      ,bg: 'Когато е активно ,иконката за редактиране ще се вижда'
      ,hr: 'Kada je omogućeno, mod uređivanje je omogućen'
      ,fi: 'Muokkausmoodin ikoni tulee näkyviin kun laitat tämän päälle'
      ,ru: 'При активации видна пиктограмма начать режим редактирования'
      ,sk: 'Keď je povolený, je zobrazená ikona editačného módu'
      ,pl: 'Po aktywacji, widoczne ikony, aby uruchomić tryb edycji'
      ,pt: 'Quando ativado, o ícone iniciar modo de edição estará visível'
      ,nl: 'Als geactiveerd is Wijzigen modus beschikbaar'
      ,ko: '편집모드를 시작하기 위해 아이콘을 활성화하면 볼 수 있습니다.'
      ,it: 'Quando abilitata, l\'icona della Modalità di Modifica è visibile'
      ,tr: 'Etkinleştirildiğinde düzenleme modunun başında simgesi görünecektir.'
      ,zh_cn: '启用后开始编辑模式图标可见'
      ,zh_tw: '啟用後開始編輯模式圖標可見'
      ,hu: 'Engedélyezés után a szerkesztési ikon látható'
      }
    ,'Operation' : {
      cs: 'Operace'
      ,he: 'פעולה '
      ,ro: 'Operațiune'
      ,fr: 'Opération'
      ,de: 'Operation'
      ,dk: 'Operation'
      ,es: 'Operación'
      ,el: 'Λειτουργία'
      ,sv: 'Operation'
      ,nb: 'Operasjon'
      ,bg: 'Операция'
      ,hr: 'Operacija'
      ,fi: 'Operaatio'
      ,ru: 'Операция'
      ,sk: 'Operácia'
      ,pl: 'Operacja'
      ,pt: 'Operação'
      ,nl: 'Operatie'
      ,ko: '동작'
      ,it: 'Operazione'
      ,tr: 'İşlem'  //Operasyon
      ,zh_cn: '操作'
      ,hu: 'Operáció'
      }
    ,'Move' : {
      cs: 'Přesunout'
      ,he: 'הזז '
      ,ro: 'Mutați'
      ,fr: 'Déplacer'
      ,de: 'Verschieben'
      ,dk: 'Flyt'
      ,es: 'Mover'
      ,el: 'Μετακίνηση'
      ,sv: 'Flytta'
      ,nb: 'Flytt'
      ,bg: 'Премести'
      ,hr: 'Pomakni'
      ,fi: 'Liikuta'
      ,ru: 'Переместить'
      ,sk: 'Presunúť'
      ,pl: 'Przesuń'
      ,pt: 'Mover'
      ,nl: 'Verplaats'
      ,ko: '이동'
      ,it: 'Muovi'
      ,tr: 'Taşı'
      ,zh_cn: '移动'
      ,hu: 'Áthelyezés'
      }
    ,'Delete' : {
      cs: 'Odstranit'
      ,he: 'בטל '
      ,de: 'Löschen'
      ,dk: 'Slet'
      ,es: 'Borrar'
      ,fr: 'Effacer'
      ,el: 'Διαγραφή'
      ,ro: 'Ștergeți'
      ,sv: 'Ta bort'
      ,nb: 'Slett'
      ,bg: 'Изтрий'
      ,hr: 'Obriši'
      ,fi: 'Poista'
      ,sk: 'Zmazať'
      ,ru: 'Удалить'
      ,pl: 'Skasuj'
      ,pt: 'Apagar'
      ,nl: 'Verwijder'
      ,ko: '삭제'
      ,it: 'Elimina'
      ,ja: '削除'
      ,tr: 'Sil'
      ,zh_cn: '删除'
      ,hu: 'Törlés'
      }
    ,'Move insulin' : {
      cs: 'Přesunout inzulín'
      ,he: 'הזז אינסולין '
      ,ro: 'Mutați insulina'
      ,fr: 'Déplacer l\'insuline'
      ,de: 'Insulin verschieben'
      ,dk: 'Flyt insulin'
      ,es: 'Mover insulina'
      ,el: 'Μετακίνηση ινσουλίνης'
      ,sv: 'Flytta insulin'
      ,nb: 'Flytt insulin'
      ,bg: 'Премести инсулин'
      ,hr: 'Premjesti inzulin'
      ,fi: 'Liikuta insuliinia'
      ,ru: 'Переместить инсулин'
      ,sk: 'Presunúť inzulín'
      ,pl: 'Przesuń insulinę'
      ,pt: 'Mover insulina'
      ,nl: 'Verplaats insuline'
      ,ko: '인슐린을 이동하세요.'
      ,it: 'Muovi Insulina'
      ,tr: 'İnsülini taşı'
      ,zh_cn: '移动胰岛素'
      ,hu: 'Inzulin áthelyezése'
      }
    ,'Move carbs' : {
      cs: 'Přesunout sacharidy'
      ,he: 'הזז פחמימות '
      ,ro: 'Mutați carbohidrații'
      ,de: 'Kohlenhydrate verschieben'
      ,dk: 'Flyt kulhydrater'
      ,es: 'Mover carbohidratos'
      ,fr: 'Déplacer les glucides'
      ,el: 'Μετακίνηση υδατανθράκων'
      ,sv: 'Flytta kolhydrater'
      ,nb: 'Flytt karbohydrater'
      ,bg: 'Премести ВХ'
      ,hr: 'Premejsti UGH'
      ,fi: 'Liikuta hiilihydraatteja'
      ,ru: 'Переместить углеводы'
      ,sk: 'Presunúť sacharidy'
      ,pl: 'Przesuń węglowodany'
      ,pt: 'Mover carboidratos'
      ,nl: 'Verplaats KH'
      ,ko: '탄수화물을 이동하세요.'
      ,it: 'Muovi carboidrati'
      ,tr: 'Karbonhidratları taşı'
      ,zh_cn: '移动碳水'
      ,hu: 'Szénhidrát áthelyezése'
      }
    ,'Remove insulin' : {
      cs: 'Odstranit inzulín'
      ,he: 'בטל אינסולין '
      ,ro: 'Ștergeți insulina'
      ,de: 'Insulin löschen'
      ,dk: 'Fjern insulin'
      ,es: 'Eliminar insulina'
      ,fr: 'Effacer l\'insuline'
      ,el: 'Αφαίρεση ινσουλίνης'
      ,sv: 'Ta bort insulin'
      ,nb: 'Fjern insulin'
      ,bg: 'Изтрий инсулин'
      ,hr: 'Obriši inzulin'
      ,fi: 'Poista insuliini'
      ,ru: 'Удалить инсулин'
      ,sk: 'Odstrániť inzulín'
      ,pl: 'Usuń insulinę'
      ,pt: 'Remover insulina'
      ,nl: 'Verwijder insuline'
      ,ko: '인슐린을 지우세요.'
      ,it: 'Rimuovi insulina'
      ,tr: 'İnsülini kaldır'
      ,zh_cn: '去除胰岛素'
      ,hu: 'Inzulin törlése'
      }
    ,'Remove carbs' : {
      cs: 'Odstranit sacharidy'
      ,he: 'בטל פחמימות '
      ,ro: 'Ștergeți carbohidrații'
      ,fr: 'Effacer les glucides'
      ,de: 'Kohlenhydrate löschen'
      ,dk: 'Fjern kulhydrater'
      ,es: 'Eliminar carbohidratos'
      ,el: 'Αφαίρεση υδατανθράκων'
      ,sv: 'Ta bort kolhydrater'
      ,nb: 'Fjern karbohydrater'
      ,bg: 'Изтрий ВХ'
      ,hr: 'Obriši UGH'
      ,fi: 'Poista hiilihydraatit'
      ,ru: 'Удалить углеводы'
      ,sk: 'Odstrániť sacharidy'
      ,pl: 'Usuń węglowodany'
      ,pt: 'Remover carboidratos'
      ,nl: 'Verwijder KH'
      ,ko: '탄수화물을 지우세요.'
      ,it: 'Rimuovi carboidrati'
      ,tr: 'Karbonhidratları kaldır'
      ,zh_cn: '去除碳水'
      ,hu: 'Szénhidrát törlése'
      }
    ,'Change treatment time to %1 ?' : {
      cs: 'Změnit čas ošetření na %1 ?'
      ,he: 'שנה זמן לטיפול ל %1 '
      ,ro: 'Schimbați ora acțiunii cu %1 ?'
      ,fr: 'Modifier l\'horaire du traitment? Nouveau: %1'
      ,de: 'Behandlungsdauer ändern in %1 ?'
      ,dk: 'Ændre behandlingstid til %1 ?'
      ,es: 'Cambiar horario tratamiento a %1 ?'
      ,el: 'Αλλαγή του χρόνου της ενέργειας σε %1?'
      ,sv: 'Ändra behandlingstid till %1 ?'
      ,nb: 'Endre behandlingstid til %1 ?'
      ,bg: 'Да променя ли времето на събитието с %1?'
      ,hr: 'Promijeni vrijeme tretmana na %1?'
      ,fi: 'Muuta hoidon aika? Uusi: %1'
      ,ru: 'Изменить время события на %1 ?'
      ,sk: 'Zmeniť čas ošetrenia na %1 ?'
      ,pl: 'Zmień czas zdarzenia na %1 ?'
      ,pt: 'Alterar horário do tratamento para %1 ?'
      ,nl: 'Wijzig behandel tijdstip naar %1'
      ,ko: '%1 treatment을 변경하세요.'
      ,it: 'Cambiare tempo alla somministrazione a %1 ?'
      ,tr: 'Tedavi tarihini %1 e değiştirilsin mi?'
      ,zh_cn: '修改操作时间到%1？'
      ,hu: 'A kezelés időpontjának áthelyezése %1?'
      }
    ,'Change carbs time to %1 ?' : {
      cs: 'Změnit čas sacharidů na %1 ?'
      ,he: 'שנה זמן פחמימות ל %1 '
      ,ro: 'Schimbați ora carbohidraților cu %1 ?'
      ,de: 'Kohlenhydrat-Zeit ändern zu %1 ?'
      ,dk: 'Ændre kulhydratstid til %1 ?'
      ,es: 'Cambiar horario carbohidratos a %1 ?'
      ,fr: 'Modifier l\'horaire des glucides? Nouveau: %1'
      ,el: 'Αλλαγή του χρόνου πρόσληψης υδατανθράκων σε %1?'
      ,sv: 'Ändra kolhydratstid till %1 ?'
      ,nb: 'Endre Karbohydrattid til %1 ?'
      ,bg: 'Да променя ли времето на ВХ с %1?'
      ,hr: 'Promijeni vrijeme UGH na %1?'
      ,fi: 'Muuta hiilihydraattien aika? Uusi: %1'
      ,ru: 'Изменить время приема углеводов на % ?'
      ,sk: 'Zmeniť čas sacharidov na %1 ?'
      ,pl: 'Zmień czas węglowodanów na %1 ?'
      ,pt: 'Alterar horário do carboidrato para %1 ?'
      ,nl: 'Wijzig KH tijdstip naar %1'
      ,ko: '%1로 탄수화물 시간을 변경하세요.'
      ,it: 'Cambiare durata carboidrati a %1 ?'
      ,tr: 'Karbonhidrat zamanını %1 e değiştirilsin mi?'
      ,zh_cn: '修改碳水时间到%1？'
      ,hu: 'Szénhidrát időpontjának áthelyezése %1'
      }
    ,'Change insulin time to %1 ?' : {
      cs: 'Změnit čas inzulínu na %1 ?'
      ,he: 'שנה זמן אינסולין ל %1 '
      ,ro: 'Schimbați ora insulinei cu %1 ?'
      ,el: 'Αλλαγή του χρόνου χορήγησης ινσουλίνης σε %1 ?'
      ,es: 'Cambiar horario insulina a %1 ?'
      ,fr: 'Modifier l\'horaire de l\'insuline? Nouveau: %1'
      ,de: 'Insulinzeit ändern zu %1 ?'
      ,dk: 'Ændre insulintid til %1 ?'
      ,sv: 'Ändra insulintid till %1 ?'
      ,nb: 'Endre insulintid til %1 ?'
      ,bg: 'Да променя ли времето на инсулина с %1?'
      ,hr: 'Promijeni vrijeme inzulina na %1?'
      ,fi: 'Muuta insuliinin aika? Uusi: %1'
      ,ru: 'Изменить время подачи инсулина на % ?'
      ,sk: 'Zmeniť čas inzulínu na %1 ?'
      ,pl: 'Zmień czas insuliny na %1 ?'
      ,pt: 'Alterar horário da insulina para %1 ?'
      ,nl: 'Wijzig insuline tijdstip naar %1'
      ,ko: '%1로 인슐린 시간을 변경하세요.'
      ,it: 'Cambiare durata insulina a %1 ?'
      ,tr: 'İnsülin tarihini %1 e değiştirilsin mi?' // zamanı
      ,zh_cn: '修改胰岛素时间到%1？'
      ,hu: 'Inzulin időpont áthelyezése %1'
      }
    ,'Remove treatment ?' : {
      cs: 'Odstranit ošetření ?'
      ,he: 'בטל טיפול '
      ,ro: 'Ștergeți acțiunea?'
      ,de: 'Behandlung löschen?'
      ,dk: 'Fjern behandling ?'
      ,es: 'Eliminar tratamiento?'
      ,fr: 'Effacer le traitment?'
      ,el: 'Διαγραφή ενέργειας ?'
      ,sv: 'Ta bort behandling ?'
      ,nb: 'Fjern behandling ?'
      ,bg: 'Изтрий събитието'
      ,hr: 'Obriši tretman?'
      ,fi: 'Poista hoito?'
      ,ru: 'Удалить событие ?'
      ,sk: 'Odstrániť ošetrenie?'
      ,pl: 'Usunąć wydarzenie?'
      ,pt: 'Remover tratamento?'
      ,nl: 'Verwijder behandeling'
      ,ko: 'Treatment를 지우세요.'
      ,it: 'Rimuovere somministrazione ?'
      ,tr: 'Tedavi kaldırılsın mı? '
      ,zh_cn: '去除操作？'
      ,hu: 'Kezelés törlése?'
      }
    ,'Remove insulin from treatment ?' : {
      cs: 'Odstranit inzulín z ošetření ?'
      ,he: 'הסר אינסולין מהטיפול? '
      ,de: 'Insulin der Behandlung löschen?'
      ,dk: 'Fjern insulin fra behandling ?'
      ,es: 'Eliminar insulina del tratamiento?'
      ,fr: 'Effacer l\'insuline du traitement?'
      ,ro: 'Ștergeți insulina din acțiune?'
      ,el: 'Διαγραφή ινσουλίνης από την ενέργεια?'
      ,sv: 'Ta bort insulin från behandling ?'
      ,nb: 'Fjern insulin fra behandling ?'
      ,bg: 'Да изтрия ли инсулина от събитието?'
      ,hr: 'Obriši inzulin iz tretmana?'
      ,fi: 'Poista insuliini hoidosta?'
      ,ru: 'Удалить инсулин из событий ?'
      ,sk: 'Odstrániť inzulín z ošetrenia?'
      ,pl: 'Usunąć insulinę z wydarzenia?'
      ,pt: 'Remover insulina do tratamento?'
      ,nl: 'Verwijder insuline van behandeling'
      ,ko: 'Treatment에서 인슐린을 지우세요.'
      ,it: 'Rimuovere insulina dalla somministrazione ?'
      ,tr: 'İnsülini tedaviden çıkartılsın mı?'
      ,zh_cn: '从操作中去除胰岛素？'
      ,hu: 'Inzulin törlése a kezelésből?'
      }
    ,'Remove carbs from treatment ?' : {
      cs: 'Odstranit sacharidy z ošetření ?'
      ,he: 'הסר פחמימות מהטיפול? '
      ,ro: 'Ștergeți carbohidrații din acțiune?'
      ,de: 'Kohlenhydrate der Behandlung löschen?'
      ,dk: 'Fjern kulhydrater fra behandling ?'
      ,es: 'Eliminar carbohidratos del tratamiento?'
      ,fr: 'Effacer les glucides du traitement?'
      ,el: 'Διαγραφή των υδατανθράκων από την ενέργεια?'
      ,sv: 'Ta bort kolhydrater från behandling ?'
      ,nb: 'Fjern karbohydrater fra behandling ?'
      ,bg: 'Да изтрия ли ВХ от събитието?'
      ,hr: 'Obriši UGH iz tretmana?'
      ,fi: 'Poista hiilihydraatit hoidosta?'
      ,ru: 'Удалить углеводы из событий ?'
      ,sk: 'Odstrániť sacharidy z ošetrenia?'
      ,pl: 'Usunąć węglowodany z wydarzenia?'
      ,pt: 'Remover carboidratos do tratamento?'
      ,nl: 'Verwijder KH van behandeling'
      ,ko: 'Treatment에서 탄수화물을 지우세요.'
      ,it: 'Rimuovere carboidrati dalla somministrazione ?'
      ,tr: 'Karbonhidratları tedaviden çıkartılsın mı ?' // kaldırılsın mı
      ,zh_cn: '从操作中去除碳水化合物？'
      ,hu: 'Szénhidrát törlése a kezelésből?'
      }
    ,'Rendering' : {
      cs: 'Vykresluji'
      ,he: 'מציג... '
      ,ro: 'Se desenează'
      ,fr: 'Représentation graphique'
      ,de: 'Darstellung'
      ,dk: 'Rendering'
      ,es: 'Gráfica'
      ,el: 'Απεικόνιση'
      ,sv: 'Rendering'
      ,nb: 'Rendering'
      ,bg: 'Показване на графика'
      ,hr: 'Iscrtavanje'
      ,fi: 'Piirrän graafeja'
      ,ru: 'Построение графика'
      ,sk: 'Vykresľujem'
      ,pl: 'Rysuję'
      ,pt: 'Renderizando'
      ,nl: 'Renderen'
      ,ko: '랜더링'
      ,it: 'Traduzione'
      ,tr: 'Grafik oluşturuluyor...'
      ,zh_cn: '渲染'
      ,hu: 'Kirajzolás'
      }
    ,'Loading OpenAPS data of' : {
      cs: 'Nahrávám OpenAPS data z'
      ,he: 'טוען מידע מ '
      ,de: 'Lade OpenAPS-Daten von'
      ,dk: 'Henter OpenAPS data for'
      ,es: 'Cargando datos OpenAPS de'
      ,fr: 'Chargement des données OpenAPS de'
      ,ro: 'Se încarcă datele OpenAPS pentru'
      ,el: 'Φόρτωση δεδομένων OpenAPS'
      ,sv: 'Laddar OpenAPS data för'
      ,nb: 'Laster OpenAPS data for'
      ,bg: 'Зареждане на OpenAPS данни от'
      ,hr: 'Učitavanje OpenAPS podataka od'
      ,fi: 'Lataan OpenAPS tietoja'
      ,ru: 'Загрузка данных OpenAPS от'
      ,sk: 'Nahrávam OpenAPS dáta z'
      ,pl: 'Ładuję dane OpenAPS z'
      ,pt: 'Carregando dados de OpenAPS de'
      ,ko: 'OpenAPS 데이터 로딩'
      ,it: 'Caricamento in corso dati OpenAPS'
      ,nl: 'OpenAPS gegevens opladen van'
      ,tr: 'dan OpenAPS verileri yükleniyor'
      ,zh_cn: '载入OpenAPS数据从'
      ,hu: 'OpenAPS adatainak betöltése innen'
      }
    ,'Loading profile switch data' : {
      cs: 'Nahrávám data přepnutí profilu'
      ,he: 'טוען מידע החלפת פרופיל '
      ,de: 'Lade Daten Profil-Wechsel'
      ,dk: 'Henter ny profildata'
      ,es: 'Cargando el cambio de perfil de datos'
      ,fr: 'Chargement de données de changement de profil'
      ,el: 'Φόρτωση δεδομένων νέου προφίλ σε ισχύ'
      ,ro: 'Se încarcă datele de schimbare profil'
      ,sv: 'Laddar ny profildata'
      ,nb: 'Laster nye profildata'
      ,bg: 'Зареждане на данни от сменения профил'
      ,hr: 'Učitavanje podataka promjene profila'
      ,fi: 'Lataan profiilinvaihtotietoja'
      ,ru: 'Загрузка данных нового профиля'
      ,sk: 'Nahrávam dáta prepnutia profilu'
      ,pl: 'Ładuję dane zmienionego profilu'
      ,pt: 'Carregando dados de troca de perfil'
      ,ko: '프로파일 변환 데이터 로딩'
      ,it: 'Caricamento in corso dati cambio profilo'
      ,nl: 'Ophalen van data om profiel te wisselen'
      ,tr: 'Veri profili değişikliği yükleniyor'
      ,zh_cn: '载入配置文件交换数据'
      ,hu: 'Profil változás adatainak betöltése'
      }
    ,'Redirecting you to the Profile Editor to create a new profile.' : {
      cs: 'Chybě nastavený profil.\nNení definovaný žádný platný profil k času zobrazení.\nProvádím přesměrování na editor profilu.'
      ,he: 'הגדרת פרופיל שגוי. \n פרופיל מוגדר לזמן המוצג. מפנה מחדש לעורך פרופיל כדי ליצור פרופיל חדש. '
      ,el: 'Λάθος προφίλ. Παρακαλώ δημιουργήστε ένα νέο προφίλ'
      ,fr: 'Erreur de réglage de profil. \nAucun profil défini pour indiquer l\'heure. \nRedirection vers la création d\'un nouveau profil. '
      ,de: 'Sie werden zum Profil-Editor weitergeleitet, um ein neues Profil anzulegen.'
      ,dk: 'Forkert profilindstilling.\nIngen profil defineret til at vise tid.\nOmdirigere til profil editoren for at lave en ny profil.'
      ,es: 'Configuración incorrecta del perfil. \n No establecido ningún perfil en el tiempo mostrado. \n Continuar en editor de perfil para crear perfil nuevo.'
      ,bg: 'Грешни настройки на профила. \nНяма определен профил към избраното време. \nПрепращане към редактора на профила, за създаване на нов профил.'
      ,hr: 'Krive postavke profila.\nNiti jedan profil nije definiran za prikazano vrijeme.\nPreusmjeravanje u editor profila kako biste stvorili novi.'
      ,ro: 'Setare de profil eronată.\nNu este definit niciun profil pentru perioada afișată.\nMergeți la editorul de profiluri pentru a defini unul nou.'
      ,sv: 'Fel profilinställning.\nIngen profil vald för vald tid.\nOmdirigerar för att skapa ny profil.'
      ,nb: 'Feil profilinstilling.\nIngen profil valgt for valgt tid.\nVideresender for å lage ny profil.'
      ,fi: 'Väärä profiiliasetus tai profiilia ei löydy.\nSiirrytään profiilin muokkaamiseen uuden profiilin luontia varten.'
      ,ru: 'Переход к редактору профиля для создания нового'
      ,sk: 'Zle nastavený profil.\nK zobrazenému času nieje definovaný žiadny profil.\nPresmerovávam na vytvorenie profilu.'
      ,pl: 'Złe ustawienia profilu.\nDla podanego czasu nie zdefiniowano profilu.\nPrzekierowuję do edytora profili aby utworzyć nowy.'
      ,pt: 'Configuração de perfil incorreta. \nNão há perfil definido para mostrar o horário. \nRedirecionando para o editor de perfil para criar um perfil novo.'
      ,ko: '잘못된 프로파일 설정. \n프로파일이 없어서 표시된 시간으로 정의됩니다. 새 프로파일을 생성하기 위해 프로파일 편집기로 리다이렉팅'
      ,it: 'Impostazione errata del profilo. \nNessun profilo definito per visualizzare l\'ora. \nReindirizzamento al profilo editor per creare un nuovo profilo.'
      ,nl: 'Verkeerde profiel instellingen.\ngeen profiel beschibaar voor getoonde tijd.\nVerder naar profiel editor om een profiel te maken.'
      ,tr: 'Yanlış profil ayarı.\nGörüntülenen zamana göre profil tanımlanmamış.\nYeni profil oluşturmak için profil düzenleyicisine yönlendiriliyor.'
      ,zh_cn: '配置文件设置错误。\n没有配置文件定义为显示时间。\n返回配置文件编辑器以新建配置文件。'
      ,hu: 'Átirányítás a profil szerkesztőre, hogy egy új profilt készítsen'
      }
    ,'Pump' : {
      cs: 'Pumpa'
      ,he: 'משאבה '
      ,sk: 'Pumpa'
      ,fr: 'Pompe'
      ,sv: 'Pump'
      ,el: 'Αντλία'
      ,nb: 'Pumpe'
      ,de: 'Pumpe'
      ,dk: 'Pumpe'
      ,es: 'Bomba'
      ,bg: 'Помпа'
      ,hr: 'Pumpa'
      ,ro: 'Pompă'
      ,ru: 'Помпа'
      ,nl: 'Pomp'
      ,ko: '펌프'
      ,fi: 'Pumppu'
      , pl: 'Pompa'
      ,pt: 'Bomba'
      ,it: 'Pompa'
      ,tr: 'Pompa'
      ,zh_cn: '胰岛素泵'
      ,hu: 'Pumpa'
      }
    ,'Sensor Age' : {
      cs: 'Stáří senzoru (SAGE)'
      ,he: 'גיל סנסור סוכר '
      ,sk: 'Zavedenie senzoru (SAGE)'
      ,sv: 'Sensorålder (SAGE)'
      ,fr: 'Âge du senseur (SAGE)'
      ,el: 'Ημέρες χρήσης αισθητήρα (SAGE)'
      ,nb: 'Sensoralder (SAGE)'
      ,de: 'Sensor-Alter'
      ,dk: 'Sensor alder (SAGE)'
      ,es: 'Días uso del sensor'
      ,bg: 'Възраст на сензора (ВС)'
      ,hr: 'Starost senzora'
      ,ro: 'Vechimea senzorului'
      ,ru: 'Сенсор работает'
      ,nl: 'Sensor leeftijd'
      ,ko: '센서 사용 기간'
      ,fi: 'Sensorin ikä'
      , pl: 'Wiek sensora'
      ,pt: 'Idade do sensor'
      ,it: 'SAGE - Durata Sensore'
      ,tr: '(SAGE) Sensör yaşı '
      ,zh_cn: '探头使用时间（SAGE）'
      ,zh_tw: '探頭使用時間（SAGE）'
      ,hu: 'Szenzor élettartalma (SAGE)'
      }
    ,'Insulin Age' : {
      cs: 'Stáří inzulínu (IAGE)'
      ,he: 'גיל אינסולין '
      ,sk: 'Výmena inzulínu (IAGE)'
      ,fr: 'Âge de l\'insuline (IAGE)'
      ,el: 'Ημέρες χρήσης αμπούλας ινσουλίνης (IAGE)'
      ,sv: 'Insulinålder (IAGE)'
      ,nb: 'Insulinalder (IAGE)'
      ,de: 'Insulin-Alter'
      ,dk: 'Insulinalder (IAGE)'
      ,es: 'Días uso cartucho insulina'
      ,bg: 'Възраст на инсулина (ВИ)'
      ,hr: 'Starost inzulina'
      ,ro: 'Vechimea insulinei'
      ,ru: 'Инсулин работает'
      ,ko: '인슐린 사용 기간'
      ,fi: 'Insuliinin ikä'
      , pl: 'Wiek insuliny'
      ,pt: 'Idade da insulina'
      ,it: 'IAGE - Durata Insulina'
      ,nl: 'Insuline ouderdom (IAGE)'
      ,tr: '(IAGE) İnsülin yaşı'
      ,zh_cn: '胰岛素使用时间（IAGE）'
      ,zh_tw: '胰島素使用時間（IAGE）'
      ,hu: 'Inzulin élettartalma (IAGE)'
      }
    ,'Temporary target' : {
      cs: 'Dočasný cíl'
      ,he: 'מטרה זמנית '
      ,de: 'Temporäres Ziel'
      ,dk: 'Midlertidig mål'
      ,es: 'Objetivo temporal'
      ,fr: 'Cible temporaire'
      ,sk: 'Dočasný cieľ'
      ,el: 'Προσωρινός στόχος'
      ,sv: 'Tillfälligt mål'
      ,nb: 'Mildertidig mål'
      ,bg: 'Временна граница'
      ,hr: 'Privremeni cilj'
      ,ro: 'Țintă temporară'
      ,ru: 'Временная цель'
      ,nl: 'Tijdelijk doel'
      ,ko: '임시 목표'
      ,fi: 'Tilapäinen tavoite'
      , pl: 'Tymczasowy cel'
      ,pt: 'Meta temporária'
      ,it: 'Obiettivo Temporaneo'
      ,tr: 'Geçici hedef'
      ,zh_cn: '临时目标'
      ,hu: 'Átmeneti cél'
      }
    ,'Reason' : {
      cs: 'Důvod'
      ,he: 'סיבה '
      ,sk: 'Dôvod'
      ,fr: 'Raison'
      ,sv: 'Orsak'
      ,el: 'Αιτία'
      ,nb: 'Årsak'
      ,de: 'Begründung'
      ,dk: 'Årsag'
      ,es: 'Razonamiento'
      ,bg: 'Причина'
      ,hr: 'Razlog'
      ,nl: 'Reden'
      ,ro: 'Motiv'
      ,ru: 'Причина'
      ,ko: '근거'
      ,fi: 'Syy'
      , pl: 'Powód'
      ,pt: 'Razão'
      ,it: 'Ragionare'
      ,tr: 'Neden' // Gerekçe
      ,zh_cn: '原因'
      ,hu: 'Indok'
      }
    ,'Eating soon' : {
      cs: 'Následuje jídlo'
      ,he: 'אוכל בקרוב'
      ,sk: 'Jesť čoskoro'
      ,fr: 'Repas sous peu'
      ,sv: 'Snart matdags'
      ,nb: 'Snart tid for mat'
      ,de: 'Bald essen'
      ,dk: 'Spiser snart'
      ,es: 'Comer pronto'
      ,bg: 'Ядене скоро'
      ,hr: 'Uskoro jelo'
      ,ro: 'Mâncare în curând'
      ,ru: 'Ожидаемый прием пищи'
      ,nl: 'Binnenkort eten'
      ,ko: '편집 중'
      ,fi: 'Syödään pian'
      , pl: 'Zjedz wkrótce'
      ,pt: 'Refeição em breve'
      ,it: 'Mangiare prossimamente'
      ,tr: 'Yakında yemek'  // Kısa zamanda yemek yenecek
      ,zh_cn: '接近用餐时间'
      ,hu: 'Hamarosan eszem'
      }
    ,'Top' : {
      cs: 'Horní'
      ,he: 'למעלה '
      ,sk: 'Vrchná'
      ,fr: 'Haut'
      ,sv: 'Toppen'
      ,el: 'Πάνω'
      ,nb: 'Øverst'
      ,de: 'Oben'
      ,dk: 'Toppen'
      ,es: 'Superior'
      ,bg: 'Горе'
      ,hr: 'Vrh'
      ,ro: 'Deasupra'
      ,ru: 'Верх'
      ,nl: 'Boven'
      ,ko: '최고'
      ,fi: 'Ylä'
      ,pt: 'Superior'
      ,it: 'Superiore'
      ,tr: 'Üst'
      ,zh_cn: '顶部'
      ,pl: 'Góra'
      ,hu: 'Felső'
      }
    ,'Bottom' : {
      cs: 'Dolní'
      ,he: 'למטה '
      ,sk: 'Spodná'
      ,sv: 'Botten'
      ,fr: 'Bas'
      ,el: 'Κάτω'
      ,nb: 'Nederst'
      ,de: 'Unten'
      ,dk: 'Bunden'
      ,es: 'Inferior'
      ,bg: 'Долу'
      ,hr: 'Dno'
      ,ro: 'Sub'
      ,ru: 'Низ'
      ,nl: 'Beneden'
      ,ko: '최저'
      ,fi: 'Ala'
      ,pt: 'Inferior'
      ,it: 'Inferiore'
      ,tr: 'Alt' //aşağı, alt, düşük
      ,zh_cn: '底部'
      ,pl: "Dół"
      ,hu: "Alsó"
      }
    ,'Activity' : {
      cs: 'Aktivita'
      ,he: 'פעילות '
      ,sk: 'Aktivita'
      ,fr: 'Activité'
      ,sv: 'Aktivitet'
      ,el: 'Δραστηριότητα'
      ,nb: 'Aktivitet'
      ,de: 'Aktivität'
      ,dk: 'Aktivitet'
      ,es: 'Actividad'
      ,bg: 'Активност'
      ,hr: 'Aktivnost'
      ,ro: 'Activitate'
      ,ru: 'Активность'
      ,nl: 'Activiteit'
      ,ko: '활성도'
      ,fi: 'Aktiviteetti'
      ,pt: 'Atividade'
      ,it: 'Attività'
      ,tr: 'Aktivite'
      ,zh_cn: '有效的'
      ,pl: 'Aktywność'
      ,hu: 'Aktivitás'
      }
    ,'Targets' : {
      cs: 'Cíl'
      ,he: 'מטרות '
      ,sk: 'Ciele'
      ,fr: 'Cibles'
      ,el: 'Στόχοι'
      ,de: 'Ziele'
      ,dk: 'Mål'
      ,es: 'Objetivos'
      ,sv: 'Mål'
      ,nb: 'Mål'
      ,bg: 'Граници'
      ,hr: 'Ciljevi'
      ,ro: 'Ținte'
      ,ru: 'Цели'
      ,nl: 'Doelen'
      ,ko: '목표'
      ,fi: 'Tavoitteet'
      ,pt: 'Metas'
      ,it: 'Obiettivi'
      ,tr: 'Hedefler'
      ,zh_cn: '目标'
      ,pl: 'Cele'
      ,hu: 'Célok'
      }
    ,'Bolus insulin:' : {
      cs: 'Bolusový inzulín:'
      ,he: 'אינסולין בולוס '
      ,bg: 'Болус инсулин'
      ,fr: 'Bolus d\'Insuline'
      ,ro: 'Insulină bolusată:'
      ,el: 'Ινσουλίνη'
      ,es: 'Bolo de Insulina'
      ,ru: 'Болюсный инсулин'
      ,sv: 'Bolusinsulin:'
      ,nb: 'Bolusinsulin:'
      ,hr: 'Bolus:'
      ,ko: 'Bolus 인슐린'
      ,fi: 'Bolusinsuliini:'
      ,de: 'Bolus Insulin:'
      ,dk: 'Bolusinsulin:'
      ,pt: 'Insulina de bolus'
      ,sk: 'Bolusový inzulín:'
      ,it: 'Insulina Bolo'
      ,nl: 'Bolus insuline'
      ,tr: 'Bolus insülin:'
      ,zh_cn: '大剂量胰岛素'
      ,pl: 'Bolus insuliny'
      ,hu: 'Bólus inzulin'
      }
    ,'Base basal insulin:' : {
      cs: 'Základní bazální inzulín:'
      ,he: 'אינסולין בזלי בסיס '
      ,bg: 'Основен базален инсулин'
      ,fr: 'Débit basal de base'
      ,ro: 'Bazala obișnuită:'
      ,el: 'Βασική Ινσουλίνη'
      ,es: 'Insulina basal básica'
      ,ru: 'Профильный базальный инсулин'
      ,sv: 'Basalinsulin:'
      ,nb: 'Basalinsulin:'
      ,hr: 'Osnovni bazal:'
      ,ko: '기본 basal 인슐린'
      ,fi: 'Basaalin perusannos:'
      ,de: 'Basis Basal Insulin:'
      ,dk: 'Basalinsulin:'
      ,pt: 'Insulina basal programada:'
      ,sk: 'Základný bazálny inzulín:'
      ,it: 'Insulina Basale:'
      ,nl: 'Basis basaal insuline'
      ,tr: 'Temel bazal insülin'
      ,zh_cn: '基础率胰岛素'
      ,pl: 'Bazowa dawka insuliny'
      ,hu: 'Általános bazal inzulin'
      }
    ,'Positive temp basal insulin:' : {
      cs: 'Pozitivní dočasný bazální inzulín:'
      ,he: 'אינסולין בזלי זמני חיובי '
      ,bg: 'Положителен временен базален инсулин'
      ,fr: 'Débit basal temporaire positif'
      ,ro: 'Bazala temporară marită:'
      ,es: 'Insulina Basal temporal positiva:'
      ,el: 'Θετική βασική ινσουλίνη'
      ,ru: 'Положит знач временн базал инс '
      ,sv: 'Positiv tempbasal insulin:'
      ,nb: 'Positiv midlertidig basalinsulin:'
      ,hr: 'Pozitivni temp bazal:'
      ,ko: '초과된 임시 basal 인슐린'
      ,fi: 'Positiivinen tilapäisbasaali:'
      ,de: 'Positives temporäres Basal Insulin:'
      ,dk: 'Positiv midlertidig basalinsulin:'
      , pl: 'Zwiększona bazowa dawka insuliny'
      ,pt: 'Insulina basal temporária positiva:'
      ,sk: 'Pozitívny dočasný bazálny inzulín:'
      ,it: 'Insulina basale temp positiva:'
      ,nl: 'Extra tijdelijke basaal insuline'
      ,tr: 'Pozitif geçici bazal insülin:'
      ,zh_cn: '实际临时基础率胰岛素'
      ,hu: 'Pozitív átmeneti bazál inzulin'
      }
    ,'Negative temp basal insulin:' : {
      cs:'Negativní dočasný bazální inzulín:'
      ,he: 'אינסולין בזלי זמני שלילי '
      ,bg: 'Отрицателен временен базален инсулин'
      ,fr: 'Débit basal temporaire négatif'
      ,ro: 'Bazala temporară micșorată:'
      ,el: 'Αρνητική βασική ινσουλίνη'
      ,ru: 'Отриц знач временн базал инс'
      ,sv: 'Negativ tempbasal för insulin:'
      ,es: 'Insulina basal temporal negativa:'
      ,nb: 'Negativ midlertidig basalinsulin:'
      ,hr: 'Negativni temp bazal:'
      ,ko: '남은 임시 basal 인슐린'
      ,fi: 'Negatiivinen tilapäisbasaali:'
      ,de: 'Negatives temporäres Basal Insulin:'
      ,dk: 'Negativ midlertidig basalinsulin:'
      , pl: 'Zmniejszona bazowa dawka insuliny'
      ,pt: 'Insulina basal temporária negativa:'
      ,sk: 'Negatívny dočasný bazálny inzulín:'
      ,it: 'Insulina basale Temp negativa:'
      ,nl: 'Negatieve tijdelijke basaal insuline'
      ,tr: 'Negatif geçici bazal insülin:'
      ,zh_cn: '其余临时基础率胰岛素'
      ,hu: 'Negatív átmeneti bazál inzulin'
      }
    ,'Total basal insulin:' : {
      cs: 'Celkový bazální inzulín:'
      ,he: 'סך אינסולין בזלי '
      ,bg: 'Общо базален инсулин'
      ,fr: 'Insuline basale au total:'
      ,ro: 'Bazala totală:'
      ,es: 'Total Insulina basal:'
      ,el: 'Συνολική Βασική Ινσουλίνη (BASAL)'
      ,ru: 'Всего базал инсулина'
      ,sv: 'Total dagsdos basalinsulin:'
      ,nb: 'Total daglig basalinsulin:'
      ,hr: 'Ukupno bazali:'
      ,ko: '전체 basal 인슐린'
      ,fi: 'Basaali yhteensä:'
      ,de: 'Gesamt Basal Insulin:'
      ,dk: 'Total daglig basalinsulin:'
      , pl: 'Całkowita ilość bazowej dawki insuliny'
      ,pt: 'Insulina basal total:'
      ,sk: 'Celkový bazálny inzulín:'
      ,it: 'Insulina Basale Totale:'
      ,nl: 'Totaal basaal insuline'
      ,tr: 'Toplam bazal insülin:'
      ,zh_cn: '基础率胰岛素合计'
      ,hu: 'Teljes bazál inzulin'
      }
    ,'Total daily insulin:' : {
      cs:'Celkový denní inzulín:'
      ,he: 'סך אינסולין ביום '
      ,bg: 'Общо инсулин за деня'
      ,fr: 'Insuline totale journalière'
      ,ro: 'Insulina totală zilnică:'
      ,el: 'Συνολική Ημερήσια Ινσουλίνη'
      ,ru: 'Всего суточн базал инсулина'
      ,sv: 'Total dagsdos insulin'
      ,nb: 'Total daglig insulin'
      ,hr: 'Ukupno dnevni inzulin:'
      ,ko: '하루 인슐린 총량'
      ,fi: 'Päivän kokonaisinsuliiniannos'
      ,de: 'Gesamtes tägliches Insulin:'
      ,dk: 'Total dagsdosis insulin'
      ,es: 'Total Insulina diaria:'
      ,pl: 'Całkowita dzienna ilość insuliny'
      ,pt: 'Insulina diária total:'
      ,sk: 'Celkový denný inzulín:'
      ,it: 'Totale giornaliero d\'insulina:'
      ,nl: 'Totaal dagelijkse insuline'
      ,tr: 'Günlük toplam insülin:'
      ,zh_cn: '每日胰岛素合计'
      ,hu: 'Teljes napi inzulin'
      }
    ,'Unable to %1 Role' : { // PUT or POST
      cs: 'Chyba volání %1 Role:'
      ,he: 'לא יכול תפקיד %1'
      ,bg: 'Невъзможно да %1 Роля'
      ,hr: 'Unable to %1 Role'
      ,fr: 'Incapable de %1 rôle'
      ,ro: 'Imposibil de %1 Rolul'
      ,es: 'Incapaz de %1 Rol'
      ,ru: 'Невозможно назначить %1 Роль'
      ,sv: 'Kan inte ta bort roll %1'
      ,nb: 'Kan ikke %1 rolle'
      ,fi: '%1 operaatio roolille opäonnistui'
      ,de: 'Unpassend zu %1 Rolle'
      ,dk: 'Kan ikke slette %1 rolle'
      ,pl: 'Nie można %1 roli'
      ,pt: 'Função %1 não foi possível'
      ,sk: 'Chyba volania %1 Role'
      ,ko: '%1로 비활성'
      ,it: 'Incapace di %1 Ruolo'
      ,nl: 'Kan %1 rol niet verwijderen'
      ,tr: '%1 Rolü yapılandırılamadı'
      ,zh_cn: '%1角色不可用'
      ,hu: 'Hiba a %1 szabály hívásánál'
      }
    ,'Unable to delete Role' : {
      cs: 'Nelze odstranit Roli:'
      ,he: 'לא יכול לבטל תפקיד '
      ,bg: 'Невъзможно изтриването на Роля'
      ,hr: 'Ne mogu obrisati ulogu'
      ,ro: 'Imposibil de șters Rolul'
      ,fr: 'Effacement de rôle impossible'
      ,ru: 'Невозможно удалить Роль'
      ,sv: 'Kan ej ta bort roll'
      ,es: 'Imposible elimar Rol'
      ,nb: 'Kan ikke ta bort rolle'
      ,fi: 'Roolin poistaminen epäonnistui'
      ,de: 'Rolle nicht löschbar'
      ,dk: 'Kan ikke slette rolle'
      ,pt: 'Não foi possível apagar a Função'
      ,sk: 'Rola sa nedá zmazať'
      ,ko: '삭제로 비활성'
      ,it: 'Incapace di eliminare Ruolo'
      ,nl: 'Niet mogelijk rol te verwijderen'
      ,tr: 'Rol silinemedi'
      ,zh_cn: '无法删除角色'
      ,pl: 'Nie można usunąć roli'
      ,hu: 'Nem lehetett a Szerepet törölni'
      }
    ,'Database contains %1 roles' : {
      cs: 'Databáze obsahuje %1 rolí'
      ,he: 'בסיס הנתונים מכיל %1 תפקידים '
      ,bg: 'Базата данни съдържа %1 роли'
      ,hr: 'baza sadrži %1 uloga'
      ,ro: 'Baza de date conține %1 rol(uri)'
      ,fr: 'La base de données contient %1 rôles'
      ,ru: 'База данных содержит %1 Ролей'
      ,sv: 'Databasen innehåller %1 roller'
      ,es: 'Base de datos contiene %1 Roles'
      ,nb: 'Databasen inneholder %1 roller'
      ,fi: 'Tietokanta sisältää %1 roolia'
      ,de: 'Datenbank enthält %1 Rollen'
      ,dk: 'Databasen indeholder %1 roller'
      , pl: 'Baza danych zawiera %1 ról'
      ,pt: 'Banco de dados contém %1 Funções'
      ,sk: 'Databáza obsahuje %1 rolí'
      ,ko: '데이터베이스가 %1 포함'
      ,it: 'Database contiene %1 ruolo'
      ,nl: 'Database bevat %1 rollen'
      ,tr: 'Veritabanı %1 rol içerir'
      ,zh_cn: '数据库包含%1个角色'
      ,hu: 'Az adatbázis %1 szerepet tartalmaz'
      }
    ,'Edit Role' : {
      cs:'Editovat roli'
      ,he: 'ערוך תפקיד '
      ,bg: 'Промени Роля'
      ,hr: 'Uredi ulogu'
      ,ro: 'Editează Rolul'
      ,fr: 'Éditer le rôle'
      ,ru: 'Редактировать Роль'
      ,sv: 'Editera roll'
      ,nb: 'Editer rolle'
      ,fi: 'Muokkaa roolia'
      ,de: 'Rolle editieren'
      ,dk: 'Rediger rolle'
      ,es: 'Editar Rol'
      ,pl: 'Edycja roli'
      ,pt: 'Editar Função'
      ,sk: 'Editovať rolu'
      ,ko: '편집 모드'
      ,it: 'Modifica ruolo'
      ,nl: 'Pas rol aan'
      ,tr: 'Rolü düzenle'
      ,zh_cn: '编辑角色'
      ,hu: 'Szerep szerkesztése'
      }
    ,'admin, school, family, etc' : {
      cs: 'administrátor, škola, rodina atd...'
      ,he: 'מנהל, בית ספר, משפחה, וכו  '
      ,bg: 'администратор,училище,семейство и т.н.'
      ,hr: 'admin, škola, obitelj, itd.'
      ,fr: 'Administrateur, école, famille, etc'
      ,ro: 'administrator, școală, familie, etc'
      ,ru: 'админ, школа, семья и т д'
      ,es: 'Adminitrador, escuela, família, etc'
      ,sv: 'Administratör, skola, familj, etc'
      ,nb: 'Administrator, skole, familie osv'
      ,fi: 'ylläpitäjä, koulu, perhe jne'
      ,de: 'Administrator, Schule, Familie, etc'
      ,dk: 'Administrator, skole, familie, etc'
      ,pl: 'administrator, szkoła, rodzina, itp'
      ,pt: 'Administrador, escola, família, etc'
      ,sk: 'administrátor, škola, rodina atď...'
      ,ko: '관리자, 학교, 가족 등'
      ,it: 'amministrazione, scuola, famiglia, etc'
      ,nl: 'admin, school, familie, etc'
      ,tr: 'yönetici, okul, aile, vb'
      ,zh_cn: '政府、学校、家庭等'
      ,hu: 'admin, iskola, család, stb'
      }
    ,'Permissions' : {
      cs: 'Oprávnění'
      ,he: 'הרשאות '
      ,bg: 'Права'
      ,hr: 'Prava'
      ,ro: 'Permisiuni'
      ,fr: 'Permissions'
      ,ru: 'Разрешения'
      ,sv: 'Rättigheter'
      ,nb: 'Rettigheter'
      ,fi: 'Oikeudet'
      ,de: 'Berechtigungen'
      ,dk: 'Rettigheder'
      ,es: 'Permisos'
      ,pl: 'Uprawnienia'
      ,pt: 'Permissões'
      ,sk: 'Oprávnenia'
      ,ko: '허가'
      ,it: 'Permessi'
      ,nl: 'Rechten'
      ,tr: 'İzinler'
      ,zh_cn: '权限'
      ,hu: 'Engedély'
      }
    ,'Are you sure you want to delete: ' : {
      cs: 'Opravdu vymazat: '
      ,he: 'אתה בטוח שאתה רוצה למחוק'
      ,bg: 'Потвърдете изтриването'
      ,hr: 'Sigurno želite obrisati?'
      ,ro: 'Confirmați ștergerea: '
      ,fr: 'Êtes-vous sûr de vouloir effacer:'
      ,ru: 'Подтвердите удаление'
      ,sv: 'Är du säker att du vill ta bort:'
      ,nb: 'Er du sikker på at du vil slette:'
      ,fi: 'Oletko varmat että haluat tuhota: '
      ,de: 'Sind sie sicher, das Sie löschen wollen:'
      ,dk: 'Er du sikker på at du vil slette:'
      ,es: 'Seguro que quieres eliminarlo:'
      ,pl: 'Jesteś pewien, że chcesz usunąć:'
      ,pt: 'Tem certeza de que deseja apagar:'
      ,sk: 'Naozaj zmazať:'
      ,ko: '정말로 삭제하시겠습니까: '
      ,it: 'Sei sicuro di voler eliminare:'
      ,nl: 'Weet u het zeker dat u wilt verwijderen?'
      ,tr: 'Silmek istediğinizden emin misiniz:'
      ,zh_cn: '你确定要删除：'
      ,hu: 'Biztos, hogy törölni szeretnéd: '
      }
    ,'Each role will have a 1 or more permissions.  The <em>*</em> permission is a wildcard, permissions are a hierarchy using <em>:</em> as a separator.' : {
      cs: 'Každá role má 1 nebo více oprávnění. Oprávnění <em>*</em> je zástupný znak, oprávnění jsou hiearchie používající <em>:</em> jako oddělovač.'
      ,bg: 'Всяка роля ще има 1 или повече права. В <em> * </em> правото е маска, правата са йерархия използвайки <em>:</em> като разделител'
      ,hr: 'Svaka uloga ima 1 ili više prava. Pravo <em>*</em> je univerzalno, a prava su hijerarhija koja koristi znak <em>:</em> kao graničnik.'
      ,fr: 'Chaque rôle aura une ou plusieurs permissions. La permission <em>*</em> est un joker (permission universelle), les permissions sont une hierarchie utilisant <em>:</em> comme séparatuer'
      ,ro: 'Fiecare rol va avea cel puțin o permisiune. Permisiunea <em>*</em> este totală, permisiunile sunt ierarhice utilizând <em>:</em> pe post de separator.'
      ,ru: 'Каждая роль имеет 1 или более разрешений. Разрешение <em>*</em> это подстановочный символ, разрешения это иерархия, использующая <em>:</em> как разделитель.'
      ,sv: 'Varje roll kommer få en eller flera rättigheter. <em>*</em> är en wildcard, rättigheter sätts hirarkiskt med <em>:</em> som avgränsare.'
      ,dk: 'Hver rolle vil have en eller flere rettigheder. Rollen <em>*</em> fungere som wildcard / joker, rettigheder sættes hierakisk med <em>:</em> som skilletegn.'
      ,nb: 'Hver enkelt rolle vil ha en eller flere rettigheter. <em>*</em>-rettigheten er wildcard. Rettigheter settes hierarkisk med <em>:</em> som separator.'
      ,fi: 'Jokaisella roolilla on yksi tai useampia oikeuksia. <em>*</em> on jokeri (tunnistuu kaikkina oikeuksina), oikeudet ovat hierarkia joka käyttää <em>:</em> merkkiä erottimena.'
      ,de: 'Jede Rolle hat eine oder mehrere Berechtigungen. Die <em>*</em> Berechtigung ist ein Platzhalter, Berechtigungen sind hierachrchisch mit <em>:</em> als Separator.'
      ,es: 'Cada Rol tiene uno o más permisos. El permiso <em>*</em> es un marcador de posición y los permisos son jerárquicos con <em>:</em> como separador.'
      , pl: 'Każda rola będzie mieć 1 lub więcej uprawnień. Symbol <em>*</em> uprawnia do wszystkiego. Uprawnienia są hierarchiczne, używając <em>: </em> jako separatora.'
      ,pt: 'Cada função terá uma ou mais permissões. A permissão <em>*</em> é um wildcard, permissões são uma hierarquia utilizando <em>*</em> como um separador.'
      ,sk: 'Každá rola má 1 alebo viac oprávnení. Oprávnenie <em>*</em> je zástupný znak, oprávnenia sú hierarchie používajúce <em>:</em> ako oddelovač.'
      ,ko: '각각은 1 또는 그 이상의 허가를 가지고 있습니다. 허가는 예측이 안되고 구분자로 <em>:</em> 사용한 계층이 있습니다'
      ,it: 'Ogni ruolo avrà un 1 o più autorizzazioni. Il <em>*</em> il permesso è un jolly, i permessi sono una gerarchia utilizzando <em>:</em> come separatore.'
      ,nl: 'Elke rol heeft mintens 1 machtiging.  De <em>*</em> machtiging is een wildcard, machtigingen hebben een hyrarchie door gebruik te maken van <em>:</em> als scheidingsteken.'
      ,tr: 'Her rolün bir veya daha fazla izni vardır.<em>*</em>izni bir yer tutucudur ve izinler ayırıcı olarak <em>:</em> ile hiyerarşiktir.'
      ,zh_cn: '每个角色都具有一个或多个权限。权限设置时使用<em>*</em>作为通配符，层次结构使用<em>:</em>作为分隔符。'
      ,hu: 'Minden szerepnek egy vagy több engedélye van. A <em>*</em> engedély helyettesítő engedély amely a hierarchiához <em>:</em> használja elválasztásnak.'
    }
    ,'Add new Role' : {
      cs: 'Přidat novou roli'
      ,he: 'הוסף תפקיד חדש '
      ,bg: 'Добавете нова роля'
      ,hr: 'Dodaj novu ulogu'
      ,fr: 'Ajouter un nouveau rôle'
      ,ro: 'Adaugă un Rol nou'
      ,ru: 'Добавить новую Роль'
      ,es: 'Añadir nuevo Rol'
      ,sv: 'Lägg till roll'
      ,nb: 'Legg til ny rolle'
      ,fi: 'Lisää uusi rooli'
      ,de: 'Eine neue Rolle hinzufügen'
      ,dk: 'Tilføj ny rolle'
      ,pt: 'Adicionar novo Papel'
      ,sk: 'Pridať novú rolu'
      ,ko: '새 역할 추가'
      ,it: 'Aggiungere un nuovo ruolo'
      ,nl: 'Voeg rol toe'
      ,tr: 'Yeni Rol ekle'
      ,zh_cn: '添加新角色'
      ,pl: 'Dodaj nową rolę'
      ,hu: 'Új szerep hozzáadása'
    }
    ,'Roles - Groups of People, Devices, etc' : {
      cs: 'Role - Skupiny lidí, zařízení atd.'
      ,he: 'תפקידים - קבוצות של אנשים, התקנים, וכו '
      ,bg: 'Роли - Група хора,устройства,т.н.'
      ,hr: 'Uloge - Grupe ljudi, uređaja, itd.'
      ,fr: 'Rôles - Groupe de Personnes ou d\'appareils'
      ,ro: 'Roluri - Grupuri de persoane, dispozitive, etc'
      ,es: 'Roles - Grupos de Gente, Dispositivos, etc.'
      ,ru: 'Роли- группы людей, устройств и т п'
      ,sv: 'Roller - Grupp av användare, Enheter, etc'
      ,nb: 'Roller - Grupper av brukere, enheter osv'
      ,fi: 'Roolit - Ihmisten ja laitteiden muodostamia ryhmiä'
      ,de: 'Rollen - Gruppierungen von Menschen, Geräten, etc.'
      ,dk: 'Roller - Grupper af brugere, Enheder, etc'
      ,pt: 'Funções - grupos de pessoas, dispositivos, etc'
      ,sk: 'Role - skupiny ľudí, zariadení atď...'
      ,ko: '역할 - 그룹, 기기, 등'
      ,it: 'Ruoli - gruppi di persone, dispositivi, etc'
      ,nl: 'Rollen - Groepen mensen apparaten etc'
      ,tr: 'Roller - İnsan grupları, Cihazlar vb.'
      ,zh_cn: '角色 - 一组人或设备等'
      ,pl: 'Role - Grupy ludzi, urządzeń, itp'
      ,hu: 'Szerepek - Emberek csoportja, berendezések, stb.'
    }
    ,'Edit this role' : {
      cs: 'Editovat tuto roli'
      ,he: 'ערוך תפקיד זה '
      ,bg: 'Промени тази роля'
      ,hr: 'Uredi ovu ulogu'
      ,fr: 'Editer ce rôle'
      ,ro: 'Editează acest rol'
      ,ru: 'Редактировать эту роль'
      ,es: 'Editar este Rol'
      ,sv: 'Editera denna roll'
      ,nb: 'Editer denne rollen'
      ,fi: 'Muokkaa tätä roolia'
      ,de: 'Editiere diese Rolle'
      ,dk: 'Rediger denne rolle'
      ,pt: 'Editar esta Função'
      ,sk: 'Editovať túto rolu'
      ,ko: '이 역할 편집'
      ,it: 'Modifica questo ruolo'
      ,nl: 'Pas deze rol aan'
      ,tr: 'Bu rolü düzenle'
      ,zh_cn: '编辑角色'
      ,pl: 'Edytuj rolę'
      ,hu: 'Szerep szerkesztése'
    }
    ,'Admin authorized' : {
      cs: 'Admin autorizován'
      ,he: 'מנהל אושר '
      ,bg: 'Оторизиран като администратор'
      ,hr: 'Administrator ovlašten'
      ,fr: 'Administrateur autorisé'
      ,ro: 'Autorizat de admin'
      ,es: 'Administrador autorizado'
      ,ru: 'Разрешено администратором'
      ,sv: 'Administratorgodkänt'
      ,nb: 'Administratorgodkjent'
      ,fi: 'Ylläpitäjä valtuutettu'
      ,de: 'als Administrator autorisiert'
      ,dk: 'Administrator godkendt'
      ,pt: 'Administrador autorizado'
      ,sk: 'Admin autorizovaný'
      ,ko: '인증된 관리자'
      ,it: 'Amministrativo autorizzato'
      ,nl: 'Admin geauthoriseerd'
      ,tr: 'Yönetici yetkilendirildi'
      ,zh_cn: '已授权'
      ,zh_tw: '已授權'
      ,pl: 'Administrator autoryzowany'
      ,hu: 'Adminisztrátor engedélyezve'
    }
    ,'Subjects - People, Devices, etc' : {
      cs: 'Subjekty - Lidé, zařízení atd.'
      ,he: 'נושאים - אנשים, התקנים וכו '
      ,bg: 'Субекти - Хора,Устройства,т.н.'
      ,hr: 'Subjekti - Ljudi, uređaji, itd.'
      ,fr: 'Utilisateurs - Personnes, Appareils, etc'
      ,ro: 'Subiecte - Persoane, dispozitive, etc'
      ,es: 'Sujetos - Personas, Dispositivos, etc'
      ,ru: 'Субъекты - Люди, устройства и т п'
      ,sv: 'Ämnen - Användare, Enheter, etc'
      ,nb: 'Ressurser - Brukere, enheter osv'
      ,fi: 'Käyttäjät (Ihmiset, laitteet jne)'
      ,de: 'Subjekte - Menschen, Geräte, etc'
      ,dk: 'Emner - Brugere, Enheder, etc'
      ,pt: 'Assunto - Pessoas, dispositivos, etc'
      ,sk: 'Subjekty - ľudia, zariadenia atď...'
      ,ko: '주제 - 사람, 기기 등'
      ,it: 'Soggetti - persone, dispositivi, etc'
      ,nl: 'Onderwerpen - Mensen, apparaten etc'
      ,tr: 'Konular - İnsanlar, Cihazlar, vb.'
      ,zh_cn: '用户 - 人、设备等'
      ,pl: 'Obiekty - ludzie, urządzenia itp' 
      ,hu: 'Semélyek - Emberek csoportja, berendezések, stb.'
    }
    ,'Each subject will have a unique access token and 1 or more roles.  Click on the access token to open a new view with the selected subject, this secret link can then be shared.' : {
      cs: 'Každý subjekt má svůj unikátní token a 1 nebo více rolí. Klikem na přístupový token se otevře nové okno pro tento subjekt. Tento link je možné sdílet.'
      ,he: 'לכל נושא תהיה אסימון גישה ייחודי ותפקיד אחד או יותר. לחץ על אסימון הגישה כדי לפתוח תצוגה חדשה עם הנושא הנבחר, קישור סודי זה יכול להיות משותף '
      ,bg: 'Всеки обект ще има уникален ключ за достъп и 1 или повече роли. Кликнете върху ключа за достъп, за да отворите нов изглед с избрания обект, тази секретна връзка може след това да се споделя'
      ,hr: 'Svaki subjekt će dobiti jedinstveni pristupni token i jednu ili više uloga. Kliknite na pristupni token kako bi se otvorio novi pogled sa odabranim subjektom, a tada se tajni link može podijeliti.'
      ,fr: 'Chaque utilisateur aura un identificateur unique et un ou plusieurs rôles. Cliquez sur l\'identificateur pour révéler l\'utilisateur, ce lien secret peut être partagé.'
      ,ro: 'Fiecare subiect va avea o cheie unică de acces și unul sau mai multe roluri. Apăsați pe cheia de acces pentru a vizualiza subiectul selectat, acest link poate fi distribuit.'
      ,ru: 'Каждый субъект получает уникальный код доступа и 1 или более ролей. Нажмите на иконку кода доступа чтобы открыть новое окно с выбранным субъектом, эту секретную ссылку можно переслать.'
      ,es: 'Cada sujeto tendrá un identificador de acceso único y 1 o más roles. Haga clic en el identificador de acceso para abrir una nueva vista con el tema seleccionado, este enlace secreto puede ser compartido.'
      ,sv: 'Varje ämne får en unik säkerhetsnyckel och en eller flera roller. Klicka på accessnyckeln för att öppna en ny vy med det valda ämnet, denna hemliga länk kan sedan delas.'
      ,nb: 'Hver enkelt ressurs får en unik sikkerhetsnøkkel og en eller flere roller. Klikk på sikkerhetsnøkkelen for å åpne valgte ressurs, den hemmelige lenken kan så deles.'
      ,fi: 'Jokaisella käyttäjällä on uniikki pääsytunniste ja yksi tai useampi rooli. Klikkaa pääsytunnistetta nähdäksesi käyttäjän, jolloin saat jaettavan osoitteen tämän käyttäjän oikeuksilla.'
      ,de: 'Jedes Subjekt erhält einen einzigartigen Zugriffsschlüssel und eine oder mehrere Rollen. Klicke auf den Zugriffsschlüssel, um eine neue Ansicht mit dem ausgewählten Subjekt zu erhalten. Dieser geheime Link kann geteilt werden.'
      ,dk: 'Hvert emne vil have en unik sikkerhedsnøgle samt en eller flere roller. Klik på sikkerhedsnøglen for at åbne et nyt view med det valgte emne, dette hemmelige link kan derefter blive delt.'
      ,pt: 'Cada assunto terá um único token de acesso e uma ou mais Funções. Clique no token de acesso para abrir uma nova visualização com o assunto selecionado. Este link secreto poderá então ser compartilhado'
      ,sk: 'Každý objekt má svoj unikátny prístupový token a 1 alebo viac rolí. Klikni na prístupový token pre otvorenie nového okna pre tento subjekt. Tento link je možné zdielať.'
      ,ko: '각 주제는 유일한 access token을 가지고 1 또는 그 이상의 역할을 가질 것이다. 선택된 주제와 새로운 뷰를 열기 위해 access token을 클릭하세요. 이 비밀 링크는 공유되어질 수 있다.'
      ,it: 'Ogni soggetto avrà un gettone d\'accesso unico e 1 o più ruoli. Fare clic sul gettone d\'accesso per aprire una nuova vista con il soggetto selezionato, questo legame segreto può quindi essere condiviso.'
      ,nl: 'Elk onderwerp heeft een unieke toegangscode en 1 of meer rollen. Klik op de toegangscode om een nieu venster te openen om het onderwerp te bekijken, deze verborgen link kan gedeeld worden.'
      ,tr: 'Her konu benzersiz bir erişim anahtarı ve bir veya daha fazla rol alır. Seçilen konuyla ilgili yeni bir görünüm elde etmek için erişim tuşuna tıklayın. Bu gizli bağlantı paylaşılabilinir.'
      ,zh_cn: '每个用户具有唯一的访问令牌和一个或多个角色。在访问令牌上单击打开新窗口查看已选择用户，此时该链接可分享。'
      ,pl: 'Każdy obiekt będzie miał unikalny token dostępu i jedną lub więcej ról. Kliknij token dostępu, aby otworzyć nowy widok z wybranym obiektem, ten tajny link może być następnie udostępniony'
      ,hu: 'Mindegyik személynek egy egyedi hozzáférése lesz 1 vagy több szereppel. Kattints a tokenre, hogy egy új nézetet kapj - a kapott linket megoszthatod velük.'
    }
    ,'Add new Subject' : {
      cs: 'Přidat nový subjekt'
      ,he: 'הוסף נושא חדש '
      ,bg: 'Добави нов субект'
      ,hr: 'Dodaj novi subjekt'
      ,fr: 'Ajouter un nouvel Utilisateur'
      ,ro: 'Adaugă un Subiect nou'
      ,ru: 'Добавить нового субъекта'
      ,sv: 'Lägg till nytt ämne'
      ,nb: 'Legg til ny ressurs'
      ,fi: 'Lisää uusi käyttäjä'
      ,de: 'Füge ein neues Subjekt hinzu'
      ,dk: 'Tilføj nye emner'
      ,es: 'Añadir nuevo Sujeto'
      ,pt: 'Adicionar novo assunto'
      ,sk: 'Pridať nový subjekt'
      ,ko: '새 주제 추가'
      ,it: 'Aggiungere un nuovo Soggetto'
      ,nl: 'Voeg onderwerp toe'
      ,tr: 'Yeni konu ekle'
      ,zh_cn: '添加新用户'
      ,pl: 'Dodaj obiekt'
      ,hu: 'Új személy hozzáadása'
    }
    ,'Unable to %1 Subject' : { // PUT or POST
      cs: 'Chyba volání %1 Subjektu:'
      ,en: 'לא יכול ל %1 נושאי'
      ,bg: 'Невъзможно %1 субект'
      ,hr: 'Ne mogu %1 subjekt'
      ,ro: 'Imposibil de %1 Subiectul'
      ,fr: 'Impossible de créer l\'Utilisateur %1'
      ,ru: 'Невозможно создать %1 субъект'
      ,sv: 'Kan ej %1 ämne'
      ,nb: 'Kan ikke %1 ressurs'
      ,fi: '%1 operaatio käyttäjälle epäonnistui'
      ,de: 'Unpassend zum %1 Subjekt'
      ,dk: 'Kan ikke %1 emne'
      ,es: 'Imposible poner %1 Sujeto'
      ,pt: 'Impossível postar %1 assunto'
      ,sk: 'Chyba volania %1 subjektu'
      ,ko: '%1 주제 비활성화'
      ,it: 'Incapace di %1 sottoporre'
      ,nl: 'Niet in staat %1 Onderwerp'
      ,tr: '%1 konu yapılamıyor'
      ,zh_cn: '%1用户不可用'
      ,pl: 'Nie można %1 obiektu'
      ,hu: 'A %1 személy hozáadása nem sikerült'
    }
    ,'Unable to delete Subject' : {
      cs: 'Nelze odstranit Subjekt:'
      ,he: 'לא יכול לבטל נושא '
      ,bg: 'Невъзможно изтриването на субекта'
      ,hr: 'Ne mogu obrisati subjekt'
      ,ro: 'Imposibil de șters Subiectul'
      ,fr: 'Impossible d\'effacer l\'Utilisateur'
      ,ru: 'Невозможно удалить Субъект '
      ,sv: 'Kan ej ta bort ämne'
      ,nb: 'Kan ikke slette ressurs'
      ,fi: 'Käyttäjän poistaminen epäonnistui'
      ,de: 'Kann Subjekt nicht löschen'
      ,dk: 'Kan ikke slette emne'
      ,es: 'Imposible eliminar sujeto'
      ,pt: 'Impossível apagar assunto'
      ,sk: 'Subjekt sa nedá odstrániť'
      ,ko: '주제를 지우기 위해 비활성화'
      ,it: 'Impossibile eliminare Soggetto'
      ,nl: 'Kan onderwerp niet verwijderen'
      ,tr: 'Konu silinemedi'
      ,zh_cn: '无法删除用户'
      ,pl: 'Nie można usunąć obiektu'
      ,hu: 'A személyt nem sikerült törölni'
    }
    ,'Database contains %1 subjects' : {
      cs: 'Databáze obsahuje %1 subjektů'
      ,he: 'מסד נתונים מכיל %1 נושאים '
      ,bg: 'Базата данни съдържа %1 субекти'
      ,hr: 'Baza sadrži %1 subjekata'
      ,fr: 'La base de données contient %1 utilisateurs'
      ,fi: 'Tietokanta sisältää %1 käyttäjää'
      ,ru: 'База данных содержит %1 субъекта/ов'
      ,ro: 'Baza de date are %1 subiecți'
      ,sv: 'Databasen innehåller %1 ämnen'
      ,nb: 'Databasen inneholder %1 ressurser'
      ,de: 'Datenbank enthält %1 Subjekte'
      ,dk: 'Databasen indeholder %1 emner'
      ,es: 'Base de datos contiene %1 sujetos'
      ,pt: 'Banco de dados contém %1 assuntos'
      ,sk: 'Databáza obsahuje %1 subjektov'
      ,ko: '데이터베이스는 %1 주제를 포함'
      ,it: 'Database contiene %1 soggetti'
      ,nl: 'Database bevat %1 onderwerpen'
      ,tr: 'Veritabanı %1 konu içeriyor'
      ,zh_cn: '数据库包含%1个用户'
      ,pl: 'Baza danych zawiera %1 obiektów'
      ,hu: 'Az adatbázis %1 személyt tartalmaz'
    }
    ,'Edit Subject' : {
      cs:'Editovat subjekt'
      ,he: 'ערוך נושא '
      ,bg: 'Промени субект'
      ,hr: 'Uredi subjekt'
      ,ro: 'Editează Subiectul'
      ,es: 'Editar sujeto'
      ,fr: 'Éditer l\'Utilisateur'
      ,ru: 'Редактировать Субъект'
      ,sv: 'Editera ämne'
      ,nb: 'Editer ressurs'
      ,fi: 'Muokkaa käyttäjää'
      ,de: 'Editiere Subjekt'
      ,dk: 'Rediger emne'
      ,pt: 'Editar assunto'
      ,sk: 'Editovať subjekt'
      ,ko: '주제 편집'
      ,it: 'Modifica Oggetto'
      ,nl: 'Pas onderwerp aan'
      ,tr: 'Konuyu düzenle'
      ,zh_cn: '编辑用户'
      ,pl: 'Edytuj obiekt'
      ,hu: 'Személy szerkesztése'
    }
    ,'person, device, etc' : {
      cs:'osoba, zařízeni atd.'
      ,he: 'אדם, מכשיר, וכו '
      ,bg: 'човек,устройство,т.н.'
      ,hr: 'osoba, uređaj, itd.'
      ,ro: 'persoană, dispozitiv, etc'
      ,fr: 'personne, appareil, etc'
      ,ru: 'лицо, устройство и т п'
      ,sv: 'person,enhet,etc'
      ,nb: 'person, enhet osv'
      ,fi: 'henkilö, laite jne'
      ,de: 'Person, Gerät, etc'
      ,dk: 'person, emne, etc'
      ,es: 'Persona, dispositivo, etc'
      ,pt: 'Pessoa, dispositivo, etc'
      ,sk: 'osoba, zariadenie atď...'
      ,ko: '사람, 기기 등'
      ,it: 'persona, dispositivo, ecc'
      ,nl: 'persoon, apparaat etc'
      ,tr: 'kişi, cihaz, vb'
      ,zh_cn: '人、设备等'
      ,pl: 'osoba, urządzenie, itp'
      ,hu: 'személy, berendezés, stb.'
    }
    ,'role1, role2' : {
      cs:'role1, role2'
      ,he: 'תפקיד1, תפקיד2 '
      ,bg: 'Роля1, Роля2'
      ,hr: 'uloga1, uloga2'
      ,ro: 'Rol1, Rol2'
      ,fr: 'rôle1, rôle2'
      ,ru: 'Роль1, Роль2'
      ,sv: 'Roll1, Roll2'
      ,nb: 'rolle1, rolle2'
      ,fi: 'rooli1, rooli2'
      ,de: 'Rolle1, Rolle2'
      ,dk: 'Rolle1, Rolle2'
      ,pt: 'papel1, papel2'
      ,es: 'Rol1, Rol2'
      ,sk: 'rola1, rola2'
      ,ko: '역할1, 역할2'
      ,it: 'ruolo1, ruolo2'
      ,nl: 'rol1, rol2'
      ,tr: 'rol1, rol2'
      ,zh_cn: '角色1、角色2'
      ,pl: 'rola1, rola2'
      ,hu: 'szerep1, szerep2'
    }
    ,'Edit this subject' : {
      cs:'Editovat tento subjekt'
      ,he: 'ערוך נושא זה '
      ,bg: 'Промени този субект'
      ,hr: 'Uredi ovaj subjekt'
      ,ro: 'Editează acest subiect'
      ,fr: 'Éditer cet utilisateur'
      ,ru: 'Редактировать этот субъект'
      ,sv: 'Editera ämnet'
      ,es: 'Editar este sujeto'
      ,nb: 'Editer ressurs'
      ,fi: 'Muokkaa tätä käyttäjää'
      ,de: 'Editiere dieses Subjekt'
      ,dk: 'Rediger emne'
      ,pt: 'Editar esse assunto'
      ,sk: 'Editovať tento subjekt'
      ,ko: '이 주제 편집'
      ,it: 'Modifica questo argomento'
      ,nl: 'pas dit onderwerp aan'
      ,tr: 'Bu konuyu düzenle'
      ,zh_cn: '编辑此用户'
      ,pl: 'Edytuj ten obiekt'
      ,hu: 'A kiválasztott személy szerkesztése'
    }
    ,'Delete this subject' : {
      cs:'Smazat tento subjekt'
      ,he: 'בטל נושא זה '
      ,bg: 'Изтрий този субект'
      ,hr: 'Obriši ovaj subjekt'
      ,ro: 'Șterge acest subiect'
      ,fr: 'Effacer cet utilisateur:'
      ,ru: 'Удалить этот субъект'
      ,sv: 'Ta bort ämnet'
      ,nb: 'Slett ressurs'
      ,fi: 'Poista tämä käyttäjä'
      ,de: 'Lösche dieses Subjekt'
      ,dk: 'Fjern emne'
      ,es: 'Eliminar este sujeto'
      ,pt: 'Apagar esse assunto'
      ,sk: 'Zmazať tento subjekt'
      ,ko: '이 주제 삭제'
      ,it: 'Eliminare questo argomento'
      ,nl: 'verwijder dit onderwerp'
      ,tr: 'Bu konuyu sil'
      ,zh_cn: '删除此用户'
      ,pl: 'Usuń ten obiekt'
      ,hu: 'A kiválasztott személy törlése'
    }
    ,'Roles' : {
      cs:'Role'
      ,he: 'תפקידים '
      ,bg: 'Роли'
      ,hr: 'Uloge'
      ,ro: 'Roluri'
      ,fr: 'Rôles'
      ,ru: 'Роли'
      ,sv: 'Roller'
      ,nb: 'Roller'
      ,es: 'Roles'
      ,fi: 'Rooli'
      ,de: 'Rollen'
      ,dk: 'Roller'
      ,pt: 'Papéis'
      ,sk: 'Rola'
      ,ko: '역할'
      ,it: 'Ruoli'
      ,nl: 'Rollen'
      ,tr: 'Roller'
      ,zh_cn: '角色'
      ,pl: 'Role'
      ,hu: 'Szerepek'
    }
    ,'Access Token' : {
      cs:'Přístupový token'
      ,he: 'אסימון גישה '
      ,bg: 'Ключ за достъп'
      ,hr: 'Pristupni token'
      ,ro: 'Cheie de acces'
      ,fr: 'Identificateur unique'
      ,ru: 'Код доступа'
      ,sv: 'Åtkomstnyckel'
      ,nb: 'Tilgangsnøkkel'
      ,fi: 'Pääsytunniste'
      ,de: 'Zugriffsschlüssel'
      ,dk: 'Sikkerhedsnøgle'
      ,pt: 'Token de acesso'
      ,es: 'Acceso Identificador'
      ,sk: 'Prístupový Token'
      ,ko: 'Access Token'
      ,it: 'Gettone d\'accesso'
      ,nl: 'toegangscode'
      ,tr: 'Erişim Simgesi (Access Token)'
      ,zh_cn: '访问令牌'
      ,pl: 'Token dostępu'
      ,hu: 'Hozzáférési token'
    }
    ,'hour ago' : {
      cs:'hodina zpět'
      ,he: 'לפני שעה '
      ,bg: 'Преди час'
      ,hr: 'sat ranije'
      ,fr: 'heure avant'
      ,ro: 'oră în trecut'
      ,ru: 'час назад'
      ,sv: 'timme sedan'
      ,nb: 'time siden'
      ,fi: 'tunti sitten'
      ,de: 'vor einer Stunde'
      ,dk: 'time siden'
      ,es: 'hora atrás'
      ,pt: 'hora atrás'
      ,sk: 'pred hodinou'
      ,ko: '시간 후'
      ,it: 'ora fa'
      ,nl: 'uur geleden'
      ,tr: 'saat önce'
      ,zh_cn: '小时前'
      ,pl: 'Godzię temu'
      ,hu: 'órája'
    }
    ,'hours ago' : {
      cs:'hodin zpět'
      ,he: 'לפני שעות '
      ,bg: 'Преди часове'
      ,hr: 'sati ranije'
      ,fr: 'heures avant'
      ,ro: 'ore în trecut'
      ,ru: 'часов назад'
      ,sv: 'timmar sedan'
      ,nb: 'timer siden'
      ,fi: 'tuntia sitten'
      ,de: 'vor mehreren Stunden'
      ,dk: 'timer sidan'
      ,es: 'horas atrás'
      ,pt: 'horas atrás'
      ,sk: 'hodín pred'
      ,ko: '시간 후'
      ,it: 'ore fa'
      ,nl: 'uren geleden'
      ,tr: 'saat önce'
      ,zh_cn: '小时前'
      ,pl: 'Godzin temu'
      ,hu: 'órája'
    }
    ,'Silence for %1 minutes' : {
      cs:'Ztlumit na %1 minut'
      ,he: 'שקט ל %1 דקות '
      ,bg: 'Заглушаване за %1 минути'
      ,hr: 'Utišaj na %1 minuta'
      ,ro: 'Liniște pentru %1 minute'
      ,fr: 'Silence pour %1 minutes'
      ,ru: 'Молчание %1 минут'
      ,sv: 'Tyst i %1 minuter'
      ,nb: 'Stille i %1 minutter'
      ,fi: 'Hiljennä %1 minuutiksi'
      ,de: 'Ruhe für %1 Minuten'
      ,dk: 'Stilhed i %1 minutter'
      ,pt: 'Silencir por %1 minutos'
      ,es: 'Silenciado por %1 minutos'
      ,sk: 'Stíšiť na %1 minút'
      ,ko: '%1 분 동안 무음'
      ,it: 'Silenzio per %1 minuti'
      ,nl: 'Sluimer %1 minuten'
      ,tr: '%1 dakika sürelik sessizlik'
      ,zh_cn: '静音%1分钟'
      ,zh_tw: '靜音%1分鐘'
      ,pl: 'Wycisz na %1 minut'
      ,hul: 'Lehalkítás %1 percre'
    }
    ,'Check BG' : {
      cs:'Zkontrolovat glykémii'
      ,he: 'בדוק סוכר בדם '
      ,de: 'BZ kontrollieren'
      ,dk: 'Kontroller blodsukker'
      ,ro: 'Verificați glicemia'
      ,es: 'Verificar glucemia'
      ,fr: 'Vérifier la glycémie'
      ,ru: 'Проверьте гликемию'
      ,sv: 'Kontrollera blodglukos'
      ,nb: 'Sjekk blodsukker'
      ,sk: 'Skontrolovať glykémiu'
      ,fi: 'Tarkista VS'
      ,pt: 'Verifique a glicemia'
      ,bg: 'Проверка КЗ'
      ,hr: 'Provjeri GUK'
      ,ko: '혈당 체크'
      ,it: 'Controllare BG'
      ,nl: 'Controleer BG'
      ,tr: 'KŞ\'ini kontrol et'
      ,zh_cn: '测量血糖'
      ,pl: 'Sprawdź glukozę z krwi'
      ,hu: 'Ellenőrizd a cukorszintet'
    }
    ,'BASAL' : {
      cs: 'BAZÁL'
      ,he: 'רמה בזלית '
      ,de: 'BASAL'
      ,dk: 'BASAL'
      ,ro: 'Bazală'
      ,ru: 'Базал'
      ,fr: 'Basale'
      ,sk: 'BAZÁL'
      ,sv: 'BASAL'
      ,nb: 'BASAL'
      ,fi: 'Basaali'
      ,pt: 'BASAL'
      ,bg: 'Базал'
      ,hr: 'Bazal'
      ,ko: 'BASAL'
      ,es: 'BASAL'
      ,it: 'BASALE'
      ,nl: 'Basaal'
      ,tr: 'Bazal'
      ,zh_cn: '基础率'
      ,zh_tw: '基礎率'
      ,pl: 'BAZA'
      ,hu: 'BAZÁL'
    }
    ,'Current basal' : {
      cs:'Současný bazál'
      ,he: 'רמה בזלית נוכחית '
      ,de: 'Aktuelle Basalrate'
      ,dk: 'Nuværende basal'
      ,ro: 'Bazala curentă'
      ,fr: 'Débit basal actuel'
      ,ru: 'Актуальный базал'
      ,sk: 'Aktuálny bazál'
      ,sv: 'Nuvarande basal'
      ,nb: 'Gjeldende basal'
      ,fi: 'Nykyinen basaali'
      ,pt: 'Basal atual'
      ,es: 'Basal actual'
      ,bg: 'Актуален базал'
      ,hr: 'Trenutni bazal'
      ,ko: '현재 basal'
      ,it: 'Basale corrente'
      ,nl: 'Huidige basaal'
      ,tr: 'Geçerli Bazal'
      ,zh_cn: '当前基础率'
      ,pl: 'Dawka podstawowa'
      ,hu: 'Aktuális bazál'
    }
    ,'Sensitivity' : {
      cs:'Citlivost (ISF)'
      ,he: 'רגישות '
      ,de: 'Sensitivität'
      ,dk: 'Insulinfølsomhed (ISF)'
      ,ro: 'Sensibilitate la insulină (ISF)'
      ,fr: 'Sensibilité à l\'insuline (ISF)'
      ,ru: 'Чуствительность к инсулину ISF'
      ,sk: 'Citlivosť (ISF)'
      ,sv: 'Insulinkönslighet (ISF)'
      ,nb: 'Insulinsensitivitet (ISF)'
      ,fi: 'Herkkyys'
      ,es: 'Sensibilidad'
      ,pt: 'Fator de sensibilidade'
      ,bg: 'Инсулинова чувствителност (ISF)'
      ,hr: 'Osjetljivost'
      ,ko: '인슐린 민감도(ISF)'
      ,it: 'ISF - sensibilità'
      ,nl: 'Gevoeligheid'
      ,tr: 'Duyarlılık Faktörü (ISF)'
      ,zh_cn: '胰岛素敏感系数'
      ,pl: 'Wrażliwość'
      ,hu: 'Inzulin érzékenység'
    }
    ,'Current Carb Ratio' : {
      cs:'Sacharidový poměr (I:C)'
      ,he: 'וחס פחמימות לאינסולין נוכחי '
      ,de: 'Aktuelles KH-Verhältnis'
      ,dk: 'Nuværende kulhydratratio'
      ,fr: 'Rapport Insuline-glucides actuel (I:C)'
      ,ro: 'Raport Insulină:Carbohidrați (ICR)'
      ,ru: 'Актуальное соотношение инсулин:углеводы I:C'
      ,sk: 'Aktuálny sacharidový pomer (I"C)'
      ,sv: 'Gällande kolhydratkvot'
      ,nb: 'Gjeldende karbohydratforhold'
      ,es: 'Relación actual Insulina:Carbohidratos'
      ,pt: 'Relação insulina:carboidrato atual'
      ,fi: 'Nykyinen hiilihydraattiherkkyys'
      ,bg: 'Актуално Въглехидратно Съотношение'
      ,hr: 'Trenutni I:C'
      ,ko: '현재 탄수화물 비율(ICR)'
      ,it: 'Attuale rapporto I:C'
      ,nl: 'Huidige KH ratio'
      ,tr: 'Geçerli Karbonhidrat oranı I/C (ICR)'
      ,zh_cn: '当前碳水化合物系数'
      ,pl: 'Obecny przelicznik węglowodanowy'
      ,hu: 'Aktuális szénhidrát arány'
    }
    ,'Basal timezone' : {
      cs:'Časová zóna'
      ,he: 'איזור זמן לרמה הבזלית '
      ,de: 'Basal Zeitzone'
      ,dk: 'Basal tidszone'
      ,ro: 'Fus orar pentru bazală'
      ,es: 'Zona horaria basal'
      ,fr: 'Fuseau horaire'
      ,ru: 'Часовой пояс базала'
      ,sk: 'Časová zóna pre bazál'
      ,sv: 'Basal tidszon'
      ,nb: 'Basal tidssone'
      ,pt: 'Fuso horário da basal'
      ,fi: 'Aikavyöhyke'
      ,bg: 'Базална часова зона'
      ,hr: 'Vremenska zona bazala'
      ,ko: 'Basal 타임존'
      ,it: 'fuso orario basale'
      ,nl: 'Basaal tijdzone'
      ,tr: 'Bazal saat dilimi'
      ,zh_cn: '基础率时区'
      ,pl: 'Strefa czasowa dawki podstawowej'
      ,hu: 'Bazál időzóna'
    }
    ,'Active profile' : {
      cs:'Aktivní profil'
      ,he: 'פרופיל פעיל '
      ,de: 'Aktives Profil'
      ,dk: 'Aktiv profil'
      ,ro: 'Profilul activ'
      ,fr: 'Profil actif'
      ,ru: 'Действующий профиль'
      ,sk: 'Aktívny profil'
      ,sv: 'Aktiv profil'
      ,nb: 'Aktiv profil'
      ,pt: 'Perfil ativo'
      ,es: 'Perfil activo'
      ,fi: 'Aktiivinen profiili'
      ,bg: 'Активен профил'
      ,hr: 'Aktivni profil'
      ,ko: '활성 프로파일'
      ,it: 'Attiva profilo'
      ,nl: 'Actief profiel'
      ,tr: 'Aktif profil'
      ,zh_cn: '当前配置文件'
      ,pl: 'Profil aktywny'
      ,hu: 'Aktív profil'
    }
    ,'Active temp basal' : {
      cs:'Aktivní dočasný bazál'
      ,he: 'רמה בזלית זמנית פעילה '
      ,de: 'Aktive temp. Basalrate'
      ,dk: 'Aktiv midlertidig basal'
      ,ro: 'Bazală temporară activă'
      ,fr: 'Débit basal temporaire actif'
      ,ru: 'Активный временный базал'
      ,sk: 'Aktívny dočasný bazál'
      ,sv: 'Aktiv tempbasal'
      ,nb: 'Aktiv midlertidig basal'
      ,pt: 'Basal temporária ativa'
      ,es: 'Basal temporal activa'
      ,fi: 'Aktiivinen tilapäisbasaali'
      ,bg: 'Активен временен базал'
      ,hr: 'Aktivni temp bazal'
      ,ko: '활성 임시 basal'
      ,it: 'Attiva Basale Temp'
      ,nl: 'Actieve tijdelijke basaal'
      ,tr: 'Aktif geçici bazal oranı'
      ,zh_cn: '当前临时基础率'
      ,pl: 'Aktywa tymczasowa dawka podstawowa'
      ,hu: 'Aktív átmeneti bazál'
    }
    ,'Active temp basal start' : {
      cs:'Začátek dočasného bazálu'
      ,he: 'התחלה רמה בזלית זמנית '
      ,de: 'Start aktive temp. Basalrate'
      ,dk: 'Aktiv midlertidig basal start'
      ,ro: 'Start bazală temporară activă'
      ,fr: 'Début du débit basal temporaire'
      ,ru: 'Старт актуального временного базала'
      ,sk: 'Štart dočasného bazálu'
      ,sv: 'Aktiv tempbasal start'
      ,nb: 'Aktiv midlertidig basal start'
      ,pt: 'Início da basal temporária ativa'
      ,es: 'Inicio Basal temporal activa'
      ,fi: 'Aktiivisen tilapäisbasaalin aloitus'
      ,bg: 'Старт на активен временен базал'
      ,hr: 'Početak aktivnog tamp bazala'
      ,ko: '활성 임시 basal 시작'
      ,it: 'Attiva Inizio Basale temp'
      ,nl: 'Actieve tijdelijke basaal start'
      ,tr: 'Aktif geçici bazal oranı başlangıcı'
      ,zh_cn: '当前临时基础率开始'
      ,pl: 'Start aktywnej tymczasowej dawki podstawowej'
      ,hu: 'Aktív átmeneti bazál kezdete'
    }
    ,'Active temp basal duration' : {
      cs:'Trvání dočasného bazálu'
      ,he: 'משך רמה בזלית זמנית '
      ,de: 'Dauer aktive temp. Basalrate'
      ,dk: 'Aktiv midlertidig basal varighed'
      ,ro: 'Durata bazalei temporare active'
      ,fr: 'Durée du débit basal temporaire'
      ,ru: 'Длительность актуального временного базала'
      ,sk: 'Trvanie dočasného bazálu'
      ,sv: 'Aktiv tempbasal varaktighetstid'
      ,nb: 'Aktiv midlertidig basal varighet'
      ,pt: 'Duração de basal temporária ativa'
      ,es: 'Duración Basal Temporal activa'
      ,fi: 'Aktiivisen tilapäisbasaalin kesto'
      ,bg: 'Продължителност на Активен временен базал'
      ,hr: 'Trajanje aktivnog temp bazala'
      ,ko: '활성 임시 basal 시간'
      ,it: 'Attiva durata basale temp'
      ,nl: 'Actieve tijdelijk basaal duur'
      ,zh_cn: '当前临时基础率期间'
      ,pl: 'Czas trwania aktywnej tymczasowej dawki podstawowej'
      ,tr: 'Aktif geçici bazal süresi'
      ,hu: 'Aktív átmeneti bazál időtartalma'
    }
    ,'Active temp basal remaining' : {
      cs:'Zbývající dočasný bazál'
      ,he: 'זמן שנשאר ברמה בזלית זמנית '
      ,de: 'Verbleibene Dauer temp. Basalrate'
      ,dk: 'Resterende tid for aktiv midlertidig basal'
      ,ro: 'Rest de bazală temporară activă'
      ,fr: 'Durée restante de débit basal temporaire'
      ,ru: 'Остается актуального временного базала'
      ,sk: 'Zostatok dočasného bazálu'
      ,sv: 'Återstående tempbasaltid'
      ,nb: 'Gjenstående midlertidig basal tid'
      ,pt: 'Basal temporária ativa restante'
      ,es: 'Basal temporal activa restante'
      ,fi: 'Aktiivista tilapäisbasaalia jäljellä'
      ,bg: 'Оставащ Активен временен базал'
      ,hr: 'Prestali aktivni temp bazal'
      ,ko: '남아 있는 활성 임시 basal'
      ,it: 'Attiva residuo basale temp'
      ,nl: 'Actieve tijdelijke basaal resteert'
      ,zh_cn: '当前临时基础率剩余'
      ,pl: 'Pozostała aktywna tymczasowa dawka podstawowa'
      ,tr: 'Aktif geçici bazal kalan'
      ,hu: 'Átmeneti bazál visszamaradó ideje'
    }
    ,'Basal profile value' : {
      cs: 'Základní hodnota bazálu'
      ,he: 'רמה בזלית מפרופיל '
      ,de: 'Basal-Profil Wert'
      ,dk: 'Basalprofil værdi'
      ,ro: 'Valoarea profilului bazalei'
      ,ru: 'Величина профильного базала'
      ,fr: 'Valeur du débit basal'
      ,sv: 'Basalprofil värde'
      ,es: 'Valor perfil Basal'
      ,nb: 'Basalprofil verdi'
      ,fi: 'Basaaliprofiilin arvo'
      ,pt: 'Valor do perfil basal'
      ,sk: 'Základná hodnota bazálu'
      ,bg: 'Базален профил стойност'
      ,hr: 'Profilna vrijednost bazala'
      ,ko: 'Basal 프로파일 값'
      ,it: 'Valore profilo basale'
      ,nl: 'Basaal profiel waarde'
      ,zh_cn: '基础率配置文件值'
      ,pl: 'Wartość profilu podstawowego'
      ,tr: 'Bazal profil değeri'
      ,hu: 'Bazál profil értéke'
    }
    ,'Active combo bolus' : {
      cs:'Aktivní kombinovaný bolus'
      ,he: 'בולוס קומבו פעיל '
      ,de: 'Aktiver verzögerter Bolus'
      ,dk: 'Aktiv kombobolus'
      ,ro: 'Bolus combinat activ'
      ,fr: 'Bolus Duo/Combo actif'
      ,ru: 'Активный комбинированный болюс'
      ,sv: 'Aktiv kombobolus'
      ,nb: 'Kombinasjonsbolus'
      ,fi: 'Aktiivinen yhdistelmäbolus'
      ,pt: 'Bolus duplo em atividade'
      ,es: 'Activo combo-bolo'
      ,sk: 'Aktívny kombinovaný bolus'
      ,bg: ''
      ,hr: 'Aktivni dual bolus'
      ,ko: '활성 콤보 bolus'
      ,it: 'Attiva Combo bolo'
      ,nl: 'Actieve combo bolus'
      ,zh_cn: '当前双波大剂量'
      ,pl: 'Aktywny bolus złożony'
      ,tr: 'Aktive kombo bolus'
      ,hu: 'Aktív kombinált bólus'
    }
    ,'Active combo bolus start' : {
      cs: 'Začátek kombinovaného bolusu'
      ,he: 'התחלת בולוס קומבו פעיל '
      ,de: 'Start des aktiven verzögerten Bolus'
      ,dk: 'Aktiv kombibolus start'
      ,ro: 'Start bolus combinat activ'
      ,fr: 'Début de Bolus Duo/Combo'
      ,ru: 'Старт активного комбинир болюса'
      ,sv: 'Aktiv kombobolus start'
      ,nb: 'Kombinasjonsbolus start'
      ,fi: 'Aktiivisen yhdistelmäboluksen alku'
      ,pt: 'Início do bolus duplo em atividade'
      ,es: 'Inicio del combo-bolo activo'
      ,sk: 'Štart kombinovaného bolusu'
      ,bg: 'Активен комбиниран болус'
      ,hr: 'Početak aktivnog dual bolusa'
      ,ko: '활성 콤보 bolus 시작'
      ,it: 'Attivo inizio Combo bolo'
      ,nl: 'Actieve combo bolus start'
      ,zh_cn: '当前双波大剂量开始'
      ,pl: 'Start aktywnego bolusa złożonego'
      ,tr: 'Aktif gecikmeli bolus başlangıcı'
      ,hu: 'Aktív kombinált bólus kezdete'
    }
    ,'Active combo bolus duration' : {
      cs: 'Trvání kombinovaného bolusu'
      ,he: 'משך בולוס קומבו פעיל '
      ,de: 'Dauer des aktiven verzögerten Bolus'
      ,dk: 'Aktiv kombibolus varighed'
      ,ro: 'Durată bolus combinat activ'
      ,fr: 'Durée du Bolus Duo/Combo'
      ,ru: 'Длительность активного комбинир болюса'
      ,sv: 'Aktiv kombibolus varaktighet'
      ,es: 'Duración del Combo-Bolo activo'
      ,nb: 'Kombinasjonsbolus varighet'
      ,fi: 'Aktiivisen yhdistelmäboluksen kesto'
      ,pt: 'Duração de bolus duplo em atividade'
      ,sk: 'Trvanie kombinovaného bolusu'
      ,bg: 'Продължителност на активния комбиниран болус'
      ,hr: 'Trajanje aktivnog dual bolusa'
      ,ko: '활성 콤보 bolus 기간'
      ,it: 'Attivo durata Combo bolo'
      ,nl: 'Actieve combo bolus duur'
      ,zh_cn: '当前双波大剂量期间'
      ,pl: 'Czas trwania aktywnego bolusa złożonego'
      ,tr: 'Active combo bolus süresi'
      ,hu: 'Aktív kombinált bólus időtartalma'
    }
    ,'Active combo bolus remaining' : {
      cs: 'Zbývající kombinovaný bolus'
      ,he: 'זמן שנשאר בבולוס קומבו פעיל '
      ,de: 'Verbleibender aktiver verzögerter Bolus'
      ,dk: 'Resterende aktiv kombibolus'
      ,ro: 'Rest de bolus combinat activ'
      ,fr: 'Activité restante du Bolus Duo/Combo'
      ,ru: 'Остается активного комбинир болюса'
      ,sv: 'Återstående aktiv kombibolus'
      ,es: 'Restante Combo-Bolo activo'
      ,nb: 'Gjenstående kombinasjonsbolus'
      ,fi: 'Aktiivista yhdistelmäbolusta jäljellä'
      ,pt: 'Restante de bolus duplo em atividade'
      ,sk: 'Zostávajúci kombinovaný bolus'
      ,bg: 'Оставащ активен комбиниран болус'
      ,hr: 'Preostali aktivni dual bolus'
      ,ko: '남아 있는 활성 콤보 bolus'
      ,it: 'Attivo residuo Combo bolo'
      ,nl: 'Actieve combo bolus resteert'
      ,zh_cn: '当前双波大剂量剩余'
      ,pl: 'Pozostały aktywny bolus złożony'
      ,tr: 'Aktif kombo (yayım) bolus kaldı'
      ,hu: 'Aktív kombinált bólus fennmaradó idő'
    }
    ,'BG Delta' : {
      cs:'Změna glykémie'
      ,he: 'הפרש רמת סוכר '
      ,de: 'BZ Differenz'
      ,dk: 'BS deltaværdi'
      ,ro: 'Diferență glicemie'
      ,fr: 'Différence de glycémie'
      ,ru: 'Изменение гликемии'
      ,sv: 'BS deltavärde'
      ,nb: 'BS forskjell'
      ,fi: 'VS muutos'
      ,pt: 'Diferença de glicemia'
      ,es: 'Diferencia de glucemia'
      ,sk: 'Zmena glykémie'
      ,bg: 'Дельта ГК'
      ,hr: 'GUK razlika'
      ,ko: '혈당 차이'
      ,it: 'BG Delta'
      ,nl: 'BG Delta'
      ,zh_cn: '血糖增量'
      ,zh_tw: '血糖增量'
      ,pl: 'Zmiana glikemii'
      ,tr: 'KŞ farkı'
      ,hu: 'Cukorszint változása'
    }
    ,'Elapsed Time' : {
      cs:'Dosažený čas'
      ,he: 'זמן שעבר '
      ,de: 'Vergangene Zeit'
      ,dk: 'Forløbet tid'
      ,ro: 'Timp scurs'
      ,fr: 'Temps écoulé'
      ,ru: 'Прошло времени'
      ,sv: 'Förfluten tid'
      ,nb: 'Forløpt tid'
      ,fi: 'Kulunut aika'
      ,pt: 'Tempo transcorrido'
      ,es: 'Tiempo transcurrido'
      ,sk: 'Uplynutý čas'
      ,bg: 'Изминало време'
      ,hr: 'Proteklo vrijeme'
      ,ko: '경과 시간'
      ,it: 'Tempo Trascorso'
      ,nl: 'Verstreken tijd'
      ,zh_cn: '所需时间'
      ,zh_tw: '所需時間'
      ,pl: 'Upłynął czas'
      ,tr: 'Geçen zaman'
      ,hu: 'Eltelt idő'
    }
    ,'Absolute Delta' : {
      cs:'Absolutní rozdíl'
      ,he: 'הפרש רמת סוכר אבסולוטית '
      ,de: 'Absolute Differenz'
      ,dk: 'Absolut deltaværdi'
      ,ro: 'Diferență absolută'
      ,fr: 'Différence absolue'
      ,ru: 'Абсолютное изменение'
      ,sv: 'Absolut deltavärde'
      ,nb: 'Absolutt forskjell'
      ,fi: 'Absoluuttinen muutos'
      ,pt: 'Diferença absoluta'
      ,es: 'Diferencia absoluta'
      ,sk: 'Absolútny rozdiel'
      ,bg: 'Абсолютно изменение'
      ,hr: 'Apsolutna razlika'
      ,ko: '절대적인 차이'
      ,it: 'Delta Assoluto'
      ,nl: 'Abslute delta'
      ,zh_cn: '绝对增量'
      ,zh_tw: '絕對增量'
      ,pl: 'różnica absolutna'
      ,tr: 'Mutlak fark'
      ,hu: 'Abszolút külonbség'
    }
    ,'Interpolated' : {
      cs:'Interpolováno'
      ,he: 'אינטרפולציה '
      ,de: 'Interpoliert'
      ,dk: 'Interpoleret'
      ,ro: 'Interpolat'
      ,fr: 'Interpolé'
      ,ru: 'Интерполировано'
      ,sv: 'Interpolerad'
      ,nb: 'Interpolert'
      ,fi: 'Laskettu'
      ,pt: 'Interpolado'
      ,es: 'Interpolado'
      ,sk: 'Interpolované'
      ,bg: 'Интерполирано'
      ,hr: 'Interpolirano'
      ,ko: '삽입됨'
      ,it: 'Interpolata'
      ,nl: 'Geinterpoleerd'
      ,zh_cn: '插值'
      ,zh_tw: '插值'
      ,pl: 'Interpolowany'
      ,tr: 'Aralıklı'
      ,hu: 'Interpolált'
    }
    ,'BWP' : { // Bolus Wizard Preview
      cs: 'KALK'
      ,he: 'BWP'
      ,de: 'BWP'
      ,dk: 'Bolusberegner (BWP)'
      ,ro: 'Ajutor bolusare (BWP)'
      ,ru: 'Калькулятор болюса'
      ,fr: 'Calculateur de bolus (BWP)'
      ,sv: 'Boluskalkylator'
      ,es: 'VistaPreviaCalculoBolo (BWP)'
      ,nb: 'Boluskalkulator'
      ,hr: 'Čarobnjak bolusa'
      ,fi: 'Annoslaskuri'
      ,pt: 'Ajuda de bolus'
      ,sk: 'BK'
      ,bg: 'БП'
      ,ko: 'BWP'
      ,it: 'BWP'
      ,nl: 'BWP'
      ,zh_cn: 'BWP'
      ,zh_tw: 'BWP'
      ,pl: 'Kalkulator bolusa'
      ,tr: 'BWP'
      ,hu: 'BWP'
    }
    ,'Urgent' : {
      cs:'Urgentní'
      ,he: 'דחוף '
      ,de: 'Akut'
      ,dk: 'Akut'
      ,ro: 'Urgent'
      ,fr: 'Urgent'
      ,ru: 'Срочно'
      ,es: 'Urgente'
      ,sv: 'Akut'
      ,nb: 'Akutt'
      ,fi: 'Kiireellinen'
      ,pt: 'Urgente'
      ,sk: 'Urgentné'
      ,bg: 'Спешно'
      ,hr: 'Hitno'
      ,ko: '긴급'
      ,it: 'Urgente'
      ,ja: '緊急'
      ,nl: 'Urgent'
      ,zh_cn: '紧急'
      ,zh_tw: '緊急'
      ,pl:'Pilny'
      ,tr: 'Acil'
      ,hu: 'Sűrgős'
    }
    ,'Warning' : {
      cs:'Varování'
      ,he: 'אזהרה '
      ,de: 'Warnung'
      ,dk: 'Advarsel'
      ,ro: 'Atenție'
      ,fr: 'Attention'
      ,ru: 'Осторожно'
      ,sv: 'Varning'
      ,nb: 'Advarsel'
      ,fi: 'Varoitus'
      ,pt: 'Aviso'
      ,es: 'Aviso'
      ,sk: 'Varovanie'
      ,bg: 'Предупреждение'
      ,hr: 'Upozorenje'
      ,ko: '경고'
      ,it: 'Avviso'
      ,nl: 'Waarschuwing'
      ,zh_cn: '警告'
      ,zh_tw: '警告'
      ,pl: 'Ostrzeżenie'
      ,tr: 'Uyarı'
      ,hu: 'Figyelmeztetés'
    }
    ,'Info' : {
      cs: 'Informativní'
      ,he: 'לידיעה '
      ,de: 'Info'
      ,dk: 'Info'
      ,ro: 'Informație'
      ,fr: 'Information'
      ,ru: 'Информация'
      ,sv: 'Information'
      ,nb: 'Informasjon'
      ,fi: 'Info'
      ,pt: 'Informações'
      ,es: 'Información'
      ,sk: 'Info'
      ,bg: 'Информация'
      ,hr: 'Info'
      ,ko: '정보'
      ,it: 'Info'
      ,nl: 'Info'
      ,zh_cn: '信息'
      ,zh_tw: '資訊'
      ,pl: 'Informacja'
      ,tr: 'Info'
      ,hu: 'Információ'
    }
    ,'Lowest' : {
      cs: 'Nejnižší'
      ,he: 'הנמוך ביותר '
      ,de:  'Niedrigster'
      ,dk: 'Laveste'
      ,ro: 'Cea mai mică'
      ,ru: 'Самое нижнее'
      ,fr: 'Valeur la plus basse'
      ,sv: 'Lägsta'
      ,nb: 'Laveste'
      ,es: 'Más bajo'
      ,fi: 'Matalin'
      ,pt: 'Mais baixo'
      ,sk: 'Najnižsie'
      ,bg: 'Най-ниско'
      ,hr: 'Najniže'
      ,ko: '가장 낮은'
      ,it: 'Minore'
      ,nl: 'Laagste'
      ,tr: 'En düşük değer'
      ,zh_cn: '血糖极低'
      ,zh_tw: '血糖極低'
      ,pl: 'Niski'
      ,hu: 'Legalacsonyabb'
    }
    ,'Snoozing high alarm since there is enough IOB' : {
      cs:'Vypínání alarmu vyskoké glykémie, protože je dostatek IOB'
      ,he: 'נודניק את ההתראה הגבוהה מפני שיש מספיק אינסולין פעיל בגוף'
      ,de: 'Ignoriere Alarm hoch, da genügend aktives Bolus-Insulin (IOB) vorhanden'
      ,dk: 'Udsætter høj alarm siden der er nok aktiv insulin (IOB)'
      ,ro: 'Ignoră alarma de hiper deoarece este suficientă insulină activă IOB'
      ,fr: 'Alarme haute ignorée car suffisamment d\'insuline à bord (IOB)'
      ,bg: 'Изключване на аларма за висока КЗ, тъй като има достатъчно IOB'
      ,hr: 'Stišan alarm za hiper pošto ima dovoljno aktivnog inzulina'
      ,es: 'Ignorar alarma de Hiperglucemia al tener suficiente insulina activa'
      ,ru: 'Отключение предупреждение о высоком СК ввиду достаточности инсулина в организме'
      ,sv: 'Snoozar höglarm då aktivt insulin är tillräckligt'
      ,nb: 'Utsetter høyalarm siden det er nok aktivt insulin'
      ,fi: 'Korkean sokerin varoitus poistettu riittävän insuliinin vuoksi'
      ,pt: 'Ignorar alarme de hiper em função de IOB suficiente'
      ,sk: 'Odloženie alarmu vysokej glykémie, pretože je dostatok IOB'
      ,ko: '충분한 IOB가 남아 있기 때문에 고혈당 알람을 스누즈'
      ,it: 'Addormenta allarme alto poiché non vi è sufficiente IOB'
      ,nl: 'Snooze hoog alarm, er is genoeg IOB'
      ,tr: 'Yeterli IOB(Aktif İnsülin) olduğundan KŞ yüksek uyarımını ertele'
      ,zh_cn: '有足够的IOB（活性胰岛素），暂停高血糖警报'
      ,pl: 'Wycisz alarm wysokiej glikemi, jest wystarczająco dużo aktywnej insuliny'
      ,hu: 'Magas cukor riasztás késleltetése mivel elegendő inzulin van kiadva (IOB)'
    }
    ,'Check BG, time to bolus?' : {
      cs:'Zkontrolovat glykémii, čas na bolus?'
      ,he: 'בדוק רמת סוכר. צריך להוסיף אינסולין? '
      ,de: 'BZ kontrollieren, ggf. Bolus abgeben?'
      ,dk: 'Kontroler BS, tid til en bolus?'
      ,ro: 'Verifică glicemia, poate este necesar un bolus?'
      ,fr: 'Vérifier la glycémie, bolus nécessaire ?'
      ,bg: 'Провери КЗ, не е ли време за болус?'
      ,hr: 'Provjeri GUK, vrijeme je za bolus?'
      ,ru: 'Проверьте ГК, дать болюс?'
      ,sv: 'Kontrollera BS, dags att ge bolus?'
      ,nb: 'Sjekk blodsukker, på tide med bolus?'
      ,fi: 'Tarkista VS, aika bolustaa?'
      ,pt: 'Meça a glicemia, hora de bolus de correção?'
      ,es: 'Controle su glucemia, ¿quizás un bolo Insulina?'
      ,sk: 'Skontrolovať glykémiu, čas na bolus?'
      ,ko: 'Bolus를 주입할 시간입니다. 혈당 체크 하시겠습니까?'
      ,it: 'Controllare BG, il tempo del bolo?'
      ,nl: 'Controleer BG, tijd om te bolussen?'
      ,tr: 'KŞine bakın, gerekirse bolus verin?'
      ,zh_cn: '测量血糖，该输注大剂量了？'
      ,pl: 'Sprawdź glikemię, czas na bolusa ?'
      ,hu: 'Ellenőrizd a cukorszintet. Ideje bóluszt adni?'
    }
    ,'Notice' : {
      cs:'Poznámka'
      ,he: 'הודעה '
      ,de: 'Notiz'
      ,dk: 'Note'
      ,ro: 'Notificare'
      ,ru: 'Заметка'
      ,fr: 'Notification'
      ,bg: 'Известие'
      ,hr: 'Poruka'
      ,sv: 'Notering'
      ,es: 'Nota'
      ,nb: 'NB'
      ,fi: 'Huomio'
      ,nl: 'Notitie'
      ,pt: 'Nota'
      ,sk: 'Poznámka'
      ,ko: '알림'
      ,it: 'Preavviso'
      ,tr: 'Not'
      ,zh_cn: '提示'
      ,pl: 'Uwaga'
      ,hu: 'Megjegyzés'
    }
    ,'required info missing' : {
      cs:'chybějící informace'
      ,he: 'חסרה אינפורמציה '
      ,de: 'Benötigte Information fehlt'
      ,dk: 'nødvendig information mangler'
      ,ro: 'Informații importante lipsă'
      ,fr: 'Information nécessaire manquante'
      ,ru: 'Отсутствует необходимая информация'
      ,bg: 'Липсва необходима информация'
      ,hr: 'nedostaju potrebne informacije'
      ,sv: 'Nödvändig information saknas'
      ,nb: 'Nødvendig informasjon mangler'
      ,es: 'Falta información requerida'
      ,fi: 'tarvittava tieto puuttuu'
      ,pt: 'Informação essencial faltando'
      ,sk: 'chýbajúca informácia'
      ,ko: '요청한 정보 손실'
      ,it: 'richiesta informazioni mancanti'
      ,nl: 'vereiste gegevens ontbreken'
      ,tr: 'gerekli bilgi eksik'
      ,zh_cn: '所需信息不全'
      ,pl: 'brak wymaganych informacji'
      ,hu: 'Szükséges információ hiányos'
    }
    ,'Insulin on Board' : {
      cs:'Aktivní inzulín'
      ,he: 'אינסולין פעיל בגוף '
      ,de: 'Aktives Insulin'
      ,dk: 'Aktivt insulin (IOB)'
      ,ro: 'Insulină activă (IOB)'
      ,fr: 'Insuline à bord (IOB)'
      ,ru: 'Активный инсулин (IOB)'
      ,bg: 'Активен Инсулин (IOB)'
      ,hr: 'Aktivni inzulín'
      ,sv: 'Aktivt insulin (IOB)'
      ,nb: 'Aktivt insulin (IOB)'
      ,fi: 'Aktiivinen insuliini'
      ,pt: 'Insulina ativa'
      ,es: 'Insulina activa (IOB)'
      ,sk: 'Aktívny inzulín (IOB)'
      ,ko: '활성 인슐린(IOB)'
      ,it: 'IOB - Insulina Attiva'
      ,nl: 'IOB - Inuline on board'
      ,tr: '(IOB) Aktif İnsülin'
      ,zh_cn: '活性胰岛素（IOB）'
      ,pl: 'Aktywna insulina'
      ,hu: 'Aktív inzulin (IOB)'
    }
    ,'Current target' : {
      cs:'Aktuální cílová hodnota'
      ,he: 'מטרה נוכחית '
      ,de: 'Aktueller Zielbereich'
      ,dk: 'Aktuelt mål'
      ,ro: 'Țintă curentă'
      ,fr: 'Cible actuelle'
      ,ru: 'Актуальное целевое значение'
      ,bg: 'Настояща целева КЗ'
      ,hr: 'Trenutni cilj'
      ,sv: 'Aktuellt mål'
      ,nb: 'Gjeldende mål'
      ,fi: 'Tämänhetkinen tavoite'
      ,es: 'Objetivo actual'
      ,pt: 'Meta atual'
      ,sk: 'Aktuálny cieľ'
      ,ko: '현재 목표'
      ,it: 'Obiettivo attuale'
      ,nl: 'huidig doel'
      ,tr: 'Mevcut hedef'
      ,zh_cn: '当前目标'
      ,pl: 'Aktualny cel'
      ,hu: 'Jelenlegi cél'
    }
    ,'Expected effect' : {
      cs:'Očekávaný efekt'
      ,he: 'אפקט צפוי '
      ,de: 'Erwarteter Effekt'
      ,dk: 'Forventet effekt'
      ,ro: 'Efect presupus'
      ,fr: 'Effect escompté'
      ,ru: 'Ожидаемый эффект'
      ,bg: 'Очакван ефект'
      ,hr: 'Očekivani efekt'
      ,sv: 'Förväntad effekt'
      ,nb: 'Forventet effekt'
      ,fi: 'Oletettu vaikutus'
      ,pt: 'Efeito esperado'
      ,es: 'Efecto previsto'
      ,sk: 'Očakávaný efekt'
      ,ko: '예상 효과'
      ,it: 'Effetto Previsto'
      ,nl: 'verwacht effect'
      ,tr: 'Beklenen etki'
      ,zh_cn: '预期效果'
      ,pl: 'Oczekiwany efekt'
      ,hu: 'Elvárt efektus'
    }
    ,'Expected outcome' : {
      cs:'Očekávaný výsledek'
      ,he: 'תוצאת צפויה '
      ,de: 'Erwartetes Ergebnis'
      ,dk: 'Forventet udfald'
      ,ro: 'Rezultat așteptat'
      ,fr: 'Résultat escompté'
      ,ru: 'Ожидаемый результат'
      ,bg: 'Очакван резултат'
      ,hr: 'Očekivani ishod'
      ,sv: 'Förväntat resultat'
      ,nb: 'Forventet resultat'
      ,fi: 'Oletettu lopputulos'
      ,pt: 'Resultado esperado'
      ,es: 'Resultado previsto'
      ,sk: 'Očakávaný výsledok'
      ,ko: '예상 결과'
      ,it: 'Risultato previsto'
      ,nl: 'Veracht resultaat'
      ,tr: 'Beklenen sonuç'
      ,zh_cn: '预期结果'
      ,pl: 'Oczekowany resultat'
      ,hu: 'Elvárt eredmény'
    }
    ,'Carb Equivalent' : {
      cs:'Ekvivalent v sacharidech'
      ,he: 'מקבילה בפחמימות '
      ,de: 'Kohlenhydrat-Äquivalent'
      ,dk: 'Kulhydrat ækvivalent'
      ,ro: 'Echivalență în carbohidrați'
      ,fr: 'Equivalent glucidique'
      ,ru: 'Эквивалент в углеводах'
      ,bg: 'Равностойност във ВХ'
      ,hr: 'Ekvivalent u UGH'
      ,sv: 'Kolhydratsinnehåll'
      ,nb: 'Karbohydratekvivalent'
      ,nl: 'Koolhydraat equivalent'
      ,fi: 'Hiilihydraattivastaavuus'
      ,es: 'Equivalencia en Carbohidratos'
      ,pt: 'Equivalente em carboidratos'
      ,sk: 'Sacharidový ekvivalent'
      ,ko: '탄수화물 양'
      ,it: 'Carb equivalenti'
      ,tr: 'Karbonhidrat eşdeğeri'
      ,zh_cn: '碳水当量'
      ,pl: 'Odpowiednik w węglowodanach'
      ,hu: 'Szénhidrát megfelelője'
    }
    ,'Excess insulin equivalent %1U more than needed to reach low target, not accounting for carbs' : {
      cs:'Nadbytek inzulínu: o %1U více, než na dosažení spodní hranice cíle. Nepočítáno se sacharidy.'
      ,he: 'עודף אינסולין שווה ערך ליחידה אחת%1 יותר מאשר הצורך להגיע ליעד נמוך, לא לוקח בחשבון פחמימות '
      ,de: 'Überschüssiges Insulin: %1U mehr als zum Erreichen der Untergrenze notwendig, Kohlenhydrate sind unberücksichtigt'
      ,dk: 'Overskud af insulin modsvarende %1U mere end nødvendigt for at nå lav mål, kulhydrater ikke medregnet'
      ,es: 'Exceso de insulina en %1U más de lo necesario para alcanzar un objetivo bajo, sin tener en cuenta los carbohidratos'
      ,ro: 'Insulină în exces: %1U mai mult decât este necesar pentru a atinge ținta inferioară, fără a ține cont de carbohidrați'
      ,fr: 'Insuline en excès: %1U de plus que nécessaire pour atteindre la cible inférieure, sans prendre en compte les glucides'
      ,bg: 'Излишният инсулин %1U е повече от необходимия за достигане до долната граница, ВХ не се вземат под внимание'
      ,hr: 'Višak inzulina je %1U više nego li je potrebno da se postigne donja ciljana granica, ne uzevši u obzir UGH'
      ,ru: 'Избыток инсулина равного %1U, необходимого для достижения нижнего целевого значения, углеводы не приняты в расчет'
      ,sv: 'Överskott av insulin motsvarande %1U mer än nödvändigt för att nå lågt målvärde, kolhydrater ej medräknade'
      ,nb: 'Insulin tilsvarende %1U mer enn det trengs for å nå lavt mål, karbohydrater ikke medregnet'
      ,nl: 'Insulineoverschot van %1U om laag doel te behalen (excl. koolhydraten)'
      ,fi: 'Liikaa insuliinia: %1U enemmän kuin tarvitaan tavoitteeseen pääsyyn (huomioimatta hiilihydraatteja)'
      , pl: 'Nadmiar insuliny, %1J więcej niż potrzeba, aby osiągnąć cel dolnej granicy, nie biorąc pod uwagę węglowodanów'
      ,pt: 'Excesso de insulina equivalente a %1U além do necessário para atingir a meta inferior, sem levar em conta carboidratos'
      ,sk: 'Nadbytok inzulínu o %1U viac ako je potrebné na dosiahnutie spodnej cieľovej hranice. Neráta sa so sacharidmi.'
      ,ko: '낮은 혈당 목표에 도달하기 위해 필요한 인슐린양보다 %1U의 인슐린 양이 초과 되었고 탄수화물 양이 초과되지 않았습니다.'
      ,it: 'L\'eccesso d\'insulina equivalente %1U più che necessari per raggiungere l\'obiettivo basso, non rappresentano i carboidrati.'
      ,tr: 'Fazla insülin: Karbonhidratları dikkate alınmadan, alt hedefe ulaşmak için gerekenden %1U\'den daha fazla' //???
      ,zh_cn: '胰岛素超过至血糖下限目标所需剂量%1单位，不计算碳水化合物'
      ,hu: 'Felesleges inzulin megegyező %1U egységgel az alacsony cél eléréséhez, nem számolva a szénhidrátokkal'
    }
    ,'Excess insulin equivalent %1U more than needed to reach low target, MAKE SURE IOB IS COVERED BY CARBS' : {
      cs:'Nadbytek inzulínu: o %1U více, než na dosažení spodní hranice cíle. UJISTĚTE SE, ŽE JE TO POKRYTO SACHARIDY'
      ,he: 'עודף אינסולין %1 נדרש כדי להגיע למטרה התחתונה. שים לב כי עליך לכסות אינסולין בגוף על ידי פחמימות '
      ,de: 'Überschüssiges Insulin: %1U mehr als zum Erreichen der Untergrenze notwendig. SICHERSTELLEN, DASS IOB DURCH KOHLENHYDRATE ABGEDECKT WIRD'
      ,dk: 'Overskud af insulin modsvarande %1U mere end nødvendigt for at nå lav målværdi, VÆR SIKKER PÅ AT IOB ER DÆKKET IND AF KULHYDRATER'
      ,ro: 'Insulină în exces: %1U mai mult decât este necesar pentru a atinge ținta inferioară, ASIGURAȚI-VĂ CĂ  INSULINA ESTE ACOPERITĂ DE CARBOHIDRAȚI'
      ,fr: 'Insuline en excès: %1U de plus que nécessaire pour atteindre la cible inférieure, ASSUREZ UN APPORT SUFFISANT DE GLUCIDES'
      ,bg: 'Излишният инсулин %1U е повече от необходимия за достигане до долната граница, ПРОВЕРИ ДАЛИ IOB СЕ ПОКРИВА ОТ ВЪГЛЕХИДРАТИТЕ'
      ,hr: 'Višak inzulina je %1U više nego li je potrebno da se postigne donja ciljana granica, OBAVEZNO POKRIJTE SA UGH'
      ,ru: 'Избыток инсулина, равного %1U, необходимого для достижения нижнего целевого значения, ПОКРОЙТЕ АКТИВН IOB ИНСУЛИН УГЛЕВОДАМИ'
      ,sv: 'Överskott av insulin motsvarande %1U mer än nödvändigt för att nå lågt målvärde, SÄKERSTÄLL ATT IOB TÄCKS AV KOLHYDRATER'
      ,es: 'Exceso de insulina en %1U más de la necesaria para alcanzar objetivo inferior. ASEGÚRESE QUE LA INSULINA ACTIVA IOB ESTA CUBIERTA POR CARBOHIDRATOS'
      ,nb: 'Insulin tilsvarende %1U mer enn det trengs for å nå lavt mål, PASS PÅ AT AKTIVT INSULIN ER DEKKET OPP MED KARBOHYDRATER'
      ,nl: 'Insulineoverschot van %1U om laag doel te behalen, ZORG VOOR VOLDOENDE KOOLHYDRATEN VOOR DE ACTIEVE INSULINE'
      ,fi: 'Liikaa insuliinia: %1U enemmän kuin tarvitaan tavoitteeseen pääsyyn, VARMISTA RIITTÄVÄ HIILIHYDRAATTIEN SAANTI'
      ,pt: 'Excesso de insulina equivalente a %1U além do necessário para atingir a meta inferior. ASSEGURE-SE DE QUE A IOB ESTEJA COBERTA POR CARBOIDRATOS'
      ,sk: 'Nadbytok inzulínu o %1U viac ako je potrebné na dosiahnutie spodnej cieľovej hranice. UISTITE SA, ŽE JE TO POKRYTÉ SACHARIDMI'
      ,ko: '낮은 혈당 목표에 도달하기 위해 필요한 인슐린양보다 %1U의 인슐린 양이 초과 되었습니다. 탄수화물로 IOB를 커버하세요.'
      ,it: 'L\'eccesso d\'insulina equivalente %1U più che necessario per raggiungere l\'obiettivo basso, ASSICURARSI IOB SIA COPERTO DA CARBOIDRATI'
      ,tr: 'Fazla insülin: Alt KŞ hedefine ulaşmak için gerekenden %1 daha fazla insülin.IOB(Aktif İnsülin) Karbonhidrat tarafından karşılandığından emin olun.'
      ,zh_cn: '胰岛素超过至血糖下限目标所需剂量%1单位，确认IOB（活性胰岛素）被碳水化合物覆盖'
      ,pl: 'Nadmiar insuliny: o %1J więcej niż potrzeba, aby osiągnąć cel dolnej granicy. UPEWNIJ SIĘ, ŻE AKTYWNA INSULINA JEST POKRYTA WĘGLOWODANAMI'
      ,hu: 'Felesleges inzulin megegyező %1U egységgel az alacsony cél eléréséhez. FONTOS, HOGY A IOB LEGYEN SZÉNHIDRÁTTAL TAKARVA'
    }
    ,'%1U reduction needed in active insulin to reach low target, too much basal?' : {
      cs:'Nutné snížení aktivního inzulínu o %1U k dosažení spodního cíle. Příliž mnoho bazálu?'
      ,he: 'צריך %1 פחות אינסולין כדי להגיע לגבול התחתון. האם הרמה הבזלית גבוהה מדי? '
      ,de: 'Aktives Insulin um %1U reduzieren, um Untergrenze zu erreichen. Basal zu hoch?'
      ,dk: '%1U reduktion nødvendig i aktiv insulin for at nå lav mål, for meget basal?'
      ,ro: '%1U sunt în plus ca insulină activă pentru a atinge ținta inferioară, bazala este prea mare?'
      ,fr: 'Réduction d\'insuline active nécessaire pour atteindre la cible inférieure. Débit basal trop élevé ?'
      ,bg: '%1U намаляне е необходимо в активния инсулин до достигане до долната граница, прекалено висок базал?'
      ,hr: 'Potrebno je smanjiti aktivni inzulin za %1U kako bi se dostigla donja ciljana granica, previše bazala? '
      ,ru: 'Для достижения нижнего целевого значения необходимо понизить величину активного инсулина на %1U, велика база?'
      ,sv: '%1U minskning nödvändig i aktivt insulin för att nå lågt målvärde, för hög basal?'
      ,nb: '%1U reduksjon trengs i aktivt insulin for å nå lavt mål, for høy basal?'
      ,fi: 'Pääset tavoitteesen vähentämällä %1U aktiivista insuliinia, liikaa basaalia?'
      ,es: 'Se necesita una reducción del %1U insulina activa para objetivo inferior, exceso en basal?'
      ,pt: 'Necessária redução de %1U na insulina ativa para atingir a meta inferior, excesso de basal?'
      ,nl: '%1U reductie vereist in actieve insuline om laag doel te bereiken, teveel basaal?'
      ,sk: 'Nutné zníženie aktívneho inzulínu o %1U pre dosiahnutie spodnej cieľovej hranice. Príliš veľa bazálu?'
      ,ko: '낮은 혈당 목표에 도달하기 위해 활성 인슐린 양을 %1U 줄일 필요가 있습니다. basal양이 너무 많습니까?'
      ,it: 'Riduzione 1U% necessaria d\'insulina attiva per raggiungere l\'obiettivo basso, troppa basale?'
      ,tr: 'Alt KŞ hedefi için %1U aktif insülin azaltılmalı, bazal oranı çok mu yüksek?'
      ,zh_cn: '活性胰岛素已可至血糖下限目标，需减少%1单位，基础率过高？'
      ,pl: '%1J potrzebnej redukcji w aktywnej insulinie, aby osiągnąć niski cel dolnej granicy, Za duża dawka podstawowa ?'
      ,hu: '%1U egységnyi inzulin redukció szükséges az alacsony cél eléréséhez, túl magas a bazál?'
    }
    ,'basal adjustment out of range, give carbs?' : {
      cs:'úprava změnou bazálu není možná. Podat sacharidy?'
      ,he: 'השינוי ברמה הבזלית גדול מדי. תן פחמימות? '
      ,de: 'Basalrate geht außerhalb des Zielbereiches. Kohlenhydrate nehmen?'
      ,dk: 'basalændring uden for grænseværdi, giv kulhydrater?'
      ,ro: 'ajustarea bazalei duce în afara intervalului țintă. Suplimentați carbohirații?'
      ,fr: 'ajustement de débit basal hors de limites, prenez des glucides?'
      ,bg: 'Корекция на базала не е възможна, добавка на въглехидрати? '
      ,hr: 'prilagodba bazala je izvan raspona, dodati UGH?'
      ,ru: 'Корректировка базала вне диапазона, добавить углеводов?'
      ,sv: 'basaländring utanför gräns, ge kolhydrater?'
      ,es: 'ajuste basal fuera de rango, dar carbohidratos?'
      ,nb: 'basaljustering utenfor tillatt område, gi karbohydrater?'
      ,fi: 'säätö liian suuri, anna hiilihydraatteja?'
      ,pt: 'ajuste de basal fora da meta, dar carboidrato?'
      ,sk: 'úprava pomocou zmeny bazálu nie je možná. Podať sacharidy?'
      ,ko: '적정 basal 양의 범위를 초과했습니다. 탄수화물 보충 하시겠습니까?'
      ,it: 'regolazione basale fuori campo, dare carboidrati?'
      ,nl: 'basaal aanpassing buiten bereik, geef KH?'
      ,tr: 'Bazal oran ayarlaması limit dışı, karbonhidrat alınsın mı?'
      ,zh_cn: '基础率调整在范围之外，需要碳水化合物？'
      ,pl: 'dawka podstawowa poza zakresem, podać węglowodany?'
      ,hu: 'bazál változtatása az arányokon kívül esik, szénhidrát bevitele?'
    }
    ,'basal adjustment out of range, give bolus?' : {
      cs:'úprava změnou bazálu není možná. Podat bolus?'
      ,he: 'השינוי ברמה הבזלית גדול מדי. תן אינסולין? '
      ,de: 'Anpassung der Basalrate  außerhalb des Zielbereichs. Bolus abgeben?'
      ,dk: 'basalændring udenfor grænseværdi, giv bolus?'
      ,ro: 'ajustarea bazalei duce în afara intervalului țintă. Suplimentați insulina?'
      ,fr: 'ajustement de débit basal hors de limites, prenez un bolus?'
      ,bg: 'Корекция на базала не е възможна, добавка на болус? '
      ,hr: 'prilagodna bazala je izvan raspona, dati bolus?'
      ,ru: 'Корректировка базала вне диапазона, добавить болюс?'
      ,sv: 'basaländring utanför gräns, ge bolus?'
      ,nb: 'basaljustering utenfor tillatt område, gi bolus?'
      ,fi: 'säätö liian suuri, anna bolus?'
      ,es: 'Ajuste de basal fuera de rango, corregir con insulina?'
      ,pt: 'ajuste de basal fora da meta, dar bolus de correção?'
      ,sk: 'úprava pomocou zmeny bazálu nie je možná. Podať bolus?'
      ,ko: '적정 basal 양의 범위를 초과했습니다. bolus를 추가하시겠습니까?'
      ,it: 'regolazione basale fuori campo, dare bolo?'
      ,nl: 'basaal aanpassing buiten bereik, bolus?'
      ,tr: 'Bazal oran ayarlaması limit dışı, bolus alınsın mı?'
      ,zh_cn: '基础率调整在范围之外，需要大剂量？'
      ,pl: 'dawka podstawowa poza zakresem, podać insulinę?'
      ,hu: 'bazál változtatása az arányokon kívül esik, bólusz beadása?'
    }
    ,'above high' : {
      cs:'nad horním'
      ,he: 'מעל גבוה '
      ,de: 'überhalb Obergrenze'
      ,dk: 'over højt grænse'
      ,ro: 'peste ținta superioară'
      ,fr: 'plus haut que la limite supérieure'
      ,ru: 'Выше верхней границы'
      ,bg: 'над горната'
      ,hr: 'iznad gornje granice'
      ,sv: 'över hög nivå'
      ,nb: 'over høy grense'
      ,fi: 'yli korkean'
      ,pt: 'acima do limite superior'
      ,sk: 'nad horným'
      ,es: 'por encima límite superior'
      ,ko: '고혈당 초과'
      ,it: 'sopra alto'
      ,nl: 'boven hoog'
      ,tr: 'üzerinde yüksek'
      ,zh_cn: '血糖过高'
      ,zh_tw: '血糖過高'
      ,pl: 'powyżej wysokiego'
      ,hu: 'magas felett'
    }
    ,'below low' : {
      cs:'pod spodním'
      ,he: 'מתחת נמוך '
      ,de: 'unterhalb Untergrenze'
      ,dk: 'under lavt grænse'
      ,ro: 'sub ținta inferioară'
      ,fr: 'plus bas que la limite inférieure'
      ,bg: 'под долната'
      ,hr: 'ispod donje granice'
      ,ru: 'Ниже нижней границы'
      ,sv: 'under låg nivå'
      ,nb: 'under lav grense'
      ,fi: 'alle matalan'
      ,es: 'por debajo límite inferior'
      ,pt: 'abaixo do limite inferior'
      ,sk: 'pod spodným'
      ,ko: '저혈당 미만'
      ,it: 'sotto bassa'
      ,nl: 'onder laag'
      ,tr: 'altında düşük'
      ,zh_cn: '血糖过低'
      ,zh_tw: '血糖過低'
      ,pl: 'poniżej niskiego'
      ,hu: 'alacsony alatt'
    }
    ,'Projected BG %1 target' : {
      cs:'Předpokládaná glykémie %1 cílem'
      ,he: 'תחזית רמת סןכר %1 '
      ,de: 'Erwarteter BZ %1'
      ,dk: 'Ønsket BS %1 mål'
      ,ro: 'Glicemie estimată %1 în țintă'
      ,fr: 'Glycémie cible projetée %1 '
      ,bg: 'Предполагаемата КЗ %1 в граници'
      ,hr: 'Procjena GUK %1 cilja'
      ,ru: 'Расчетная целевая гликемия %1'
      ,sv: 'Önskat BS %1 mål'
      ,nb: 'Ønsket BS %1 mål'
      ,fi: 'Laskettu VS %1 tavoitteen'
      ,pt: 'Meta de glicemia estimada %1'
      ,sk: 'Predpokladaná glykémia %1 cieľ'
      ,ko: '목표된 혈당 %1'
      ,it: 'Proiezione BG %1 obiettivo'
      ,es: 'Glucemia estimada %1 en objetivo'
      ,nl: 'Verwachte BG %1 doel'
      ,tr: 'Beklenen KŞ %1 hedefi'
      ,zh_cn: '预计血糖%1目标'
      ,pl: 'Oczekiwany poziom glikemii %1'
      ,hu: 'Kiszámított BG cél %1'
    }
    ,'aiming at' : {
      cs:'s cílem'
      ,he: 'מטרה '
      ,de: 'angestrebt werden'
      ,dk: 'ønsket udfald'
      ,fr: 'visant'
      ,ro: 'ținta este'
      ,ru: 'цель на'
      ,bg: 'цел към'
      ,hr: 'ciljano'
      ,sv: 'önskad utgång'
      ,nb: 'sikter på'
      ,fi: 'tavoitellaan'
      ,es: 'resultado deseado'
      ,pt: 'meta'
      ,sk: 'cieľom'
      ,ko: '목표'
      ,it: 'puntare a'
      ,nl: 'doel is'
      ,tr: 'istenen sonuç'
      ,zh_cn: '目标在'
      ,pl: 'pożądany wynik'
      ,hu: 'cél'
    }
    ,'Bolus %1 units' : {
      cs:'Bolus %1 jednotek'
      ,he: 'בולוס %1 יחידות '
      ,de: 'Bolus von %1 Einheiten'
      ,dk: 'Bolus %1 enheder'
      ,ro: 'Bolus de %1 unități'
      ,fr: 'Bolus %1 unités'
      ,bg: 'Болус %1 единици'
      ,hr: 'Bolus %1 jedinica'
      ,ru: 'Болюс %1 единиц'
      ,sv: 'Bolus %1 enheter'
      ,nb: 'Bolus %1 enheter'
      ,fi: 'Bolusta %1 yksikköä'
      ,pt: 'Bolus %1 unidades'
      ,es: 'Bolus %1 unidades'
      ,sk: 'Bolus %1 jednotiek'
      ,ko: 'Bolus %1 단위'
      ,it: 'Bolo %1 unità'
      ,nl: 'Bolus %1 eenheden'
      ,tr: 'Bolus %1 Ünite'
      ,zh_cn: '大剂量%1单位'
      ,pl: 'Bolus %1 jednostek'
      ,hu: 'Bólus %1 egységet'
    }
    ,'or adjust basal' : {
      cs:'nebo úprava bazálu'
      ,he: 'או שנה רמה בזלית '
      ,de: 'oder Basal anpassen'
      ,dk: 'eller juster basal'
      ,ro: 'sau ajustează bazala'
      ,fr: 'ou ajuster le débit basal'
      ,bg: 'или корекция на базала'
      ,hr: 'ili prilagodba bazala'
      ,ru: 'или корректировать базал'
      ,sv: 'eller justera basal'
      ,nb: 'eller justere basal'
      ,fi: 'tai säädä basaalia'
      ,pt: 'ou ajuste basal'
      ,es: 'o ajustar basal'
      ,sk: 'alebo úprava bazálu'
      ,ko: '또는 조절 basal'
      ,it: 'o regolare basale'
      ,nl: 'of pas basaal aan'
      ,tr: 'ya da bazal ayarlama'
      ,zh_cn: '或调整基础率'
      ,pl: 'lub dostosuj dawkę bazową'
      ,hu: 'vagy a bazál változtatása'
    }
    ,'Check BG using glucometer before correcting!' : {
      cs:'Před korekcí zkontrolujte glukometrem glykémii!'
      ,he: 'מדוד רמת סוכר בדם באמצעות מד סוכר לפני תיקון '
      ,de: 'Überprüfe deinen BZ mit dem Messgerät, bevor du eine Korrektur vornimmst!'
      ,dk: 'Kontrollere blodsukker med fingerprikker / blodsukkermåler før der korrigeres!'
      ,ro: 'Verifică glicemia cu glucometrul înainte de a face o corecție!'
      ,fr: 'Vérifier la glycémie avec un glucomètre avant de corriger!'
      ,bg: 'Провери КЗ с глюкомер, преди кореция!'
      ,hr: 'Provjeri GUK glukometrom prije korekcije!'
      ,ru: 'Перед корректировкой сверьте ГК с глюкометром'
      ,sv: 'Kontrollera blodglukos med fingerstick före korrigering!'
      ,nb: 'Sjekk blodsukker før korrigering!'
      ,fi: 'Tarkista VS mittarilla ennen korjaamista!'
      ,pt: 'Verifique glicemia de ponta de dedo antes de corrigir!'
      ,es: 'Verifique glucemia antes de corregir!'
      ,sk: 'Pred korekciou skontrolujte glykémiu glukometrom!'
      ,ko: '수정하기 전에 혈당체크기를 사용하여 혈당을 체크하세요!'
      ,it: 'Controllare BG utilizzando glucometro prima di correggere!'
      ,nl: 'Controleer BG met bloeddruppel voor correctie!'
      ,zh_cn: '校正前请使用血糖仪测量血糖！'
      ,pl: 'Sprawdź glikemię z krwi przed podaniem korekty!'
      ,tr: 'Düzeltme bolusu öncesi glikometreyle parmaktan KŞini kontrol edin!'
      ,hu: 'Ellenőrizd a cukorszintet mérővel korrekció előtt!'
    }
    ,'Basal reduction to account %1 units:' : {
      cs:'Úprava bazálu pro náhradu bolusu %1 U '
      ,he: 'משנה רמה בזלית בשביל %1 יחידות '
      ,de: 'Reduktion der Basalrate um %1 Einheiten zu kompensieren'
      ,dk: 'Basalsænkning for at nå %1 enheder'
      ,ro: 'Reducere bazală pentru a compensa %1 unități:'
      ,fr: 'Réduction du débit basal pour obtenir l\'effet d\' %1 unité'
      ,bg: 'Намаляне на базала с %1 единици'
      ,hr: 'Smanjeni bazal da uračuna %1 jedinica:'
      ,ru: 'Снижение базы из-за %1 единиц болюса'
      ,sv: 'Basalsänkning för att nå %1 enheter'
      ,nb: 'Basalredusering for å nå %1 enheter'
      ,fi: 'Basaalin vähennys saadaksesi %1 yksikön vaikutuksen:'
      ,pt: 'Redução de basal para compensar %1 unidades:'
      ,es: 'Reducir basal para compesar %1 unidades:'
      ,sk: 'Úprava bazálu pre výpočet %1 jednotiek:'
      ,ko: '%1 단위로 계산하기 위해 Basal 감소'
      ,it: 'Riduzione basale per conto %1 unità:'
      ,nl: 'Basaal verlaagd voor %1 eenheden'
      ,zh_cn: '基础率减少到%1单位'
      ,pl: 'Dawka bazowa zredukowana do 1% J'
      ,tr: '%1 birimi telafi etmek için azaltılmış Bazaloranı:'
      ,hu: 'A bazál csökkentése %1 egység kiszámításához:'
    }
    ,'30m temp basal' : {
      cs:'30ti minutový dočasný bazál'
      ,he: 'שלושים דקות רמה בזלית זמנית '
      ,de: '30min temporäres Basal'
      ,dk: '30 minuters midlertidig basal'
      ,ro: 'bazală temporară de 30 minute'
      ,fr: 'débit basal temporaire de 30 min'
      ,bg: '30м временен базал'
      ,hr: '30m temp bazal'
      ,ru: '30 мин врем базал'
      ,sv: '30 minuters temporär basal'
      ,nb: '30 minutters midlertidig basal'
      ,fi: '30m tilapäinen basaali'
      ,pt: 'Basal temp 30m'
      ,es: '30 min. temporal basal'
      ,sk: '30 minutový dočasný bazál'
      ,ko: '30분 임시 basal'
      ,it: '30m basale temp'
      ,nl: '30 minuten tijdelijke basaal'
      ,zh_cn: '30分钟临时基础率'
      ,pl: '30 minut tymczasowej dawki bazowej'
      ,tr: '30 dk. geçici Bazal '
      ,hu: '30p általános bazál'
    }
    ,'1h temp basal' : {
      cs:'hodinový dočasný bazál'
      ,he: 'שעה רמה בזלית זמנית '
      ,de: '1h temporäres Basal'
      ,dk: '60 minutters midlertidig basal'
      ,ro: 'bazală temporară de 1 oră'
      ,fr: 'débit basal temporaire de 1 heure'
      ,bg: '1 час временен базал'
      ,hr: '1h temp bazal'
      ,ru: '1 час врем базал'
      ,sv: '60 minuters temporär basal'
      ,nb: '60 minutters midlertidig basal'
      ,fi: '1h tilapäinen basaali'
      ,es: '1h temporal Basasl'
      ,pt: 'Basal temp 1h'
      ,sk: 'hodinový dočasný bazál'
      ,ko: '1시간 임시 basal'
      ,it: '1h basale temp'
      ,nl: '1 uur tijdelijke basaal'
      ,zh_cn: '1小时临时基础率'
      ,pl: '1 godzina tymczasowej dawki bazowej'
      ,tr: '1 sa. geçici bazal'
      ,hu: '10 általános bazál'
    }
    ,'Cannula change overdue!' : {
      cs:'Čas na výměnu set vypršel!'
      ,he: 'יש צורך בהחלפת קנולה! '
      ,de: 'Kanülenwechsel überfällig!'
      ,dk: 'Tid for skift af Infusionssæt overskredet!'
      ,ro: 'Depășire termen schimbare canulă!'
      ,bg: 'Времето за смяна на сет просрочено'
      ,hr: 'Prošao rok za zamjenu kanile!'
      ,fr: 'Dépassement de date de changement de canule!'
      ,ru: 'Срок замены катетера помпы истек'
      ,sv: 'Infusionsset, bytestid överskriden'
      ,nb: 'Byttetid for infusjonssett overskredet'
      ,fi: 'Kanyylin ikä yli määräajan!'
      ,pt: 'Substituição de catéter vencida!'
      ,es: '¡Cambio de agujas vencido!'
      ,sk: 'Výmena kanyli po lehote!'
      ,ko: '주입세트(cannula) 기한이 지났습니다. 변경하세요!'
      ,it: 'Cambio Cannula in ritardo!'
      ,nl: 'Cannule te oud!'
      ,zh_cn: '超过更换管路的时间'
      ,pl: 'Przekroczono czas wymiany wkłucia!'
      ,tr: 'Kanül değişimi gecikmiş!'
      ,hu: 'Kanil cseréjének ideje elmúlt'
    }
    ,'Time to change cannula' : {
      cs:'Čas na výměnu setu'
      ,he: 'הגיע הזמן להחליף קנולה '
      ,de: 'Es ist Zeit, die Kanüle zu wechseln'
      ,dk: 'Tid til at skifte infusionssæt'
      ,fr: 'Le moment est venu de changer de canule'
      ,ro: 'Este vremea să schimbați canula'
      ,bg: 'Време за смяна на сет'
      ,hr: 'Vrijeme za zamjenu kanile'
      ,ru: 'Пора заменить катетер помпы'
      ,sv: 'Dags att byta infusionsset'
      ,nb: 'På tide å bytte infusjonssett'
      ,fi: 'Aika vaihtaa kanyyli'
      ,es:' Hora sustituir cánula'
      ,pt: 'Hora de subistituir catéter'
      ,sk: 'Čas na výmenu kanyli'
      ,ko: '주입세트(cannula)를 변경할 시간'
      ,it: 'Tempo di cambiare la cannula'
      ,nl: 'Verwissel canule'
      ,zh_cn: '已到更换管路的时间'
      ,pl: 'Czas do wymiany wkłucia'
      ,tr: 'Kanül değiştirme zamanı'
      ,hu: 'Ideje kicserélni a kanilt'
    }
    ,'Change cannula soon' : {
      cs:'Blíží se čas na výměnu setu'
      ,he: 'החלף קנולה בקרוב '
      ,de: 'Kanüle bald wechseln'
      ,dk: 'Skift infusionssæt snart'
      ,ro: 'Schimbați canula în curând'
      ,fr: 'Changement de canule bientòt'
      ,bg: 'Смени сета скоро'
      ,hr: 'Zamijena kanile uskoro'
      ,ru: 'Приближается время замены катетера помпы'
      ,sv: 'Byt infusionsset snart'
      ,nb: 'Bytt infusjonssett snart'
      ,fi: 'Vaihda kanyyli pian'
      ,pt: 'Substituir catéter em breve'
      ,sk: 'Čoskoro bude potrebné vymeniť kanylu'
      ,ko: '주입세트(cannula)를 곧 변경하세요.'
      ,it: 'Cambio cannula prossimamente'
      ,nl: 'Verwissel canule binnenkort'
      ,zh_cn: '接近更换管路的时间'
      ,pl: 'Wkrótce wymiana wkłucia'
      ,tr: 'Yakında kanül değiştirin'
      ,hu: 'Hamarosan cseréld ki a kanilt'
    }
    ,'Cannula age %1 hours' : {
      cs:'Stáří setu %1 hodin'
      ,he: 'כיל הקנולה %1 שעות '
      ,de: 'Kanülen Alter %1 Stunden'
      ,dk: 'Infusionssæt tid %1 timer'
      ,fr: 'âge de la canule %1 heures'
      ,ro: 'Vechimea canulei în ore: %1'
      ,bg: 'Сетът е на %1 часове'
      ,hr: 'Staros kanile %1 sati'
      ,ru: 'Катетер помпы работает %1 час'
      ,sv: 'Infusionsset tid %1 timmar'
      ,nb: 'infusjonssett alder %1 timer'
      ,fi: 'Kanyylin ikä %1 tuntia'
      ,pt: 'Idade do catéter %1 horas'
      ,es: 'Cánula usada %1 horas'
      ,sk: 'Vek kanyli %1 hodín'
      ,ko: '주입세트(cannula) %1시간 사용'
      ,it: 'Durata Cannula %1 ore'
      ,nl: 'Canule leeftijd %1 uren'
      ,zh_cn: '管路已使用%1小时'
      ,pl: 'Czas od wymiany wkłucia %1 godzin'
      ,tr: 'Kanül yaşı %1 saat'
      ,hu: 'Kamil életkora %1 óra'
    }
    ,'Inserted' : {
      cs:'Nasazený'
      ,he: 'הוכנס '
      ,de: 'Eingesetzt'
      ,dk: 'Isat'
      ,ro: 'Inserat'
      ,fr: 'Insérée'
      ,bg: 'Поставен'
      ,hr: 'Postavljanje'
      ,ru: 'Установлен'
      ,sv: 'Applicerad'
      ,nb: 'Satt inn'
      ,fi: 'Asetettu'
      ,es: 'Insertado'
      ,pt: 'Inserido'
      ,sk: 'Zavedený'
      ,ko: '삽입된'
      ,it: 'Inserito'
      ,nl: 'Ingezet'
      ,zh_cn: '已植入'
      ,zh_tw: '已植入'
      ,pl: 'Zamontowano'
      ,tr: 'Yerleştirilmiş'
      ,hu: 'Behelyezve'

    }
   ,'CAGE' : {
      cs:'SET'
      ,he: 'גיל הקנולה '
      ,de: 'CAGE'
      ,dk: 'Indstik alder'
      ,ro: 'VC'
      ,bg: 'ВС'
      ,hr: 'Starost kanile'
      ,fr: 'CAGE'
      ,ru: 'КатПомп'
      ,sv: 'Nål'
      ,nb: 'Nål alder'
      ,fi: 'KIKÄ'
      ,pt: 'ICAT'
      ,es: 'Cánula desde'
      ,sk: 'SET'
      ,ko: '주입세트사용기간'
      ,it: 'CAGE'
      ,nl: 'CAGE'
      ,zh_cn: '管路'
      ,zh_tw: '管路'
      ,pl: 'Wiek wkłucia'
      ,tr: 'CAGE'
      ,hu: 'CAGE'
    }
   ,'COB' : {
      cs:'SACH'
      ,he: 'COB '
      ,de: 'COB'
      ,dk: 'COB'
      ,ro: 'COB'
      ,bg: 'АВХ'
      ,hr: 'Aktivni UGH'
      ,fr: 'COB'
      ,ru: 'АктУгл COB'
      ,sv: 'COB'
      ,nb: 'Aktive karbohydrater'
      ,fi: 'AH'
      ,pt: 'COB'
      ,es: 'Carb. activos'
      ,sk: 'SACH'
      ,ko: 'COB'
      ,it: 'COB'
      ,nl: 'COB'
      ,zh_cn: '活性碳水COB'
      ,pl: 'Aktywne węglowodany'
      ,tr: 'COB'
      ,hu: 'COB'
    }
   ,'Last Carbs' : {
      cs:'Poslední sacharidy'
      ,he: 'פחמימות אחרונות '
      ,de: 'Letzte Kohlenhydrate'
      ,dk: 'Seneste kulhydrater'
      ,ro: 'Ultimii carbohidrați'
      ,fr: 'Derniers glucides'
      ,bg: 'Последни ВХ'
      ,hr: 'Posljednji UGH'
      ,ru: 'Прошлые углеводы'
      ,sv: 'Senaste kolhydrater'
      ,nb: 'Siste karbohydrater'
      ,fi: 'Viimeisimmät hiilihydraatit'
      ,pt: 'Último carboidrato'
     ,es: 'último carbohidrato'
      ,sk: 'Posledné sacharidy'
      ,ko: '마지막 탄수화물'
      ,it: 'Ultimi carboidrati'
      ,nl: 'Laatse KH'
      ,zh_cn: '上次碳水'
      ,pl: 'Ostatnie węglowodany'
      ,tr: 'Son Karbonhidrat'
      ,hu: 'Utolsó szénhidrátok'
    }
    ,'IAGE' : {
      cs:'INZ'
      ,he: 'גיל אינסולין '
      ,de: 'IAGE'
      ,dk: 'Insulinalder'
      ,ro: 'VI'
      ,fr: 'IAGE'
      ,bg: 'ИнсСрок'
      ,hr: 'Starost inzulina'
      ,ru: 'ИнсСрок'
      ,sv: 'Insulinålder'
      ,nb: 'Insulinalder'
      ,fi: 'IIKÄ'
      ,pt: 'IddI'
      ,es: 'Insul.desde'
      ,sk: 'INZ'
      ,ko: '인슐린사용기간'
      ,it: 'IAGE'
      ,nl: 'IAGE'
      ,zh_cn: '胰岛素'
      ,zh_tw: '胰島素'
      ,pl: 'Wiek insuliny'
      ,tr: 'IAGE'
      ,hu: 'IAGE'
    }
    ,'Insulin reservoir change overdue!' : {
      cs:'Čas na výměnu zásobníku vypršel!'
      ,he: 'החלף מאגר אינסולין! '
      ,de: 'Ampullenwechsel überfällig!'
      ,dk: 'Tid for skift af insulinreservoir overskredet!'
      ,fr: 'Dépassement de date de changement de réservoir d\'insuline!'
      ,ro: 'Termenul de schimbare a rezervorului de insulină a fost depășit'
      ,bg: 'Смянатата на резервоара просрочена'
      ,hr: 'Prošao rok za zamjenu spremnika!'
      ,ru: 'Срок замены резервуара инсулина истек'
      ,sv: 'Insulinbytestid överskriden'
      ,nb: 'Insulinbyttetid overskrevet'
      ,fi: 'Insuliinisäiliö vanhempi kuin määräaika!'
      ,pt: 'Substituição de reservatório vencida!'
      ,es: 'Excedido plazo del cambio depósito de insulina!'
      ,sk: 'Čas na výmenu inzulínu po lehote!'
      ,ko: '레저보(펌프 주사기)의 사용기한이 지났습니다. 변경하세요!'
      ,it: 'Cambio serbatoio d\'insulina in ritardo!'
      ,nl: 'Verwissel insulinereservoir nu!'
      ,zh_cn: '超过更换胰岛素储液器的时间'
      ,pl: 'Przekroczono czas wymiany zbiornika na insulinę!'
      ,tr: 'İnsülin rezervuarı değişimi gecikmiş!'
      ,hu: 'Inzulin tartály cseréjének ideje elmúlt'
    }
    ,'Time to change insulin reservoir' : {
      cs:'Čas na výměnu zásobníku'
      ,he: 'הגיע הזמן להחליף מאגר אינסולין '
      ,de: 'Es ist Zeit, die Ampulle zu wechseln'
      ,dk: 'Tid til at skifte insulinreservoir'
      ,ro: 'Este timpul pentru schimbarea rezervorului de insulină'
      ,fr: 'Le moment est venu de changer de réservoir d\'insuline'
      ,bg: 'Време е за смяна на резервоара'
      ,hr: 'Vrijeme za zamjenu spremnika'
      ,ru: 'Наступил срок замены резервуара инсулина'
      ,sv: 'Dags att byta insulinreservoar'
      ,nb: 'På tide å bytte insulinreservoar'
      ,fi: 'Aika vaihtaa insuliinisäiliö'
      ,pt: 'Hora de substituir reservatório'
      ,es: 'Hora de sustituir depósito insulina'
      ,sk: 'Čas na výmenu inzulínu'
      ,ko: '레저보(펌프 주사기)를 변경할 시간'
      ,it: 'Momento di cambiare serbatoio d\'insulina'
      ,nl: 'Verwissel insuline reservoir'
      ,zh_cn: '已到更换胰岛素储液器的时间'
      ,pl: 'Czas do zmiany zbiornika na insulinę!'
      ,tr: 'İnsülin rezervuarını değiştirme zamanı!'
      ,hu: 'Itt az ideje az inzulin tartály cseréjének'
    }
    ,'Change insulin reservoir soon' : {
      cs:'Blíží se čas na výměnu zásobníku'
      ,he: 'החלף מאגר אינסולין בקרוב '
      ,de: 'Ampulle bald wechseln'
      ,dk: 'Skift insulinreservoir snart'
      ,ro: 'Rezervorul de insulină trebuie schimbat în curând'
      ,fr: 'Changement de réservoir d\'insuline bientôt'
      ,bg: 'Смени резервоара скоро'
      ,hr: 'Zamjena spremnika uskoro'
      ,ru: 'Наступает срок замены резервуара инсулина'
      ,sv: 'Byt insulinreservoar snart'
      ,nb: 'Bytt insulinreservoar snart'
      ,fi: 'Vaihda insuliinisäiliö pian'
      ,pt: 'Substituir reservatório em brave'
      ,es: 'Sustituir depósito insulina en breve'
      ,sk: 'Čoskoro bude potrebné vymeniť inzulín'
      ,ko: '레저보(펌프 주사기)안의 인슐린을 곧 변경하세요.'
      ,it: 'Cambiare serbatoio d\'insulina prossimamente'
      ,nl: 'Verwissel insuline reservoir binnenkort'
      ,zh_cn: '接近更换胰岛素储液器的时间'
      ,pl: 'Wkrótce wymiana zbiornika na insulinę!'
      ,tr: 'Yakında insülin rezervuarını değiştirin'
      ,hu: 'Hamarosan cseréld ki az inzulin tartályt'
    }
    ,'Insulin reservoir age %1 hours' : {
      cs:'Stáří zásobníku %1 hodin'
      ,he: 'גיל מאגר אינסולין %1 שעות '
      ,de: 'Ampullen Alter %1 Stunden'
      ,dk: 'Insulinreservoiralder %1 timer'
      ,fr: 'Âge du réservoir d\'insuline %1 heures'
      ,ro: 'Vârsta reservorului de insulină %1 ore'
      ,ru: 'Картридж инсулина отработал %1часов'
      ,bg: 'Резервоарът е на %1 часа'
      ,hr: 'Spremnik zamijenjen prije %1 sati'
      ,sv: 'Insulinreservoarsålder %1 timmar'
      ,nb: 'Insulinreservoaralder %1 timer'
      ,fi: 'Insuliinisäiliön ikä %1 tuntia'
      ,pt: 'Idade do reservatório %1 horas'
      ,sk: 'Vek inzulínu %1 hodín'
      ,es: 'Depósito insulina desde %1 horas'
      ,ko: '레저보(펌프 주사기) %1시간 사용'
      ,it: 'IAGE - Durata Serbatoio d\'insulina %1 ore'
      ,nl: 'Insuline reservoir leeftijd %1 uren'
      ,zh_cn: '胰岛素储液器已使用%1小时'
      ,pl: 'Wiek zbiornika na insulinę %1 godzin'
      ,tr: 'İnsülin rezervuar yaşı %1 saat'
      ,hu: 'Az inzulin tartály %1 órája volt cserélve'
    }
    ,'Changed' : {
      cs:'Vyměněno'
      ,he: 'הוחלף '
      ,de: 'Gewechselt'
      ,dk: 'Skiftet'
      ,fr: 'Changé'
      ,ro: 'Schimbat'
      ,ru: 'Замена произведена'
      ,bg: 'Сменен'
      ,hr: 'Promijenjeno'
      ,sv: 'Bytt'
      ,nb: 'Byttet'
      ,fi: 'Vaihdettu'
      ,pt: 'Substituído'
      ,es: 'Cambiado'
      ,sk: 'Vymenený'
      ,ko: '변경됨'
      ,it: 'Cambiato'
      ,nl: 'veranderd'
      ,zh_cn: '已更换'
      ,pl: 'Wymieniono'
      ,tr: 'Değişmiş'
      ,hu: 'Cserélve'
    }
    ,'IOB' : {
      cs:'IOB'
      ,he: 'IOB '
      ,de: 'IOB'
      ,dk: 'IOB'
      ,ro: 'IOB'
      ,ru: 'АктИнс IOB'
      ,fr: 'IOB'
      ,bg: 'АИ'
      ,hr: 'Aktivni inzulin'
      ,sv: 'IOB'
      ,nb: 'Aktivt insulin'
      ,es: 'Insulina Activa IOB'
      ,fi: 'IOB'
      ,pt: 'IOB'
      ,sk: 'IOB'
      ,ko: 'IOB'
      ,it: 'IOB'
      ,nl: 'IOB'
      ,zh_cn: '活性胰岛素IOB'
      ,pl: 'Aktywna insulina'
      ,tr: 'IOB'
      ,hu: 'IOB'
    }
    ,'Careportal IOB' : {
      cs:'IOB z ošetření'
      ,he: 'Careportal IOB '
      ,de: 'Careportal IOB'
      ,dk: 'IOB i Careportal'
      ,ro: 'IOB în Careportal'
      ,ru: 'АктИнс на портале лечения'
      ,fr: 'Careportal IOB'
      ,bg: 'АИ от Кеърпортал'
      ,hr: 'Careportal IOB'
      ,sv: 'IOB i Careportal'
      ,nb: 'Aktivt insulin i Careportal'
      ,fi: 'Careportal IOB'
      ,es: 'Insulina activa en Careportal'
      ,pt: 'IOB do Careportal'
      ,sk: 'IOB z portálu starostlivosti'
      ,ko: '케어포털 IOB'
      ,it: 'IOB Somministrazioni'
      ,nl: 'Careportal IOB'
      ,zh_cn: '服务面板IOB（活性胰岛素）'
      ,pl: 'Aktywna insulina z portalu'
      ,tr: 'Careportal IOB (Aktif İnsülin)'
      ,hu: 'Careportal IOB érték'
    }
    ,'Last Bolus' : {
      cs:'Poslední bolus'
      ,he: 'בולוס אחרון '
      ,de: 'Letzter Bolus'
      ,dk: 'Seneste Bolus'
      ,fr: 'Dernier Bolus'
      ,ro: 'Ultimul bolus'
      ,ru: 'Прошлый болюс'
      ,bg: 'Последен болус'
      ,hr: 'Prethodni bolus'
      ,sv: 'Senaste Bolus'
      ,nb: 'Siste Bolus'
      ,fi: 'Viimeisin bolus'
      ,pt: 'Último bolus'
      ,es: 'Último bolo'
      ,sk: 'Posledný bolus'
      ,ko: '마지막 Bolus'
      ,it: 'Ultimo bolo'
      ,nl: 'Laatste bolus'
      ,zh_cn: '上次大剂量'
      ,pl: 'Ostatni bolus'
      ,tr: 'Son Bolus'
      ,hu: 'Utolsó bólus'
    }
    ,'Basal IOB' : {
      cs:'IOB z bazálů'
      ,he: 'IOB בזלי '
      ,de: 'Basal IOB'
      ,dk: 'Basal IOB'
      ,ro: 'IOB bazală'
      ,ru: 'АктуальнБазал IOB'
      ,fr: 'IOB du débit basal'
      ,bg: 'Базален АИ'
      ,hr: 'Bazalni aktivni inzulin'
      ,sv: 'Basal IOB'
      ,nb: 'Basal Aktivt Insulin'
      ,fi: 'Basaalin IOB'
      ,pt: 'IOB basal'
      ,es: 'Basal Insulina activa'
      ,sk: 'Bazálny IOB'
      ,ko: 'Basal IOB'
      ,it: 'Basale IOB'
      ,nl: 'Basaal IOB'
      ,zh_cn: '基础率IOB（活性胰岛素）'
      ,pl: 'Aktywna insulina z dawki bazowej'
      ,tr: 'Bazal IOB'
      ,hu: 'Bazál IOB'
    }
    ,'Source' : {
      cs:'Zdroj'
      ,he: 'מקור '
      ,de: 'Quelle'
      ,dk: 'Kilde'
      ,ro: 'Sursă'
      ,fr: 'Source'
      ,ru: 'Источник'
      ,bg: 'Източник'
      ,hr: 'Izvor'
      ,sv: 'Källa'
      ,nb: 'Kilde'
      ,fi: 'Lähde'
      ,pt: 'Fonte'
      ,es: 'Fuente'
      ,sk: 'Zdroj'
      ,ko: '출처'
      ,it: 'Fonte'
      ,nl: 'bron'
      ,zh_cn: '来源'
      ,pl: 'Źródło'
      ,tr: 'Kaynak'
      ,hu: 'Forrás'
    }
    ,'Stale data, check rig?' : {
      cs:'Zastaralá data, zkontrolovat mobil?'
      ,he: 'מידע ישן, בדוק את המערכת? '
      ,de: 'Daten sind veraltet, Übertragungsgerät prüfen?'
      ,dk: 'Gammel data, kontrollere uploader?'
      ,ro: 'Date învechite, verificați uploaderul!'
      ,fr: 'Valeurs trop anciennes, vérifier l\'uploadeur'
      ,ru: 'Старые данные, проверьте загрузчик'
      ,bg: 'Стари данни, провери телефона'
      ,hr: 'Nedostaju podaci, provjera opreme?'
      ,es: 'Datos desactualizados, controlar la subida?'
      ,sv: 'Gammal data, kontrollera rigg?'
      ,nb: 'Gamle data, sjekk rigg?'
      ,fi: 'Tiedot vanhoja, tarkista lähetin?'
      , pl: 'Dane są nieaktualne, sprawdź urządzenie transmisyjne.'
      ,pt: 'Dados antigos, verificar uploader?'
      ,sk: 'Zastaralé dáta, skontrolujte uploader'
      ,ko: '오래된 데이터입니다. 확인해 보시겠습니까?'
      ,it: 'dati non aggiornati, controllare il telefono?'
      ,nl: 'Geen data, controleer uploader'
      ,zh_cn: '数据过期，检查一下设备？'
      ,tr: 'Veri güncel değil, vericiyi kontrol et?'
      ,hu: 'Öreg adatok, ellenőrizd a feltöltőt'
    }
    ,'Last received:' : {
      cs:'Naposledy přijato:'
      ,he: 'התקבל לאחרונה: '
      ,de: 'Zuletzt empfangen:'
      ,dk: 'Senest modtaget:'
      ,fr: 'Dernière réception:'
      ,ro: 'Ultimile date:'
      ,ru: 'Получено:'
      ,bg: 'Последно получени'
      ,hr: 'Zadnji podaci od:'
      ,sv: 'Senast mottagen:'
      ,nb: 'Sist mottatt:'
      ,fi: 'Viimeksi vastaanotettu:'
      ,pt: 'Último recebido:'
      ,es: 'Último recibido:'
      ,sk: 'Naposledy prijaté:'
      ,ko: '마지막 수신'
      ,it: 'Ultime ricevute:'
      ,nl: 'laatste ontvangen'
      ,zh_cn: '上次接收：'
      ,pl: 'Ostatnio odebrane:'
      ,tr: 'Son alınan:'
      ,hu: 'Utóljára fogadott:'
    }
    ,'%1m ago' : {
      cs:'%1m zpět'
      ,he: 'לפני %1 דקות '
      ,de: 'vor %1m'
      ,dk: '%1m siden'
      ,ro: 'acum %1 minute'
      ,fr: 'il y a %1 min'
      ,ru: '% мин назад'
      ,bg: 'преди %1 мин.'
      ,hr: 'prije %1m'
      ,sv: '%1m sedan'
      ,nb: '%1m siden'
      ,fi: '%1m sitten'
      ,es: '%1min. atrás'
      ,pt: '%1m atrás'
      ,sk: 'pred %1m'
      ,ko: '%1분 전'
      ,it: '%1m fa'
      ,nl: '%1m geleden'
      ,zh_cn: '%1分钟前'
      ,pl: '%1 minut temu'
      ,tr: '%1 dk. önce'
      ,hu: '%1p ezelőtt'
    }
    ,'%1h ago' : {
      cs:'%1h zpět'
      ,he: 'לפני %1 שעות '
      ,de: 'vor %1h'
      ,dk: '%1t siden'
      ,ro: 'acum %1 ore'
      ,fr: '%1 heures plus tôt'
      ,ru: '% час назад'
      ,bg: 'преди %1 час'
      ,hr: 'prije %1 sati'
      ,sv: '%1h sedan'
      ,nb: '%1h siden'
      ,fi: '%1h sitten'
      ,pt: '%1h atrás'
      ,es: '%1h. atrás'
      ,sk: 'pred %1h'
      ,ko: '%1시간 전'
      ,it: '%1h fa'
      ,nl: '%1u geleden'
      ,zh_cn: '%1小时前'
      ,pl: '%1 godzin temu'
      ,tr: '%1 sa. önce'
      ,hu: '%1ó ezelőtt'
    }
    ,'%1d ago' : {
      cs:'%1d zpět'
      ,he: 'לפני %1 ימים '
      ,de: 'vor 1d'
      ,dk: '%1d siden'
      ,ro: 'acum %1 zile'
      ,fr: '%1 jours plus tôt'
      ,ru: '% дн назад'
      ,bg: 'преди %1 ден'
      ,hr: 'prije %1 dana'
      ,sv: '%1d sedan'
      ,nb: '%1d siden'
      ,fi: '%1d sitten'
      ,pt: '%1d atrás'
      ,es: '%1d atrás'
      ,sk: 'pred %1d'
      ,ko: '%1일 전'
      ,it: '%1d fa'
      ,nl: '%1d geleden'
      ,zh_cn: '%1天前'
      ,pl: '%1 dni temu'
      ,tr: '%1 gün önce'
      ,hu: '%1n ezelőtt'
    }
    ,'RETRO' : {
      cs:'RETRO'
      ,he: 'רטרו '
      ,de: 'RETRO'
      ,dk: 'RETRO'
      ,ro: 'VECHI'
      ,ru: 'ПРОШЛОЕ'
      ,bg: 'РЕТРО'
      ,hr: 'RETRO'
      ,fr: 'RETRO'
      ,sv: 'RETRO'
      ,nb: 'GAMMELT'
      ,fi: 'RETRO'
      ,pt: 'RETRO'
      ,es: 'RETRO'
      ,sk: 'RETRO'
      ,ko: 'RETRO'
      ,it: 'RETRO'
      ,nl: 'RETRO'
      ,zh_cn: '历史数据'
      ,pl: 'RETRO'
      ,tr: 'RETRO Geçmiş'
      ,hu: 'RETRO'
    }
    ,'SAGE' : {
      cs:'SENZ'
      ,he: 'גיל הסנסור '
      ,de:'SAGE'
      ,dk: 'Sensoralder'
      ,ro: 'VS'
      ,ru: 'Сенсор работает'
      ,fr: 'SAGE'
      ,bg: 'ВС'
      ,hr: 'Starost senzora'
      ,sv: 'Sensor'
      ,nb: 'Sensoralder'
      ,fi: 'SIKÄ'
      ,pt: 'IddS'
      ,sk: 'SENZ'
      ,es: 'Sensor desde'
      ,ko: '센서사용기간'
      ,it: 'SAGE'
      ,nl: 'SAGE'
      ,zh_cn: '探头'
      ,zh_tw: '探頭'
      ,pl: 'Wiek sensora'
      ,tr: 'SAGE'
      ,hu: 'SAGE'
    }
    ,'Sensor change/restart overdue!' : {
      cs:'Čas na výměnu senzoru vypršel!'
      ,he: 'שנה או אתחל את הסנסור! '
      ,de: 'Sensorwechsel/-neustart überfällig!'
      ,dk: 'Sensor skift/genstart overskredet!'
      ,ro: 'Depășire termen schimbare/restart senzor!'
      ,fr: 'Changement/Redémarrage du senseur dépassé!'
      ,ru: 'Рестарт сенсора пропущен'
      ,bg: 'Смяната/рестартът на сензора са пресрочени'
      ,hr: 'Prošao rok za zamjenu/restart senzora!'
      ,sv: 'Sensor byte/omstart överskriden!'
      ,nb: 'Sensor bytte/omstart overskredet!'
      ,fi: 'Sensorin vaihto/uudelleenkäynnistys yli määräajan!'
      ,pt: 'Substituição/reinício de sensor vencido'
      ,es: 'Sustituir/reiniciar, sensor vencido'
      ,sk: 'Čas na výmenu/reštart sensoru uplynul!'
      ,ko: '센서 사용기한이 지났습니다. 센서를 교체/재시작 하세요!'
      ,it: 'Cambio/riavvio del sensore in ritardo!'
      ,nl: 'Sensor vevang/hertstart tijd gepasseerd'
      ,zh_cn: '超过更换/重启探头的时间'
      ,pl: 'Przekroczono czas wymiany/restartu sensora!'
      ,tr: 'Sensör değişimi/yeniden başlatma gecikti!'
      ,hu: 'Szenzor cseréjének / újraindításának ideje lejárt'
    }
    ,'Time to change/restart sensor' : {
      cs:'Čas na výměnu senzoru'
      ,he: 'הגיע הזמן לשנות או לאתחל את הסנסור '
      ,de: 'Es ist Zeit, den Sensor zu wechseln/neuzustarten'
      ,dk: 'Tid til at skifte/genstarte sensor'
      ,ro: 'Este timpul pentru schimbarea senzorului'
      ,ru: 'Время замены/рестарта сенсора'
      ,fr: 'C\'est le moment de changer/redémarrer le senseur'
      ,bg: 'Време за смяна/рестарт на сензора'
      ,hr: 'Vrijeme za zamjenu/restart senzora'
      ,sv: 'Dags att byta/starta om sensorn'
      ,nb: 'På tide å bytte/restarte sensoren'
      ,fi: 'Aika vaihtaa / käynnistää sensori uudelleen'
      ,pt: 'Hora de substituir/reiniciar sensor'
      ,es: 'Hora de sustituir/reiniciar sensor'
      ,sk: 'Čas na výmenu/reštart senzoru'
      ,ko: '센서 교체/재시작 시간'
      ,it: 'Tempo di cambiare/riavvio sensore'
      ,nl: 'Sensor vervangen of herstarten'
      ,zh_cn: '已到更换/重启探头的时间'
      ,pl: 'Czas do wymiany/restartu sensora'
      ,tr: 'Sensörü değiştirme/yeniden başlatma zamanı'
      ,hu: 'Ideje a szenzort cserélni / újraindítani'
    }
    ,'Change/restart sensor soon' : {
      cs:'Blíží se čas na výměnu senzoru'
      ,he: 'שנה או אתחל את הסנסור בקרוב '
      ,de: 'Sensor bald wechseln/neustarten'
      ,dk: 'Skift eller genstart sensor snart'
      ,ro: 'Schimbați/restartați senzorul în curând'
      ,fr: 'Changement/Redémarrage du senseur bientôt'
      ,ru: 'Приближается срок замены/рестарта сенсора'
      ,bg: 'Смени/рестартирай сензора скоро'
      ,hr: 'Zamijena/restart senzora uskoro'
      ,sv: 'Byt/starta om sensorn snart'
      ,nb: 'Bytt/restarta sensoren snart'
      ,fi: 'Vaihda/käynnistä sensori uudelleen pian'
      ,pt: 'Mudar/reiniciar sensor em breve'
      ,es: 'Cambiar/Reiniciar sensor en breve'
      ,sk: 'Čoskoro bude potrebné vymeniť/reštartovať senzor'
      ,ko: '센서를 곧 교체/재시작 하세요'
      ,it: 'Modifica/riavvio sensore prossimamente'
      ,nl: 'Herstart of vervang sensor binnenkort'
      ,zh_cn: '接近更换/重启探头的时间'
      ,pl: 'Wkrótce czas wymiany/restartu sensora'
      ,tr: 'Sensörü yakında değiştir/yeniden başlat'
      ,hu: 'Hamarosan indítsd újra vagy cseréld ki a szenzort'
    }
    ,'Sensor age %1 days %2 hours' : {
      cs:'Stáří senzoru %1 dní %2 hodin'
      ,he: 'גיל הסנסור %1 ימים %2 שעות '
      ,de: 'Sensor Alter %1 Tage %2 Stunden'
      ,dk: 'Sensoralder %1 dage %2 timer'
      ,ro: 'Senzori vechi de %1 zile și %2 ore'
      ,fr: 'Âge su senseur %1 jours et %2 heures'
      ,ru: 'Сенсор работает %1 дн %2 час'
      ,bg: 'Сензорът е на %1 дни %2 часа '
      ,hr: 'Starost senzora %1 dana i %2 sati'
      ,sv: 'Sensorålder %1 dagar %2 timmar'
      ,nb: 'Sensoralder %1 dag %2 timer'
      ,fi: 'Sensorin ikä %1 päivää, %2 tuntia'
      ,pt: 'Idade do sensor %1 dias %2 horas'
      ,es: 'Sensor desde %1 días %2 horas'
      ,sk: 'Vek senzoru %1 dní %2 hodín'
      ,ko: '센서사용기간 %1일 %2시간'
      ,it: 'Durata Sensore %1 giorni %2 ore'
      ,nl: 'Sensor leeftijd %1 dag(en) en %2 uur'
      ,zh_cn: '探头使用了%1天%2小时'
      ,pl: 'Wiek sensora: %1 dni %2 godzin'
      ,tr: 'Sensör yaşı %1 gün %2 saat'
      ,hu: 'Szenzor ideje %1 nap és %2 óra'
    }
    ,'Sensor Insert' : {
      cs: 'Výměna sensoru'
      ,he: 'הכנס סנסור '
      ,de: 'Sensor eingesetzt'
      ,dk: 'Sensor isat'
      ,ro: 'Inserția senzorului'
      ,fr: 'Insertion du senseur'
      ,ru: 'Сенсор установлен'
      ,bg: 'Поставяне на сензора'
      ,hr: 'Postavljanje senzora'
      ,sv: 'Sensor insättning'
      ,nb: 'Sensor satt inn'
      ,fi: 'Sensorin Vaihto'
      ,es: 'Insertar sensor'
      ,pt: 'Inserção de sensor'
      ,sk: 'Výmena senzoru'
      ,ko: '센서삽입'
      ,it: 'SAGE - inserimento sensore'
      ,nl: 'Sensor ingebracht'
      ,zh_cn: '植入探头'
      ,pl: 'Zamontuj sensor'
      ,tr: 'Sensor yerleştirme'
      ,hu: 'Szenzor behelyezve'
    }
    ,'Sensor Start' : {
      cs: 'Znovuspuštění sensoru'
      ,he: 'סנסור התחיל '
      ,de: 'Sensorstart'
      ,dk: 'Sensor start'
      ,ro: 'Pornirea senzorului'
      ,ru: 'Старт сенсора'
      ,fr: 'Démarrage du senseur'
      ,bg: 'Стартиране на сензора'
      ,hr: 'Pokretanje senzora'
      ,sv: 'Sensorstart'
      ,nb: 'Sensorstart'
      ,fi: 'Sensorin Aloitus'
      ,pl: 'Uruchomienie sensora'
      ,pt: 'Início de sensor'
      ,es: 'Inicio del sensor'
      ,sk: 'Štart senzoru'
      ,ko: '센서시작'
      ,it: 'SAGE - partenza sensore'
      ,nl: 'Sensor start'
      ,zh_cn: '启动探头'
      ,tr: 'Sensör başlatma'
      ,hu: 'Szenzor indítása'
    }
    ,'days' : {
      cs: 'dní'
      ,he: 'ימים '
      ,de: 'Tage'
      ,dk: 'dage'
      ,ro: 'zile'
      ,fr: 'jours'
      ,ru: 'дн'
      ,bg: 'дни'
      ,hr: 'dana'
      ,sv: 'dagar'
      ,nb: 'dager'
      ,fi: 'päivää'
      ,pt: 'dias'
      ,es: 'días'
      ,sk: 'dní'
      ,ko: '일'
      ,it: 'giorni'
      ,nl: 'dagen'
      ,zh_cn: '天'
      ,pl: 'dni'
      ,tr: 'Gün'
      ,hu: 'napok'
    }
    ,'Insulin distribution' : {
      cs: 'Rozložení inzulínu'
      ,he: 'התפלגות אינסולין '
      ,de: 'Insulinverteilung'
      ,dk: 'Insulinfordeling'
      ,ro: 'Distribuția de insulină'
      ,fr: 'Distribution de l\'insuline'
      ,ru: 'распределение инсулина'
      ,fi: 'Insuliinijakauma'
      ,sv: 'Insulindistribution'
      ,ko: '인슐린주입'
      ,it: 'Distribuzione di insulina'
      ,es: 'Distribución de la insulina'
      ,nl: 'Insuline verdeling'
      ,zh_cn: '胰岛素分布'
      ,bg: 'разпределение на инсулина'
      ,hr: 'Raspodjela inzulina'
      ,pl: 'podawanie insuliny'
      ,tr: 'İnsülin dağılımı'
      ,hu: 'Inzulin disztribúció'
    }
    ,'To see this report, press SHOW while in this view' : {
      cs: 'Pro zobrazení toho výkazu stiskněte Zobraz na této záložce'
      ,he: 'כדי להציג דוח זה, לחץ על"הראה" בתצוגה זו '
      ,de: 'Auf ZEIGEN drücken, um den Bericht in dieser Ansicht anzuzeigen'
      ,dk: 'For at se denne rapport, klick på "VIS"'
      ,fr: 'Pour voir le rapport, cliquer sur MONTRER dans cette fenêtre'
      ,ro: 'Pentru a vedea acest raport, apăsați butonul SHOW'
      ,ru: 'чтобы увидеть отчет, нажмите show/показать'
      ,fi: 'Nähdäksesi tämän raportin, paina NÄYTÄ tässä näkymässä'
      ,ko: '이 보고서를 보려면 "확인"을 누르세요'
      ,it: 'Per guardare questo report, premere SHOW all\'interno della finestra'
      ,es: 'Presione SHOW para mostrar el informe en esta vista'
      ,nl: 'Om dit rapport te zien, druk op "Laat zien"'
      ,zh_cn: '要查看此报告，请在此视图中按生成'
      ,sv: 'För att se denna rapport, klicka på "Visa"'
      ,bg: 'За да видите тази статистика, натиснете ПОКАЖИ'
      ,hr: 'Za prikaz ovog izvješća, pritisnite PRIKAŽI na ovom prozoru'
      ,pl: 'Aby wyświetlić ten raport, naciśnij przycisk POKAŻ w tym widoku'
      ,tr: 'Bu raporu görmek için bu görünümde GÖSTER düğmesine basın.'
      ,hu: 'A jelentés megtekintéséhez kattints a MUTASD gombra'
    }
    ,'AR2 Forecast' : {
      cs: 'AR2 predikci'
      ,he: 'AR2 תחזית '
      ,de: 'AR2-Vorhersage'
      ,dk: 'AR2 Forudsiglse'
      ,ro: 'Predicție AR2'
      ,fr: 'Prédiction AR2'
      ,ru: 'прогноз AR2'
      ,es: 'Pronóstico AR2'
      ,fi: 'AR2 Ennusteet'
      ,ko: 'AR2 예측'
      ,it: 'Previsione AR2'
      ,nl: 'AR2 Voorspelling'
      ,zh_cn: 'AR2 预测'
      ,sv: 'AR2 Förutsägelse'
      ,bg: 'AR2 прогнози'
      ,hr: 'AR2 procjena'
      ,pl: 'Prognoza AR2'
      ,tr: 'AR2 Tahmini'
      ,hu: 'AR2 előrejelzés'
    }
    ,'OpenAPS Forecasts' : {
      cs: 'OpenAPS predikci'
      ,he: 'תחזית OPENAPS '
      ,de: 'OpenAPS-Vorhersage'
      ,dk: 'OpenAPS Forudsiglse'
      ,ro: 'Predicții OpenAPS'
      ,fr: 'Prédictions OpenAPS'
      ,ru: 'прогнозы OpenAPS'
      ,es: 'Pronóstico OpenAPS'
      ,fi: 'OpenAPS Ennusteet'
      ,ko: 'OpenAPS 예측'
      ,it: 'Previsione OpenAPS'
      ,nl: 'OpenAPS Voorspelling'
      ,zh_cn: 'OpenAPS 预测'
      ,sv: 'OpenAPS Förutsägelse'
      ,bg: 'OpenAPS прогнози'
      ,hr: 'OpenAPS prognoze'
      ,pl: 'Prognoza OpenAPS'
      ,tr: 'OpenAPS Tahminleri'
      ,hu: 'OpenAPS előrejelzés'
     }
    ,'Temporary Target' : {
      cs: 'Dočasný cíl glykémie'
      ,he: 'מטרה זמנית '
      ,de: 'Temporäres Ziel'
      ,dk: 'Midlertidigt mål'
      ,fr: 'Cible temporaire'
      ,ro: 'Țintă temporară'
      ,ru: 'Временная цель'
      ,fi: 'Tilapäinen tavoite'
      ,es: 'Objetivo temporal'
      ,ko: '임시목표'
      ,it: 'Obbiettivo temporaneo'
      ,nl: 'Tijdelijk doel'
      ,zh_cn: '临时目标'
      ,sv: 'Tillfälligt mål'
      ,bg: 'временна цел'
      ,hr: 'Privremeni cilj'
      ,pl: 'Cel tymczasowy'
      ,tr: 'Geçici Hedef'
      ,hu: 'Átmeneti cél'
    }
    ,'Temporary Target Cancel' : {
      cs: 'Dočasný cíl glykémie konec'
      ,he: 'בטל מטרה זמנית '
      ,de: 'Temporäres Ziel abbrechen'
      ,dk: 'Afslut midlertidigt mål'
      ,fr: 'Effacer la cible temporaire'
      ,ro: 'Renunțare la ținta temporară'
      ,ru: 'Отмена временной цели'
      ,fi: 'Peruuta tilapäinen tavoite'
      ,es: 'Objetivo temporal cancelado'
      ,ko: '임시목표취소'
      ,it: 'Obbiettivo temporaneo cancellato'
      ,nl: 'Annuleer tijdelijk doel'
      ,zh_cn: '临时目标取消'
      ,sv: 'Avsluta tillfälligt mål'
      ,bg: 'Отмяна на временна цел'
      ,hr: 'Otkaz privremenog cilja'
      ,pl: 'Zel tymczasowy anulowany'
      ,tr: 'Geçici Hedef İptal'
      ,hu: 'Átmeneti cél törlése'
    }
    ,'OpenAPS Offline' : {
      cs: 'OpenAPS vypnuto'
      ,he: 'OPENAPS לא פעיל '
      ,de: 'OpenAPS Offline'
      ,dk: 'OpenAPS Offline'
      ,ro: 'OpenAPS deconectat'
      ,fr: 'OpenAPS déconnecté'
      ,ru: 'OpenAPS вне сети'
      ,fi: 'OpenAPS poissa verkosta'
      ,ko: 'OpenAPS Offline'
      ,it: 'OpenAPS disconnesso'
      ,es: 'OpenAPS desconectado'
      ,nl: 'OpenAPS Offline'
      ,zh_cn: 'OpenAPS 离线'
      ,sv: 'OpenAPS Offline'
      ,bg: 'OpenAPS спрян'
      ,hr: 'OpenAPS odspojen'
      ,pl: 'OpenAPS nieaktywny'
      ,tr: 'OpenAPS Offline (çevrimdışı)'
      ,hu: 'OpenAPS nem elérhető (offline)'
    }
    ,'Profiles' : {
      cs: 'Profily'
      ,he: 'פרופילים '
      ,de: 'Profile'
      ,dk: 'Profiler'
      ,fr: 'Profils'
      ,ro: 'Profile'
      ,ru: 'Профили'
      ,fi: 'Profiilit'
      ,ko: '프로파일'
      ,it: 'Profili'
      ,es: 'Perfil'
      ,nl: 'Profielen'
      ,zh_cn: '配置文件'
      ,sv: 'Profiler'
      ,bg: 'Профили'
      ,hr: 'Profili'
      ,pl: 'Profile'
      ,tr: 'Profiller'
      ,hu: 'Profilok'
    }
    ,'Time in fluctuation' : {
      cs: 'Doba měnící se glykémie'
      ,he: 'זמן בתנודות '
      ,fi: 'Aika muutoksessa'
      ,fr: 'Temps passé en fluctuation'
      ,ko: '변동시간'
      ,it: 'Tempo in fluttuazione'
      ,ro: 'Timp în fluctuație'
      ,es: 'Tiempo fluctuando'
      ,ru: 'Время флуктуаций'
      ,nl: 'Tijd met fluctuaties'
      ,zh_cn: '波动时间'
      ,sv: 'Tid i fluktation'
      ,de: 'Zeit in Fluktuation (Schwankung)'
      ,dk: 'Tid i fluktation'
      ,bg: 'Време в промяна'
      ,hr: 'Vrijeme u fluktuaciji'
      ,pl: 'Czas fluaktacji (odchyleń)'
      ,tr: 'Dalgalanmada geçen süre'
      ,hu: 'Kilengésben töltött idő'
    }
    ,'Time in rapid fluctuation' : {
      cs: 'Doba rychle se měnící glykémie'
      ,he: 'זמן בתנודות מהירות '
      ,fi: 'Aika nopeassa muutoksessa'
      ,fr: 'Temps passé en fluctuation rapide'
      ,ko: '빠른변동시간'
      ,it: 'Tempo in rapida fluttuazione'
      ,ro: 'Timp în fluctuație rapidă'
      ,es: 'Tiempo fluctuando rápido'
      ,ru: 'Время быстрых флуктуаций'
      ,nl: 'Tijd met grote fluctuaties'
      ,zh_cn: '快速波动时间'
      ,sv: 'Tid i snabb fluktation'
      ,de: 'Zeit in starker Fluktuation (Schwankung)'
      ,dk: 'Tid i hurtig fluktation'
      ,bg: 'Време в бърза промяна'
      ,hr: 'Vrijeme u brzoj fluktuaciji'
      ,pl: 'Czas szybkich fluaktacji (odchyleń)'
      ,tr: 'Hızlı dalgalanmalarda geçen süre'
      ,hu: 'Magas kilengésekben töltött idő'
    }
    ,'This is only a rough estimation that can be very inaccurate and does not replace actual blood testing. The formula used is taken from:' : {
      cs: 'Toto je pouze hrubý odhad, který může být nepřesný a nenahrazuje kontrolu z krve. Vzorec je převzatý z:'
      ,he: 'זוהי רק הערכה גסה שיכולה להיות מאוד לא מדויקת ואינה מחליפה את בדיקת הדם בפועל. הנוסחה המשמשת נלקחת מ: '
      ,fi: 'Tämä on epätarkka arvio joka saattaa heittää huomattavasti mittaustuloksesta, eikä korvaa laboratoriotestiä. Laskentakaava on otettu artikkelista: '
      ,fr: 'Ceci est seulement une estimation grossière qui peut être très imprécise et ne remplace pas une mesure sanguine adéquate. La formule est empruntée à l\'article:'
      ,ko: '이것은 대충 예측한 것이기 때문에 부정확할 수 있고 실제 혈당으로 대체되지 않습니다. 사용된 공식:'
      ,it: 'Questa è solo un\'approssimazione che può essere molto inaccurata e che non sostituisce la misurazione capillare. La formula usata è presa da:'
      ,ro: 'Aceasta este doar o aproximare brută, care poate fi foarte imprecisă și nu ține loc de testare capilară. Formula matematică folosită este luată din:'
      ,es: 'Esto es sólo una estimación apróximada que puede ser muy inexacta y no reemplaza las pruebas de sangre reales. La fórmula utilizada está tomada de: '
      ,ru: 'Это приблизительная оценка не заменяющая фактический анализ крови. Используемая формула взята из:'
      ,zh_cn: '这只是一个粗略的估计，可能非常不准确，并不能取代测指血'
      ,nl: 'Dit is enkel een grove schatting die onjuist kan zijn welke geen bloedtest vervangt. De gebruikte formule is afkomstig van:'
      ,sv: 'Detta är en grov uppskattning som kan vara missvisande. Det ersätter inte blodprov. Formeln är hämtad från:'
      ,de: 'Dies ist lediglich eine grobe Schätzung, die sehr ungenau sein kann und eine Überprüfung des tatsächlichen Blutzuckers nicht ersetzen kann. Die verwendete Formel wurde genommen von:'
      ,dk: 'Dette er kun en grov estimering som kan være misvisende. Det erstatter ikke en blodprøve. Formelen er hemtet fra:'
      ,bg: 'Това е само грубо изчисление, което може да е много неточно и не изключва реалния кръвен тест. Формулата, кокято е използвана е взета от:'
      ,hr: 'Ovo je samo gruba procjena koja može biti neprecizna i ne mijenja testiranje iz krvi. Formula je uzeta iz:'
      ,pl: 'To tylko przybliżona ocena, która może być bardzo niedokładna i nie może zastąpić faktycznego poziomu cukru we krwi. Zastosowano formułę:'
      ,tr: 'Bu bir kaba tahmindir ve çok hata içerebilir gerçek kan şekeri testlerinin yerini tutmayacaktır. Kullanılan formülde buradandır:'
      ,hu: 'Ez egy nagyon durva számítás ami nem pontos és nem helyettesíti a cukorszint mérését. A képlet a következő helyről lett véve:'
    }
    , 'Filter by hours' : {
      cs: ' Filtr podle hodin'
      ,fi: 'Huomioi raportissa seuraavat tunnit'
      ,ko: '시간으로 정렬'
      ,it: 'Filtra per ore'
      ,fr: 'Filtrer par heures'
      ,ro: 'Filtrare pe ore'
      ,es: 'Filtrar por horas'
      ,ru: 'Почасовая фильтрация'
      ,nl: 'Filter op uren'
      ,zh_cn: '按小时过滤'
      ,sv: 'Filtrera per timme'
      ,de: 'Filtern nach Stunden'
      ,dk: 'Filtrer per time'
      ,bg: 'Филтър по часове'
      ,hr: 'Filter po satima'
      ,pl: 'Filtruj po godzinach'
      ,tr: 'Saatlere göre filtrele'
      ,hu: 'Megszűrni órák alapján'
    }
    , 'Time in fluctuation and Time in rapid fluctuation measure the % of time during the examined period, during which the blood glucose has been changing relatively fast or rapidly. Lower values are better.' : {
      cs: 'Doba měnící se glykémie a rapidně se měnící glykémie měří % času ve zkoumaném období, během kterého se glykémie měnila relativně rychle nebo rapidně. Nižší hodnota je lepší.'
      ,fi: 'Aika Muutoksessa ja Aika Nopeassa Muutoksessa mittaa osuutta tarkkailtavasta aikaperiodista, jolloin glukoosi on ollut nopeassa tai hyvin nopeassa muutoksessa. Pienempi arvo on parempi.'
      ,fr: 'Le Temps passé en fluctuation et le temps passé en fluctuation rapide mesurent la part de temps durant la période examinée, pendant laquelle la glycémie a évolué relativement ou très rapidement. Les valeurs basses sont les meilleures.'
      ,ko: '변동시간과 빠른 변동시간은 조사된 기간 동안 %의 시간으로 측정되었습니다.혈당은 비교적 빠르게 변화되었습니다. 낮을수록 좋습니다.'
      ,it: 'Tempo in fluttuazione e Tempo in rapida fluttuazione misurano la % di tempo durante il periodo esaminato, durante il quale la glicemia stà variando velocemente o rapidamente. Bassi valori sono migliori.'
      ,ro: 'Timpul în fluctuație și timpul în fluctuație rapidă măsoară procentul de timp, din perioada examinată, în care glicemia din sânge a avut o variație relativ rapidă sau rapidă. Valorile mici sunt de preferat.'
      ,es: 'Tiempo en fluctuación y Tiempo en fluctuación rápida miden el % de tiempo del período exáminado, durante la cual la glucosa en sangre ha estado cambiando relativamente rápido o rápidamente. Valores más bajos son mejores.'
      ,ru: 'Время флуктуаций и время быстрых флуктуаций означает % времени в рассматриваемый период в течение которого ГК менялась относительно быстро или просто быстро. Более низкие значения предпочтительней'
      ,nl: 'Tijd met fluctuaties of grote fluctuaties in % van de geevalueerde periode, waarbij de bloed glucose relatief snel wijzigde.Lagere waarden zijn beter.'
      ,zh_cn: '在检查期间血糖波动时间和快速波动时间占的时间百分比，在此期间血糖相对快速或快速地变化。百分比值越低越好。'
      ,sv: 'Tid i fluktuation och tid i snabb fluktuation mäter% av tiden under den undersökta perioden, under vilken blodsockret har förändrats relativt snabbt eller snabbt. Lägre värden är bättre'
      ,de: 'Zeit in Fluktuation und Zeit in starker Fluktuation messen den Teil der Zeit, in der sich der Blutzuckerwert relativ oder sehr schnell verändert hat. Niedrigere Werte sind besser.'
      ,dk: 'Tid i fluktuation og tid i hurtig fluktuation måler % af tiden i den undersøgte periode, under vilket blodsukkret har ændret sig relativt hurtigt. Lavere værdier er bedre.'
      ,bg: 'Време в промяна и време в бърза промяна измерват % от време в разгледания период, през който КЗ са се променяли бързо или много бързо. По-ниски стойности са по-добри.'
      ,hr: 'Vrijeme u fluktuaciji i vrijeme u brzoj fluktuaciji mjere % vremena u gledanom periodu, tijekom kojeg se GUK mijenja relativno brzo ili brzo. Niže vrijednosti su bolje.'
      ,pl: 'Czas fluktuacji i szybki czas fluktuacji mierzą % czasu w badanym okresie, w którym poziom glukozy we krwi zmieniał się szybko lub bardzo szybko. Preferowane są wolniejsze zmiany'
      ,tr: 'Dalgalanmadaki zaman ve Hızlı dalgalanmadaki zaman, kan şekerinin nispeten hızlı veya çok hızlı bir şekilde değiştiği, incelenen dönemdeki zamanın %\'sini ölçer. Düşük değerler daha iyidir.'
      ,hu: 'A sima és magas kilengésnél mért idő százalékban kifelyezve, ahol a cukorszint aránylag nagyokat változott. A kisebb értékek jobbak ebben az esetben'
    }
    , 'Mean Total Daily Change is a sum of the absolute value of all glucose excursions for the examined period, divided by the number of days. Lower is better.' : {
      cs: 'Průměrná celková denní změna je součet absolutních hodnoty všech glykémií za sledované období, děleno počtem dní. Nižší hodnota je lepší.'
      ,fi: 'Keskimääräinen Kokonaismuutos kertoo kerkimääräisen päivätason verensokerimuutoksien yhteenlasketun arvon. Pienempi arvo on parempi.'
      ,fr: 'La Variation Totale Journalière Moyenne est la somme de toute les excursions glycémiques absolues pour une période analysée, divisée par le nombre de jours. Les valeurs basses sont les meilleures.'
      ,ko: '전체 일일 변동 평균은 조사된 기간동안 전체 혈당 절대값의 합을 전체 일수로 나눈 값입니다. 낮을수록 좋습니다.'
      ,it: 'Media Totale Giornaliera Variazioni è la somma dei valori assoluti di tutte le escursioni glicemiche per il periodo esaminato, diviso per il numero di giorni. Bassi valori sono migliori.'
      ,ro: 'Schimbarea medie totală zilnică este suma valorilor absolute ale tuturor excursiilor glicemice din perioada examinată,  împărțite la numărul de zile. Valorile mici sunt de preferat.'
      ,ru: 'Усредненное ежедневное изменение это сумма абсолютных величин всех отклонений ГК в рассматриваемый период, деленная на количество дней. Меньшая величина предпочтительней'
      ,es: 'El cambio medio diario total es la suma de los valores absolutos de todas las glucémias en el período examinado, dividido por el número de días. Mejor valores bajos.'
      ,nl: 'Gemiddelde veranderingen per dag is een som van alle waardes die uitschieten over de bekeken periode, gedeeld door het aantal dagen in deze periode. Lager is beter.'
     ,zh_cn: '平均每日总变化是检查期间所有血糖偏移的绝对值之和除以天数。越低越好'
      ,sv: 'Medel Total Daglig Förändring är summan av absolutvärdet av alla glukosförändringar under den undersökta perioden, dividerat med antalet dagar. Lägre är bättre.'
      ,de: 'Die gesamte mittlere Änderung pro Tag ist die Summe der absoluten Werte aller Glukoseveränderungen im Betrachtungszeitraum geteilt durch die Anzahl der Tage. Niedrigere Werte sind besser.'
      ,dk: 'Middel Total Daglig Ændring er summen af absolutværdier af alla glukoseændringer i den undersøgte periode, divideret med antallet af dage. Lavere er bedre.'
      ,bg: 'Средната дневна промяна е сумата на всички промени в стойностите на КЗ за разгледания период, разделена на броя дни в периода. По-ниската стойност е по-добра'
      ,hr: 'Srednja ukupna dnevna promjena je suma apsolutnih vrijednosti svih pomaka u gledanom periodu, podijeljeno s brojem dana. Niže vrijednosti su bolje.'
      ,pl: 'Sednia całkowita dziennych zmian jest sumą wszystkich zmian glikemii w badanym okresie, podzielonym przez liczbę dni. Mniejsze są lepsze'
      ,tr: 'Toplam Günlük Değişim, incelenen süre için, gün sayısına bölünen tüm glukoz değerlerinin mutlak değerinin toplamıdır. Düşük değer daha iyidir.'
      ,hu: 'Az átlagos napi változás az abszolút értékek összege elosztva a napok számával. A kisebb érték jobb ebben az esetben.'
    }
    , 'Mean Hourly Change is a sum of the absolute value of all glucose excursions for the examined period, divided by the number of hours in the period. Lower is better.' : {
      cs: 'Průměrná hodinová změna je součet absolutní hodnoty všech glykémií za sledované období, dělených počtem hodin v daném období. Nižší hodnota je lepší.'
      ,fi: 'Keskimääräinen tunti kertoo keskimääräisen tuntitason verensokerimuutoksien yhteenlasketun arvon. Pienempi arvo on parempi.'
      ,fr: 'La Variation Horaire Moyenne est la somme de toute les excursions glycémiques absolues pour une période analysée, divisée par le nombre d\'heures dans la période. Les valeures basses sont les meilleures.'
      ,ko: '시간당 변동 평균은 조사된 기간 동안 전체 혈당 절대값의 합을 기간의 시간으로 나눈 값입니다.낮을수록 좋습니다.'
      ,it: 'Media Oraria Variazioni è la somma del valore assoluto di tutte le escursioni glicemiche per il periodo esaminato, diviso per il numero di ore. Bassi valori sono migliori.'
      ,ro: 'Variația media orară este suma valorilor absolute ale tuturor excursiilor glicemice din perioada examinată, împărțite la numărul de ore din aceeași perioadă. Valorile mici sunt de preferat.'
      ,ru: 'Усредненное часовое изменение это сумма абсолютных величин всех отклонений ГК в рассматриваемый период, деленная на количество часов в этот период. Более низкое предпочтительней'
      ,es: 'El cambio medio por hora, es la suma del valor absoluto de todas las glucemias para el período examinado, dividido por el número de horas en el período. Más bajo es mejor.'
      ,nl: 'Gemiddelde veranderingen per uur is een som van alle waardes die uitschieten over de bekeken periode, gedeeld door het aantal uur in deze periode. Lager is beter.'
      ,zh_cn: '平均每小时变化是检查期间所有血糖偏移的绝对值之和除以该期间的小时数。 越低越好'
      ,sv: 'Medelvärde per timme är summan av absolutvärdet av alla glukosförändringar under den undersökta perioden dividerat med antalet timmar under perioden. Lägre är bättre.'
      ,de: 'Die mittlere Änderung pro Stunde ist die Summe der absoluten Werte aller Glukoseveränderungen im Betrachtungszeitraum geteilt durch die Anzahl der Stunden. Niedrigere Werte sind besser.'
      ,dk: 'Middelværdier per time er summen af absolutværdier fra alle glukoseændringer i den undersøgte periode divideret med antallet af timer. Lavere er bedre.'
      ,bg: 'Средната промяна за час е сумата на всички промени в стойностите на КЗ за разгледания период, разделена на броя часове в периода. По-ниската стойност е по-добра'
      ,hr: 'Srednja ukupna promjena po satu je suma apsolutnih vrijednosti svih pomaka u gledanom periodu, podijeljeno s brojem sati. Niže vrijednosti su bolje.'
      ,pl: 'Sednia całkowita godzinnych zmian jest sumą wszystkich zmian glikemii w badanym okresie, podzielonym przez liczbę godzin. Mniejsze są lepsze'
      ,tr: 'Saat başına ortalama değişim, gözlem periyodu üzerindeki tüm glikoz değişikliklerinin mutlak değerlerinin saat sayısına bölünmesiyle elde edilen toplam değerdir. Düşük değerler daha iyidir.'
      ,hu: 'Az átlagos óránkénti változás az abszút értékek összege elosztva a napok számával. A kisebb érték jobb ebben az esetben.'
    }
    , 'GVI (Glycemic Variability Index) and PGS (Patient Glycemic Status) are measures developed by Dexcom, detailed <a href="' : {
     cs: 'GVI (Glycemic Variability Index) a PGS (Patient Glycemic Status) jsou měření vyvinutá společností Dexcom, podrobněji <a href="'
     ,he: 'GVI (Glycemic Variability Index) and PGS (Patient Glycemic Status) are measures developed by Dexcom, detailed <a href="'
     ,fi: 'GVI (Glycemic Variability Index) ja PGS (Patient Glycemic Status) ovat Dexcom-yrityksen kehittämiä mittaustapoja, joista voit lukea lisää <a href="'
     ,ko: 'GVI (Glycemic Variability Index)와 PGS (Patient Glycemic Status)는 Dexcom에서 개발된 측정값입니다, 자세한 내용은 다음 링크를 참고하세요.<a href="'
     ,it: 'GVI (Glycemic Variability Index) e PGS (Patient Glycemic Status) sono misure sviluppate da Dexcom, dettagliate <a href="'
     ,es: 'Variabilidad de la glucosa en sangre y el estado glucémico del paciente es un valor diseñado por Dexcom, más detalles en <a href="'
     ,fr: 'GVI (Glycemic Variability Index) et PGS (Patient Glycemic Status) sont des mesures développées par la firme Dexcom, présentées en détail <a href="'
     ,ro: 'GVI (Glycemic Variability Index) și PGS (Patient Glycemic Status) sunt caracteristici de măsurare inventate de Dexcom, ale căror detalii le găsiți <a href="'
     ,ru: 'Вариабельность гликемии и статус гликемии больного это величины, разработанные декскомом, подробнее <a href="'
     ,nl: 'GVI (Glycemic Variability Index) en PGS (Patient Glycemic Status) zijn indicatoren (measures) ontworpen door Dexcom, gedetaillieerde info <a href="'
     ,zh_cn: 'GVI (Glycemic Variability Index)和PGS (Patient Glycemic Status)是由Dexcom开发的措施，详细说明 <a href="'
     ,sv: 'GVI (Glycemic Variability Index) och PGS (Patient Glycemic Status) är värden utvecklade av Dexcom, detaljer <a href="'
     ,de: 'GVI (Glycemic Variability Index) und PGS (Patient Glycemic Status) sind von Dexcom entwickelte Werte. Details <a href="'
     ,dk: 'GVI (Glycemic Variability Index) og PGS (Patient Glycemic Status) er værdier udviklet af Dexcom, detaljer <a href="'
     ,bg: 'GVI (Glycemic Variability Index) и PGS (Patient Glycemic Status) са параметри разработени от Декском, повече детайли <a href="'
     ,hr: 'GVI (Glycemic Variability Index) i PGS (Patient Glycemic Status) su mjere osmišljene od Dexcoma, više detalja <a href="'
     ,pl: 'GVI (Glycemic Variability Index) i PGS (Patient Glycemic Status) są pomiarami opracowanymi przez Dexcom, szczegóły <a href="'
     ,tr: 'GVI ve PGS Dexcom tarafından geliştirilen önlemlerdir, detaylı  <a href="'
     ,hu: 'GVI (Glycemic Variability Index) and PGS (Patient Glycemic Status) a dexcom cég által fejlesztett mérések, részletek <a href="'
    }
    , '">can be found here</a>.' : {
     cs: '">zde</a>.'
     ,he: '">here</a>.'
     ,fi: '">here</a>.'
     ,ko: '">here</a>.'
     ,it: '">qui</a>.'
     ,es: '">here</a>.'
     ,fr: '">ici</a>.'
     ,ro: '">aici</a>.'
     ,ru: '">здесь</a>.'
     ,nl: '">is hier te vinden</a>.'
     ,zh_cn: '">here</a>.'
     ,sv: '">här</a>.'
     ,de: '">hier</a>.'
     ,dk: '">her</a>.'
     ,bg: '">тук</a>.'
     ,hr: '">ovdje</a>.'
     ,pl: '">tutaj</a>.'
     ,tr: '">buradan</a>.'
     ,hu: '">itt találhatóak</a>.'
    }
    , 'Mean Total Daily Change' : {
      cs: 'Průměrná celková denní změna'
      ,he: 'שינוי יומי ממוצע '
      ,fi: 'Keskimääräinen Kokonaismuutos'
      ,fr: 'Variation Totale Journalière Moyenne'
      ,ko: '전체 일일 변동 평균'
      ,it: 'Media Totale Giornaliera Variazioni'
      ,ro: 'Variația medie totală zilnică'
      ,ru: 'Усредненное изменение за день'
      ,es: 'Variación media total diaria'
      ,nl: 'Gemiddelde veranderingen per dag'
      ,zh_cn: '平均每日总变化'
      ,sv: 'Medel Total Daglig Förändring'
      ,de: 'Gesamte mittlere Änderung pro Tag'
      ,dk: 'Middel Total Daglig Ændring'
      ,bg: 'Средна промяна за ден'
     ,hr: 'Srednja ukupna dnevna promjena'
      ,pl: 'Średnia całkowita dziennych zmian'
      ,tr: 'Günde toplam ortalama değişim'
      ,hu: 'Áltagos napi változás'
    }
    , 'Mean Hourly Change' : {
      cs: 'Průměrná hodinová změna'
      ,he: 'שינוי ממוצע לשעה '
      ,fi: 'Keskimääräinen tuntimuutos'
      ,fr: 'Variation Horaire Moyenne'
      ,ko: '시간당 변동 평균'
      ,it: 'Media Oraria Variazioni'
      ,ro: 'Variația medie orară'
      ,es: 'Variación media total por horas'
      ,ru: 'Усредненное изменение за час'
      ,nl: 'Gemiddelde veranderingen per uur'
       ,zh_cn: '平均每小时变化'
      ,sv: 'Medelvärde per timme'
      ,de: 'Mittlere Änderung pro Stunde'
      ,dk: 'Middelværdier per time'
      ,bg: 'Средна промяна за час'
     ,hr: 'Srednja ukupna promjena po satu'
      ,pl: 'Średnia całkowita godzinnych zmian'
      ,tr: 'Saatte ortalama değişim'
      ,hu: 'Átlagos óránkénti változás'
    }
      , 'FortyFiveDown': {
          bg: 'slightly dropping'
          , cs: 'lehce dolů'
          , de: 'leicht sinkend'
          , dk: 'svagt faldende'
          , el: 'slightly dropping'
          , en: 'slightly dropping'
          , es: 'Disminuye lentamente'
          , fi: 'laskee hitaasti'
          , fr: 'en chute lente'
          , he: 'slightly dropping'
          , hr: 'sporo padajuće'
          , ko: 'slightly dropping'
          , it: 'leggera diminuzione'
          , nb: 'svakt fallende'
          , pl: 'niewielki spadek'
          , pt: 'slightly dropping'
          , ro: 'scădere ușoară'
          , ru: 'незначительное падение'
          , sk: 'slightly dropping'
          , sv: 'slightly dropping'
          , nl: 'slightly dropping'
          , tr: 'biraz düşen'
          , zh_cn: '缓慢下降'
          , zh_tw: 'slightly dropping'
          , hu: 'lassan csökken'

      },
    'FortyFiveUp': {
        bg: 'slightly rising'
        , cs: 'lehce nahoru'
        , de: 'leicht steigend'
        , dk: 'svagt stigende'
        , el: 'slightly rising'
        , en: 'slightly rising'
        , es: 'Asciende lentamente'
        , fi: 'nousee hitaasti'
        , fr: 'en montée lente'
        , he: 'slightly rising'
        , hr: 'sporo rastuće'
        , it: 'leggero aumento'
        , ko: 'slightly rising'
        , nb: 'svakt stigende'
        , pl: 'niewielki wzrost'
        , pt: 'slightly rising'
        , ro: 'creștere ușoară'
        , ru: 'незначительный подъем'
        , sk: 'slightly rising'
        , sv: 'slightly rising'
        , nl: 'slightly rising'
        , tr: 'biraz yükselen'
        , zh_cn: '缓慢上升'
        , zh_tw: 'slightly rising'
        , hu: 'lassan növekszik'
    },
    'Flat': {
        bg:  'holding'
        , cs:  'stabilní'
        , de:  'gleichbleibend'
        , dk:  'stabilt'
        , el:  'holding'
        , en:  'holding'
        , es:  'Sin variación'
        , fi:  'tasainen'
        , fr:  'stable'
        , he:  'holding'
        , hr:  'ravno'
        , it:  'stabile'
        , ko:  'holding'
        , nb:  'stabilt'
        , pl:  'stabilny'
        , pt:  'holding'
        , ro:  'stabil'
        , ru:  'ровный'
        , sk:  'holding'
        , sv:  'holding'
        , nl:  'holding'
        , tr:  'sabit'
        , zh_cn:  '平'
        , zh_tw:  'holding'
        , hu:  'stabil'
    },
    'SingleUp': {
        bg:  'rising'
        , cs:  'nahoru'
        , de:  'steigend'
        , dk:  'stigende'
        , el:  'rising'
        , en:  'rising'
        , es:  'Ascendiendo'
        , fi:  'nousussa'
        , fr:  'en montée'
        , he:  'rising'
        , hr:  'rastuće'
        , it:  'aumento'
        , ko:  'rising'
        , nb:  'stigende'
        , pl:  'wzrost'
        , pt:  'rising'
        , ro:  'creștere'
        , ru:  'растет'
        , sk:  'rising'
        , sv:  'rising'
        , nl:  'rising'
        , tr:  'yükseliyor'
        , zh_cn:  '上升'
        , zh_tw:  'rising'
        , hu:  'emelkedik'
    },
    'SingleDown': {
        bg:  'dropping'
        , cs:  'dolů'
        , de:  'sinkend'
        , dk:  'faldende'
        , el:  'dropping'
        , en:  'dropping'
        , es:  'Bajando'
        , fi:  'laskussa'
        , fr:  'en chute'
        , he:  'dropping'
        , hr:  'padajuće'
        , it:  'diminuzione'
        , ko:  'dropping'
        , nb:  'fallende'
        , pl:  'spada'
        , pt:  'dropping'
        , ro:  'scădere'
        , ru:  'падает'
        , sk:  'dropping'
        , sv:  'dropping'
        , nl:  'dropping'
        , tr:  'düşüyor'
        , zh_cn:  '下降'
        , zh_tw:  'dropping'
        , hu:  'csökken'
    },
    'DoubleDown': {
        bg:  'rapidly dropping'
        , cs:  'rychle dolů'
        , de:  'schnell sinkend'
        , dk:  'hurtigt faldende'
        , el:  'rapidly dropping'
        , en:  'rapidly dropping'
        , es:  'Bajando rápido'
        , fi:  'laskee nopeasti'
        , fr:  'en chute rapide'
        , he:  'rapidly dropping'
        , hr:  'brzo padajuće'
        , it:  'rapida diminuzione'
        , ko:  'rapidly dropping'
        , nb:  'hurtig stigende'
        , pl:  'szybko spada'
        , pt:  'rapidly dropping'
        , ro:  'scădere bruscă'
        , ru:  'быстро падает'
        , sk:  'rapidly dropping'
        , sv:  'rapidly dropping'
        , nl:  'rapidly dropping'
        , tr:  'hızlı düşen'
        , zh_cn:  '快速下降'
        , zh_tw:  'rapidly dropping'
        , hu:  'gyorsan csökken'
    },
    'DoubleUp': {
        bg:  'rapidly rising'
        , cs:  'rychle nahoru'
        , de:  'schnell steigend'
        , dk:  'hurtigt stigende'
        , el:  'rapidly rising'
        , en:  'rapidly rising'
        , es:  'Subiendo rápido'
        , fi:  'nousee nopeasti'
        , fr:  'en montée rapide'
        , he:  'rapidly rising'
        , hr:  'brzo rastuće'
        , it:  'rapido aumento'
        , ko:  'rapidly rising'
        , nb:  'hurtig fallende'
        , pl:  'szybko rośnie'
        , pt:  'rapidly rising'
        , ro:  'creștere rapidă'
        , ru:  'быстрый рост'
        , sk:  'rapidly rising'
        , sv:  'rapidly rising'
        , nl:  'rapidly rising'
        , tr:  'hızla yükselen'
        , zh_cn:  '快速上升'
        , zh_tw:  'rapidly rising'
        , hu:  'gyorsan emelkedik'
    },
    'virtAsstUnknown': {
      bg:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , cs:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , de:  'Dieser Wert ist momentan unbekannt. Prüfe deine Nightscout-Webseite für weitere Details!'
      , dk:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , el:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , en:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , es:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , fi:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , fr:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , he:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , hr:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , it:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , ko:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , nb:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , pl:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , pt:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , ro:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , nl:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , ru:  'В настоящий момент величина неизвестна. Зайдите на сайт Nightscout.'
      , sk:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , sv:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , tr:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , zh_cn:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , zh_tw:  'That value is unknown at the moment. Please see your Nightscout site for more details.'
      , hu:  'Az adat ismeretlen. Kérem nézd meg a Nightscout oldalt részletekért'
    },
    'virtAsstTitleAR2Forecast': {
      bg:  'AR2 Forecast'
      , cs:  'AR2 Forecast'
      , de:  'AR2-Vorhersage'
      , dk:  'AR2 Forecast'
      , el:  'AR2 Forecast'
      , en:  'AR2 Forecast'
      , es:  'AR2 Forecast'
      , fi:  'AR2 Forecast'
      , fr:  'AR2 Forecast'
      , he:  'AR2 Forecast'
      , hr:  'AR2 Forecast'
      , it:  'AR2 Forecast'
      , ko:  'AR2 Forecast'
      , nb:  'AR2 Forecast'
      , pl:  'AR2 Forecast'
      , pt:  'AR2 Forecast'
      , ro:  'AR2 Forecast'
      , nl:  'AR2 Forecast'
      , ru:  'Прогноз AR2'
      , sk:  'AR2 Forecast'
      , sv:  'AR2 Forecast'
      , tr:  'AR2 Forecast'
      , zh_cn:  'AR2 Forecast'
      , zh_tw:  'AR2 Forecast'
      , hu:  'AR2 Előrejelzés'
    },
    'virtAsstTitleCurrentBasal': {
      bg:  'Current Basal'
      , cs:  'Current Basal'
      , de:  'Aktuelles Basalinsulin'
      , dk:  'Current Basal'
      , el:  'Current Basal'
      , en:  'Current Basal'
      , es:  'Current Basal'
      , fi:  'Current Basal'
      , fr:  'Current Basal'
      , he:  'Current Basal'
      , hr:  'Current Basal'
      , it:  'Current Basal'
      , ko:  'Current Basal'
      , nb:  'Current Basal'
      , pl:  'Current Basal'
      , pt:  'Current Basal'
      , ro:  'Current Basal'
      , nl:  'Current Basal'
      , ru:  'Актуальный Базал'
      , sk:  'Current Basal'
      , sv:  'Current Basal'
      , tr:  'Current Basal'
      , zh_cn:  'Current Basal'
      , zh_tw:  'Current Basal'
      , hu:  'Jelenlegi Bazál'
    },
    'virtAsstTitleCurrentCOB': {
      bg:  'Current COB'
      , cs:  'Current COB'
      , de:  'Aktuelle Kohlenhydrate'
      , dk:  'Current COB'
      , el:  'Current COB'
      , en:  'Current COB'
      , es:  'Current COB'
      , fi:  'Current COB'
      , fr:  'Current COB'
      , he:  'Current COB'
      , hr:  'Current COB'
      , it:  'Current COB'
      , ko:  'Current COB'
      , nb:  'Current COB'
      , pl:  'Current COB'
      , pt:  'Current COB'
      , ro:  'Current COB'
      , nl:  'Current COB'
      , ru:  'АктивнУгл COB'
      , sk:  'Current COB'
      , sv:  'Current COB'
      , tr:  'Current COB'
      , zh_cn:  'Current COB'
      , zh_tw:  'Current COB'
      , hu:  'Jelenlegi COB'
    },
    'virtAsstTitleCurrentIOB': {
      bg:  'Current IOB'
      , cs:  'Current IOB'
      , de:  'Aktuelles Restinsulin'
      , dk:  'Current IOB'
      , el:  'Current IOB'
      , en:  'Current IOB'
      , es:  'Current IOB'
      , fi:  'Current IOB'
      , fr:  'Current IOB'
      , he:  'Current IOB'
      , hr:  'Current IOB'
      , it:  'Current IOB'
      , ko:  'Current IOB'
      , nb:  'Current IOB'
      , pl:  'Current IOB'
      , pt:  'Current IOB'
      , ro:  'Current IOB'
      , nl:  'Current IOB'
      , ru:  'АктИнс IOB'
      , sk:  'Current IOB'
      , sv:  'Current IOB'
      , tr:  'Current IOB'
      , zh_cn:  'Current IOB'
      , zh_tw:  'Current IOB'
      , hu:  'Jelenlegi IOB'
    },
    'virtAsstTitleLaunch': {
      bg:  'Welcome to Nightscout'
      , cs:  'Welcome to Nightscout'
      , de:  'Willkommen bei Nightscout'
      , dk:  'Welcome to Nightscout'
      , el:  'Welcome to Nightscout'
      , en:  'Welcome to Nightscout'
      , es:  'Welcome to Nightscout'
      , fi:  'Welcome to Nightscout'
      , fr:  'Welcome to Nightscout'
      , he:  'Welcome to Nightscout'
      , hr:  'Welcome to Nightscout'
      , it:  'Welcome to Nightscout'
      , ko:  'Welcome to Nightscout'
      , nb:  'Welcome to Nightscout'
      , pl:  'Welcome to Nightscout'
      , pt:  'Welcome to Nightscout'
      , ro:  'Welcome to Nightscout'
      , nl:  'Welcome to Nightscout'
      , ru:  'Добро пожаловать в Nightscout'
      , sk:  'Welcome to Nightscout'
      , sv:  'Welcome to Nightscout'
      , tr:  'Welcome to Nightscout'
      , zh_cn:  'Welcome to Nightscout'
      , zh_tw:  'Welcome to Nightscout'
      , hu:  'Üdvözöllek a Nightscouton'
    },
    'virtAsstTitleLoopForecast': {
      bg:  'Loop Forecast'
      , cs:  'Loop Forecast'
      , de:  'Loop-Vorhersage'
      , dk:  'Loop Forecast'
      , el:  'Loop Forecast'
      , en:  'Loop Forecast'
      , es:  'Loop Forecast'
      , fi:  'Loop Forecast'
      , fr:  'Loop Forecast'
      , he:  'Loop Forecast'
      , hr:  'Loop Forecast'
      , it:  'Loop Forecast'
      , ko:  'Loop Forecast'
      , nb:  'Loop Forecast'
      , pl:  'Loop Forecast'
      , pt:  'Loop Forecast'
      , ro:  'Loop Forecast'
      , nl:  'Loop Forecast'
      , ru:  'Прогноз Loop'
      , sk:  'Loop Forecast'
      , sv:  'Loop Forecast'
      , tr:  'Loop Forecast'
      , zh_cn:  'Loop Forecast'
      , zh_tw:  'Loop Forecast'
      , hu:  'Loop Előrejelzés'
    },
    'virtAsstTitleLastLoop': {
      bg:  'Last Loop'
      , cs:  'Last Loop'
      , de:  'Letzter Loop'
      , dk:  'Last Loop'
      , el:  'Last Loop'
      , en:  'Last Loop'
      , es:  'Last Loop'
      , fi:  'Last Loop'
      , fr:  'Last Loop'
      , he:  'Last Loop'
      , hr:  'Last Loop'
      , it:  'Last Loop'
      , ko:  'Last Loop'
      , nb:  'Last Loop'
      , pl:  'Last Loop'
      , pt:  'Last Loop'
      , ro:  'Last Loop'
      , nl:  'Last Loop'
      , ru:  'Прошлый Loop'
      , sk:  'Last Loop'
      , sv:  'Last Loop'
      , tr:  'Last Loop'
      , zh_cn:  'Last Loop'
      , zh_tw:  'Last Loop'
      , hu:  'Utolsó Loop'
    },
    'virtAsstTitleOpenAPSForecast': {
      bg:  'OpenAPS Forecast'
      , cs:  'OpenAPS Forecast'
      , de:  'OpenAPS-Vorhersage'
      , dk:  'OpenAPS Forecast'
      , el:  'OpenAPS Forecast'
      , en:  'OpenAPS Forecast'
      , es:  'OpenAPS Forecast'
      , fi:  'OpenAPS Forecast'
      , fr:  'OpenAPS Forecast'
      , he:  'OpenAPS Forecast'
      , hr:  'OpenAPS Forecast'
      , it:  'OpenAPS Forecast'
      , ko:  'OpenAPS Forecast'
      , nb:  'OpenAPS Forecast'
      , pl:  'OpenAPS Forecast'
      , pt:  'OpenAPS Forecast'
      , ro:  'OpenAPS Forecast'
      , nl:  'OpenAPS Forecast'
      , ru:  'Прогноз OpenAPS'
      , sk:  'OpenAPS Forecast'
      , sv:  'OpenAPS Forecast'
      , tr:  'OpenAPS Forecast'
      , zh_cn:  'OpenAPS Forecast'
      , zh_tw:  'OpenAPS Forecast'
      , hu:  'OpenAPS Előrejelzés'
    },
    'virtAsstTitlePumpReservoir': {
      bg:  'Insulin Remaining'
      , cs:  'Insulin Remaining'
      , de:  'Verbleibendes Insulin'
      , dk:  'Insulin Remaining'
      , el:  'Insulin Remaining'
      , en:  'Insulin Remaining'
      , es:  'Insulin Remaining'
      , fi:  'Insulin Remaining'
      , fr:  'Insulin Remaining'
      , he:  'Insulin Remaining'
      , hr:  'Insulin Remaining'
      , it:  'Insulin Remaining'
      , ko:  'Insulin Remaining'
      , nb:  'Insulin Remaining'
      , pl:  'Insulin Remaining'
      , pt:  'Insulin Remaining'
      , ro:  'Insulin Remaining'
      , nl:  'Insulin Remaining'
      , ru:  'Осталось Инсулина'
      , sk:  'Insulin Remaining'
      , sv:  'Insulin Remaining'
      , tr:  'Insulin Remaining'
      , zh_cn:  'Insulin Remaining'
      , zh_tw:  'Insulin Remaining'
      , hu:  'Fennmaradó inzulin'
    },
    'virtAsstTitlePumpBattery': {
      bg:  'Pump Battery'
      , cs:  'Pump Battery'
      , de:  'Pumpenbatterie'
      , dk:  'Pump Battery'
      , el:  'Pump Battery'
      , en:  'Pump Battery'
      , es:  'Pump Battery'
      , fi:  'Pump Battery'
      , fr:  'Pump Battery'
      , he:  'Pump Battery'
      , hr:  'Pump Battery'
      , it:  'Pump Battery'
      , ko:  'Pump Battery'
      , nb:  'Pump Battery'
      , pl:  'Pump Battery'
      , pt:  'Pump Battery'
      , ro:  'Pump Battery'
      , nl:  'Pump Battery'
      , ru:  'Батарея помпы'
      , sk:  'Pump Battery'
      , sv:  'Pump Battery'
      , tr:  'Pump Battery'
      , zh_cn:  'Pump Battery'
      , zh_tw:  'Pump Battery'
      , hu:  'Pumpa töltöttsége'
    },
    'virtAsstTitleRawBG': {
      bg:  'Current Raw BG'
      , cs:  'Current Raw BG'
      , de:  'Aktueller Blutzucker-Rohwert'
      , dk:  'Current Raw BG'
      , el:  'Current Raw BG'
      , en:  'Current Raw BG'
      , es:  'Current Raw BG'
      , fi:  'Current Raw BG'
      , fr:  'Current Raw BG'
      , he:  'Current Raw BG'
      , hr:  'Current Raw BG'
      , it:  'Current Raw BG'
      , ko:  'Current Raw BG'
      , nb:  'Current Raw BG'
      , pl:  'Current Raw BG'
      , pt:  'Current Raw BG'
      , ro:  'Current Raw BG'
      , nl:  'Current Raw BG'
      , ru:  'Актуальн RAW ГК '
      , sk:  'Current Raw BG'
      , sv:  'Current Raw BG'
      , tr:  'Current Raw BG'
      , zh_cn:  'Current Raw BG'
      , zh_tw:  'Current Raw BG'
      , hu:  'Jelenlegi nyers cukorszint'
    },
    'virtAsstTitleUploaderBattery': {
      bg:  'Uploader Battery'
      , cs:  'Uploader Battery'
      , de:  'Uploader Batterie'
      , dk:  'Uploader Battery'
      , el:  'Uploader Battery'
      , en:  'Uploader Battery'
      , es:  'Uploader Battery'
      , fi:  'Uploader Battery'
      , fr:  'Uploader Battery'
      , he:  'Uploader Battery'
      , hr:  'Uploader Battery'
      , it:  'Uploader Battery'
      , ko:  'Uploader Battery'
      , nb:  'Uploader Battery'
      , pl:  'Uploader Battery'
      , pt:  'Uploader Battery'
      , ro:  'Uploader Battery'
      , nl:  'Uploader Battery'
      , ru:  'Батарея загрузчика'
      , sk:  'Uploader Battery'
      , sv:  'Uploader Battery'
      , tr:  'Uploader Battery'
      , zh_cn:  'Uploader Battery'
      , zh_tw:  'Current Raw BG'
      , hu:  'Feltöltő töltöttsége'
    },
    'virtAsstTitleCurrentBG': {
      bg:  'Current BG'
      , cs:  'Current BG'
      , de:  'Aktueller Blutzucker'
      , dk:  'Current BG'
      , el:  'Current BG'
      , en:  'Current BG'
      , es:  'Current BG'
      , fi:  'Current BG'
      , fr:  'Current BG'
      , he:  'Current BG'
      , hr:  'Current BG'
      , it:  'Current BG'
      , ko:  'Current BG'
      , nb:  'Current BG'
      , pl:  'Current BG'
      , pt:  'Current BG'
      , ro:  'Current BG'
      , nl:  'Current BG'
      , ru:  'Актуальная ГК'
      , sk:  'Current BG'
      , sv:  'Current BG'
      , tr:  'Current BG'
      , zh_cn:  'Current BG'
      , zh_tw:  'Current BG'
      , hu:  'Jelenlegi Cukorszint'
    },
    'virtAsstTitleFullStatus': {
      bg:  'Full Status'
      , cs:  'Full Status'
      , de:  'Gesamtstatus'
      , dk:  'Full Status'
      , el:  'Full Status'
      , en:  'Full Status'
      , es:  'Full Status'
      , fi:  'Full Status'
      , fr:  'Full Status'
      , he:  'Full Status'
      , hr:  'Full Status'
      , it:  'Full Status'
      , ko:  'Full Status'
      , nb:  'Full Status'
      , pl:  'Full Status'
      , pt:  'Full Status'
      , ro:  'Full Status'
      , nl:  'Full Status'
      , ru:  'Полная информация о статусе'
      , sk:  'Full Status'
      , sv:  'Full Status'
      , tr:  'Full Status'
      , zh_cn:  'Full Status'
      , zh_tw:  'Full Status'
      , hu:  'Teljes Státusz'
    },
    'virtAsstTitleCGMMode': {
      bg: 'CGM Mode'
      , cs: 'CGM Mode'
      , de: 'CGM Mode'
      , dk: 'CGM Mode'
      , el: 'CGM Mode'
      , en: 'CGM Mode'
      , es: 'CGM Mode'
      , fi: 'CGM Mode'
      , fr: 'CGM Mode'
      , he: 'CGM Mode'
      , hr: 'CGM Mode'
      , it: 'CGM Mode'
      , ko: 'CGM Mode'
      , nb: 'CGM Mode'
      , pl: 'CGM Mode'
      , pt: 'CGM Mode'
      , ro: 'CGM Mode'
      , nl: 'CGM Mode'
      , ru: 'CGM Mode'
      , sk: 'CGM Mode'
      , sv: 'CGM Mode'
      , tr: 'CGM Mode'
      , zh_cn: 'CGM Mode'
      , zh_tw: 'CGM Mode'
      , hu: 'CGM Mód'
    },
    'virtAsstTitleCGMStatus': {
      bg: 'CGM Status'
      , cs: 'CGM Status'
      , de: 'CGM Status'
      , dk: 'CGM Status'
      , el: 'CGM Status'
      , en: 'CGM Status'
      , es: 'CGM Status'
      , fi: 'CGM Status'
      , fr: 'CGM Status'
      , he: 'CGM Status'
      , hr: 'CGM Status'
      , it: 'CGM Status'
      , ko: 'CGM Status'
      , nb: 'CGM Status'
      , pl: 'CGM Status'
      , pt: 'CGM Status'
      , ro: 'CGM Status'
      , nl: 'CGM Status'
      , ru: 'CGM Status'
      , sk: 'CGM Status'
      , sv: 'CGM Status'
      , tr: 'CGM Status'
      , zh_cn: 'CGM Status'
      , zh_tw: 'CGM Status'
      , hu: 'CGM Státusz'
    },
    'virtAsstTitleCGMSessionAge': {
      bg: 'CGM Session Age'
      , cs: 'CGM Session Age'
      , de: 'CGM Session Age'
      , dk: 'CGM Session Age'
      , el: 'CGM Session Age'
      , en: 'CGM Session Age'
      , es: 'CGM Session Age'
      , fi: 'CGM Session Age'
      , fr: 'CGM Session Age'
      , he: 'CGM Session Age'
      , hr: 'CGM Session Age'
      , it: 'CGM Session Age'
      , ko: 'CGM Session Age'
      , nb: 'CGM Session Age'
      , pl: 'CGM Session Age'
      , pt: 'CGM Session Age'
      , ro: 'CGM Session Age'
      , nl: 'CGM Session Age'
      , ru: 'CGM Session Age'
      , sk: 'CGM Session Age'
      , sv: 'CGM Session Age'
      , tr: 'CGM Session Age'
      , zh_cn: 'CGM Session Age'
      , zh_tw: 'CGM Session Age'
      , hu: 'CGM életkora'
    },
    'virtAsstTitleCGMTxStatus': {
      bg: 'CGM Transmitter Status'
      , cs: 'CGM Transmitter Status'
      , de: 'CGM Transmitter Status'
      , dk: 'CGM Transmitter Status'
      , el: 'CGM Transmitter Status'
      , en: 'CGM Transmitter Status'
      , es: 'CGM Transmitter Status'
      , fi: 'CGM Transmitter Status'
      , fr: 'CGM Transmitter Status'
      , he: 'CGM Transmitter Status'
      , hr: 'CGM Transmitter Status'
      , it: 'CGM Transmitter Status'
      , ko: 'CGM Transmitter Status'
      , nb: 'CGM Transmitter Status'
      , pl: 'CGM Transmitter Status'
      , pt: 'CGM Transmitter Status'
      , ro: 'CGM Transmitter Status'
      , nl: 'CGM Transmitter Status'
      , ru: 'CGM Transmitter Status'
      , sk: 'CGM Transmitter Status'
      , sv: 'CGM Transmitter Status'
      , tr: 'CGM Transmitter Status'
      , zh_cn: 'CGM Transmitter Status'
      , zh_tw: 'CGM Transmitter Status'
      , hu: 'CGM kapcsolat státusza'
    },
    'virtAsstTitleCGMTxAge': {
      bg: 'CGM Transmitter Age'
      , cs: 'CGM Transmitter Age'
      , de: 'CGM Transmitter Age'
      , dk: 'CGM Transmitter Age'
      , el: 'CGM Transmitter Age'
      , en: 'CGM Transmitter Age'
      , es: 'CGM Transmitter Age'
      , fi: 'CGM Transmitter Age'
      , fr: 'CGM Transmitter Age'
      , he: 'CGM Transmitter Age'
      , hr: 'CGM Transmitter Age'
      , it: 'CGM Transmitter Age'
      , ko: 'CGM Transmitter Age'
      , nb: 'CGM Transmitter Age'
      , pl: 'CGM Transmitter Age'
      , pt: 'CGM Transmitter Age'
      , ro: 'CGM Transmitter Age'
      , nl: 'CGM Transmitter Age'
      , ru: 'CGM Transmitter Age'
      , sk: 'CGM Transmitter Age'
      , sv: 'CGM Transmitter Age'
      , tr: 'CGM Transmitter Age'
      , zh_cn: 'CGM Transmitter Age'
      , zh_tw: 'CGM Transmitter Age'
    },
    'virtAsstTitleCGMNoise': {
      bg: 'CGM Noise'
      , cs: 'CGM Noise'
      , de: 'CGM Noise'
      , dk: 'CGM Noise'
      , el: 'CGM Noise'
      , en: 'CGM Noise'
      , es: 'CGM Noise'
      , fi: 'CGM Noise'
      , fr: 'CGM Noise'
      , he: 'CGM Noise'
      , hr: 'CGM Noise'
      , it: 'CGM Noise'
      , ko: 'CGM Noise'
      , nb: 'CGM Noise'
      , pl: 'CGM Noise'
      , pt: 'CGM Noise'
      , ro: 'CGM Noise'
      , nl: 'CGM Noise'
      , ru: 'CGM Noise'
      , sk: 'CGM Noise'
      , sv: 'CGM Noise'
      , tr: 'CGM Noise'
      , zh_cn: 'CGM Noise'
      , zh_tw: 'CGM Noise'
      , hu: 'CGM Zaj'
      },
    'virtAsstTitleDelta': {
      bg:  'Blood Glucose Delta'
      , cs:  'Blood Glucose Delta'
      , de:  'Blutzucker-Delta'
      , dk:  'Blood Glucose Delta'
      , el:  'Blood Glucose Delta'
      , en:  'Blood Glucose Delta'
      , es:  'Blood Glucose Delta'
      , fi:  'Blood Glucose Delta'
      , fr:  'Blood Glucose Delta'
      , he:  'Blood Glucose Delta'
      , hr:  'Blood Glucose Delta'
      , it:  'Blood Glucose Delta'
      , ko:  'Blood Glucose Delta'
      , nb:  'Blood Glucose Delta'
      , pl:  'Blood Glucose Delta'
      , pt:  'Blood Glucose Delta'
      , ro:  'Blood Glucose Delta'
      , nl:  'Blood Glucose Delta'
      , ru:  'Дельта ГК'
      , sk:  'Blood Glucose Delta'
      , sv:  'Blood Glucose Delta'
      , tr:  'Blood Glucose Delta'
      , zh_cn:  'Blood Glucose Delta'
      , zh_tw:  'Blood Glucose Delta'
      , hu:  'Csukoszint delta'
    },
    'virtAsstStatus': {
        bg:  '%1 and %2 as of %3.'
        , cs:  '%1 %2 čas %3.'
        , de:  '%1 und bis %3 %2.'
        , dk:  '%1 og %2 af %3.'
        , el:  '%1 and %2 as of %3.'
        , en:  '%1 and %2 as of %3.'
        , es:  '%1 y %2 como de %3.'
        , fi:  '%1 ja %2 alkaen %3.'
        , fr:  '%1 and %2 as of %3.'
        , he:  '%1 and %2 as of %3.'
        , hr:  '%1 and %2 as of %3.'
        , it:  '%1 e %2 come %3.'
        , ko:  '%1 and %2 as of %3.'
        , nb:  '%1 and %2 as of %3.'
        , pl:  '%1 i %2 rozpoczęte od %3.'
        , pt:  '%1 and %2 as of %3.'
        , ro:  '%1 și %2 din %3.'
        , nl:  '%1 and %2 as of %3.'
        , ru:  '%1 и %2 начиная с %3.'
        , sk:  '%1 and %2 as of %3.'
        , sv:  '%1 and %2 as of %3.'
        , tr:  '%1 ve %2 e kadar %3.'
        , zh_cn:  '%1 和 %2 到 %3.'
        , zh_tw:  '%1 and %2 as of %3.'
        , hu:  '%1 es %2 %3-tól.'
    },
    'virtAsstBasal': {
        bg:  '%1 současný bazál je %2 jednotek za hodinu'
        , cs:  '%1 current basal is %2 units per hour'
        , de:  '%1 aktuelle Basalrate ist %2 Einheiten je Stunde'
        , dk:  '%1 nuværende basal er %2 enheder per time'
        , el:  '%1 current basal is %2 units per hour'
        , en:  '%1 current basal is %2 units per hour'
        , es:  '%1 basal actual es %2 unidades por hora'
        , fi:  '%1 nykyinen basaali on %2 yksikköä tunnissa'
        , fr:  '%1 current basal is %2 units per hour'
        , he:  '%1 current basal is %2 units per hour'
        , hr:  '%1 current basal is %2 units per hour'
        , it:  '%1 basale attuale è %2 unità per ora'
        , ko:  '%1 current basal is %2 units per hour'
        , nb:  '%1 current basal is %2 units per hour'
        , pl:  '%1 obecna dawka bazalna %2 J na godzinę'
        , pt:  '%1 current basal is %2 units per hour'
        , ro:  '%1 bazala curentă este %2 unități pe oră'
        , ru:  '%1 текущий базал %2 ед в час'
        , sk:  '%1 current basal is %2 units per hour'
        , sv:  '%1 current basal is %2 units per hour'
        , nl:  '%1 current basal is %2 units per hour'
        , tr:  '%1 geçerli bazal oranı saatte %2 ünite'
        , zh_cn:  '%1  当前基础率是 %2 U/小时'
        , zh_tw:  '%1 current basal is %2 units per hour'
        , hu:  '%1 a jelenlegi bazál %2 egység óránként'
    },
    'virtAsstBasalTemp': {
        bg:  '%1 dočasný bazál %2 jednotek za hodinu skončí %3'
        , cs:  '%1 temp basal of %2 units per hour will end %3'
        , de:  '%1 temporäre Basalrate von %2 Einheiten endet %3'
        , dk:  '%1 midlertidig basal af %2 enheder per time stopper %3'
        , el:  '%1 temp basal of %2 units per hour will end %3'
        , en:  '%1 temp basal of %2 units per hour will end %3'
        , es:  '%1 Basal temporal de %2 unidades por hora hasta el fin %3'
        , fi:  '%1 tilapäinen basaali on %2 tunnissa, päättyy %3'
        , fr:  '%1 temp basal of %2 units per hour will end %3'
        , he:  '%1 temp basal of %2 units per hour will end %3'
        , hr:  '%1 temp basal of %2 units per hour will end %3'
        , it:  '%1 basale temporanea di %2 unità per ora finirà %3'
        , ko:  '%1 temp basal of %2 units per hour will end %3'
        , nb:  '%1 temp basal of %2 units per hour will end %3'
        , pl:  '%1 tymczasowa dawka bazalna %2 J na godzinę zakoczy się o %3'
        , pt:  '%1 temp basal of %2 units per hour will end %3'
        , ro:  '%1 bazala temporară de %2 unități pe oră se va termina la %3'
        , ru:  '%1 временный базал %2 ед в час закончится в %3'
        , sk:  '%1 temp basal of %2 units per hour will end %3'
        , sv:  '%1 temp basal of %2 units per hour will end %3'
        , nl:  '%1 temp basal of %2 units per hour will end %3'
        , tr:  '%1 geçici bazal %2 ünite %3 sona eriyor'
        , zh_cn:  '%1 临时基础率 %2 U/小时将会在 %3结束'
        , zh_tw:  '%1 temp basal of %2 units per hour will end %3'
        , hu:  '%1 átmeneti bazál %2 egység óránként ami %3 -kor jár le'
    },
    'virtAsstIob': {
        bg: 'a máte %1 jednotek aktivního inzulínu.'
        , cs: 'and you have %1 insulin on board.'
        , de: 'und du hast %1 Insulin wirkend.'
        , dk: 'og du har %1 insulin i kroppen.'
        , el: 'and you have %1 insulin on board.'
        , en: 'and you have %1 insulin on board.'
        , es: 'y tu tienes %1 insulina activa.'
        , fi: 'ja sinulla on %1 aktivista insuliinia.'
        , fr: 'and you have %1 insulin on board.'
        , he: 'and you have %1 insulin on board.'
        , hr: 'and you have %1 insulin on board.'
        , it: 'e tu hai %1 insulina attiva.'
        , ko: 'and you have %1 insulin on board.'
        , nb: 'and you have %1 insulin on board.'
        , pl: 'i masz %1 aktywnej insuliny.'
        , pt: 'and you have %1 insulin on board.'
        , ro: 'și mai aveți %1 insulină activă.'
        , ru: 'и вы имеете %1 инсулина в организме.'
        , sk: 'and you have %1 insulin on board.'
        , sv: 'and you have %1 insulin on board.'
        , nl: 'and you have %1 insulin on board.'
        , tr: 've Sizde %1 aktif insulin var'
        , zh_cn: '并且你有 %1 的活性胰岛素.'
        , zh_tw: 'and you have %1 insulin on board.'
        , hu: 'és neked %1 inzulin van a testedben.'
    },
    'virtAsstIobIntent': {
        bg: 'Máte %1 jednotek aktivního inzulínu'
        , cs: 'You have %1 insulin on board'
        , de: 'Du hast noch %1 Insulin wirkend'
        , dk: 'Du har %1 insulin i kroppen'
        , el: 'You have %1 insulin on board'
        , en: 'You have %1 insulin on board'
        , es: 'Tienes %1 insulina activa'
        , fi: 'Sinulla on %1 aktiivista insuliinia'
        , fr: 'You have %1 insulin on board'
        , he: 'You have %1 insulin on board'
        , hr: 'You have %1 insulin on board'
        , it: 'Tu hai %1 insulina attiva'
        , ko: 'You have %1 insulin on board'
        , nb: 'You have %1 insulin on board'
        , pl: 'Masz %1 aktywnej insuliny'
        , pt: 'You have %1 insulin on board'
        , ro: 'Aveți %1 insulină activă'
        , ru: 'вы имеете %1 инсулина в организме'
        , sk: 'You have %1 insulin on board'
        , sv: 'You have %1 insulin on board'
        , nl: 'You have %1 insulin on board'
        , tr: 'Sizde %1 aktif insülin var'
        , zh_cn: '你有 %1 的活性胰岛素'
        , zh_tw: 'You have %1 insulin on board'
        , hu: 'Neked %1 inzulin van a testedben'
    },
    'virtAsstIobUnits': {
        bg: '%1 units of'
        , cs: '%1 jednotek'
        , de: 'noch %1 Einheiten'
        , dk: '%1 enheder af'
        , el: '%1 units of'
        , en: '%1 units of'
        , es: '%1 unidades de'
        , fi: '%1 yksikköä'
        , fr: '%1 units of'
        , he: '%1 units of'
        , hr: '%1 units of'
        , it: '%1 unità di'
        , ko: '%1 units of'
        , nb: '%1 units of'
        , pl: '%1 jednostek'
        , pt: '%1 units of'
        , ro: '%1 unități'
        , nl: '%1 units of'
        , ru: '%1 единиц'
        , sk: '%1 units of'
        , sv: '%1 units of'
        , tr: 'hala %1 birim'
        , zh_cn: '%1 单位'
        , zh_tw: '%1 units of'
        , hu: '%1 egység'
    },
    'virtAsstLaunch': {
      bg:  'What would you like to check on Nightscout?'
      , cs:  'What would you like to check on Nightscout?'
      , de:  'Was möchtest du von Nightscout wissen?'
      , dk:  'What would you like to check on Nightscout?'
      , el:  'What would you like to check on Nightscout?'
      , en:  'What would you like to check on Nightscout?'
      , es:  'What would you like to check on Nightscout?'
      , fi:  'What would you like to check on Nightscout?'
      , fr:  'What would you like to check on Nightscout?'
      , he:  'What would you like to check on Nightscout?'
      , hr:  'What would you like to check on Nightscout?'
      , it:  'What would you like to check on Nightscout?'
      , ko:  'What would you like to check on Nightscout?'
      , nb:  'What would you like to check on Nightscout?'
      , pl:  'What would you like to check on Nightscout?'
      , pt:  'What would you like to check on Nightscout?'
      , ro:  'What would you like to check on Nightscout?'
      , nl:  'What would you like to check on Nightscout?'
      , ru:  'Что проверить в Nightscout?'
      , sk:  'What would you like to check on Nightscout?'
      , sv:  'What would you like to check on Nightscout?'
      , tr:  'What would you like to check on Nightscout?'
      , zh_cn:  'What would you like to check on Nightscout?'
      , zh_tw:  'What would you like to check on Nightscout?'
      , hu:  'Mit szeretnél ellenőrizni a Nightscout oldalon?'
    },
    'virtAsstPreamble': {
        bg: 'Your'
        , cs: 'Vaše'
        , de: 'Deine'
        , dk: 'Dine'
        , el: 'Your'
        , en: 'Your'
        , es: 'Tú'
        , fi: 'Sinun'
        , fr: 'Your'
        , he: 'Your'
        , hr: 'Your'
        , it: 'Tuo'
        , ko: 'Your'
        , nb: 'Your'
        , pl: 'twój'
        , pt: 'Your'
        , nl: 'Jouw'
        , ro: ''
        , ru: 'ваш'
        , sk: 'Your'
        , sv: 'Your'
        , tr: 'Senin'
        , zh_cn: '你的'
        , zh_tw: 'Your'
        , hu: 'A tied'
    },
    'virtAsstPreamble3person': {
        bg: '%1 has a '
        , cs: '%1 má '
        , de: '%1 hat eine'
        , dk: '%1 har en '
        , el: '%1 has a '
        , en: '%1 has a '
        , es: '%1 tiene un '
        , fi: '%1 on '
        , fr: '%1 has a '
        , he: '%1 has a '
        , hr: '%1 has a '
        , it: '%1 ha un '
        , ko: '%1 has a '
        , nb: '%1 has a '
        , nl: '%1 heeft een '
        , pl: '%1 ma '
        , pt: '%1 has a '
        , ro: '%1 are '
        , ru: '%1 имеет '
        , sk: '%1 has a '
        , sv: '%1 has a '
        , tr: '%1 bir tane var'
        , zh_cn: '%1 有一个 '
        , zh_tw: '%1 has a '
        , hu: '%1 -nak van '
    },
    'virtAsstNoInsulin': {
        bg: 'no'
        , cs: 'žádný'
        , de: 'kein'
        , dk: 'nej'
        , el: 'no'
        , en: 'no'
        , es: 'no'
        , fi: 'ei'
        , fr: 'no'
        , he: 'no'
        , hr: 'no'
        , it: 'no'
        , ko: 'no'
        , nb: 'no'
        , nl: 'geen'
        , pl: 'nie'
        , pt: 'no'
        , ro: 'fără'
        , ru: 'нет'
        , sk: 'no'
        , sv: 'no'
        , tr: 'yok'
        , zh_cn: '否'
        , zh_tw: 'no'
        , hu: 'semmilyen'
    },
    'virtAsstUploadBattery': {
      bg: 'Your uploader battery is at %1'
      , cs: 'Baterie mobilu má %1'
      , en: 'Your uploader battery is at %1'
      , hr: 'Your uploader battery is at %1'
      , de: 'Der Akku deines Uploader-Handys ist bei %1'
      , dk: 'Din uploaders batteri er %1'
      , ko: 'Your uploader battery is at %1'
      , nl: 'De batterij van je mobiel is bij %l'
      , zh_cn: '你的手机电池电量是 %1 '
      , sv: 'Din uppladdares batteri är %1'
      , fi: 'Lähettimen paristoa jäljellä %1'
      , ro: 'Bateria uploaderului este la %1'
      , pl: 'Twoja bateria ma %1'
      , ru: 'батарея загрузчика %1'
      , tr: 'Yükleyici piliniz %1'
      , hu: 'A felöltőd töltöttsége %1'
    },
    'virtAsstReservoir': {
      bg: 'You have %1 units remaining'
      , cs: 'V zásobníku zbývá %1 jednotek'
      , en: 'You have %1 units remaining'
      , hr: 'You have %1 units remaining'
      , de: 'Du hast %1 Einheiten übrig'
      , dk: 'Du har %1 enheder tilbage'
      , ko: 'You have %1 units remaining'
      , nl: 'Je hebt nog %l eenheden in je reservoir'
      , zh_cn: '你剩余%1 U的胰岛素'
      , sv: 'Du har %1 enheter kvar'
      , fi: '%1 yksikköä insuliinia jäljellä'
      , ro: 'Mai aveți %1 unități rămase'
      , pl: 'W zbiorniku pozostało %1 jednostek'
      , ru: 'остается %1 ед'
      , tr: '%1 birim kaldı'
      , hu: '%1 egység maradt hátra'
    },
    'virtAsstPumpBattery': {
      bg: 'Your pump battery is at %1 %2'
      , cs: 'Baterie v pumpě má %1 %2'
      , en: 'Your pump battery is at %1 %2'
      , hr: 'Your pump battery is at %1 %2'
      , de: 'Der Batteriestand deiner Pumpe ist bei %1 %2'
      , dk: 'Din pumpes batteri er %1 %2'
      , ko: 'Your pump battery is at %1 %2'
      , nl: 'Je pomp batterij is bij %1 %2'
      , zh_cn: '你的泵电池电量是%1 %2'
      , sv: 'Din pumps batteri är %1 %2'
      , fi: 'Pumppu on %1 %2'
      , ro: 'Bateria pompei este la %1 %2'
      , pl: 'Bateria pompy jest w %1 %2'
      , ru: 'батарея помпы %1 %2'
      , tr: 'Pompa piliniz %1 %2'
      , hu: 'A pumpád töltöttsége %1 %2'
    },
    'virtAsstUploaderBattery': {
      bg: 'Your uploader battery is at %1'
      , cs: 'Your uploader battery is at %1'
      , en: 'Your uploader battery is at %1'
      , hr: 'Your uploader battery is at %1'
      , de: 'Der Akku deines Uploader-Handys ist bei %1'
      , dk: 'Your uploader battery is at %1'
      , ko: 'Your uploader battery is at %1'
      , nl: 'Your uploader battery is at %1'
      , zh_cn: 'Your uploader battery is at %1'
      , sv: 'Your uploader battery is at %1'
      , fi: 'Your uploader battery is at %1'
      , ro: 'Your uploader battery is at %1'
      , pl: 'Your uploader battery is at %1'
      , ru: 'Батарея загрузчика %1'
      , tr: 'Your uploader battery is at %1'
      , hu: 'A feltöltőd töltöttsége %1'
    },
    'virtAsstLastLoop': {
      bg: 'The last successful loop was %1'
      , cs: 'Poslední úšpěšné provedení smyčky %1'
      , en: 'The last successful loop was %1'
      , hr: 'The last successful loop was %1'
      , de: 'Der letzte erfolgreiche Loop war %1'
      , dk: 'Seneste successfulde loop var %1'
      , ko: 'The last successful loop was %1'
      , nl: 'De meest recente goede loop was %1'
      , zh_cn: '最后一次成功闭环的是在%1'
      , sv: 'Senaste lyckade loop var %1'
      , fi: 'Viimeisin onnistunut loop oli %1'
      , ro: 'Ultima decizie loop implementată cu succes a fost %1'
      , pl: 'Ostatnia pomyślna pętla była %1'
      , ru: 'недавний успешный цикл был %1'
      , tr: 'Son başarılı döngü %1 oldu'
      , hu: 'Az utolsó sikeres loop %1-kor volt'
    },
    'virtAsstLoopNotAvailable': {
      bg: 'Loop plugin does not seem to be enabled'
      , cs: 'Plugin smyčka není patrně povolený'
      , en: 'Loop plugin does not seem to be enabled'
      , hr: 'Loop plugin does not seem to be enabled'
      , de: 'Das Loop-Plugin scheint nicht aktiviert zu sein'
      , dk: 'Loop plugin lader ikke til at være slået til'
      , ko: 'Loop plugin does not seem to be enabled'
      , nl: 'De Loop plugin is niet geactiveerd'
      , zh_cn: 'Loop插件看起来没有被启用'
      , sv: 'Loop plugin verkar inte vara aktiverad'
      , fi: 'Loop plugin ei ole aktivoitu'
      , ro: 'Extensia loop pare a fi dezactivată'
      , pl: 'Plugin Loop prawdopodobnie nie jest włączona'
      , ru: 'Расширение ЗЦ Loop не активировано'
      , tr: 'Döngü eklentisi etkin görünmüyor'
      , hu: 'A loop kiegészítés valószínűleg nincs bekapcsolva'
    },
    'virtAsstLoopForecastAround': {
      bg: 'According to the loop forecast you are expected to be around %1 over the next %2'
      , cs: 'Podle přepovědi smyčky je očekávána glykémie around %1 během následujících %2'
      , en: 'According to the loop forecast you are expected to be around %1 over the next %2'
      , hr: 'According to the loop forecast you are expected to be around %1 over the next %2'
      , de: 'Entsprechend der Loop-Vorhersage landest in den nächsten %2 bei %1'
      , dk: 'Ifølge Loops forudsigelse forventes du at blive around %1 i den næste %2'
      , ko: 'According to the loop forecast you are expected to be around %1 over the next %2'
      , nl: 'Volgens de Loop voorspelling is je waarde around %1 over de volgnede %2'
      , zh_cn: '根据loop的预测，在接下来的%2你的血糖将会是around %1'
      , sv: 'Enligt Loops förutsägelse förväntas du bli around %1 inom %2'
      , fi: 'Ennusteen mukaan olet around %1 seuraavan %2 ajan'
      , ro: 'Potrivit previziunii date de loop se estiemază around %1 pentru următoarele %2'
      , pl: 'Zgodnie z prognozą pętli, glikemia around %1 będzie podczas następnego %2'
      , ru: 'по прогнозу алгоритма ЗЦ ожидается около %1 за последующие %2'
      , tr: 'Döngü tahminine göre sonraki %2 ye göre around %1 olması bekleniyor'
      , hu: 'A loop előrejelzése alapján a követlező %2 időszakban körülbelül %1 lesz'
    },
    'virtAsstLoopForecastBetween': {
      bg: 'According to the loop forecast you are expected to be between %1 and %2 over the next %3'
      , cs: 'Podle přepovědi smyčky je očekávána glykémie between %1 and %2 během následujících %3'
      , en: 'According to the loop forecast you are expected to be between %1 and %2 over the next %3'
      , hr: 'According to the loop forecast you are expected to be between %1 and %2 over the next %3'
      , de: 'Entsprechend der Loop-Vorhersage landest du zwischen %1 und %2 während der nächsten %3'
      , dk: 'Ifølge Loops forudsigelse forventes du at blive between %1 and %2 i den næste %3'
      , ko: 'According to the loop forecast you are expected to be between %1 and %2 over the next %3'
      , nl: 'Volgens de Loop voorspelling is je waarde between %1 and %2 over de volgnede %3'
      , zh_cn: '根据loop的预测，在接下来的%3你的血糖将会是between %1 and %2'
      , sv: 'Enligt Loops förutsägelse förväntas du bli between %1 and %2 inom %3'
      , fi: 'Ennusteen mukaan olet between %1 and %2 seuraavan %3 ajan'
      , ro: 'Potrivit previziunii date de loop se estiemază between %1 and %2 pentru următoarele %3'
      , pl: 'Zgodnie z prognozą pętli, glikemia between %1 and %2 będzie podczas następnego %3'
      , ru: 'по прогнозу алгоритма ЗЦ ожидается между %1 и %2 за последующие %3'
      , tr: 'Döngü tahminine göre sonraki %3 ye göre between %1 and %2 olması bekleniyor'
      , hu: 'A loop előrejelzése alapján a követlező %3 időszakban %1 és %2 között leszel'
    },
    'virtAsstAR2ForecastAround': {
      bg: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , cs: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , en: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , hr: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , de: 'Entsprechend der AR2-Vorhersage landest du in %2 bei %1'
      , dk: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , ko: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , nl: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , zh_cn: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , sv: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , fi: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , ro: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , pl: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , ru: 'По прогнозу AR2 ожидается около %1 в следующие %2'
      , tr: 'According to the AR2 forecast you are expected to be around %1 over the next %2'
      , hu: 'Az AR2ües előrejelzés alapján a követlező %2 időszakban körülbelül %1 lesz'
    },
    'virtAsstAR2ForecastBetween': {
      bg: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , cs: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , en: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , hr: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , de: 'Entsprechend der AR2-Vorhersage landest du in %3 zwischen %1 and %2'
      , dk: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , ko: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , nl: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , zh_cn: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , sv: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , fi: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , ro: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , pl: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , ru: 'По прогнозу AR2 ожидается между %1 и %2 в следующие %3'
      , tr: 'According to the AR2 forecast you are expected to be between %1 and %2 over the next %3'
      , hu: 'Az AR2 előrejelzése alapján a követlező %3 időszakban %1 és %2 között leszel'
    },
    'virtAsstForecastUnavailable': {
      bg: 'Unable to forecast with the data that is available'
      , cs: 'S dostupnými daty přepověď není možná'
      , en: 'Unable to forecast with the data that is available'
      , hr: 'Unable to forecast with the data that is available'
      , de: 'Mit den verfügbaren Daten ist eine Loop-Vorhersage nicht möglich'
      , dk: 'Det er ikke muligt at forudsige md de tilgængelige data'
      , ko: 'Unable to forecast with the data that is available'
      , nl: 'Niet mogelijk om een voorspelling te doen met de data die beschikbaar is'
      , zh_cn: '血糖数据不可用，无法预测未来走势'
      , sv: 'Förutsägelse ej möjlig med tillgänlig data'
      , fi: 'Ennusteet eivät ole toiminnassa puuttuvan tiedon vuoksi'
      , ro: 'Estimarea este imposibilă pe baza datelor disponibile'
      , pl: 'Prognoza pętli nie jest możliwa, z dostępnymi danymi.'
      , ru: 'прогноз при таких данных невозможен'
      , tr: 'Mevcut verilerle tahmin edilemedi'
      , hu: 'Nem tudok előrejelzést készíteni hiányos adatokból'
    },
    'virtAsstRawBG': {
      en: 'Your raw bg is %1'
      , cs: 'Raw glykémie je %1'
      , de: 'Dein Rohblutzucker ist %1'
      , dk: 'Dit raw blodsukker er %1'
      , ko: 'Your raw bg is %1'
      , nl: 'Je raw bloedwaarde is %1'
      , zh_cn: '你的血糖是 %1'
      , sv: 'Ditt raw blodsocker är %1'
      , fi: 'Suodattamaton verensokeriarvo on %1'
      , ro: 'Glicemia brută este %1'
      , bg: 'Your raw bg is %1'
      , hr: 'Your raw bg is %1'
      , pl: 'Glikemia RAW wynosi %1'
      , ru: 'ваши необработанные данные RAW %1'
      , tr: 'Ham kan şekeriniz %1'
      , hu: 'A nyers cukorszinted %1'
    },
    'virtAsstOpenAPSForecast': {
      en: 'The OpenAPS Eventual BG is %1'
      , cs: 'OpenAPS Eventual BG je %1'
      , de: 'Der von OpenAPS vorhergesagte Blutzucker ist %1'
      , dk: 'OpenAPS forventet blodsukker er %1'
      , ko: 'The OpenAPS Eventual BG is %1'
      , nl: 'OpenAPS uiteindelijke bloedglucose van %1'
      , zh_cn: 'OpenAPS 预测最终血糖是 %1'
      , sv: 'OpenAPS slutgiltigt blodsocker är %1'
      , fi: 'OpenAPS verensokeriarvio on %1'
      , ro: 'Glicemia estimată de OpenAPS este %1'
      , bg: 'The OpenAPS Eventual BG is %1'
      , hr: 'The OpenAPS Eventual BG is %1'
      , pl: 'Glikemia prognozowana przez OpenAPS wynosi %1'
      , ru: 'OpenAPS прогнозирует ваш СК как %1 '
      , tr: 'OpenAPS tarafından tahmin edilen kan şekeri %1'
      , hu: 'Az OpenAPS cukorszinted %1'
    },
    'virtAsstCob3person': {
        bg: '%1 has %2 carbohydrates on board'
        , cs: '%1 has %2 carbohydrates on board'
        , de: '%1 hat %2 Kohlenhydrate wirkend'
        , dk: '%1 has %2 carbohydrates on board'
        , el: '%1 has %2 carbohydrates on board'
        , en: '%1 has %2 carbohydrates on board'
        , es: '%1 has %2 carbohydrates on board'
        , fi: '%1 has %2 carbohydrates on board'
        , fr: '%1 has %2 carbohydrates on board'
        , he: '%1 has %2 carbohydrates on board'
        , hr: '%1 has %2 carbohydrates on board'
        , it: '%1 has %2 carbohydrates on board'
        , ko: '%1 has %2 carbohydrates on board'
        , nb: '%1 has %2 carbohydrates on board'
        , nl: '%1 has %2 carbohydrates on board'
        , pl: '%1 has %2 carbohydrates on board'
        , pt: '%1 has %2 carbohydrates on board'
        , ro: '%1 has %2 carbohydrates on board'
        , ru: '%1 имеет %2 активных углеводов'
        , sk: '%1 has %2 carbohydrates on board'
        , sv: '%1 has %2 carbohydrates on board'
        , tr: '%1 has %2 carbohydrates on board'
        , zh_cn: '%1 has %2 carbohydrates on board'
        , zh_tw: '%1 has %2 carbohydrates on board'
        , hu: '%1 -nak  %2 szénhodrátja van a testében'
    },
    'virtAsstCob': {
        bg: 'You have %1 carbohydrates on board'
        , cs: 'You have %1 carbohydrates on board'
        , de: 'Du hast noch %1 Kohlenhydrate wirkend.'
        , dk: 'You have %1 carbohydrates on board'
        , el: 'You have %1 carbohydrates on board'
        , en: 'You have %1 carbohydrates on board'
        , es: 'You have %1 carbohydrates on board'
        , fi: 'You have %1 carbohydrates on board'
        , fr: 'You have %1 carbohydrates on board'
        , he: 'You have %1 carbohydrates on board'
        , hr: 'You have %1 carbohydrates on board'
        , it: 'You have %1 carbohydrates on board'
        , ko: 'You have %1 carbohydrates on board'
        , nb: 'You have %1 carbohydrates on board'
        , nl: 'You have %1 carbohydrates on board'
        , pl: 'You have %1 carbohydrates on board'
        , pt: 'You have %1 carbohydrates on board'
        , ro: 'You have %1 carbohydrates on board'
        , ru: 'У вас %1 активных углеводов'
        , sk: 'You have %1 carbohydrates on board'
        , sv: 'You have %1 carbohydrates on board'
        , tr: 'You have %1 carbohydrates on board'
        , zh_cn: 'You have %1 carbohydrates on board'
        , zh_tw: 'You have %1 carbohydrates on board'
        , hu: 'Neked %1 szénhidrát van a testedben'
    },
    'virtAsstCGMMode': {
        bg: 'Your CGM mode was %1 as of %2.'
        , cs: 'Your CGM mode was %1 as of %2.'
        , de: 'Your CGM mode was %1 as of %2.'
        , dk: 'Your CGM mode was %1 as of %2.'
        , el: 'Your CGM mode was %1 as of %2.'
        , en: 'Your CGM mode was %1 as of %2.'
        , es: 'Your CGM mode was %1 as of %2.'
        , fi: 'Your CGM mode was %1 as of %2.'
        , fr: 'Your CGM mode was %1 as of %2.'
        , he: 'Your CGM mode was %1 as of %2.'
        , hr: 'Your CGM mode was %1 as of %2.'
        , it: 'Your CGM mode was %1 as of %2.'
        , ko: 'Your CGM mode was %1 as of %2.'
        , nb: 'Your CGM mode was %1 as of %2.'
        , nl: 'Your CGM mode was %1 as of %2.'
        , pl: 'Your CGM mode was %1 as of %2.'
        , pt: 'Your CGM mode was %1 as of %2.'
        , ro: 'Your CGM mode was %1 as of %2.'
        , ru: 'Your CGM mode was %1 as of %2.'
        , sk: 'Your CGM mode was %1 as of %2.'
        , sv: 'Your CGM mode was %1 as of %2.'
        , tr: 'Your CGM mode was %1 as of %2.'
        , zh_cn: 'Your CGM mode was %1 as of %2.'
        , zh_tw: 'Your CGM mode was %1 as of %2.'
        , hu: 'A CGM módod %1 volt %2 -kor.'
    },
    'virtAsstCGMStatus': {
        bg: 'Your CGM status was %1 as of %2.'
        , cs: 'Your CGM status was %1 as of %2.'
        , de: 'Your CGM status was %1 as of %2.'
        , dk: 'Your CGM status was %1 as of %2.'
        , el: 'Your CGM status was %1 as of %2.'
        , en: 'Your CGM status was %1 as of %2.'
        , es: 'Your CGM status was %1 as of %2.'
        , fi: 'Your CGM status was %1 as of %2.'
        , fr: 'Your CGM status was %1 as of %2.'
        , he: 'Your CGM status was %1 as of %2.'
        , hr: 'Your CGM status was %1 as of %2.'
        , it: 'Your CGM status was %1 as of %2.'
        , ko: 'Your CGM status was %1 as of %2.'
        , nb: 'Your CGM status was %1 as of %2.'
        , nl: 'Your CGM status was %1 as of %2.'
        , pl: 'Your CGM status was %1 as of %2.'
        , pt: 'Your CGM status was %1 as of %2.'
        , ro: 'Your CGM status was %1 as of %2.'
        , ru: 'Your CGM status was %1 as of %2.'
        , sk: 'Your CGM status was %1 as of %2.'
        , sv: 'Your CGM status was %1 as of %2.'
        , tr: 'Your CGM status was %1 as of %2.'
        , zh_cn: 'Your CGM status was %1 as of %2.'
        , zh_tw: 'Your CGM status was %1 as of %2.'
        , hu: 'A CGM státuszod %1 volt %2 -kor.'
    },
    'virtAsstCGMSessAge': {
        bg: 'Your CGM session has been active for %1 days and %2 hours.'
        , cs: 'Your CGM session has been active for %1 days and %2 hours.'
        , de: 'Your CGM session has been active for %1 days and %2 hours.'
        , dk: 'Your CGM session has been active for %1 days and %2 hours.'
        , el: 'Your CGM session has been active for %1 days and %2 hours.'
        , en: 'Your CGM session has been active for %1 days and %2 hours.'
        , es: 'Your CGM session has been active for %1 days and %2 hours.'
        , fi: 'Your CGM session has been active for %1 days and %2 hours.'
        , fr: 'Your CGM session has been active for %1 days and %2 hours.'
        , he: 'Your CGM session has been active for %1 days and %2 hours.'
        , hr: 'Your CGM session has been active for %1 days and %2 hours.'
        , it: 'Your CGM session has been active for %1 days and %2 hours.'
        , ko: 'Your CGM session has been active for %1 days and %2 hours.'
        , nb: 'Your CGM session has been active for %1 days and %2 hours.'
        , nl: 'Your CGM session has been active for %1 days and %2 hours.'
        , pl: 'Your CGM session has been active for %1 days and %2 hours.'
        , pt: 'Your CGM session has been active for %1 days and %2 hours.'
        , ro: 'Your CGM session has been active for %1 days and %2 hours.'
        , ru: 'Your CGM session has been active for %1 days and %2 hours.'
        , sk: 'Your CGM session has been active for %1 days and %2 hours.'
        , sv: 'Your CGM session has been active for %1 days and %2 hours.'
        , tr: 'Your CGM session has been active for %1 days and %2 hours.'
        , zh_cn: 'Your CGM session has been active for %1 days and %2 hours.'
        , zh_tw: 'Your CGM session has been active for %1 days and %2 hours.'
        , hu: 'A CGM kapcsolatod %1 napja és %2 órája aktív'
    },
    'virtAsstCGMSessNotStarted': {
        bg: 'There is no active CGM session at the moment.'
        , cs: 'There is no active CGM session at the moment.'
        , de: 'There is no active CGM session at the moment.'
        , dk: 'There is no active CGM session at the moment.'
        , el: 'There is no active CGM session at the moment.'
        , en: 'There is no active CGM session at the moment.'
        , es: 'There is no active CGM session at the moment.'
        , fi: 'There is no active CGM session at the moment.'
        , fr: 'There is no active CGM session at the moment.'
        , he: 'There is no active CGM session at the moment.'
        , hr: 'There is no active CGM session at the moment.'
        , it: 'There is no active CGM session at the moment.'
        , ko: 'There is no active CGM session at the moment.'
        , nb: 'There is no active CGM session at the moment.'
        , nl: 'There is no active CGM session at the moment.'
        , pl: 'There is no active CGM session at the moment.'
        , pt: 'There is no active CGM session at the moment.'
        , ro: 'There is no active CGM session at the moment.'
        , ru: 'There is no active CGM session at the moment.'
        , sk: 'There is no active CGM session at the moment.'
        , sv: 'There is no active CGM session at the moment.'
        , tr: 'There is no active CGM session at the moment.'
        , zh_cn: 'There is no active CGM session at the moment.'
        , zh_tw: 'There is no active CGM session at the moment.'
        , hu: 'Jelenleg nincs aktív CGM kapcsolatod'
    },
    'virtAsstCGMTxStatus': {
        bg: 'Your CGM transmitter status was %1 as of %2.'
        , cs: 'Your CGM transmitter status was %1 as of %2.'
        , de: 'Your CGM transmitter status was %1 as of %2.'
        , dk: 'Your CGM transmitter status was %1 as of %2.'
        , el: 'Your CGM transmitter status was %1 as of %2.'
        , en: 'Your CGM transmitter status was %1 as of %2.'
        , es: 'Your CGM transmitter status was %1 as of %2.'
        , fi: 'Your CGM transmitter status was %1 as of %2.'
        , fr: 'Your CGM transmitter status was %1 as of %2.'
        , he: 'Your CGM transmitter status was %1 as of %2.'
        , hr: 'Your CGM transmitter status was %1 as of %2.'
        , it: 'Your CGM transmitter status was %1 as of %2.'
        , ko: 'Your CGM transmitter status was %1 as of %2.'
        , nb: 'Your CGM transmitter status was %1 as of %2.'
        , nl: 'Your CGM transmitter status was %1 as of %2.'
        , pl: 'Your CGM transmitter status was %1 as of %2.'
        , pt: 'Your CGM transmitter status was %1 as of %2.'
        , ro: 'Your CGM transmitter status was %1 as of %2.'
        , ru: 'Your CGM transmitter status was %1 as of %2.'
        , sk: 'Your CGM transmitter status was %1 as of %2.'
        , sv: 'Your CGM transmitter status was %1 as of %2.'
        , tr: 'Your CGM transmitter status was %1 as of %2.'
        , zh_cn: 'Your CGM transmitter status was %1 as of %2.'
        , zh_tw: 'Your CGM transmitter status was %1 as of %2.'
        , hu: 'A CGM jeladód státusza %1 volt %2-kor'
    },
    'virtAsstCGMTxAge': {
        bg: 'Your CGM transmitter is %1 days old.'
        , cs: 'Your CGM transmitter is %1 days old.'
        , de: 'Your CGM transmitter is %1 days old.'
        , dk: 'Your CGM transmitter is %1 days old.'
        , el: 'Your CGM transmitter is %1 days old.'
        , en: 'Your CGM transmitter is %1 days old.'
        , es: 'Your CGM transmitter is %1 days old.'
        , fi: 'Your CGM transmitter is %1 days old.'
        , fr: 'Your CGM transmitter is %1 days old.'
        , he: 'Your CGM transmitter is %1 days old.'
        , hr: 'Your CGM transmitter is %1 days old.'
        , it: 'Your CGM transmitter is %1 days old.'
        , ko: 'Your CGM transmitter is %1 days old.'
        , nb: 'Your CGM transmitter is %1 days old.'
        , nl: 'Your CGM transmitter is %1 days old.'
        , pl: 'Your CGM transmitter is %1 days old.'
        , pt: 'Your CGM transmitter is %1 days old.'
        , ro: 'Your CGM transmitter is %1 days old.'
        , ru: 'Your CGM transmitter is %1 days old.'
        , sk: 'Your CGM transmitter is %1 days old.'
        , sv: 'Your CGM transmitter is %1 days old.'
        , tr: 'Your CGM transmitter is %1 days old.'
        , zh_cn: 'Your CGM transmitter is %1 days old.'
        , zh_tw: 'Your CGM transmitter is %1 days old.'
        , hu: 'A CGM jeladód %1 napos.'
    },
    'virtAsstCGMNoise': {
        bg: 'Your CGM noise was %1 as of %2.'
        , cs: 'Your CGM noise was %1 as of %2.'
        , de: 'Your CGM noise was %1 as of %2.'
        , dk: 'Your CGM noise was %1 as of %2.'
        , el: 'Your CGM noise was %1 as of %2.'
        , en: 'Your CGM noise was %1 as of %2.'
        , es: 'Your CGM noise was %1 as of %2.'
        , fi: 'Your CGM noise was %1 as of %2.'
        , fr: 'Your CGM noise was %1 as of %2.'
        , he: 'Your CGM noise was %1 as of %2.'
        , hr: 'Your CGM noise was %1 as of %2.'
        , it: 'Your CGM noise was %1 as of %2.'
        , ko: 'Your CGM noise was %1 as of %2.'
        , nb: 'Your CGM noise was %1 as of %2.'
        , nl: 'Your CGM noise was %1 as of %2.'
        , pl: 'Your CGM noise was %1 as of %2.'
        , pt: 'Your CGM noise was %1 as of %2.'
        , ro: 'Your CGM noise was %1 as of %2.'
        , ru: 'Your CGM noise was %1 as of %2.'
        , sk: 'Your CGM noise was %1 as of %2.'
        , sv: 'Your CGM noise was %1 as of %2.'
        , tr: 'Your CGM noise was %1 as of %2.'
        , zh_cn: 'Your CGM noise was %1 as of %2.'
        , zh_tw: 'Your CGM noise was %1 as of %2.'
        , hu: 'A CGM jeladó zaja %1 volt %2-kor'
    },
    'virtAsstCGMBattOne': {
        bg: 'Your CGM battery was %1 volts as of %2.'
        , cs: 'Your CGM battery was %1 volts as of %2.'
        , de: 'Your CGM battery was %1 volts as of %2.'
        , dk: 'Your CGM battery was %1 volts as of %2.'
        , el: 'Your CGM battery was %1 volts as of %2.'
        , en: 'Your CGM battery was %1 volts as of %2.'
        , es: 'Your CGM battery was %1 volts as of %2.'
        , fi: 'Your CGM battery was %1 volts as of %2.'
        , fr: 'Your CGM battery was %1 volts as of %2.'
        , he: 'Your CGM battery was %1 volts as of %2.'
        , hr: 'Your CGM battery was %1 volts as of %2.'
        , it: 'Your CGM battery was %1 volts as of %2.'
        , ko: 'Your CGM battery was %1 volts as of %2.'
        , nb: 'Your CGM battery was %1 volts as of %2.'
        , nl: 'Your CGM battery was %1 volts as of %2.'
        , pl: 'Your CGM battery was %1 volts as of %2.'
        , pt: 'Your CGM battery was %1 volts as of %2.'
        , ro: 'Your CGM battery was %1 volts as of %2.'
        , ru: 'Your CGM battery was %1 volts as of %2.'
        , sk: 'Your CGM battery was %1 volts as of %2.'
        , sv: 'Your CGM battery was %1 volts as of %2.'
        , tr: 'Your CGM battery was %1 volts as of %2.'
        , zh_cn: 'Your CGM battery was %1 volts as of %2.'
        , zh_tw: 'Your CGM battery was %1 volts as of %2.'
        , hu: 'A CGM töltöttsége %1 VOLT volt %2-kor'
    },
    'virtAsstCGMBattTwo': {
        bg: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , cs: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , de: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , dk: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , el: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , en: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , es: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , fi: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , fr: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , he: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , hr: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , it: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , ko: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , nb: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , nl: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , pl: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , pt: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , ro: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , ru: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , sk: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , sv: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , tr: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , zh_cn: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , zh_tw: 'Your CGM battery levels were %1 volts and %2 volts as of %3.'
        , hu: 'A CGM töltöttsége %1 és %2 VOLT volt %3-kor'
    },
    'virtAsstDelta': {
        bg: 'Your delta was %1 between %2 and %3.'
        , cs: 'Your delta was %1 between %2 and %3.'
        , de: 'Dein Delta war %1 zwischen %2 und %3.'
        , dk: 'Your delta was %1 between %2 and %3.'
        , el: 'Your delta was %1 between %2 and %3.'
        , en: 'Your delta was %1 between %2 and %3.'
        , es: 'Your delta was %1 between %2 and %3.'
        , fi: 'Your delta was %1 between %2 and %3.'
        , fr: 'Your delta was %1 between %2 and %3.'
        , he: 'Your delta was %1 between %2 and %3.'
        , hr: 'Your delta was %1 between %2 and %3.'
        , it: 'Your delta was %1 between %2 and %3.'
        , ko: 'Your delta was %1 between %2 and %3.'
        , nb: 'Your delta was %1 between %2 and %3.'
        , nl: 'Your delta was %1 between %2 and %3.'
        , pl: 'Your delta was %1 between %2 and %3.'
        , pt: 'Your delta was %1 between %2 and %3.'
        , ro: 'Your delta was %1 between %2 and %3.'
        , ru: 'Дельта была %1 между %2 и %3.'
        , sk: 'Your delta was %1 between %2 and %3.'
        , sv: 'Your delta was %1 between %2 and %3.'
        , tr: 'Your delta was %1 between %2 and %3.'
        , zh_cn: 'Your delta was %1 between %2 and %3.'
        , zh_tw: 'Your delta was %1 between %2 and %3.'
        , hu: 'A deltád %1 volt %2 és %3 között'
    },
    'virtAsstUnknownIntentTitle': {
      en: 'Unknown Intent'
      , cs: 'Unknown Intent'
      , de: 'Unbekannte Absicht'
      , dk: 'Unknown Intent'
      , ko: 'Unknown Intent'
      , nl: 'Unknown Intent'
      , zh_cn: 'Unknown Intent'
      , sv: 'Unknown Intent'
      , fi: 'Unknown Intent'
      , ro: 'Unknown Intent'
      , bg: 'Unknown Intent'
      , hr: 'Unknown Intent'
      , pl: 'Unknown Intent'
      , ru: 'Неизвестное намерение'
      , tr: 'Ismeretlen szándék'
    },
    'virtAsstUnknownIntentText': {
      en: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , cs: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , de: 'Tut mir leid, ich hab deine Frage nicht verstanden.'
      , dk: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , ko: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , nl: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , zh_cn: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , sv: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , fi: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , ro: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , bg: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , hr: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , pl: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , ru: 'Ваш запрос непонятен'
      , tr: 'I\'m sorry, I don\'t know what you\'re asking for.'
      , hu: 'Sajnálom, nem tudom mit szeretnél tőlem.'
    },
    'Fat [g]': {
        cs: 'Tuk [g]'
        ,de: 'Fett [g]'
        ,dk: 'Fet [g]'
        ,es: 'Grasas [g]'
        ,fi: 'Rasva [g]'
        ,fr: 'Graisses [g]'
        ,ko: 'Fat [g]'
        ,nl: 'Vet [g]'
        ,zh_cn: '脂肪[g]'
        ,ro: 'Grăsimi [g]'
        ,ru: 'жиры [g]'
        ,it: 'Grassi [g]'
        ,sv: 'Fett [g]'
        ,bg: 'Мазнини [гр]'
        ,hr: 'Masnoće [g]'
        ,pl: 'Tłuszcz [g]'
        ,tr: 'Yağ [g]'
        ,he: '[g] שמן'
        ,hu: 'Zsír [g]'
    },
    'Protein [g]': {
        cs: 'Proteiny [g]'
        ,de: 'Proteine [g]'
        ,dk: 'Protein [g]'
        ,es: 'Proteina [g]'
        ,fi: 'Proteiini [g]'
        ,fr: 'Protéines [g]'
        ,ko: 'Protein [g]'
        ,nl: 'Proteine [g]'
        ,zh_cn: '蛋白质[g]'
        ,ro: 'Proteine [g]'
        ,ru: 'белки [g]'
        ,it: 'Proteine [g]'
        ,sv: 'Protein [g]'
        ,bg: 'Протеини [гр]'
        ,hr: 'Proteini [g]'
        ,pl: 'Białko [g]'
        ,tr: 'Protein [g]'
        ,he: '[g] חלבון'
        ,hu: 'Protein [g]'
    },
    'Energy [kJ]': {
        cs: 'Energie [kJ]'
       ,de: 'Energie [kJ]'
       ,dk: 'Energi [kJ]'
       ,fi: 'Energia [kJ]'
       ,es: 'Energía [Kj]'
       ,fr: 'Énergie [kJ]'
       ,ro: 'Energie [g]'
       ,ru: 'энергия [kJ]'
       ,it: 'Energia [kJ]'
       ,zh_cn: '能量 [kJ]'
       ,ko: 'Energy [kJ]'
       ,nl: 'Energie [kJ]'
       ,sv: 'Energi [kJ]'
      ,bg: 'Енергия [kJ]'
      ,hr: 'Energija [kJ]'
      ,pl: 'Energia [kJ}'
      ,tr: 'Enerji [kJ]'
      ,he: '[kJ] אנרגיה'
      ,hu: 'Energia [kJ]'
    },
    'Clock Views:': {
       cs: 'Hodiny:'
      ,fi: 'Kellonäkymä:'
      ,ko: '시계 보기'
      ,nl: 'Klokweergave:'
      ,es: 'Vista del reloj:'
      ,fr: 'Vue Horloge:'
      ,ro: 'Vedere tip ceas:'
      ,ru: 'цифры крупно:'
      ,it: 'Vista orologio:'
      ,zh_cn: '时钟视图'
      ,sv: 'Visa klocka:'
      ,de: 'Uhr-Anzeigen'
      ,dk: 'Vis klokken:'
      ,bg: 'Часовник изглед:'
      ,hr: 'Satovi:'
      ,pl: 'Widoki zegarów'
      ,tr: 'Saat Görünümü'
      ,he: 'צגים השעון'
      ,hu: 'Óra:'
    },
    'Clock': {
       cs: 'Hodiny'
       ,fr: 'L\'horloge'
       ,ko: '시계모드'
       ,nl: 'Klok'
      ,zh_cn: '时钟'
      ,sv: 'Klocka'
       ,de: 'Uhr'
       ,dk: 'Klokken'
       ,fi: 'Kello'
       ,ro: 'Ceas'
       ,it: 'Orologio'
      ,bg: 'Часовник'
      ,hr: 'Sat'
      ,pl: 'Zegar'
      ,ru: 'часы'
      ,tr: 'Saat'
      ,he: 'שעון'
      ,hu: 'Óra:'
    },
    'Color': {
       cs: 'Barva'
       ,fr: 'Couleur'
       ,ko: '색상모드'
       ,nl: 'Kleur'
      ,zh_cn: '彩色'
      ,sv: 'Färg'
       ,de: 'Farbe'
       ,dk: 'Farve'
       ,fi: 'Väri'
       ,ro: 'Culoare'
       ,it: 'Colore'
      ,bg: 'Цвят'
      ,hr: 'Boja'
      ,pl: 'Kolor'
      ,ru: 'цвет'
      ,tr: 'Renk'
      ,he: 'צבע'
      ,hu: 'Szinek'
    },
    'Simple': {
       cs: 'Jednoduchý'
       ,fr: 'Simple'
       ,ko: '간편 모드'
       ,nl: 'Simpel'
       ,zh_cn: '简单'
       ,sv: 'Simpel'
       ,de: 'Einfach'
       ,dk: 'Simpel'
       ,fi: 'Yksinkertainen'
       ,ro: 'Simplu'
       ,it: 'Semplice'
       ,bg: 'Прост'
       ,hr: 'Jednostavan'
       ,pl: 'Prosty'
       ,ru: 'простой'
       ,tr: 'Basit'
       ,he: 'פשוט'
       ,hu: 'Csak cukor'
    },
    'TDD average': {
      cs: 'Průměrná denní dávka'
      , fi: 'Päivän kokonaisinsuliinin keskiarvo'
      , ko: 'TDD average'
      , nl: 'Gemiddelde dagelijkse insuline (TDD)'
      ,zh_cn: '日胰岛素用量平均值'
      , sv: 'Genomsnittlig daglig mängd insulin'
      , de: 'durchschnittliches Insulin pro Tag (TDD)'
      , dk: 'Gennemsnitlig daglig mængde insulin'
      , ro: 'Media Dozei Zilnice Totale de insulină (TDD)'
      , it: 'Totale Dose Giornaliera media (TDD)'
      , bg: 'Обща дневна доза средно'
      , hr: 'Srednji TDD'
      , pl: 'Średnia dawka dzienna'
      , ru: 'средняя суточная доза инсулина'
      , tr: 'Ortalama günlük Toplam Doz (TDD)'
      , hu: 'Átlagos napi adag (TDD)'
    },
    'Carbs average': {
      cs: 'Průměrné množství sacharidů'
      , fi: 'Hiilihydraatit keskiarvo'
      , ko: 'Carbs average'
      , nl: 'Gemiddelde koolhydraten per dag'
      ,zh_cn: '碳水化合物平均值'
      , sv: 'Genomsnittlig mängd kolhydrater per dag'
      , de: 'durchschnittliche Kohlenhydrate pro Tag'
      , dk: 'Gennemsnitlig mængde kulhydrater per dag'
      , ro: 'Media carbohidraților'
      , it: 'Media carboidrati'
      , bg: 'Въглехидрати средно'
      , hr: 'Prosjek UGH'
      , pl: 'Średnia ilość węglowodanów'
      , ru: 'среднее кол-во углеводов за сутки'
      , tr: 'Günde ortalama karbonhidrat'
      , he: 'פחמימות ממוצע'
      , hu: 'Szenhidrát átlag'
    },
    'Eating Soon': {
      cs: 'Blížící se jídlo'
      , fi: 'Ruokailu pian'
      , ko: 'Eating Soon'
      , nl: 'Pre-maaltijd modus'
      ,zh_cn: '过会吃饭'
      , sv: 'Äter snart'
      , de: 'Bald Essen'
      , dk: 'Spiser snart'
      , ro: 'Mâncare în curând'
      , it: 'Mangiare presto'
      , bg: 'Преди хранене'
      , hr: 'Uskoro obrok'
      , pl: 'Przed jedzeniem'
      , ru: 'Ожидаемый прием пищи'
      , tr: 'Yakında Yenecek'
      , he: 'אוכל בקרוב'
      , hu: 'Hamarosan evés'
    },
    'Last entry {0} minutes ago': {
      cs: 'Poslední hodnota {0} minut zpět'
      , fi: 'Edellinen verensokeri {0} minuuttia sitten'
      , ko: 'Last entry {0} minutes ago'
      , nl:  'Laatste waarde {0}  minuten geleden'
      ,zh_cn: '最后一个条目 {0} 分钟之前'
      , sv: 'Senaste värde {0} minuter sedan'
      , de: 'Letzter Eintrag vor {0} Minuten'
      , dk: 'Seneste værdi {0} minutter siden'
      , ro: 'Ultima înregistrare acum {0} minute'
      , it: 'Ultimo inserimento {0} minuti fa'
      , bg: 'Последен запис преди {0} минути'
      , hr: 'Posljednji zapis prije {0} minuta'
      , pl: 'Ostatni wpis przed {0} minutami'
      , ru: 'предыдущая запись {0} минут назад'
      , tr: 'Son giriş {0} dakika önce'
      , hu: 'Utolsó bejegyzés {0} volt'
    },
    'change': {
      cs: 'změna'
      , fi: 'muutos'
      , ko: 'change'
      , nl: 'wijziging'
      ,zh_cn: '改变'
      , sv: 'byta'
      , de: 'verändern'
      , dk: 'ændre'
      , ro: 'schimbare'
      , it: 'cambio'
      , bg: 'промяна'
      , hr: 'promjena'
      , pl: 'zmiana'
      , ru: 'замена'
      , tr: 'değişiklik'
      , he: 'שינוי'
      , hu: 'változás'
    },
    'Speech': {
      cs: 'Hlas'
      , fi: 'Puhe'
      , ko: 'Speech'
      , nl: 'Spraak'
      ,zh_cn: '朗读'
      , sv: 'Tal'
      , de: 'Sprache'
      , dk: 'Tale'
      , ro: 'Vorbă'
      , it: 'Voce'
      , bg: 'Глас'
      , hr: 'Govor'
      , pl: 'Głos'
      , ru: 'речь'
      , tr: 'Konuş'
      , he: 'דיבור'
      , hu: 'Beszéd'
    },
    'Target Top': {
      cs: 'Horní cíl'
      , dk: 'Højt mål'
      , fi: 'Tavoite ylä'
      , ko: 'Target Top'
      , nl: 'Hoog tijdelijk doel'
      , ro: 'Țintă superioară'
      , it: 'Limite superiore'
      ,zh_cn: '目标高值'
      , sv: 'Högt målvärde'
      , bg: 'Горна граница'
      , hr: 'Gornja granica'
      , pl: 'Górny limit'
      , ru: 'верхняя граница цели'
      , de: 'Oberes Ziel'
      , tr: 'Hedef Üst'
      , he: 'ראש היעד'
      , hu: 'Felsó cél'
    },
    'Target Bottom': {
      cs: 'Dolní cíl'
      , dk: 'Lavt mål'
      , fi: 'Tavoite ala'
      , ko: 'Target Bottom'
      , nl: 'Laag tijdelijk doel'
      ,zh_cn: '目标低值'
      , ro: 'Țintă inferioară'
      , it: 'Limite inferiore'
      , sv: 'Lågt målvärde'
      , bg: 'Долна граница'
      , hr: 'Donja granica'
      , pl: 'Dolny limit'
      , ru: 'нижняя граница цели'
      , de: 'Unteres Ziel'
      , tr: 'Hedef Alt'
      , he: 'תחתית היעד'
      , hu: 'Alsó cél'
    },
    'Canceled': {
      cs: 'Zrušený'
      , dk: 'Afbrudt'
      , fi: 'Peruutettu'
      , ko: 'Canceled'
      , nl: 'Geannuleerd'
      ,zh_cn: '被取消了'
      , ro: 'Anulat'
      , it: 'Cancellato'
      , sv: 'Avbruten'
      , bg: 'Отказан'
      , hr: 'Otkazano'
      , pl: 'Anulowane'
      , ru: 'отменено'
      , de: 'Abgebrochen'
      , tr: 'İptal edildi'
      , he: 'מבוטל'
      , hu: 'Megszüntetett'
    },
    'Meter BG': {
      cs: 'Hodnota z glukoměru'
      , dk: 'Blodsukkermåler BS'
      , fi: 'Mittarin VS'
      , ko: 'Meter BG'
      , nl: 'Bloedglucosemeter waarde'
      ,zh_cn: '指血血糖值'
      , ro: 'Glicemie din glucometru'
      , it: 'Glicemia Capillare'
      , sv: 'Blodsockermätare BG'
      , bg: 'Измерена КЗ'
      , hr: 'GUK iz krvi'
      , pl: 'Glikemia z krwi'
      , ru: 'ГК по глюкометру'
      , de: 'Wert Blutzuckermessgerät'
      , tr: 'Glikometre KŞ'
      , he: 'סוכר הדם של מד'
      , hu: 'Cukorszint a mérőből'
    },
    'predicted': {
      cs: 'přepověď'
      , dk: 'forudset'
      , fi: 'ennuste'
      , ko: 'predicted'
      , nl: 'verwachting'
      ,zh_cn: '预测'
      , ro: 'estimat'
      , it: 'predetto'
      , sv: 'prognos'
      ,bg: 'прогнозна'
      , hr: 'prognozirano'
      , pl: 'prognoza'
      , ru: 'прогноз'
      , de: 'vorhergesagt'
      , tr: 'tahmin'
      , he: 'חזה'
      , hu: 'előrejelzés'
    },
    'future': {
      cs: 'budoucnost'
      , dk: 'fremtidige'
      , fi: 'tulevaisuudessa'
      , ko: 'future'
      , nl: 'toekomstig'
      ,zh_cn: '将来'
      , ro: 'viitor'
      , it: 'futuro'
      , sv: 'framtida'
      , bg: 'бъдеще'
      , hr: 'budućnost'
      , pl: 'przyszłość'
      , ru: 'будущее'
      , de: 'Zukunft'
      , tr: 'gelecek'
      , he: 'עתיד'
      , hu: 'jövő'
    },
    'ago': {
      cs: 'zpět'
      , dk: 'siden'
      , fi: 'sitten'
      , ko: 'ago'
      , nl: 'geleden'
      ,zh_cn: '之前'
      , ro: 'în trecut'
      , sv: 'förfluten'
      , bg: 'преди'
      , hr: 'prije'
      , pl: 'temu'
      , ru: 'в прошлом'
      , de: 'vor'
      , tr: 'önce'
      , he: 'לפני'
      , hu: 'ezelött'
    },
    'Last data received': {
      cs: 'Poslední data přiajata'
      , dk: 'Sidste data modtaget'
      , fi: 'Tietoa vastaanotettu viimeksi'
      , ko: 'Last data received'
      , nl: 'Laatste gegevens ontvangen'
      ,zh_cn: '上次收到数据'
      , ro: 'Ultimele date primite'
      , it: 'Ultimo dato ricevuto'
      , sv: 'Data senast mottagen'
      , bg: 'Последни данни преди'
      , hr: 'Podaci posljednji puta primljeni'
      , pl: 'Ostatnie otrzymane dane'
      , ru: 'прошлые данные получены'
      , de: 'Zuletzt Daten empfangen'
      , tr: 'Son veri alındı'
      , he: 'הנתונים המקבל אחרונים'
      , hu: 'Utólsó adatok fogadva'
    },
    'Clock View': {
      cs: 'Hodiny'
      ,dk: 'Vis klokken'
      ,fi: 'Kellonäkymä'
      ,es: 'Vista del reloj'
      ,fr: 'Vue Horloge'
      ,ro: 'Vedere tip ceas'
      ,ru: 'вид циферблата'
      ,ko: 'Clock View'
      ,it: 'Vista orologio'
      ,sv: 'Visa klocka'
      ,bg: 'Изглед часовник'
      ,hr: 'Prikaz sata'
      ,nl: 'Klokweergave'
      ,zh_cn: '时钟视图'
      ,de: 'Uhr-Anzeigen'
      ,pl: 'Widok zegara'
      ,tr: 'Saat Görünümü'
      ,he: 'צג השעון'
      ,hu: 'Idő'
    },
    'Protein': {
      fi: 'Proteiini'
      ,de: 'Protein'
      ,tr: 'Protein'
      ,hr: 'Proteini'
      , pl: 'Białko'
      ,ru: 'Белки'
      ,he: 'חלבון'
      ,nl: 'Eiwit'
      ,hu: 'Protein'
    },
    'Fat': {
      fi: 'Rasva'
      ,de: 'Fett'
      ,tr: 'Yağ'
      ,hr: 'Masti'
      , pl: 'Tłuszcz'
      ,ru: 'Жиры'
      ,he: 'שמן'
      ,nl: 'Vet'
      ,hu: 'Zsír'
    },
    'Protein average': {
      fi: 'Proteiini keskiarvo'
      ,de: 'Proteine Durchschnitt'
      ,tr: 'Protein Ortalaması'
      ,hr: 'Prosjek proteina'
      , pl: 'Średnia białka'
      ,ru: 'Средний белок'
      ,he: 'חלבון ממוצע'
      ,nl: 'eiwitgemiddelde'
      ,hu: 'Protein átlag'
    },
    'Fat average': {
      fi: 'Rasva keskiarvo'
      ,de: 'Fett Durchschnitt'
      ,tr: 'Yağ Ortalaması'
      ,hr: 'Prosjek masti'
      , pl: 'Średnia tłuszczu'
      ,ru: 'Средний жир'
      ,he: 'שמן ממוצע'
      ,nl: 'Vetgemiddelde'
      ,hu: 'Zsír átlag'
    },
    'Total carbs': {
      fi: 'Hiilihydraatit yhteensä'
      , de: 'Kohlenhydrate gesamt'
      ,tr: 'Toplam Karbonhidrat'
      ,hr: 'Ukupno ugh'
      , pl: 'Węglowodany ogółem'
      ,ru: 'Всего углеводов'
      ,he: 'כל פחמימות'
      ,nl: 'Totaal koolhydraten'
      ,hu: 'Összes szénhidrát'
    },
    'Total protein': {
      fi: 'Proteiini yhteensä'
      , de: 'Protein gesamt'
      ,tr: 'Toplam Protein'
      ,hr: 'Ukupno proteini'
      , pl: 'Białko ogółem'
      ,ru: 'Всего белков'
      ,he: 'כל חלבונים'
      ,nl: 'Totaal eiwitten'
      ,hu: 'Összes protein'
    },
    'Total fat': {
      fi: 'Rasva yhteensä'
      , de: 'Fett gesamt'
      ,tr: 'Toplam Yağ'
      ,hr: 'Ukupno masti'
      , pl: 'Tłuszcz ogółem'
      ,ru: 'Всего жиров'
      ,he: 'כל שומנים'
      ,nl: 'Totaal vetten'
      ,hu: 'Összes zsír'
    },
    'Database Size': {
      pl: 'Rozmiar Bazy Danych'
      ,nl: 'Grootte database'
      ,de: 'Datenbankgröße'
      ,hu: 'Adatbázis mérete'
    },
    'Database Size near its limits!': {
      pl: 'Rozmiar bazy danych zbliża się do limitu!'
      ,nl: 'Database grootte nadert limiet!'
      ,de: 'Datenbank fast voll!'
      ,hu: 'Az adatbázis majdnem megtelt!'
    },
    'Database size is %1 MiB out of %2 MiB. Please backup and clean up database!': {
      pl: 'Baza danych zajmuje %1 MiB z dozwolonych %2 MiB. Proszę zrób kopię zapasową i oczyść bazę danych!'
      ,nl: 'Database grootte is %1 MiB van de %2 MiB. Maak een backup en verwijder oude data'
      ,de: 'Die Datenbankgröße beträgt %1 MiB von %2 MiB. Bitte sichere deine Daten und bereinige die Datenbank!'
      ,hu: 'Az adatbázis mérete %1 MiB a rendelkezésre álló %2 MiB-ból. Készítsen biztonsági másolatot!'
    },
    'Database file size': {
      pl: 'Rozmiar pliku bazy danych'
      ,nl: 'Database bestandsgrootte'
      ,de: 'Datenbank-Dateigröße'
      ,hu: 'Adatbázis file mérete'
    },
    '%1 MiB of %2 MiB (%3%)': {
      pl: '%1 MiB z %2 MiB (%3%)'
      ,nl: '%1 MiB van de %2 MiB (%3%)'
      ,de: '%1 MiB von %2 MiB (%3%)'
      ,hu: '%1 MiB %2 MiB-ból (%3%)'
    },
    'Data size': {
      pl: 'Rozmiar danych'
      ,nl: 'Datagrootte'
      ,de: 'Datengröße'
      ,hu: 'Adatok mérete'
    },
    'virtAsstDatabaseSize': {
      en: '%1 MiB. That is %2% of available database space.'
      ,pl: '%1 MiB co stanowi %2% przestrzeni dostępnej dla bazy danych'
      ,nl: '%1 MiB dat is %2% van de beschikbaare database ruimte'
      ,de: '%1 MiB. Das sind %2% des verfügbaren Datenbank-Speicherplatzes.'
      ,hu: '%1 MiB ami %2% a rendelkezésre álló méretből'
    },
    'virtAsstTitleDatabaseSize': {
      en: 'Database file size'
      ,pl: 'Rozmiar pliku bazy danych'
      ,nl: 'Database bestandsgrootte'
      ,de: 'Datenbank-Dateigröße'
      ,hu: 'Adatbázis file méret'
    }
  };

  language.translations = translations;


  // case sensitive
  language.translateCS = function translateCaseSensitive(text) {
    if (translations[text] && translations[text][language.lang]) {
      return translations[text][language.lang];
    }
    return text;
  };

  // case insensitive
  language.translateCI = function translateCaseInsensitive(text) {
    var utext = text.toUpperCase();
    _.forEach(translations, function (ts, key) {
      var ukey = key.toUpperCase();
      if (ukey === utext && ts[language.lang]) {
        text = ts[language.lang];
      }
    });
    return text;
  };

  language.translate = function translate(text, options) {
    var translated;
    if (options && options.ci) {
      translated = language.translateCI(text);
    } else {
      translated = language.translateCS(text);
    }
    if (options && options.params) {
      for (var i = 0; i < options.params.length; i++) {
        // eslint-disable-next-line no-useless-escape
        var r = new RegExp('\%' + (i+1), 'g');
        translated = translated.replace(r, options.params[i]);
      }
    }
    return translated;
  };

  language.DOMtranslate = function DOMtranslate($) {
    // do translation of static text on load
    $('.translate').each(function () {
      $(this).text(language.translate($(this).text()));
      });
    $('.titletranslate, .tip').each(function () {
      $(this).attr('title',language.translate($(this).attr('title')));
      $(this).attr('original-title',language.translate($(this).attr('original-title')));
      $(this).attr('placeholder',language.translate($(this).attr('placeholder')));
      });
  };

  language.set = function set(newlang) {
    language.lang = newlang;

    language.languages.forEach(function (l) {
      if (l.code === language.lang && l.speechCode) language.speechCode = l.speechCode;
    });

    return language();
  };

  return language();
}

module.exports = init;
